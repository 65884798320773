import React, {Component} from "react";
import PropTypes from "prop-types";

import {connect} from "react-redux";
import {withStyles} from "@material-ui/core/styles";
import {Dialog, DialogContent, DialogTitle, IconButton, Typography} from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close';
import Registration from "../../registration";


const styles = theme => ({
  dialog: {},
  closeButton: {
    position: 'absolute',
    right: theme.spacing.unit,
    top: theme.spacing.unit,
  },
})

class EditRegFormDlg extends Component {

  handleClose = () => {
    if (typeof this.props.onToggle !== 'function') return;
    this.props.onToggle();
  }

  render() {
    const {
      classes,
      isOpened
    } = this.props
    const selectedSchool = this.props.user.selectedSchool
    const profile = this.props.user.schools[selectedSchool]
    const parentDilerId = profile.parentDilerId
    const dilerId = profile.dilerId
    const regionId = profile.regionId

    return (
      <Dialog
        open={!!isOpened}
        fullWidth
        maxWidth="lg"
        onClose={this.handleClose}
      >
        <DialogTitle disableTypography
          onClose={this.handleClose}>
          <Typography variant="h6">Редактирование Регистрационной формы-заявки</Typography>
          <IconButton aria-label="close" className={classes.closeButton} onClick={this.handleClose}>
            <CloseIcon/>
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Registration
            parentId={isOpened}
            regionId={regionId}
            dilerId={dilerId}
            parentDilerIds={parentDilerId}
            onToggle={this.handleClose}
          />
        </DialogContent>
      </Dialog>
    )
  }
}

const mapStateToProps = state => ({
  user: state.user
});

const mapDispatchToProps = {};

EditRegFormDlg.propTypes = {
  isOpened: PropTypes.number.isRequired,
  onToggle: PropTypes.func.isRequired
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(EditRegFormDlg));