import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

import withStyles from "@material-ui/core/styles/withStyles";

const styles = theme => ({
  modal: {
    [theme.breakpoints.down("xs")]: {
      alignItems: "flex-start"
    }
  },
  paper: {
    margin: 10
  },
  form: {
    width: "100%"
  }
});

const Filter = props => {
  const { classes, filter, filterIsOpen, pupil, event, pupils, eventTypes } = props;
  return (
    <Dialog
      open={filterIsOpen}
      onClose={() => filter.close()}
      maxWidth="xs"
      classes={{ root: classes.modal, paper: classes.paper }}
    >
      <DialogTitle>Фильтр сообщений</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Применив фильтр вы можете посмотреть только интересующие вас сообщения.
        </DialogContentText>
        <br />
        <br />
        <FormControl className={classes.form}>
          <InputLabel focused={false}>Ученик</InputLabel>
          <Select
            className={classes.input}
            disableUnderline
            value={pupil}
            onChange={e => filter.pupilSelect(e.target.value)}
          >
            <MenuItem value={-1}>
              <span className={classes.pupilName}>Фильтр по ученику не выбран</span>
            </MenuItem>
            {pupils.ids.map(pupilId => (
              <MenuItem key={pupilId} value={pupilId}>
                <span className={classes.pupilName}>
                  {pupils[pupilId].im} {pupils[pupilId].className}
                </span>
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl className={classes.form}>
          <InputLabel focused={false}>Тип сообщения</InputLabel>
          <Select
            className={classes.input}
            disableUnderline
            value={event}
            onChange={e => filter.eventSelect(e.target.value)}
          >
            <MenuItem value={-1}>
              <span className={classes.pupilName}>Фильтр по сообщениям не выбран</span>
            </MenuItem>
            {eventTypes.map((eventType, index) => (
              <MenuItem key={eventType.id} value={eventType.id}>
                <span className={classes.pupilName}>{eventType.name}</span>
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => filter.clear()} color="primary">
          Очистить
        </Button>
        <Button onClick={() => filter.close()} color="primary">
          ОК
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default withStyles(styles)(Filter);
