import React from "react";
import { DatePicker } from "material-ui-pickers";
import MomentUtils from "@date-io/moment";
import { MuiPickersUtilsProvider } from "material-ui-pickers";

import Hidden from "@material-ui/core/Hidden";

import { withStyles } from "@material-ui/core/styles";

const styles = theme => ({
  selecPeriodContainer: {
    paddingLeft: 5
  },
  picker: {
    marginTop: 5,
    width: 100,
    marginRight: 10,
    [theme.breakpoints.down("xs")]: {
      width: 70
    }
  }
});

const SelectPeriod = props => {
  const { classes, startDate, endDate, changeDate } = props;
  return (
    <div className={classes.selecPeriodContainer}>
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <Hidden smUp>
          <DatePicker
            className={classes.picker}
            label="Дата с"
            format="DD.MM.YY"
            showTodayButton
            autoOk
            disableFuture
            maxDateMessage="Дата не должна быть больше текущей"
            clearLabel="Очистить"
            cancelLabel="Отмена"
            todayLabel="Сегодня"
            value={startDate}
            onChange={date => changeDate("startDate", date)}
          />
          <DatePicker
            className={classes.picker}
            label="Дата по"
            format="DD.MM.YY"
            showTodayButton
            autoOk
            disableFuture
            maxDateMessage="Дата не должна быть больше текущей"
            clearLabel="Очистить"
            cancelLabel="Отмена"
            todayLabel="Сегодня"
            value={endDate}
            onChange={date => changeDate("endDate", date)}
          />
        </Hidden>
        <Hidden xsDown>
          <DatePicker
            className={classes.picker}
            label="Дата с"
            format="DD MMMM"
            showTodayButton
            autoOk
            disableFuture
            maxDateMessage="Дата не должна быть больше текущей"
            clearLabel="Очистить"
            cancelLabel="Отмена"
            todayLabel="Сегодня"
            value={startDate}
            onChange={date => changeDate("startDate", date)}
          />
          <DatePicker
            className={classes.picker}
            label="Дата по"
            format="DD MMMM"
            showTodayButton
            autoOk
            disableFuture
            maxDateMessage="Дата не должна быть больше текущей"
            clearLabel="Очистить"
            cancelLabel="Отмена"
            todayLabel="Сегодня"
            value={endDate}
            onChange={date => changeDate("endDate", date)}
          />
        </Hidden>
      </MuiPickersUtilsProvider>
    </div>
  );
};

export default withStyles(styles)(SelectPeriod);
