import React from "react";

import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepButton from "@material-ui/core/StepButton";
import StepContent from "@material-ui/core/StepContent";
import Typography from "@material-ui/core/Typography";

import Step1 from "./Step1";
import Step2 from "./Step2";
import Step3 from "./Step3";
import Step4 from "./Step4";

import { withStyles } from "@material-ui/core/styles";

const styles = theme => ({
  container: {
    width: "calc(100% - 10px)",
    display: "flex",
    flexDirection: "column",
    padding: 5
  },
  paysContainer: {
    display: "flex",
    flexDirection: "row",
    padding: "5px 0",
    overflow: "auto",
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column"
    }
  },
  stepper: {
    width: "calc(100% - 270px)",
    height: "100%",
    [theme.breakpoints.down("xs")]: {
      padding: "10px 5px",
      width: "calc(100% - 10px)",
      overflow: "hidden",
      order: 2
    }
  },
  bottomText: {
    paddingLeft: 5
  }
});

const Transfer = props => {
  const {
    classes,
    step,
    payTypeFrom,
    payTypeTo,
    profile,
    pupils,
    isFoodActive,
    errorOnValidateAmount,
    transfers: {
      setAmount,
      submitTransfer,
      onEnterPress,
      selectPayTypeFrom,
      selectPayTypeTo,
      changeStep
    },
    amount
  } = props;
  return (
    <div className={classes.container}>
      {profile.balances.ids.filter(id => profile.balances[id].balance > 0).length !== 0 ||
      pupils.ids.filter(pupilId => pupils[pupilId].foodBalance > 0).length !== 0 ? (
        <>
          <Typography variant="subtitle1" align="center">
            Перевод между услугами
          </Typography>
          <div className={classes.transfersContainer}>
            <Stepper className={classes.stepper} activeStep={step} orientation="vertical">
              <Step>
                <StepButton onClick={e => changeStep("none", 0)}>
                  {payTypeFrom ? "Перевод с услуги: " + payTypeFrom.name : "Перевод с услуги:"}
                </StepButton>
                <StepContent>
                  <Step1
                    {...{
                      profile,
                      payTypeFrom,
                      selectPayTypeFrom,
                      changeStep,
                      step,
                      isFoodActive,
                      pupils
                    }}
                  />
                </StepContent>
              </Step>
              <Step>
                <StepButton onClick={e => changeStep("none", 1)}>
                  {payTypeTo ? "Перевод на услугу: " + payTypeTo.name : "Перевод на услугу:"}
                </StepButton>
                <StepContent>
                  <Step2
                    {...{
                      profile,
                      payTypeFrom,
                      payTypeTo,
                      selectPayTypeTo,
                      changeStep,
                      step,
                      isFoodActive,
                      pupils
                    }}
                  />
                </StepContent>
              </Step>
              <Step>
                <StepButton onClick={e => changeStep("none", 1)}>
                  {step === 3
                    ? "Сумма зачисления: " + amount + " руб."
                    : "Введите сумму зачисления"}
                </StepButton>
                <StepContent>
                  <Step3
                    {...{
                      amount,
                      setAmount,
                      payTypeFrom,
                      changeStep,
                      step,
                      errorOnValidateAmount,
                      onEnterPress
                    }}
                  />
                </StepContent>
              </Step>
              <Step>
                <StepButton>Подтверждение перевода</StepButton>
                <StepContent>
                  <Step4
                    {...{ payTypeFrom, payTypeTo, step, changeStep, amount, submitTransfer }}
                  />
                </StepContent>
              </Step>
            </Stepper>
            {profile.dilerId !== 7 || profile.dilerId !== 26 ? (
              <Typography variant="caption">
                В связи с требованиями законодательства РФ в сфере финансовой деятельности, переводы
                с услуг на питание и обратно осуществляются только по письменному заявлению.
                Получить заявление Вы можете обратившись по телефону контакт-центра или по почте support@ueshka.ru.
              </Typography>
            ) : null}
          </div>
        </>
      ) : (
        <Typography variant="h6" align="center">
          Отсутствует положительный баланс по услугам, перевод невозможен
        </Typography>
      )}
    </div>
  );
};

export default withStyles(styles)(Transfer);
