import React from "react";
import PropTypes from "prop-types";
import Input from "@material-ui/core/Input";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";

const PromoCode = props => {
  const {setPromoCode, promoCode, changeStep} = props;
  return (
    <>
      <FormControl >
        <InputLabel htmlFor="promoCode">Промокод</InputLabel>
        <Input
          id="promoCode"
          onKeyDown={e => {
            if (
              e.keyCode === 13
            ) {
              changeStep('forward');
            }
          }}
          onChange={e => setPromoCode(e.target.value)}
          value={promoCode}
        />
      </FormControl>
      <br/>
      <br/>
    </>
  );
};

PromoCode.propTypes = {
  promoCode: PropTypes.string,
  setPromoCode: PropTypes.func.isRequired
};

export default PromoCode;
