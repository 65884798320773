import React, { Component } from "react";

import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

import withStyles from "@material-ui/core/styles/withStyles";
import { Typography } from "@material-ui/core";

const styles = theme => ({
  modal: {
    [theme.breakpoints.down("xs")]: {
      alignItems: "flex-start"
    }
  },
  paper: {
    margin: 10
  }
});

class ChangePassword extends Component {
  state = { password: "", password2: "", error: "" };

  submitButtonClick = () => {
    if (this.state.password !== this.state.password2) {
      this.setState({ error: "Введенные пароли не совпадают" });
      return;
    }
    this.props.changePasswordSubmit(this.state.password);
    this.props.changePasswordToggle();
    this.setState({ password: "", password2: "", error: "" });
  };

  passwordOnChange = name => event => {
    this.setState({
      [name]: event.target.value,
      error: ""
    });
  };

  onClose = () => {
    this.props.changePasswordToggle();
    this.setState({ password: "", password2: "", error: "" });
  };

  render() {
    const { classes } = this.props;
    return (
      <Dialog
        open={this.props.changePasswordIsOpen}
        onClose={this.onClose}
        maxWidth="xs"
        classes={{ root: classes.modal, paper: classes.paper }}
      >
        <DialogTitle>Изменение пароля</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Пароль должен быть длиной не менее 6 символов и состоять из прописных и строчных букв
            латинского алфавита или цифр.
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            name="password"
            label="Новый пароль"
            type="password"
            fullWidth
            value={this.state.password}
            onChange={this.passwordOnChange("password")}
          />
          <TextField
            margin="dense"
            name="password2"
            label="Подтверждение пароля"
            type="password"
            fullWidth
            value={this.state.password2}
            onChange={this.passwordOnChange("password2")}
          />
          {this.state.error ? <Typography color="error">{this.state.error}</Typography> : null}
        </DialogContent>
        <DialogActions>
          <Button onClick={this.onClose} color="primary">
            Отмена
          </Button>
          <Button onClick={this.submitButtonClick} color="primary">
            Изменить
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default withStyles(styles)(ChangePassword);
