import React from "react";
import { Placemark, Clusterer } from "react-yandex-maps";
import moment from "moment";

import PropTypes from "prop-types";

const HistoryCoordinatesPlacemarks = props => {
  const { history, ymaps } = props;

  if (ymaps && history && history.length > 0) {
    // меняем стандартный шаблон при открытии балуна на кластере и нажатии на точку
    let customBalloonContentLayout = ymaps.templateLayoutFactory.createClass(
      ["<span style='height: 0px; hidden: true'>", "</span>"].join("")
    );

    return (
      <>
        {history &&
          history.map((point, index) => {
            if (index === 0 || index === history.length - 1) {
              return (
                <Placemark
                  key={index}
                  geometry={[point.latitude, point.longitude]}
                  properties={{
                    iconContent: index + 1,
                    balloonContentHeader: `Точка  ${index + 1} - ${moment(
                      point.day,
                      "YYYY-MM-DD"
                    ).format("DD.MM.YYYY")} ${point.time}`
                  }}
                  options={{
                    preset: "islands#circleIcon",
                    iconColor:
                      index === 0 ? "#07fd11" : index === history.length - 1 ? "#ff0000" : "#4fc3f7"
                  }}
                />
              );
            } else return null;
          })}
        <Clusterer
          options={{
            preset: "islands#invertedBlueClusterIcons",
            groupByCoordinates: false,
            hasHint: true,
            clusterOpenBalloonOnClick: true,
            clusterHideIconOnBalloonOpen: false,
            clusterOpenHintOnHover: true,
            clusterIconContentLayout: null,
            clusterBalloonContentLayout: "cluster#balloonAccordion",
            clusterBalloonItemContentLayout: customBalloonContentLayout,
            clusterBalloonContentLayoutWidth: 240,
            clusterBalloonContentLayoutHeight: 250,
            clusterZoomMargin: 20
          }}
        >
          {history &&
            history.map((point, index) => {
              if (index !== 0 || index !== history.length - 1) {
                return (
                  <Placemark
                    key={index}
                    geometry={[point.latitude, point.longitude]}
                    properties={{
                      iconContent: index + 1,
                      balloonContentHeader: `Точка  ${index + 1} - ${moment(
                        point.day,
                        "YYYY-MM-DD"
                      ).format("DD.MM.YYYY")} ${point.time}`
                    }}
                    options={{
                      preset: "islands#circleIcon",
                      iconColor:
                        index === 0
                          ? "#07fd11"
                          : index === history.length - 1
                          ? "#ff0000"
                          : "#4fc3f7"
                    }}
                  />
                );
              } else return null;
            })}
        </Clusterer>
      </>
    );
  } else return null;
};

export default HistoryCoordinatesPlacemarks;

HistoryCoordinatesPlacemarks.propTypes = {
  history: PropTypes.arrayOf(
    PropTypes.shape({
      latitude: PropTypes.number.isRequired,
      longitude: PropTypes.number.isRequired,
      day: PropTypes.string.isRequired,
      time: PropTypes.string.isRequired
    })
  )
};
