import React from "react";
import PropTypes from "prop-types";
import Clear from "@material-ui/icons/Clear";
import Layers from "@material-ui/icons/Layers";
import LayersClear from "@material-ui/icons/LayersClear";
import Create from "@material-ui/icons/Create";

import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import Hidden from "@material-ui/core/Hidden";
import withStyles from "@material-ui/core/styles/withStyles";
import panelsStyles from "../styles";

const styles = theme => ({
  ...panelsStyles(theme)
});

const ZoneItem = props => {
  const {
    classes,
    zone,
    changeZoneStatus,
    deleteZone,
    selectZone,
    editZoneNameOpen,
    selectedZone
  } = props;
  return (
    <div
      key={zone.id}
      className={classes.item}
      onClick={e => {
        e.stopPropagation();
      }}
    >
      <Typography
        color="inherit"
        variant="body1"
        className={classes.itemName}
        onClick={selectZone(zone.id, zone.points, "withFocus")}
      >
        {zone.name}
      </Typography>
      <div className={classes.iconsRow}>
        {/* Показываем кнопку редактирования только на выделенной зоне в мобильной версии*/}
        {selectedZone ? (
          <Tooltip title="Изменить имя">
            <IconButton onClick={editZoneNameOpen(zone.id, zone.points, zone.name)}>
              <Create fontSize="large" />
            </IconButton>
          </Tooltip>
        ) : (
          <Hidden xsDown>
            <Tooltip title="Изменить имя">
              <IconButton onClick={editZoneNameOpen(zone.id, zone.points, zone.name)}>
                <Create fontSize="large" />
              </IconButton>
            </Tooltip>
          </Hidden>
        )}

        {zone.state === 1 ? (
          <Tooltip title="Отключить зону">
            <IconButton onClick={changeZoneStatus(zone.id, +!zone.state)}>
              <Layers fontSize="large" color="secondary" />
            </IconButton>
          </Tooltip>
        ) : (
          <Tooltip title="Включить зону">
            <IconButton onClick={changeZoneStatus(zone.id, +!zone.state)}>
              <LayersClear fontSize="large" color="error" />
            </IconButton>
          </Tooltip>
        )}
        {/* Показываем кнопку удаления только на выделенной зоне в мобильной версии*/}
        {selectedZone ? (
          <Tooltip title="Удалить зону">
            <IconButton onClick={deleteZone(zone.id)}>
              <Clear color="error" fontSize="large" />
            </IconButton>
          </Tooltip>
        ) : (
          <Hidden xsDown>
            <Tooltip title="Удалить зону">
              <IconButton onClick={deleteZone(zone.id)}>
                <Clear color="error" fontSize="large" />
              </IconButton>
            </Tooltip>
          </Hidden>
        )}
      </div>
    </div>
  );
};

export default withStyles(styles)(ZoneItem);

ZoneItem.propTypes = {
  classes: PropTypes.object,
  zone: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    state: PropTypes.number.isRequired,
    points: PropTypes.array.isRequired
  }),
  changeZoneStatus: PropTypes.func.isRequired,
  deleteZone: PropTypes.func.isRequired,
  selectZone: PropTypes.func.isRequired
};
