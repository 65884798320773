import React, {Component} from "react";

import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";

import withStyles from "@material-ui/core/styles/withStyles";

const styles = theme => ({
  container: {
    display: "flex",
    flexDirection: "column",
    height: "95%"
  },
  modal: {
    [theme.breakpoints.down("xs")]: {
      alignItems: "flex-start"
    }
  },
  paper: {
    maxHeight: 800,
    margin: 10,
    [theme.breakpoints.down("sm")]: {
      minHeight: 450,
      maxHeight: 600,
    }
  },
  iframe: {
    height: '60vh',
    display: 'block'
  }
});

class ReferralPay extends Component {

  onClose = () => {
    this.props.clearReferralPayUrl();
  };

  render() {
    const {classes, referralPayUrl} = this.props;
    return (
      <Dialog
        open={!!referralPayUrl}
        maxWidth="md"
        fullWidth
        fullHeight
        onClose={this.onClose}
        classes={{root: classes.modal, paper: classes.paper}}
      >
        <DialogTitle>Окно оплаты</DialogTitle>
        <DialogContent className={classes.container}>
          <iframe title="Оплата услуг УЭШКа" className={classes.iframe} src={referralPayUrl} height="100%"  />
        </DialogContent>
        <DialogActions>
          <Button onClick={this.onClose} color="primary">
            Закрыть окно
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default withStyles(styles)(ReferralPay);
