import React, {Component} from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";

import withStyles from "@material-ui/core/styles/withStyles";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography
} from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close';

import {Formik} from "formik";

import {
  getPupilsSelector,
  getAltCardTypesSelector,
  getAltCardKindsSelector,
  getAltCardColorsSelector
} from "selectors/shop";

import AltCardData from "./AltCardData";

const mapStateToProps = state => ({
  user: state.user,
  shop: state.shop,
  pupils: getPupilsSelector(state),
  altCardTypes: getAltCardTypesSelector(state),
  altCardKinds: getAltCardKindsSelector(state),
  altCardColors: getAltCardColorsSelector(state),
});

const mapDispatchToProps = {};

const styles = theme => ({
  dialog: {},
  closeButton: {
    position: 'absolute',
    right: theme.spacing.unit,
    top: theme.spacing.unit,
  },
})

class AltCardProperties extends Component {
  state = {}

  componentDidMount() {
    const { altCardTypeId } = this.props;
    if (!!altCardTypeId) this.props.getShopAltCardsKinds(altCardTypeId);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.altCardTypeId !== this.props.altCardTypeId){
      const { altCardTypeId } = this.props;
      if (!!altCardTypeId) this.props.getShopAltCardsKinds(altCardTypeId);
    }

  }

  handleSubmit = (values) => {
    this.props.setShopAltCardAddOrder(values.pupilId, values.altCardTypeId, values.altCardKindId, values.altCardColorId);
    this.handleClose();
    // this.props.onToggle();
  }

  handleClose = () => {
    this.props.clearShopAltCard();
    this.props.onToggle();
  }

  onInputChange = (id, value) => {

  };

  render() {
    const {classes, pupils, orderId, altCardTypeId, altCardTypes, altCardKinds, altCardColors} = this.props;
    const altCardType = (!!altCardTypes ? altCardTypes[altCardTypeId] : undefined) || {name: ''};
    const {altCardSetPriceCent} = this.props.shop;

    const initOrder = {
      pupilId: null,
      altCardTypeId: altCardTypeId,
      altCardKindId: altCardKinds.length === 1 ? altCardKinds[0].value : null,
      altCardColorId: altCardColors.length === 1 ? altCardColors[0].value : null,
    }
    return (
      <Dialog open={Number(altCardTypeId) >= 0}
              fullWidth
              maxWidth="xs"
              onClose={this.handleClose}>
        <DialogTitle
          onClose={this.handleClose}>
          <Typography>{(orderId > 0) ? `Заказ №${orderId}` : 'Новый заказ'}</Typography>
          <IconButton aria-label="close" className={classes.closeButton} onClick={this.handleClose}>
            <CloseIcon/>
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Formik
            onSubmit={(values, {setSubmitting, resetForm}) => {
              this.handleSubmit(values);
              resetForm(initOrder);
            }}

            initialValues={{...initOrder}}>
            {val => {
              const {values, handleChange, setFieldValue, handleSubmit} = val;
              return (
                <form onSubmit={handleSubmit} >
                  <AltCardData
                    {...{values, handleChange, setFieldValue, handleSubmit, altCardType, altCardKinds, altCardColors, altCardSetPriceCent, pupils}}
                    getShopAltCardsColors={this.props.getShopAltCardsColors}
                    getShopAltCardSetPrice={this.props.getShopAltCardSetPrice}
                  />
                </form>
              )
            }}
          </Formik>
        </DialogContent>
      </Dialog>
    )
  }
}

AltCardProperties.propTypes = {
  altCardTypeId: PropTypes.number,
  onToggle: PropTypes.func.isRequired,
  getShopAltCardsKinds: PropTypes.func.isRequired,
  getShopAltCardsColors: PropTypes.func.isRequired,
  getShopAltCardSetPrice: PropTypes.func.isRequired,
  setShopAltCardAddOrder: PropTypes.func.isRequired,
  clearShopAltCard: PropTypes.func.isRequired,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(AltCardProperties));
