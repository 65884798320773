import React, { Component } from "react";
import { YMaps, Map, ZoomControl } from "react-yandex-maps";
import withStyles from "@material-ui/core/styles/withStyles";

const styles = theme => ({
  container: {
    height: "calc(100% - 60px)"
  }
});

class MapView extends Component {
  render() {
    const {
      classes,
      mapCenter,
      mapZoom,
      changeMapCenter,
      schoolName,
      addZoneCoordinates,
      setYmaps,
      setMapRef
    } = this.props;

    const getAddress = ymaps => {
      // TODO: убрать как будет выводиться адрес школы в профиле
      ymaps
        .geocode(schoolName.split("-")[0] !== schoolName ? schoolName.split("-")[0] : "г.Орел")
        .then(result => {
          changeMapCenter(result.geoObjects.get(0).geometry.getCoordinates());
        });
    };

    return (
      <div className={classes.container}>
        <YMaps
          query={{
            ns: "use-load-option",
            apikey: "2342fe29-6463-4bfd-bbda-65cb391fa758",
            // apikey: "ef54330f-610b-4992-8b99-b33288ac14a6",
            load: "package.full"
            // `Map,
            // Placemark,
            // control.ZoomControl,
            // control.TypeSelector,
            // geoObject.addon.balloon,
            // geocode,
            // Polygon,
            // Clusterer,
            // map.addon.balloon,
            // clusterer.addon.balloon,
            // layout.templateBased.Base`
          }}
        >
          <Map
            onLoad={ymaps => {
              setYmaps(ymaps);
              getAddress(ymaps);
            }}
            instanceRef={ref => setMapRef(ref)}
            state={{
              center: mapCenter.length === 0 ? [55.75, 37.57] : mapCenter,
              zoom: mapZoom,
              controls: ["typeSelector"]
            }}
            width="100%"
            height="100%"
            defaultOptions={{ suppressMapOpenBlock: true }}
            onClick={addZoneCoordinates}
          >
            <ZoomControl
              options={{
                position: {
                  top: 70,
                  left: 10
                }
              }}
            />
            {this.props.children}
          </Map>
        </YMaps>
      </div>
    );
  }
}

export default withStyles(styles)(MapView);
