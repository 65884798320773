import React, {Component} from "react";
import {Route, Switch, Redirect} from "react-router-dom";
import {connect} from "react-redux";
import PropTypes from "prop-types";
import {withStyles} from "@material-ui/core/styles";

import Header from "components/header";
import Menu, {menuList} from "components/menu";
import Footer from "components/footer";

import ErrorMessage from "components/messages/ErrorMessage";
import InfoMessage from "components/messages/InfoMessage";
import ErrorBoundary from "components/ErrorBoundary";

import LoadingMessage from "components/messages/LoadingMessage";

import {getProfile} from "actions/userActions";

const mapStateToProps = state => ({
  user: state.user,
  loading: state.loading,
  errors: state.errors
});

const mapDispatchToProps = {
  getProfile
};

const styles = theme => ({
  root: {
    maxWidth: 1300,
    height: "100%",
    position: "relative",
    display: "flex",
    flexDirection: "column",
    margin: "0 auto",
    background: "white",
    borderRight: "1px solid rgba(0, 0, 0, 0.12)",
    borderLeft: "1px solid rgba(0, 0, 0, 0.12)"
  },
  centerRow: {
    display: "flex",
    flexDirection: "row",
    height: "calc(100% - 160px)",
    flexGrow: 1,
    marginTop: 65,
    [theme.breakpoints.down("xs")]: {
      marginTop: 50
    }
  },
  content: {
    flexGrow: 1,
    position: "relative",
    width: "calc(100% - 251px)",
    height: "100%",
    overflow: "auto"
  }
});

class Home extends Component {
  componentDidMount() {
    if (!this.props.user.schools) {
      this.props.getProfile(this.props.user.selectedSchool);
    }
    const referral = this.props.location.search.match(/referral=(\d+)/);
    if (referral) {
      localStorage.setItem("referral", referral[1]);
    }
  }

  componentDidUpdate(prevProps) {
    // если смена логина или обновление страницы - меняем цветовую схему
    if (
      (prevProps.location.pathname === "/" && this.props.location.pathname !== "/") ||
      (!prevProps.user.selectedSchool && this.props.user.selectedSchool)
    ) {
      const {schools, selectedSchool} = this.props.user;
      const dilerId = schools[selectedSchool].dilerId;
      this.props.changeTheme(dilerId);
    }
  }

  render() {
    const {classes} = this.props;
    const {error, errorStatus} = this.props.errors;
    const {auth, schools, selectedSchool} = this.props.user;

    if (!this.props.user.schools && !error) {
      return <LoadingMessage/>;
    }
    if ((error && errorStatus === 401) || !auth) {
      return <Redirect to="/login"/>;
    }
    const parentDilerId = schools[selectedSchool].parentDilerId;
    return (
      <>
        <ErrorMessage/>
        <InfoMessage/>
        <div className={classes.root}>
          <Header profile={schools[selectedSchool]}/>
          <div className={classes.centerRow}>
            <Menu locationPath={this.props.location.pathname}/>
            <main className={classes.content}>
              <Switch>
                <Route
                  exact
                  path="/"
                  render={() => (
                    <Redirect
                      from="/"
                      to={{
                        pathname: "/profile",
                        state: {title: "Профиль"}
                      }}
                    />
                  )}
                />
                {menuList.map(item => {
                  // берем список пунктов меню из компонента Menu и на основе их строим маршруты
                  if (
                    // если страхование не найдено в меню сервера - маршрут не строим
                    item.id === "insurance" &&
                    schools[selectedSchool].menu.filter(item => item.id === 9).length === 0
                  ) {
                    return null;
                  }
                  if (
                    // если у дилера нет gps маршрут не строим
                    item.id === "gps" &&
                    schools[selectedSchool].menu.filter(item => item.id === 8).length === 0
                  ) {
                    return null;
                  }
                  if (
                    item.availableDilers &&
                    item.availableDilers.indexOf(parentDilerId) < 0
                  )
                  {
                    return null
                  }

                  return (
                    <Route
                      exact
                      path={`/${item.id}`}
                      render={() => (
                        <ErrorBoundary component={item.id}>
                          <item.component/>
                        </ErrorBoundary>
                      )}
                      key={item.id}
                    />
                  );
                })}
                {menuList.map(item => {
                  if (
                    item.availableDilers &&
                    item.availableDilers.indexOf(parentDilerId) < 0
                  )
                  {
                    return null
                  }
                  if (!!item.subRoutes) {
                    return (
                      <Switch key={`/${item.id}-switch`}>
                        {item.subRoutes.map(subRoute => {
                          return (
                            <Route
                              exact
                              path={`/${item.id}/${subRoute.id}`}
                              render={() => (
                                <ErrorBoundary component={`${item.id}-${subRoute.id}`}>
                                  <item.component />
                                </ErrorBoundary>
                              )}
                              key={`${item.id}-${subRoute.id}`}
                            />
                          )
                        })}
                      </Switch>
                    )
                  } else return null;
                })}
                <Route render={() => <div>Страница не найдена</div>}/>
              </Switch>
            </main>
          </div>
          <Footer profile={schools[selectedSchool]}/>
        </div>
      </>
    );
  }
}

Home.propTypes = {
  user: PropTypes.shape({
    auth: PropTypes.bool,
    schools: PropTypes.object,
    selectedSchool: PropTypes.number
  }),
  getProfile: PropTypes.func.isRequired,
  location: PropTypes.shape({
    search: PropTypes.string,
    pathname: PropTypes.string
  }),
  changeTheme: PropTypes.func.isRequired,
  classes: PropTypes.object,
  errors: PropTypes.shape({
    error: PropTypes.string,
    errorStatus: PropTypes.number
  })
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(Home));
