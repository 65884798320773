import React from "react";
import PropTypes from "prop-types";

import Clear from "@material-ui/icons/Clear";
import LocationOff from "@material-ui/icons/LocationOff";
import LocationOn from "@material-ui/icons/LocationOn";
import Create from "@material-ui/icons/Create";
import Watch from "@material-ui/icons/Watch";
import Smartphone from "@material-ui/icons/Smartphone";

import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import Hidden from "@material-ui/core/Hidden";
import withStyles from "@material-ui/core/styles/withStyles";
import panelsStyles from "../styles";

const styles = theme => ({
  ...panelsStyles(theme)
});

const DeviceItem = (props) => {
  const {
    classes,
    device,
    changeDeviceStatus,
    deleteDevice,
    selectDevice,
    editDeviceNameOpen,
    selectedDevice
  } = props;
  return (
    <div
      className={classes.item}
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      {/* Иконка устройства (смартфон/часы) */}
      {device.isWatch === 1 ? <Watch fontSize="small" /> : <Smartphone fontSize="small" />}
      <div className={classes.itemName} onClick={selectDevice(device.id)}>
        <Typography color="inherit" variant="body1" style={{ lineHeight: 1 }}>
          {device.name}
        </Typography>
        <Typography color="inherit" variant="caption">
          код: {device.imei}
        </Typography>
      </div>
      <div className={classes.iconsRow}>
        {/* {Показываем кнопку редактирования только на выбранном устройстве в мобильной версии */}
        {selectedDevice ? (
          <Tooltip title="Изменить имя устройства">
            <IconButton onClick={editDeviceNameOpen(device.id, device.name)}>
              <Create fontSize="large" />
            </IconButton>
          </Tooltip>
        ) : (
          <Hidden xsDown>
            <Tooltip title="Изменить имя устройства">
              <IconButton onClick={editDeviceNameOpen(device.id, device.name)}>
                <Create fontSize="large" />
              </IconButton>
            </Tooltip>
          </Hidden>
        )}
        {device.active === 1 ? (
          <Tooltip title="Отключить устройство">
            <IconButton onClick={changeDeviceStatus(device.id, +!device.active)}>
              <LocationOn fontSize="large" color="secondary" />
            </IconButton>
          </Tooltip>
        ) : (
          <Tooltip title="Включить устройство">
            <IconButton onClick={changeDeviceStatus(device.id, +!device.active)}>
              <LocationOff fontSize="large" color="error" />
            </IconButton>
          </Tooltip>
        )}
        {/* Показываем кнопку удаления только на выбранном устройстве в мобильной версии */}
        {selectedDevice ? (
          <Tooltip title="Удалить устройство">
            <IconButton onClick={deleteDevice(device.id)}>
              <Clear fontSize="large" color="error" />
            </IconButton>
          </Tooltip>
        ) : (
          <Hidden xsDown>
            <Tooltip title="Удалить устройство">
              <IconButton onClick={deleteDevice(device.id)}>
                <Clear fontSize="large" color="error" />
              </IconButton>
            </Tooltip>
          </Hidden>
        )}
      </div>
    </div>
  );
};

export default withStyles(styles)(DeviceItem);

DeviceItem.propTypes = {
  classes: PropTypes.object,
  device: PropTypes.shape({
    id: PropTypes.number.isRequired,
    active: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired
  }),
  changeDeviceStatus: PropTypes.func.isRequired,
  deleteDevice: PropTypes.func.isRequired,
  selectDevice: PropTypes.func.isRequired,
  editDeviceNameOpen: PropTypes.func.isRequired,
  selectedDevice: PropTypes.number
};
