import React from "react";
import { Link } from "react-router-dom";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import FormControl from "@material-ui/core/FormControl";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import InputAdornment from "@material-ui/core/InputAdornment";

import CircularProgress from "@material-ui/core/CircularProgress";
import PhonelinkLock from "@material-ui/icons/PhonelinkLock";

import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";

import withStyles from "@material-ui/core/styles/withStyles";

const styles = theme => ({
  container: {
    display: "block", // Fix IE11 issue.
    width: "95%",
    maxWidth: 400,
    minWidth: 200,
    paddingTop: 100,
    marginLeft: "auto",
    marginRight: "auto",
    [theme.breakpoints.down("sm")]: {
      paddingTop: 20
    }
  },
  paper: {
    marginTop: 20,
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
  title: {
    width: "100%",
    lineHeight: 3,
    verticalAlign: "middle",
    fontWeight: 500,
    textAlign: "center",
    color: "rgba(0, 0, 0, 0.70)",
    backgroundColor: theme.palette.primary.main
  },
  form: {
    width: "100%", // Fix IE11 issue.
    marginTop: 10,
    padding: 25
  },
  submit: {
    marginTop: 30,
    marginBottom: 30
  },
  input: {
    marginTop: 10,
    fontSize: 25
  },
  positionStart: {
    fontSize: 20
  },
  buttonWrapper: {
    position: "relative"
  },
  buttonProgress: {
    color: "white",
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12
  }
});

const RecoveryForm = props => {
  const { login, classes, isLoading, generateNewPassword } = props;

  return (
    <>
      <CssBaseline />
      <main className={classes.container}>
        <Paper className={classes.paper}>
          <Typography variant="subtitle1" className={classes.title}>
            Получение нового / Восстановление пароля
          </Typography>
          <form className={classes.form}>
            <FormControl required fullWidth>
              <InputLabel htmlFor="login">Номер телефона</InputLabel>
              <Input
                error={props.errorOnValidate(login)}
                className={classes.input}
                value={login}
                onChange={props.changeInput("login")}
                autoFocus
                type="number"
                startAdornment={
                  <InputAdornment position="start">
                    <span className={classes.positionStart}>+7</span>
                  </InputAdornment>
                }
                endAdornment={
                  <InputAdornment position="end">
                    <PhonelinkLock color="primary" />
                  </InputAdornment>
                }
              />
            </FormControl>
            <Typography variant="caption" color="primary" style={{cursor: "pointer"}}
            onClick={() => {
              window.location.href = '/recovery-access'
            }}
            >Нет доступа к старому номеру телефона?</Typography>
            <div className={classes.buttonWrapper}>
              <Button
                disabled={props.errorOnValidate(login) || !login ? true : false}
                onClick={e => generateNewPassword(login)}
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
              >
                {isLoading ? "" : "Отправить пароль"}
              </Button>
              {isLoading && <CircularProgress size={24} className={classes.buttonProgress} />}
            </div>
            <Button component={Link} to="/login" fullWidth color="default" size="small">
              Назад к экрану логина
            </Button>
          </form>
        </Paper>
      </main>
    </>
  );
};

export default withStyles(styles)(RecoveryForm);
