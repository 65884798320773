import React from "react";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import FilterList from "@material-ui/icons/FilterList";
import Hidden from "@material-ui/core/Hidden";

import SelectPeriod from "./SelectPeriod";

import { withStyles } from "@material-ui/core/styles";

const styles = theme => ({
  header: {
    display: "flex",
    justifyContent: "space-between",
    position: "sticky",
    height: 60
  },
  buttons: {
    display: "flex",
    flexGrow: 1,
    justifyContent: "space-between"
  },
  button: {
    margin: theme.spacing.unit
  },
  icon: {
    padding: 16
  }
});

const Header = props => {
  const { classes, startDate, endDate, changeDate, submitClick, filter, pupil, event } = props;
  return (
    <div className={classes.header}>
      <SelectPeriod startDate={startDate} endDate={endDate} changeDate={changeDate} />
      <div className={classes.buttons}>
        <Button
          variant="outlined"
          size="small"
          className={classes.button}
          onClick={e => submitClick()}
        >
          Применить
        </Button>
        <Hidden smUp>
          <IconButton
            classes={{ root: classes.icon }}
            size="large"
            color={pupil !== -1 || event !== -1 ? "primary" : "default"}
            onClick={() => filter.open()}
          >
            <FilterList />
          </IconButton>
        </Hidden>
        <Hidden xsDown>
          <Button
            variant={pupil !== -1 || event !== -1 ? "contained" : "outlined"}
            className={classes.button}
            color={pupil !== -1 || event !== -1 ? "primary" : "default"}
            onClick={() => filter.open()}
            size="small"
          >
            Фильтр
            <FilterList />
          </Button>
        </Hidden>
      </div>
    </div>
  );
};

export default withStyles(styles)(Header);
