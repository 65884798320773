import React from "react";
import { Redirect, Link } from "react-router-dom";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import FormControl from "@material-ui/core/FormControl";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import InputAdornment from "@material-ui/core/InputAdornment";

import CircularProgress from "@material-ui/core/CircularProgress";
import AccountCircle from "@material-ui/icons/AccountCircle";
import Https from "@material-ui/icons/Https";

import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";

import withStyles from "@material-ui/core/styles/withStyles";
import {Clusterer, Map, Placemark, YMaps} from "react-yandex-maps";
import {Hidden} from "@material-ui/core";
import moment from "moment";

const styles = theme => ({
  container: {
    display: "block", // Fix IE11 issue.
    width: "95%",
    maxWidth: 400,
    minWidth: 200,
    paddingTop: 100,
    marginLeft: "auto",
    marginRight: "auto",
    [theme.breakpoints.down("sm")]: {
      paddingTop: 20
    }
  },
  paper: {
    marginTop: 20,
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
  title: {
    width: "100%",
    lineHeight: 2,
    verticalAlign: "middle",
    fontWeight: 500,
    textAlign: "center",
    color: "rgba(0, 0, 0, 0.70)",
    backgroundColor: theme.palette.primary.main
  },
  form: {
    width: "100%", // Fix IE11 issue.
    marginTop: 10,
    padding: 15,
    textAlign: "center"
  },
  submit: {
    marginTop: 30,
    marginBottom: 30
  },
  input: {
    marginTop: 10,
    fontSize: 25
  },
  positionStart: {
    fontSize: 20
  },
  buttonWrapper: {
    position: "relative"
  },
  buttonProgress: {
    color: "white",
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12
  },
  mapContainer: {
    margin: 30,
    maxHeight: 350,
    height: "50%"
  }
});

let mapPoints = [
  { latitude: 63.576182, longitude: 42.773646, header: 'Архангельская область' },
  { latitude: 50.599725, longitude: 36.579656, header: 'Белгородская область' },
  { latitude: 48.718123, longitude: 44.498444, header: 'Волгоградская область' },
  { latitude: 52.282355, longitude: 104.286039, header: 'Иркутская область' },
  { latitude: 54.713409, longitude: 20.511635, header: 'Калининградская область' },
  { latitude: 57.7665, longitude: 40.9269, header: 'Костромская область' },
  { latitude: 68.513254, longitude: 30.167305, header: 'Ленинградская область' },
  { latitude: 68.513254, longitude: 33.772653, header: 'Мурманская область' },
  { latitude: 58.521160, longitude: 31.275080, header: 'Новгородская область' },
  { latitude: 51.770837, longitude: 55.097590, header: 'Оренбургская область' },
  { latitude: 52.970554, longitude: 36.063617, header: 'Орловская область' },
  { latitude: 58.005451, longitude: 56.228216, header: 'Пермский край' },
  { latitude: 57.813676, longitude: 28.332884, header: 'Псковская область' },
  { latitude: 61.779646, longitude: 34.361755, header: 'Республика Карелия' },
  { latitude: 44.587709, longitude: 33.508296, header: 'Республика Крым' },
  { latitude: 56.637771, longitude: 47.883850, header: 'Республика Марий Эл' },
  { latitude: 47.237984, longitude: 39.699594, header: 'Ростовская область' },
  { latitude: 53.203635, longitude: 50.159945, header: 'Самарская область' },
  { latitude: 59.939868, longitude: 30.311104, header: 'Санкт-Петербург' },
  { latitude: 51.582517, longitude: 46.004913, header: 'Саратовская область' },
  { latitude: 52.736700, longitude: 41.439886, header: 'Тамбовская область' },
  { latitude: 56.858431, longitude: 35.903519, header: 'Тверская область' },
  { latitude: 54.195676, longitude: 37.617781, header: 'Тульская область' },
  { latitude: 54.319686, longitude: 48.405450, header: 'Ульяновская область' },
]

const LoginForm = props => {
  const { login, password, classes, isLoading, auth,

    mapCenter,
    mapZoom,
    changeMapCenter,
    ymaps,
    setYmaps,
    setMapRef
  } = props;

  const onEnterPress = e => {
    if (e.keyCode === 13 && !props.errorOnValidate(login) && password) {
      props.signIn(login, password);
    }
  };

  const getAddress = () => {
    changeMapCenter([57.85,48.41])
    // ymaps
    //   .geocode("Россия")
    //   .then(result => {
    //     changeMapCenter(result.geoObjects.get(0).geometry.getCoordinates());
    //   });
  };

  let customBalloonContentLayout = (!!ymaps) ? ymaps.templateLayoutFactory.createClass(
    ["<span style='height: 0px; hidden: true'>", "</span>"].join("")
  ): undefined;

  if (auth) {
    return <Redirect to="/" />;
  } else {
    return (
      <>
        <CssBaseline />
        <main className={classes.container}>
          <Paper className={classes.paper}>
            <Typography variant="h5" className={classes.title}>
              Вход в личный кабинет
            </Typography>
            <form className={classes.form}>
              <FormControl required fullWidth>
                <InputLabel htmlFor="login">Логин</InputLabel>
                <Input
                  className={classes.input}
                  value={login}
                  error={props.errorOnValidate(login)}
                  onChange={props.changeInput("login")}
                  autoFocus
                  type="number"
                  startAdornment={
                    <InputAdornment position="start">
                      <span className={classes.positionStart}>+7</span>
                    </InputAdornment>
                  }
                  endAdornment={
                    <InputAdornment position="end">
                      <AccountCircle color="primary" />
                    </InputAdornment>
                  }
                />
              </FormControl>
              <FormControl required fullWidth>
                <InputLabel htmlFor="password">Пароль</InputLabel>
                <Input
                  className={classes.input}
                  value={password}
                  onChange={props.changeInput("password")}
                  onKeyDown={onEnterPress}
                  type="password"
                  endAdornment={
                    <InputAdornment position="end">
                      <Https color="primary" />
                    </InputAdornment>
                  }
                />
              </FormControl>
              <div className={classes.buttonWrapper}>
                <Button
                  disabled={props.errorOnValidate(login) || (!login || !password) ? true : false}
                  onClick={e => props.signIn(login, password)}
                  fullWidth
                  variant="contained"
                  color="primary"
                  className={classes.submit}
                >
                  {isLoading ? " " : "Вход"}
                </Button>
                {isLoading && <CircularProgress size={24} className={classes.buttonProgress} />}
              </div>
              <Button component={Link} to="/recovery" fullWidth color="default" size="small">
                Получение нового / Восстановление пароля
              </Button>
            </form>
          </Paper>
        </main>
        <Hidden xsDown> <div className={classes.mapContainer}>
          <YMaps query={{
            ns: "use-load-option",
            apikey: "2342fe29-6463-4bfd-bbda-65cb391fa758",
            // apikey: "ef54330f-610b-4992-8b99-b33288ac14a6",
            load: "package.full"
            // `Map,
            // Placemark,
            // control.ZoomControl,
            // control.TypeSelector,
            // geoObject.addon.balloon,
            // geocode,
            // Polygon,
            // Clusterer,
            // map.addon.balloon,
            // clusterer.addon.balloon,
            // layout.templateBased.Base`
          }}
          >
            <Map
              onLoad={ymaps => {
                setYmaps(ymaps);
                getAddress(ymaps);
              }}
              instanceRef={ref => setMapRef(ref)}
              state={{
                center: mapCenter.length === 0 ? [55.75, 37.57] : mapCenter,
                zoom: mapZoom,
                controls: ["typeSelector"]
              }}
              width="100%"
              height="100%"
              defaultOptions={{ suppressMapOpenBlock: true }}
            >
              {mapPoints &&
              mapPoints.map((point, index) => {
                  return (
                    <Placemark
                      key={index}
                      geometry={[point.latitude, point.longitude]}
                      properties={{
                        // iconContent: index + 1,
                        balloonContentHeader: point.header
                      }}
                      options={{
                        preset: "islands#circleIcon",
                        iconColor: "#4fc3f7"
                      }}
                    />
                  );
              })}
            </Map>
          </YMaps>
        </div> </Hidden>
      </>
    );
  }
};

export default withStyles(styles)(LoginForm);
