import React, {Component} from "react";
import PropTypes from "prop-types";
import {Formik} from "formik";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import Typography from "@material-ui/core/Typography";

import StepButton from "@material-ui/core/StepButton";
import StepContent from "@material-ui/core/StepContent";
import ErrorMessage from "components/messages/ErrorMessage";
import InfoMessage from "components/messages/InfoMessage";
import LoadingMessage from "components/messages/LoadingMessage";

import {connect} from "react-redux";
import {withStyles} from "@material-ui/core/styles";

import * as promoActions from "actions/promo201912Actions";
import Step1 from "./Step1";
import {bindActionCreators} from "redux";

import {YMInitializer, withId} from 'react-yandex-metrika';
import Hidden from "@material-ui/core/Hidden";
import PhoneBlock from "../header/PhoneBlock";

const mapStateToProps = state => ({
  promo: state.promo
});

const mapDispatchToProps = dispatch => {
  return {
    promoActions: bindActionCreators(promoActions, dispatch)
  };
};

const styles = theme => {
  return ({
  root: {
    maxWidth: 1100,
    minHeight: "100%",
    position: "relative",
    display: "flex",
    flexDirection: "column",
    margin: "0 auto",
    padding: "0 30px",
    background: "white",
    borderRight: "1px solid rgba(0, 0, 0, 0.12)",
    borderLeft: "1px solid rgba(0, 0, 0, 0.12)",
    [theme.breakpoints.down("md")]: {
      margin: "0 20px"
    },
    [theme.breakpoints.down("sm")]: {
      margin: "0 5px"
    }
  },
  header: {
    width: "100%",
    backgroundColor: theme.palette.primary.main,
    height: "90px",
    [theme.breakpoints.down("sm")]: {
      height: "45px"
    },
    [theme.breakpoints.up("sm")]: {
      height: "90px"
    }
  },
  container: {
    paddingTop: 10,
    display: "flex",
    flexDirection: "column"
  },
  logo: {
    backgroundImage: `url("${theme.logoUrl}")`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: 0,
    display: "flex",
    position: "absolute",
    width: "120px",
    height: "120px",
    marginTop: "-75px",
    marginLeft: "15px",
    zIndex: 5,
    overflow: "visible"
  },
  logoText: {
    display: "flex",
    float: "left",
    width: "170px",
    height: "100%",
    minWidth: "170px",
    lineHeight: "45px",
    marginLeft: "10px",
    color: "rgba(255,255,255,0.9)",
    [theme.breakpoints.up("sm")]: {
      color: theme.palette.primary.main,
      lineHeight: "90px",
    }
  },
  promoTitle: {
    display: "flex",
    float: "left",
    height: "100%",
    minWidth: "170px",
    lineHeight: "45px",
    color: "rgba(255,255,255,0.9)",
    [theme.breakpoints.up("sm")]: {
      lineHeight: "90px",
    }
  },
  phoneBlock: {
    display: "flex",
    float: "right",
    marginTop: "15px",
    marginRight: "10px",
  },
  backButton: {
    maxWidth: 150,
    marginBottom: 20
  },
  stepper: {
    width: "calc(100% - 70px)",
    padding: "90px 16px",
    [theme.breakpoints.down("sm")]: {
      padding: "30px 5px",
      width: "calc(100% - 10px)"
    }
  }
})};

class Promo extends Component {

  componentWillReceiveProps(nextProps) {
    if (nextProps.promo && nextProps.promo.payUrl) {
      this.goToPay(nextProps.promo.payUrl)
    }
  }

  changeFaviconAndTitle = (props) => {
    const favicon = document.querySelector("link[rel*='icon']");
    const manifest = document.querySelector("link[rel*='manifest']");

    const { dilerId, theme }= props;
    if (
      (dilerId === 7 && theme !== 'cyber') ||
      (dilerId !== 7 && theme !== 'main')
    ) {
      props.changeTheme(dilerId);
    }

    if (props.dilerId === 7) {
      document.title = "Акция Кибер-Карта";
      favicon.href = "/favicon-cyber.ico";
      manifest.setAttribute("href", "/manifest-cyber.json");
    } else {
      document.title = "Акция УЭШКа";
      favicon.href = "/favicon-ueshka.ico";
      manifest.setAttribute("href", "/manifest-ueshka.json");
    }
  }

  goToLogin = () => {
    window.location.href = "/login";
  };

  goToPay = (payUrl) => {
    if (payUrl) {
      // ym('reachGoal', 'doPay', {URL: document.location.href})
      withId(56353417)('reachGoal', 'doPay', {URL: document.location.href})
      window.location.href = payUrl
    }
  }

  render() {
    this.changeFaviconAndTitle(this.props);
    const {classes, promo, promoActions, dilerId} = this.props;
    const {changeStep} = promoActions

    const {step} = promo;
    const initParent = {
      phone: "",
      pupilId: -1
    };
    return (
      <div className={classes.root}>
        <YMInitializer
          accounts={[56353417]}
          options={{
            clickmap: true,
            trackLinks: true,
            accurateTrackBounce: true,
            webvisor: true
          }}
          version="2"
        />
        <InfoMessage/>
        <ErrorMessage/>
        <LoadingMessage/>
        <div className={classes.header}>
          <Hidden xsDown>
            <Typography variant="h4" className={classes.logoText}>{(dilerId === 6) ? "УЭШКА" : "Кибер-Карта"}</Typography>
          </Hidden>
          <Typography variant="h4" className={classes.promoTitle}>Акция: SMART за 300</Typography>
          <Hidden mdDown>
            <div className={classes.phoneBlock}>
              <PhoneBlock dilerId={dilerId}/>
            </div>
          </Hidden>

        </div>
        <div className={classes.container}>
          <Hidden smDown>
            <div className={classes.logo}/>
          </Hidden>
          <Formik
            initialValues={{
              ...initParent
            }}
            onSubmit={(values, {setSubmitting}) => {
              this.props.promoActions.getPayUrl(values);
            }}
          >
            {val => {
              const {values, handleChange, setFieldValue, handleSubmit} = val;
              return (
                <form onSubmit={handleSubmit}>
                  <Stepper className={classes.stepper} activeStep={step} orientation="vertical">
                    <Step>
                      <StepButton onClick={() => changeStep(0)}>
                        Краткое описание акции
                      </StepButton>
                      <StepContent>
                        <Step1 {...{changeStep, dilerId, values, handleChange, setFieldValue,
                          getPupilList: this.props.promoActions.getPupilList,
                          clearPupilList: this.props.promoActions.clearPupilList}} />
                      </StepContent>
                    </Step>

                  </Stepper>
                </form>
              );
            }}
          </Formik>
        </div>
      </div>
    );
  }
}

Promo.propTypes = {
  dilerId: PropTypes.number.isRequired,
  promo: PropTypes.shape({
    step: PropTypes.number.isRequired,
    errors: PropTypes.object
  }),
  promoActions: PropTypes.shape({
    changeStep: PropTypes.func.isRequired,
    getPupilList: PropTypes.func.isRequired,
    pupilListClear: PropTypes.func.isRequired,
    getPayUrl: PropTypes.func.isRequired,
    clearPayUrl: PropTypes.func.isRequired
  })
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(Promo));
