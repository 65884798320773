import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import moment from "moment";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";

import PupilData from "./PupilData";

import {
  getRelationDegree,
  getSchoolsByRegion,
  getClassesBySchool
} from "actions/registrationActions";
import {
  getPupilDocTypesSelector,
  getRelationDegreeSelector,
  getSchoolsSelector
} from "selectors/registration";

import { withStyles } from "@material-ui/core/styles";

const styles = theme => ({
  buttons: {
    position: "relative",
    display: "flex",
    justifyContent: "flex-start",
    marginTop: 15
  },
  nextButton: {
    flexGrow: 1
  },
  deleteButton: {
    color: "#f44336",
    margin: "10px 0"
  }
});

class Step4 extends Component {
  componentDidMount() {
    const { values, getRelationDegree, getSchoolsByRegion } = this.props;
    getRelationDegree();
    getSchoolsByRegion(values.region && values.region.value, this.props.parentDilerIds, values.parentId);
  }

  handleInputChange = (id, pupilIndex) => event => {
    const { values, setFieldValue } = this.props;
    const newChildren = [...values.children];
    newChildren[pupilIndex] = {
      ...newChildren[pupilIndex],
      // если value === id - выбран чекбокс
      [id]:
        event.target.value === id
          ? event.target.checked
          : id === "cardNumber"
          ? parseInt(event.target.value)
          : event.target.value
    };
    setFieldValue("children", newChildren);
  };

  handleDateChange = (id, pupilIndex) => date => {
    const { values, setFieldValue } = this.props;
    const newChildren = [...values.children];
    newChildren[pupilIndex] = {
      ...newChildren[pupilIndex],
      [id]: moment(date).format("YYYY-MM-DD")
    };
    setFieldValue("children", newChildren);
  };

  handleSelectChange = (id, pupilIndex) => event => {
    const { values, setFieldValue } = this.props;
    const newChildren = [...values.children];
    newChildren[pupilIndex] = {
      ...newChildren[pupilIndex],
      [id]: event.target.value
    };
    setFieldValue("children", newChildren);
  };

  addPupil = () => {
    const { values, setFieldValue, initPupil } = this.props;
    const newChildren = [...values.children];
    newChildren.push(initPupil);
    setFieldValue("children", newChildren);
  };

  deletePupil = pupilIndex => () => {
    const { values, setFieldValue } = this.props;
    const newChildren = [...values.children];
    newChildren.pop();
    setFieldValue("children", newChildren);
  };

  isPupilDataIncomplete = pupil => {
    if (
      !(!!pupil.id || !!pupil.cardNumber) ||
      !pupil.fam ||
      !pupil.im ||
      !pupil.degreeOfKinship ||
      !pupil.birthDay ||
      !pupil.docSeries ||
      !pupil.docNumber ||
      !pupil.docDate ||
      !pupil.docIssuer ||
      typeof pupil.schoolId !== "number" ||
      !(pupil.classId || pupil.id)
    ) {
      return true;
    } else return false;
  };

  isPupilsDataIncomplete = pupils => {
    let res = true
    for (let pupil of pupils){
      res = res && this.isPupilDataIncomplete(pupil)
    }
    return res
  };

  render() {
    const {
      classes,
      setFieldValue,
      changeStep,
      values,
      docTypes,
      relationDegree,
      schools,
      getClassesBySchool,
      schoolClasses,
      errors,
      handleEnter,
      dilerId,parentDilerIds
    } = this.props;
    const parentDilerId = (!!parentDilerIds) ? (parentDilerIds[0] || parentDilerIds) : parentDilerIds;

    return (
      <>
        {values.children.map((child, index) => {
          return (
            <div key={index}>
              <PupilData
                {...{
                  values,
                  setFieldValue,
                  pupil: child,
                  pupilIndex: index,
                  handleInputChange: this.handleInputChange,
                  handleDateChange: this.handleDateChange,
                  handleSelectChange: this.handleSelectChange,
                  docTypes,
                  relationDegree,
                  schools,
                  schoolClasses,
                  deletePupil: this.deletePupil,
                  getClassesBySchool,
                  errors,
                  addPupil: this.addPupil,
                  parentDilerId
                }}
                onKeyUp={handleEnter}
              />
              {index > 0 && (
                <>
                  <Button
                    variant="outlined"
                    size="small"
                    disabled={!!child.id}
                    className={classes.deleteButton}
                    onClick={this.deletePupil(index)}
                  >
                    Удалить ученика №{index + 1}
                  </Button>
                  <Divider />
                </>
              )}
              <br />
              {(this.isPupilDataIncomplete(child) && dilerId !== 54) && (
                <Typography variant="body2" color="error">
                  Добавление нового ученика возможно только после заполнения данных по текущему
                </Typography>
              )}
              {(dilerId !== 54 && values.children.length === index + 1) && (
                <Button
                  variant="outlined"
                  color="primary"
                  size="large"
                  disabled={this.isPupilDataIncomplete(child)}
                  onClick={this.addPupil}
                >
                  Добавить ученика №{index + 2}
                </Button>
              )}
              <br />
              {values.children.length === index + 1 && (
                <div className={classes.buttons}>
                  <Button
                    className={classes.nextButton}
                    disabled={this.isPupilsDataIncomplete(values.children)}
                    variant="outlined"
                    color="secondary"
                    size="large"
                    onClick={e => changeStep(4)}
                  >
                    Далее
                  </Button>
                </div>
              )}
            </div>
          );
        })}
      </>
    );
  }
}

Step4.propTypes = {
  classes: PropTypes.object,
  changeStep: PropTypes.func.isRequired,
  values: PropTypes.object,
  setFieldValue: PropTypes.func.isRequired,
  docTypes: PropTypes.array,
  dilerId: PropTypes.number,
  parentDilerIds: PropTypes.oneOfType([PropTypes.oneOf([null]),PropTypes.number, PropTypes.array]).isRequired,
  getRelationDegree: PropTypes.func.isRequired,
  getSchoolsByRegion: PropTypes.func.isRequired,
  relationDegree: PropTypes.array,
  schools: PropTypes.array,
  getClassesBySchool: PropTypes.func.isRequired,
  schoolClasses: PropTypes.object,
  errors: PropTypes.object,
  initPupil: PropTypes.object
};

const mapStateToProps = state => ({
  docTypes: getPupilDocTypesSelector(state),
  relationDegree: getRelationDegreeSelector(state),
  schools: getSchoolsSelector(state),
  schoolClasses: state.registration.classes,
  errors: state.registration.errors,
  pupil: PropTypes.object
});

const mapDispatchToProps = {
  getRelationDegree,
  getSchoolsByRegion,
  getClassesBySchool
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(Step4));
