import React from "react";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";

import { withStyles } from "@material-ui/core/styles";

const styles = theme => ({
  buttons: {
    position: "relative",
    display: "flex",
    justifyContent: "flex-start",
    marginTop: 15,
    marginBottom: 15
  },
  nextButton: {
    flexGrow: 1,
    margin: "0 5px"
  }
});

const Step4 = props => {
  const { classes, pupil, payType, amount, changeStep, submitPay,  autoPayment, commissions } = props;
  const amountWithCommission = Math.round((Number(amount) + Number(amount) * (commissions[payType.id]/100)) * 100) / 100;
  return (
    <>
      <Typography variant="subtitle1">Услуга: {payType.name}</Typography>
      {payType.id === 0 ? <Typography variant="subtitle1">Ученик: {pupil.name}</Typography> : null}
      {commissions[payType.id] !== 0 ? (
        <Typography variant="subtitle1">
          Сумма к оплате: {amountWithCommission} руб. (включая комиссию {commissions[payType.id]}
          %)
        </Typography>
      ) : (
        <Typography variant="subtitle1">Сумма к оплате: {amount} руб.</Typography>
      )}
      {autoPayment === payType.id
        ? <Typography>После успешной оплаты Вам будет активирован автоплатеж по выбранной услуге. Подключенные автоплатежи
          будут отображаться в профиле родителя
      </Typography>
        : null}
      <Typography variant="body2" color="error">
        После нажатия кнопки "Подтвердить" вы будете перенаправлены на страницу оплаты. Не закрывайте окно до полного завершения оплаты.
      </Typography>
      <div className={classes.buttons}>
        <Button variant="outlined" size="small" onClick={e => changeStep("backward")}>
          Назад
        </Button>
        <Button
          className={classes.nextButton}
          variant="outlined"
          color="secondary"
          size="large"
          onClick={e => submitPay()}
        >
          Подтвердить
        </Button>
      </div>
    </>
  );
};

export default withStyles(styles)(Step4);
