import React from "react";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";

import { withStyles } from "@material-ui/core/styles";

const styles = theme => ({
  buttons: {
    position: "relative",
    display: "flex",
    justifyContent: "flex-start",
    marginTop: 15,
    marginBottom: 15
  },
  nextButton: {
    flexGrow: 1,
    margin: "0 5px"
  }
});

const Step4 = props => {
  const { classes, payTypeFrom, payTypeTo, amount, changeStep, submitTransfer } = props;
  return (
    <>
      <Typography variant="subtitle1">
        Перевод с услуги:
        {payTypeFrom === undefined
          ? "Услуга не выбрана"
          : payTypeFrom.id === 0
          ? ` ${payTypeFrom.name}, ${payTypeFrom.pupil.name}`
          : ` ${payTypeFrom.name}`}
      </Typography>
      <Typography variant="subtitle1">
        Перевод на услугу:
        {payTypeFrom === undefined
          ? "Услуга не выбрана"
          : payTypeTo.id === 0
          ? ` ${payTypeTo.name}, ${payTypeTo.pupil.name}`
          : ` ${payTypeTo.name}`}
      </Typography>
      <Typography variant="subtitle1">Сумма перевода: {amount} руб.</Typography>
      <Typography variant="body2" color="error">
        После нажатия кнопки "Подтвердить" будет осуществлен перевод.
      </Typography>
      <div className={classes.buttons}>
        <Button variant="outlined" size="small" onClick={e => changeStep("backward")}>
          Назад
        </Button>
        <Button
          className={classes.nextButton}
          variant="outlined"
          color="secondary"
          size="large"
          onClick={e => submitTransfer()}
        >
          Подтвердить
        </Button>
      </div>
    </>
  );
};

export default withStyles(styles)(Step4);
