import React from "react";
import PropTypes from "prop-types";
import Typography from "@material-ui/core/Typography";

import InputField from "../../InputField";

const ParentBirthDate = props => {
  const { values, handleChange, handleDateChange, errors, onKeyUp } = props;
  return (
    <>
      <Typography variant="button">Дата и место рождения законного представителя</Typography>
      <div>
        <InputField
          id="birthPlace"
          label="Место рождения"
          required
          error={errors.birthPlace}
          onChange={handleChange}
          value={values.birthPlace}
          onKeyUp={onKeyUp}
        />

        <InputField
          id="birthDay"
          label="Дата рождения *"
          required
          datePicker
          maxDate={new Date().setFullYear(new Date().getFullYear() - 18)}
          error={errors.birthDay}
          value={values.birthDay}
          onChange={handleDateChange("birthDay")}
          onKeyUp={onKeyUp}
        />
      </div>
      <br />
    </>
  );
};

ParentBirthDate.propTypes = {
  errors: PropTypes.object,
  values: PropTypes.shape({
    birthDay: PropTypes.string,
    birthPlace: PropTypes.string
  }),
  handleChange: PropTypes.func.isRequired,
  handleDateChange: PropTypes.func.isRequired
};

export default ParentBirthDate;
