import React from "react";

import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import withStyles from "@material-ui/core/styles/withStyles";

const styles = theme => ({
  descriptionContainer: {
    margin: 5,
    overflow: "auto",
    height: "calc(100% - 10px)"
  },
  insuranceBanner: {
    display: "block",
    maxWidth: "100%",
    maxHeight: 380,
    height: "auto",
    margin: "0 auto",
    paddingBottom: 10
  },
  text: {
    textIndent: "20px"
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "center",
    paddingBottom: 5
  },
  link: {
    color: "#0000ff",
    '&visited': {color: "#0000ff"},
    '&active': {color: "#0000ff"},
  }
});

const Description = props => {
  const {classes, newInsuranceClick} = props;
  return (
    <div className={classes.descriptionContainer}>
      <img
        src="/banner-insurance.png"
        alt="Баннер страхования"
        className={classes.insuranceBanner}
      />
      <article>
        <Typography align="justify" paragraph className={classes.text}>
          Уважаемые родители, компания «УЭШКА» совместно с
          ООО «Континент» и «Капитал Лайф Страхование Жизни» реализуют социальный проект по экспресс-страхованию
          российских школьников от COVID-19 и несчастных случаев. Вы можете застраховать Вашего ребенка от этих рисков.
        </Typography>
        <Typography align="justify" paragraph className={classes.text}>
          Страховой полис – это гарантия надежной финансовой защиты Вашего ребенка в любой непредвиденной ситуации. <b>Страховая
          сумма в тысячи раз превышает стоимость полиса.</b>
        </Typography>
        <Typography align="justify" paragraph className={classes.text}>
          Вы можете приобрести страховой полис сроком на один, три, шесть или двенадцать месяцев.
        </Typography>
        <Typography align="justify" paragraph className={classes.text}>
          <a className={classes.link} href="/docs/A+COVID-19.pdf" target="_blank" rel="noreferrer">Подробнее</a>
        </Typography>
        {/*<Typography align="justify" paragraph className={classes.text}>*/}
        {/*  Уважаемые родители, компания «УЭШКА» совместно компаниями Страховой Дом «ВСК» и ООО «Капитал Лайф Страхование*/}
        {/*  Жизни» реализуют социальный проект по экспресс-страхованию российских школьников от несчастных случаев и*/}
        {/*  COVID-19. Вы можете застраховать Вашего ребенка от несчастного случая.*/}
        {/*</Typography>*/}
        {/*<Typography align="justify" paragraph className={classes.text}>*/}
        {/*  Страховой полис – это гарантия надежной финансовой защиты Вашего ребенка в любой*/}
        {/*  непредвиденной ситуации. Размер страховой выплаты зависит от поставленного диагноза в*/}
        {/*  медицинском учреждении. Страховую компенсацию Вы можете потратить на лечение, реабилитацию*/}
        {/*  или по собственному усмотрению.*/}
        {/*</Typography>*/}
        {/*<Typography align="justify" paragraph className={classes.text}>*/}
        {/*  Вы можете приобрести обычный и спортивный страховой полис сроком на один год от компании ВСК или страхование*/}
        {/*  школьников (НС+COVID) сроком на один, три, шесть или двенадцать месяцев от компании Капитал Лайф.*/}
        {/*  <br/>Спортивный страховой полис необходим маленьким и юным спортсменам, если они регулярно тренируются и*/}
        {/*  занимаются в спортивных секциях. Полис служит допуском для участия в официальных*/}
        {/*  соревнованиях. Обычный полис не включает в свое покрытие занятия спортом и подразумевает*/}
        {/*  круглосуточную защиту ребенка от несчастного случая.*/}
        {/*  <br/>Страхование школьников (НС+COVID) включает в себя телесные повреждения от несчастного случая и*/}
        {/*  заболевание острой респираторной инфекцией, вызываемой коронавирусом SARS-CoV-2, код МКБ-10 – U07.1*/}
        {/*  (COVID-19). Подробнее: <a href="/docs/A+COVID-19.pdf" target="_blank">см. вложение</a>*/}
        {/*</Typography>*/}
      </article>
      <span className={classes.buttonContainer}>
        <Button variant="contained" color="primary" onClick={e => newInsuranceClick()}>
          Оформить полис
        </Button>
      </span>
    </div>
  );
};

export default withStyles(styles)(Description);
