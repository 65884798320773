import React from "react";
import PropTypes from "prop-types";

import {withStyles} from "@material-ui/core/styles";
import Icon from "@material-ui/core/Icon";
import Typography from "@material-ui/core/Typography";

const styles = theme => ({
  rootIcon: {
    position: "absolute",
    display: "flex",
    height: 100,
    width: "auto"
  },
  imageIcon: {
    height: "100%",
  },
  title: {
    margin: 5,
    color: "#FAFAFA"
  }
})

const Logo = (props) => {
  const {classes, parentDilerId = 6, title} = props;
  return (
    <>
      <Icon className={classes.rootIcon}>
        <img
          className={classes.imageIcon}
          src={(parentDilerId === 7) ? '/logo-cyber-192.png' : '/logo-ueshka-192.png'}
          alt={(parentDilerId === 7) ? 'Кибер-Карта' : 'УЭШКА'}
        />
      </Icon>
      {!!title && (
        <Typography variant="h6" className={classes.title}>{title}</Typography>
      )}
    </>
  )
}

Logo.propTypes = {
  parentDilerId: PropTypes.number,
}

export default withStyles(styles)(Logo);