import React from "react";
import {Typography} from "@material-ui/core";

export const registrationRes = {
  default: 6,
  6: {
    Description: ({classes, contractLink, videoLink}) => (
      <Typography>Настоящая электронная Регистрационная форма-заявка является неотъемлемой частью Публичной Оферты,
        размещенной на официальном сайте ООО «УЭШКА» по адресу:
        <a className={classes.aLink} href={contractLink} target="_blank" rel="noopener noreferrer">{contractLink}</a> ,
        и
        заполняется законным представителем обучающегося (далее – Абонент).
        <br/>Нажав кнопку «ЗАРЕГИСТРИРОВАТЬСЯ» в электронной Регистрационной форме-заявке, Абонент считается
        заключившим Договор и подтверждает, что с текстом Публичной Оферты и Приложениями к нему ознакомлен, условия,
        указанные в Публичной Оферте, принимает в полном объеме, безоговорочно и подтверждает, что до него в понятной
        и доступной форме доведены сведения об основных потребительских свойствах услуг, оказываемых Исполнителем, в
        том числе Тарифных планах, условиях оказания и оплаты услуг, дополнительных платных опциях, дополнительных
        услугах.
        {!!videoLink && (
          <><br/>Видео инструкцию можно посмотреть тут: <a className={classes.aLink} href={videoLink} target="_blank"
                                                           rel="noopener noreferrer">УЭШКА Youtube</a></>
        )}
      </Typography>
    ),
    contractLink: 'https://ueshka.ru/index.php/roditelyam?id=64',
    videoLink: 'https://youtu.be/LIHxVvLi3YI',
    privatePolicyLink: 'https://ueshka.ru/index.php/roditelyam/politika-v-oblasti-obespecheniya-bezopasnosti-personalnykh-dannykh'
  },
  7: {
    Description: ({classes, contractLink, videoLink}) => (
      <Typography>Настоящая электронная Регистрационная форма-заявка является неотъемлемой частью Публичной Оферты,
        размещенной на официальном сайте ООО «Кибер-Карта» по адресу: <a className={classes.aLink}
                                                                         href={contractLink} target="_blank"
                                                                         rel="noopener noreferrer">{contractLink}</a>,
        и заполняется законным представителем обучающегося (далее – Абонент).
        <br/>Нажав кнопку «ЗАРЕГИСТРИРОВАТЬСЯ» в электронной Регистрационной форме-заявке, Абонент считается заключившим
        Договор и подтверждает, что с текстом Публичной Оферты и Приложениями к нему ознакомлен, условия, указанные в
        Публичной Оферте, принимает в полном объеме, безоговорочно и подтверждает, что до него в понятной и доступной
        форме доведены сведения об основных потребительских свойствах услуг, оказываемых Исполнителем, в том числе
        Тарифных планах, условиях оказания и оплаты услуг, дополнительных платных опциях, дополнительных услугах.
        {!!videoLink && (
          <><br/>Видео инструкцию можно посмотреть тут: <a className={classes.aLink} href={videoLink} target="_blank"
                                                           rel="noopener noreferrer">УЭШКА Youtube</a></>
        )}
      </Typography>
    ),
    contractLink: 'http://cyber-card.ru/roditelyam/dokumenty',
    videoLink: '',
    privatePolicyLink: 'http://cyber-card.ru/images/roditelyam/politika.pdf'
  }
}
