import axios from "axios";
import {createAction} from "redux-actions";
import {API, handleApiError} from "api";
import {showInfoMessage} from "./infoMessageActions";


export const shopDirectoriesRequest = createAction("SHOP_DIRECTORIES_REQUEST");
export const shopDirectoriesSuccess = createAction("SHOP_DIRECTORIES_SUCCESS");
export const shopDirectoriesError = createAction("SHOP_DIRECTORIES_ERROR");

export const shopAltCardsTypesRequest = createAction("SHOP_ALTCARDS_TYPES_REQUEST");
export const shopAltCardsTypesSuccess = createAction("SHOP_ALTCARDS_TYPES_SUCCESS");
export const shopAltCardsTypesError = createAction("SHOP_ALTCARDS_TYPES_ERROR");

export const shopAltCardsKindsRequest = createAction("SHOP_ALTCARDS_KINDS_REQUEST");
export const shopAltCardsKindsSuccess = createAction("SHOP_ALTCARDS_KINDS_SUCCESS");
export const shopAltCardsKindsError = createAction("SHOP_ALTCARDS_KINDS_ERROR");

export const shopAltCardsColorsRequest = createAction("SHOP_ALTCARDS_COLORS_REQUEST");
export const shopAltCardsColorsSuccess = createAction("SHOP_ALTCARDS_COLORS_SUCCESS");
export const shopAltCardsColorsError = createAction("SHOP_ALTCARDS_COLORS_ERROR");

export const shopAltCardSetPriceRequest = createAction("SHOP_ALTCARD_PRICE_REQUEST");
export const shopAltCardSetPriceSuccess = createAction("SHOP_ALTCARD_PRICE_SUCCESS");
export const shopAltCardSetPriceError = createAction("SHOP_ALTCARD_PRICE_ERROR")

export const shopAltCardAddOrderRequest = createAction("SHOP_ALTCARD_ADD_ORDER_REQUEST");
export const shopAltCardAddOrderSuccess = createAction("SHOP_ALTCARD_ADD_ORDER_SUCCESS");
export const shopAltCardAddOrderError = createAction("SHOP_ALTCARD_ADD_ORDER_ERROR");

export const shopAltCardDelOrderRequest = createAction("SHOP_ALTCARD_DEL_ORDER_REQUEST");
export const shopAltCardDelOrderSuccess = createAction("SHOP_ALTCARD_DEL_ORDER_SUCCESS");
export const shopAltCardDelOrderError = createAction("SHOP_ALTCARD_DEL_ORDER_ERROR");

export const shopAltCardOrderDetailsRequest = createAction("SHOP_ALTCARD_ORDER_DETAILS_REQUEST");
export const shopAltCardOrderDetailsSuccess = createAction("SHOP_ALTCARD_ORDER_DETAILS_SUCCESS");
export const shopAltCardOrderDetailsError = createAction("SHOP_ALTCARD_ORDER_DETAILS_ERROR");

export const shopAltCardClear = createAction("SHOP_ALTCARD_CLEAR");

export const shopPayUrlRequest = createAction("SHOP_PAY_URL_REQUEST");
export const shopPayUrlSuccess = createAction("SHOP_PAY_URL_SUCCESS");
export const shopPayUrlError = createAction("SHOP_PAY_URL_ERROR")
export const shopPayUrlClear = createAction("SHOP_PAY_URL_CLEAR");

export const shopOrderDetailsClear = createAction("SHOP_NEW_ORDER_CLEAR");

export const shopTrackDevicesTypesRequest = createAction("SHOP_TRACK_DEVICES_TYPES_REQUEST");
export const shopTrackDevicesTypesSuccess = createAction("SHOP_TRACK_DEVICES_TYPES_SUCCESS");
export const shopTrackDevicesTypesError = createAction("SHOP_TRACK_DEVICES_TYPES_ERROR");

export const shopTrackDevicesModelsRequest = createAction("SHOP_TRACK_DEVICES_MODELS_REQUEST");
export const shopTrackDevicesModelsSuccess = createAction("SHOP_TRACK_DEVICES_MODELS_SUCCESS");
export const shopTrackDevicesModelsError = createAction("SHOP_TRACK_DEVICES_MODELS_ERROR");

export const shopTrackDevicesClear = createAction("SHOP_TRACK_DEVICES_ITEM_CLEAR");
export const shopTrackDevicesOrderClear = createAction("SHOP_TRACK_DEVICES_ORDER_CLEAR");

export const shopTrackDevicesOrderItemAdd = createAction("SHOP_TRACK_DEVICES_ORDER_ITEM_ADD");

export const shopTrackDevicesDeliveryPriceRequest = createAction("SHOP_TRACK_DEVICES_DELIVERY_PRICE_REQUEST");
export const shopTrackDevicesDeliveryPriceSuccess = createAction("SHOP_TRACK_DEVICES_DELIVERY_PRICE_SUCCESS");
export const shopTrackDevicesDeliveryPriceError = createAction("SHOP_TRACK_DEVICES_DELIVERY_PRICE_ERROR");

export const shopTrackDevicesDelOrderRequest = createAction("SHOP_TRACK_DEVICES_DEL_ORDER_REQUEST");
export const shopTrackDevicesDelOrderSuccess = createAction("SHOP_TRACK_DEVICES_DEL_ORDER_SUCCESS");
export const shopTrackDevicesDelOrderError = createAction("SHOP_TRACK_DEVICES_DEL_ORDER_ERROR");

export const shopTrackDevicesAddOrderRequest = createAction("SHOP_TRACK_DEVICES_ADD_ORDER_REQUEST");
export const shopTrackDevicesAddOrderSuccess = createAction("SHOP_TRACK_DEVICES_ADD_ORDER_SUCCESS");
export const shopTrackDevicesAddOrderError = createAction("SHOP_TRACK_DEVICES_ADD_ORDER_ERROR");

export const shopTrackDevicesOrderDetailsRequest = createAction("SHOP_TRACK_DEVICES_ORDER_DETAILS_REQUEST");
export const shopTrackDevicesOrderDetailsSuccess = createAction("SHOP_TRACK_DEVICES_ORDER_DETAILS_SUCCESS");
export const shopTrackDevicesOrderDetailsError = createAction("SHOP_TRACK_DEVICES_ORDER_DETAILS_ERROR");

export const shopOrdersRequest = createAction("SHOP_ORDERS_REQUEST");
export const shopOrdersSuccess = createAction("SHOP_ORDERS_SUCCESS");
export const shopOrdersError = createAction("SHOP_ORDERS_ERROR");

export const getShopDirectories = parentId => async dispatch => {
  try {
    dispatch(shopDirectoriesRequest());
    const res = await axios.get(
      `${API}/shop/directories/${parentId}`
    );
    dispatch(
      shopDirectoriesSuccess({
        directories: res.data
      })
    )
  } catch (e) {
    dispatch(handleApiError(e, shopDirectoriesError()));
  }
}

export const getShopOrders = parentId => async dispatch => {
  try {
    dispatch(shopOrdersRequest());
    const res = await axios.get(
      `${API}/shop/orders/${parentId}`
    );
    dispatch(
      shopOrdersSuccess({
        orders: res.data
      })
    )
  } catch (e) {
    dispatch(handleApiError(e, shopOrdersError()));
  }
}

/** AltCards Actions */
export const getShopAltCardsTypes = parentId => async dispatch => {
  try {
    dispatch(shopAltCardsTypesRequest());
    const res = await axios.get(
      `${API}/shop/alt-cards/types/${parentId}`
    );
    dispatch(
      shopAltCardsTypesSuccess({
        altCardTypes: res.data
      })
    )
  } catch (e) {
    dispatch(handleApiError(e, shopAltCardsTypesError()));
  }
}

export const getShopAltCardsKinds = altCardTypeId => async dispatch => {
  try {
    dispatch(shopAltCardsKindsRequest());
    const res = await axios.get(
      `${API}/shop/alt-cards/kinds?altCardTypeId=${altCardTypeId}`
    );
    dispatch(
      shopAltCardsKindsSuccess({
        altCardKinds: res.data
      })
    )
  } catch (e) {
    dispatch(handleApiError(e, shopAltCardsKindsError()));
  }

}

export const getShopAltCardsColors = (altCardTypeId, altCardKindId) => async dispatch => {
  try {
    dispatch(shopAltCardsColorsRequest());
    const res = await axios.get(
      `${API}/shop/alt-cards/colors` +
      `?altCardTypeId=${altCardTypeId}` +
      `&altCardKindId=${altCardKindId}`
    );
    dispatch(
      shopAltCardsColorsSuccess({
        altCardColors: res.data
      })
    )
  } catch (e) {
    dispatch(handleApiError(e, shopAltCardsColorsError()));
  }
}

export const getShopAltCardSetPrice = (pupilId, altCardTypeId, altCardKindId, altCardColorId) => async dispatch => {
  try {
    dispatch(shopAltCardSetPriceRequest());
    const res = await axios.get(
      `${API}/shop/alt-cards/price/${pupilId}` +
      `?altCardTypeId=${altCardTypeId}` +
      `&altCardKindId=${altCardKindId}` +
      `&altCardColorId=${altCardColorId}`
    );
    dispatch(
      shopAltCardSetPriceSuccess({
        altCardSetPriceCent: res.data.priceCent
      })
    )
  } catch (e) {
    dispatch(handleApiError(e, shopAltCardSetPriceError()));
  }
}

export const setShopAltCardAddOrder = (pupilId, altCardTypeId, altCardKindId, altCardColorId) => async dispatch => {
  try {
    dispatch(shopAltCardAddOrderRequest());
    const res = await axios.post(
      `${API}/shop/alt-cards/${pupilId}?details`, {
        altCardTypeId,
        altCardKindId,
        altCardColorId
      }
    );
    dispatch(
      shopAltCardAddOrderSuccess({
        altCardNewOrder: res.data
      })
    )
  } catch (e) {
    dispatch(handleApiError(e, shopAltCardAddOrderError()));
  }
}

export const setShopAltCardDelOrder = (parentId, pupilId, orderId) => async dispatch => {
  try {
    dispatch(shopAltCardDelOrderRequest());
    const res = await axios.delete(
      `${API}/shop/alt-cards/${pupilId}` +
      `?orderId=${orderId}`
    );
    dispatch(shopAltCardDelOrderSuccess());
    dispatch(getShopOrders(parentId));
    dispatch(showInfoMessage(res.data.message));
  } catch (e) {
    dispatch(handleApiError(e, shopAltCardDelOrderError()));
  }
}

export const setShopAltCardOrderDetails = (accountId, orderId) => async dispatch => {
  try {
    dispatch(shopAltCardOrderDetailsRequest());
    const res = await axios.get(
      `${API}/shop/alt-cards/details/${accountId}` +
      `?orderId=${orderId}`
    );
    dispatch(shopAltCardOrderDetailsSuccess({
      altCardOrderDetails: res.data
    }))
  } catch (e) {
    dispatch(handleApiError(e, shopAltCardOrderDetailsError()));
  }
}

export const clearShopAltCard = () => dispatch => dispatch(shopAltCardClear())

export const getShopPayUrl = (urlPath, parentId, orderId) => async dispatch => {
  try {
    dispatch(shopPayUrlRequest());
    const res = await axios.get(
      `${API}${urlPath.trim()}${parentId}` +
      `?orderId=${orderId}`
    );
    dispatch(shopPayUrlSuccess({
      payUrl: res.data.message
    }))
  } catch (e) {
    dispatch(handleApiError(e, shopPayUrlError()));
  }
};

export const clearShopPayUrl = () => dispatch => dispatch(shopPayUrlClear());
export const clearShopOrderDetails = () => dispatch => dispatch(shopOrderDetailsClear());


/** Track Devices Actions */
export const getShopTrackDevicesTypes = parentId => async dispatch => {
  try {
    dispatch(shopTrackDevicesTypesRequest());
    const res = await axios.get(
      `${API}/shop/track-devices/types/${parentId}`
    );
    dispatch(
      shopTrackDevicesTypesSuccess({
        trackDevicesTypes: res.data
      })
    )
  } catch (e) {
    dispatch(handleApiError(e, shopTrackDevicesTypesError()));
  }
}
export const getShopTrackDeviceModels = (parentId,trackDeviceTypeId) => async dispatch => {
  try {
    dispatch(shopTrackDevicesModelsRequest());
    const res = await axios.get(
      `${API}/shop/track-devices/models/${parentId}` +
      `?trackDeviceTypeId=${trackDeviceTypeId}`
    );
    dispatch(
      shopTrackDevicesModelsSuccess({
        trackDevicesModels: res.data
      })
    )
  } catch (e) {
    dispatch(handleApiError(e, shopTrackDevicesModelsError()));
  }
}

export const clearShopTrackDevices = () => dispatch => dispatch(shopTrackDevicesClear());
export const clearShopTrackDevicesOrder = () => dispatch => {
  dispatch(clearShopTrackDevices());
  dispatch(shopTrackDevicesOrderClear());
};

export const addShopTrackDevicesOrderItem = (item) => dispatch => dispatch(shopTrackDevicesOrderItemAdd({trackDeviceOrderItem: item}));

export const getShopTrackDevicesDeliveryPrice = (parentId) => async dispatch => {
  try {
    dispatch(shopTrackDevicesDeliveryPriceRequest());
    const res = await axios.get(
      `${API}/shop/track-devices/delivery-price/${parentId}`
    );
    dispatch( shopTrackDevicesDeliveryPriceSuccess(res.data) )
  }  catch (e) {
    dispatch(handleApiError(e, shopTrackDevicesDeliveryPriceError()));
  }
}

export const setShopTrackDevicesAddOrder = (parentId, orderData) => async dispatch => {
  try {
    dispatch(shopTrackDevicesAddOrderRequest());
    const res = await axios.post(
      `${API}/shop/track-devices/${parentId}?details`, orderData
    );
    dispatch(
      shopTrackDevicesAddOrderSuccess({
        trackDevicesOrderDetails: res.data
      })
    )
  } catch (e) {
    dispatch(handleApiError(e, shopTrackDevicesAddOrderError()));
  }
}

export const getShopTrackDevicesOrderDetails = (parentId, orderId) => async dispatch => {
  try {
    dispatch(shopTrackDevicesOrderDetailsRequest());
    const res = await axios.get(
      `${API}/shop/track-devices/details/${parentId}` +
      `?orderId=${orderId}`
    );
    dispatch(
      shopTrackDevicesOrderDetailsSuccess({
        trackDevicesOrderDetails: res.data
      })
    )
  } catch (e) {
    dispatch(handleApiError(e, shopTrackDevicesOrderDetailsError()));
  }
}

export const setShopTrackDevicesOrderDel = (parentId, orderId) => async dispatch => {
  try {
    dispatch(shopTrackDevicesDelOrderRequest());
    const res = await axios.delete(
      `${API}/shop/track-devices/${parentId}` +
      `?orderId=${orderId}`
    );
    dispatch(shopTrackDevicesDelOrderSuccess());
    dispatch(getShopOrders(parentId));
    dispatch(showInfoMessage(res.data.message));
  } catch (e) {
    dispatch(handleApiError(e, shopTrackDevicesDelOrderError()));
  }
}
