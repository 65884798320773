import React from "react";
import PropTypes from "prop-types";
import AppBar from "@material-ui/core/AppBar";
import Button from "@material-ui/core/Button";

import { withStyles } from "@material-ui/core/styles";

const styles = theme => ({
  container: {
    display: "flex",
    flexDirection: "column",
    height: "95%"
  },
  appBar: {
    height: 48,
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  button: {
    width: 250
  }
});

const PayUrl = props => {
  const { classes, payUrl, transportCardPayUrlClear } = props;
  return (
    <div className={classes.container}>
      <iframe title="Операция по транспортной карты" src={payUrl} height="100%" />
      <AppBar className={classes.appBar} color="default" position="static" elevation={1}>
        <Button
          className={classes.button}
          variant="outlined"
          size="small"
          onClick={transportCardPayUrlClear}
        >
          Закрыть окно оплаты
        </Button>
      </AppBar>
    </div>
  );
};

PayUrl.propTypes = {
  classes: PropTypes.object,
  payUrl: PropTypes.string.isRequired,
  transportCardPayUrlClear: PropTypes.func.isRequired
};

export default withStyles(styles)(PayUrl);
