import React from "react";

import Typography from "@material-ui/core/Typography";
import Tooltip from "@material-ui/core/Tooltip";

import LocationOff from "@material-ui/icons/LocationOff";
import LocationOn from "@material-ui/icons/LocationOn";
import Feedback from "@material-ui/icons/Feedback";

import withStyles from "@material-ui/core/styles/withStyles";
import PropTypes from "prop-types";
import DeliveryTypesWindow from "./DeliveryTypesWindow";

const styles = () => ({
  wrapper: {
    position: "absolute",
    display: "flex",
    flexDirection: "row",
    flexShrink: 1,
    zIndex: 100,
    top: 10,
    left: 10,
    background: "black",
    opacity: 0.6,
    color: "white"
  },
  statusPanel: {
    position: "relative",
    display: "flex",
    flexShrink: 1,
    alignItems: "center",
    padding: 5
  },
  deliveryPanel: {
    position: "relative",
    display: "flex",
    alignItems: "center",
    padding: "1.5px 5px 1.5px 0",
    cursor: "pointer",
    "&:hover > svg": {
      opacity: 0.5
    }
  },
  deliveries: {
    display: "flex",
    flexDirection: "column",
    fontSize: 10
  },
  icon: {
    padding: 5,
    cursor: "pointer",
    "&:hover": {
      opacity: 0.5
    }
  }
});

class GpsStatus extends React.PureComponent {
  state = { isDeliveryShown: false };

  toggleDeliveryModal = () => {
    this.setState({ isDeliveryShown: !this.state.isDeliveryShown });
  };

  render() {
    const {
      classes, status, changeGpsStatus, deliveryTypes, changeGpsDeliveryType
    } = this.props;
    const { isDeliveryShown } = this.state;
    return (
      <>
        <div className={classes.wrapper}>
          <div className={classes.statusPanel}>
            <span>
              {status && status.active === 1 ? (
                <Tooltip title="Отключить мониторинг" aria-label="Change">
                  <LocationOn
                    className={classes.icon}
                    color="secondary"
                    onClick={changeGpsStatus}
                  />
                </Tooltip>
              ) : (
                <Tooltip title="Включить мониторинг" aria-label="Change">
                  <LocationOff className={classes.icon} color="error" onClick={changeGpsStatus} />
                </Tooltip>
              )}
            </span>
            <span>
              <Typography color="inherit">
                {status && status.balance ? status.balance : 0} руб.
              </Typography>
            </span>
          </div>
          <Tooltip title="Способы уведомления GPS" aria-label="Change">
            <div className={classes.deliveryPanel} onClick={this.toggleDeliveryModal}>
              <Feedback className={classes.icon} color="secondary" />
              <Typography color="inherit" className={classes.deliveries}>
                {deliveryTypes.map(item => {
                  if (item.state === true) {
                    return (
                      <span key={item.deliveryTypeId}>{item.deliveryTypeName.split(" ")[0]}</span>
                    );
                  }
                  return null;
                })}
              </Typography>
            </div>
          </Tooltip>
        </div>
        <DeliveryTypesWindow
          {...{
            toggleDeliveryModal: this.toggleDeliveryModal,
            isDeliveryShown,
            deliveryTypes,
            changeGpsDeliveryType
          }}
        />
      </>
    );
  }
}

GpsStatus.propTypes = {
  classes: PropTypes.object,
  status: PropTypes.object.isRequired,
  changeGpsStatus: PropTypes.func.isRequired,
  deliveryTypes: PropTypes.array,
  changeGpsDeliveryType: PropTypes.func.isRequired
};

export default withStyles(styles)(GpsStatus);
