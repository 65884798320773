import React, { Component } from "react";

import Typography from "@material-ui/core/Typography";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Hidden from "@material-ui/core/Hidden";

import InvoiceDetails from "./InvoiceDetails";
import Invoice from "./Invoice";
import InvoiceDetailsPopup from "./InvoiceDetailsPopup";

import { withStyles } from "@material-ui/core/styles";

const styles = theme => ({
  container: {
    display: "flex",
    padding: "5px 0 5px 5px",
    height: "calc(100% - 20px)"
  },
  invoicesContainer: {
    width: 300,
    flexShrink: 0,
    [theme.breakpoints.down("xs")]: {
      width: "100%"
    }
  },
  detailsContainder: {
    width: "100%",
    padding: "5px 5px 10px 10px",
    height: "100%",
    overflowY: "scroll",
    [theme.breakpoints.down("xs")]: {
      overflowY: "auto"
    }
  },
  yearTitle: {
    lineHeight: "50px",
    paddingLeft: 25
  },
  yearSelect: {
    paddingLeft: 10,
    fontSize: 20,
    color: "inherit"
  }
});

class Invoices extends Component {
  state = {
    expandedInvoice: "",
    detailIsOpen: false,
    year: null,
    month: ""
  };

  changeYear = event => {
    this.props.getInvoices(event.target.value);
    this.setState({ year: event.target.value });
  };

  changeMonth = month => {
    this.props.getInvoiceDetails(month);
    this.setState({ month: month });
    this.detailsPopupOpen();
  };

  changeExpand = panel => (event, expanded) => {
    this.setState({
      expandedInvoice: expanded ? panel : false
    });
  };

  detailsPopupOpen = () => {
    this.setState({
      detailIsOpen: true
    });
  };

  detailsPopupClose = () => {
    this.setState({
      detailIsOpen: false
    });
  };

  render() {
    const { classes, invoices, invoicePeriods, getInvoiceDetails, invoiceDetails } = this.props;
    const { year, month, detailIsOpen } = this.state;

    return invoices === undefined ? (
      <Typography variant="h6" align="center">
        Счета отсутствуют
      </Typography>
    ) : (
      <div className={classes.container}>
        <div className={classes.invoicesContainer}>
          <Typography variant="h6" className={classes.yearTitle}>
            Выберите год:
            <Select
              disableUnderline
              value={!year && invoicePeriods ? invoicePeriods[0] : year ? year : new Date().getFullYear() + ""}
              onChange={this.changeYear}
              className={classes.yearSelect}
            >
              {invoicePeriods.map(year => (
                <MenuItem key={year} value={year}>
                  {year}
                </MenuItem>
              ))}
            </Select>
          </Typography>
          {invoices.map(invoice => (
            <Invoice
              key={invoice.invoicesPeriod}
              invoice={invoice}
              getInvoiceDetails={getInvoiceDetails}
              changeExpand={this.changeExpand}
              expandedInvoice={this.state.expandedInvoice}
              detailsPopupOpen={this.detailsPopupOpen}
              changeMonth={this.changeMonth}
            />
          ))}
        </div>
        <Hidden xsDown>
          <div className={classes.detailsContainder}>
            <InvoiceDetails {...{ month, invoiceDetails }} />
          </div>
        </Hidden>
        <Hidden smUp>
          <InvoiceDetailsPopup
            {...{ detailIsOpen, month, invoiceDetails }}
            detailsPopupClose={this.detailsPopupClose}
          />
        </Hidden>
      </div>
    );
  }
}

export default withStyles(styles)(Invoices);
