import React from "react";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Radio from "@material-ui/core/Radio";
import Divider from "@material-ui/core/Divider";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import IconButton from "@material-ui/core/IconButton";
import HelpOutline from "@material-ui/icons/HelpOutline";

import InfoWindow from "../../InfoWindow";

import { withStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Input from "@material-ui/core/Input";

const styles = theme => ({
  listItem: {
    padding: 0
  },
  buttons: {
    position: "relative",
    display: "flex",
    justifyContent: "flex-start",
    marginTop: 15
  },
  radio: {
    paddingRight: 0,
    paddingLeft: 0
  },
  nextButton: {
    flexGrow: 1,
    margin: "0 5px"
  }
});

const Step1 = props => {
  const { classes, infoWindowIsOpen, infoText, infoWindowOpen, infoWindowClose,
    profile,
    referralCode,
    changeReferral
  } = props;

  const needReferralCode = profile.menu.filter(item => item.id === 10).length !== 0;
  return (
    <>
      <List>
        {props.tariffs.map(item => (
          <Typography key={item.tarifId} variant="subtitle1">
            <ListItem
              button
              className={classes.listItem}
              name={item.tarifId}
              onClick={props.selectTariff(item.tarifId)}
            >
              <Radio className={classes.radio} checked={props.selectedTariff === item.tarifId} />
              <ListItemText primary={item.name} />
              <ListItemSecondaryAction>
                <IconButton onClick={e => infoWindowOpen(item.description)}>
                  <HelpOutline />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          </Typography>
        ))}
      </List>
      <Divider />
      {needReferralCode && (
        <FormControl>
          <InputLabel htmlFor="referral">Реферал</InputLabel>
          <Input
            // readOnly={!!localStorage.getItem("referral")}
            type="number"
            value={referralCode || ""}
            onChange={e => changeReferral(e.target.value)}
            autoFocus
          />
        </FormControl>
      )}
      <div className={classes.buttons}>
        <Button
          className={classes.nextButton}
          disabled={props.selectedTariff === undefined}
          variant="outlined"
          color="secondary"
          size="large"
          onClick={e => props.nextStep()}
        >
          Далее
        </Button>
      </div>
      <InfoWindow {...{ infoWindowIsOpen, infoText, infoWindowClose }} />
    </>
  );
};

export default withStyles(styles)(Step1);
