import {handleActions} from "redux-actions";
import {
  shopDirectoriesSuccess,
  shopOrdersSuccess,
  shopPayUrlSuccess,
  shopPayUrlClear,
  shopOrderDetailsClear,
  shopAltCardsTypesSuccess,
  shopAltCardsKindsSuccess,
  shopAltCardsColorsSuccess,
  shopAltCardSetPriceSuccess,
  shopAltCardAddOrderSuccess,
  shopAltCardOrderDetailsSuccess,
  shopAltCardClear,
  shopTrackDevicesTypesSuccess,
  shopTrackDevicesModelsSuccess,
  shopTrackDevicesClear,
  shopTrackDevicesOrderClear,
  shopTrackDevicesOrderDetailsSuccess,
  shopTrackDevicesOrderItemAdd,
  shopTrackDevicesDeliveryPriceSuccess,
  shopTrackDevicesAddOrderSuccess,
} from "actions/shopActions"

const initialState = {
  directories: undefined,
  orders: undefined,
  altCardTypes: undefined,
  altCardKinds: undefined,
  altCardColors: undefined,
  altCardSetPriceCent: undefined,
  trackDevicesTypes: undefined,
  trackDevicesModels: undefined,
  trackDevicesPriceCent: undefined,
  trackDevicesDeliveryPriceCent: undefined,
  shopOrderDetails: undefined,
  trackDevicesOrderItems: [],
  errors: {},
  payUrl: undefined,
}

const shop = handleActions(
  {
    [shopDirectoriesSuccess]: (state, action) => ({
      ...state,
      directories: action.payload.directories
    }),
    [shopOrdersSuccess]: (state, action) => ({
      ...state,
      orders: action.payload.orders
    }),
    [shopPayUrlSuccess]: (state, action) => ({
      ...state,
      payUrl: action.payload.payUrl
    }),
    [shopPayUrlClear]: (state) => ({
      ...state,
      payUrl: initialState['payUrl'],
    }),
    [shopOrderDetailsClear]: (state) => ({
      ...state,
      shopOrderDetails: initialState['shopOrderDetails'],
    }),
    [shopAltCardsTypesSuccess]: (state, action) => ({
      ...state,
      altCardTypes: action.payload.altCardTypes
    }),
    [shopAltCardsKindsSuccess]: (state, action) => ({
      ...state,
      altCardKinds: action.payload.altCardKinds
    }),
    [shopAltCardsColorsSuccess]: (state, action) => ({
      ...state,
      altCardColors: action.payload.altCardColors
    }),
    [shopAltCardSetPriceSuccess]: (state, action) => ({
      ...state,
      altCardSetPriceCent: action.payload.altCardSetPriceCent
    }),
    [shopAltCardAddOrderSuccess]: (state, action) => ({
      ...state,
      shopOrderDetails: action.payload.altCardNewOrder
    }),
    [shopAltCardOrderDetailsSuccess]: (state, action) => ({
      ...state,
      shopOrderDetails: action.payload.altCardOrderDetails
    }),
    [shopAltCardClear]: (state) => ({
      ...state,
      altCardKinds: initialState['altCardKinds'],
      altCardColors: initialState['altCardColors'],
      altCardSetPriceCent: initialState['altCardSetPriceCent'],
    }),
    [shopTrackDevicesTypesSuccess]: (state, action) => ({
      ...state,
      trackDevicesTypes: action.payload.trackDevicesTypes
    }),
    [shopTrackDevicesModelsSuccess]: (state, action) => ({
      ...state,
      trackDevicesModels: action.payload.trackDevicesModels
    }),
    [shopTrackDevicesClear]: (state) => ({
      ...state,
      trackDevicesModels: initialState['trackDevicesModels'],
      trackDevicesPriceCent: initialState['trackDevicesPriceCent'],
    }),
    [shopTrackDevicesOrderDetailsSuccess]: (state, action) => ({
      ...state,
      shopOrderDetails: action.payload.trackDevicesOrderDetails
    }),
    [shopTrackDevicesOrderItemAdd]: (state, {payload: {trackDeviceOrderItem}}) => ({
      ...state,
      trackDevicesOrderItems: [...[trackDeviceOrderItem]]
    }),
    [shopTrackDevicesOrderClear]: (state) => ({
      ...state,
      trackDevicesOrderItems: []
    }),
    [shopTrackDevicesDeliveryPriceSuccess]: (state, {payload: {deliveryPriceCent}}) => ({
      ...state,
      trackDevicesDeliveryPriceCent: deliveryPriceCent
    }),
    [shopTrackDevicesAddOrderSuccess]: (state, {payload: {trackDevicesOrderDetails}}) => ({
      ...state,
      shopOrderDetails: trackDevicesOrderDetails
    }),
  },
  initialState
)
export default shop;
