export const formatDate = date => {
  const day = date;
  let dd = day.getDate();
  let mm = day.getMonth() + 1; //January is 0!

  const yyyy = day.getFullYear();
  if (dd < 10) {
    dd = '0' + dd;
  }
  if (mm < 10) {
    mm = '0' + mm;
  }
  return dd + '.' + mm + '.' + yyyy;
};

export const formatTime = date => {
  const day = date;
  let hh = day.getHours();
  let mm = day.getMinutes();
  let ss = day.getSeconds();
  if (hh < 10) {
    hh = '0' + hh;
  }
  if (mm < 10) {
    mm = '0' + mm;
  }
  if (ss < 10) {
    ss = '0' + ss;
  }
  return hh + ':' + mm + ':' + ss;
};
