import axios from "axios";
import {API, handleApiError} from "../api";
import {createAction} from "redux-actions";
import {showInfoMessage} from "./infoMessageActions";

export const registrationClear = createAction("REGFORM_CLEAR");

export const docTypesRequest = createAction("DOC_TYPES_REQUEST");
export const docTypesSuccess = createAction("DOC_TYPES_SUCCESS");
export const docTypesError = createAction("DOC_TYPES_ERROR");

export const relationDegreeRequest = createAction("RELATION_DEGREE_REQUEST");
export const relationDegreeSuccess = createAction("RELATION_DEGREE_SUCCESS");
export const relationDegreeError = createAction("RELATION_DEGREE_ERROR");

export const classesBySchoolRequest = createAction("CLASSES_BY_SCHOOL_REQUEST");
export const classesBySchoolSuccess = createAction("CLASSES_BY_SCHOOL_SUCCESS");
export const classesBySchoolError = createAction("CLASSES_BY_SCHOOL_ERROR");

export const schoolsByRegionRequest = createAction("SCHOOLS_BY_REGIONS_REQUEST");
export const schoolsByRegionSuccess = createAction("SCHOOLS_BY_REGIONS_SUCCESS");
export const schoolsByRegionError = createAction("SCHOOLS_BY_REGIONS_ERROR");

export const sendConfirmCodeRequest = createAction("SEND_CONFIRM_CODE_REQUEST");
export const sendConfirmCodeSuccess = createAction("SEND_CONFIRM_CODE_SUCCESS");
export const sendConfirmCodeError = createAction("SEND_CONFIRM_CODE_ERROR");

export const changeStepSuccess = createAction("CHANGE_STEP");

export const checkPhoneRequest = createAction("CHECK_PHONE_REQUEST");
export const checkPhoneSuccess = createAction("CHECK_PHONE_SUCCESS");
export const checkPhoneError = createAction("CHECK_PHONE_SUCCESS");

export const getRegformRequest = createAction("GET_REGFORM_REQUEST");
export const getRegformSuccess = createAction("GET_REGFORM_SUCCESS");
export const getRegformError = createAction("GET_REGFORM_ERROR");

export const sendRegformRequest = createAction("SEND_REGFORM_REQUEST");
export const sendRegformSuccess = createAction("SEND_REGFORM_SUCCESS");
export const sendRegformError = createAction("SEND_REGFORM_ERROR");
export const sendRegformDataError = createAction("SEND_REGFORM_DATA_ERROR");

export const fetchArchiveRequest = createAction("FETCH_ARCHIVE_REQUEST");
export const fetchArchiveSuccess = createAction("FETCH_ARCHIVE_SUCCESS");
export const fetchArchiveError = createAction("FETCH_ARCHIVE_ERROR");

export const clearRegform = () => dispatch => dispatch(registrationClear());

export const getDocTypes = () => async dispatch => {
  try {
    dispatch(docTypesRequest());
    const res = await axios.get(`${API}/user/doc-types`);
    dispatch(docTypesSuccess({docTypes: res.data}));
  } catch (error) {
    dispatch(handleApiError(error, docTypesError()));
  }
};

export const getRelationDegree = () => async dispatch => {
  try {
    dispatch(relationDegreeRequest());
    const res = await axios.get(`${API}/user/degrees-of-kinship`);
    dispatch(relationDegreeSuccess({relationDegree: res.data}));
  } catch (error) {
    dispatch(handleApiError(error, relationDegreeError()));
  }
};

export const getSchoolsByRegion = (regionId, dilerId = null, accountId = null) => async dispatch => {
  try {
    dispatch(schoolsByRegionRequest());
    if (typeof dilerId === 'number' ) dilerId = [dilerId];
    const res = await axios.get(encodeURI(`${API}/school/${regionId}` +
      `?dilerId=${(!dilerId)? null : dilerId.join(',')}` +
      `&accountId=${(!accountId)? null : accountId}`));
    dispatch(schoolsByRegionSuccess({schools: res.data}));
  } catch (error) {
    dispatch(handleApiError(error, schoolsByRegionError()));
  }
};

export const getClassesBySchool = schoolId => async dispatch => {
  try {
    dispatch(classesBySchoolRequest());
    const res = await axios.get(`${API}/school/${schoolId}/classes`);
    dispatch(classesBySchoolSuccess({schoolId, classes: res.data}));
  } catch (error) {
    dispatch(handleApiError(error, classesBySchoolError()));
  }
};

export const changeStep = step => dispatch => dispatch(changeStepSuccess(step));

export const sendConfirmCode = (phone, schoolId, test = false) => async (dispatch, getState) => {
  try {
    dispatch(sendConfirmCodeRequest());
    const {codeSendDate} = getState().registration;
    if (codeSendDate) {
      const codeSendDiff = parseInt((new Date() - codeSendDate) / 1000, 10);
      if (codeSendDiff < 180) {
        return dispatch(
          showInfoMessage(`Повторный код можно запросить через ${180 - codeSendDiff} сек.`)
        );
      }
    }
    const url = test ? `${API}/user/e-reg-form/phone?test=1` : `${API}/user/e-reg-form/phone`;
    await axios.post(url, {
      phone,
      schoolId
    });
    dispatch(showInfoMessage("Код успешно отправлен"));
    dispatch(sendConfirmCodeSuccess());
  } catch (error) {
    dispatch(handleApiError(error, sendConfirmCodeError()));
  }
};

export const checkPhone = phone => async dispatch => {
  try {
    dispatch(checkPhoneRequest());
    const res = await axios.get(
      `${API}/user/is-new-phone/${phone}`
    )
    dispatch(checkPhoneSuccess({
      isNewPhone: res.data.isNewPhone,
      phone: phone
    }));
  } catch (error) {
    dispatch(handleApiError(error, checkPhoneError()));

  }
}

export const getRegform = parentId => async dispatch => {
  try {
    dispatch(getRegformRequest())
    if (!!parentId) {
      const res = await axios.get(
        `${API}/user/e-reg-form/${parentId}`
      )
      dispatch(getRegformSuccess({
        regformData: res.data
      }))
    } else {
      dispatch(getRegformSuccess({
        regformData: null
      }))
    }
  } catch (error) {
    dispatch(handleApiError(error, getRegformError()))
  }
}

export const sendRegform = regformData => async dispatch => {
  try {
    dispatch(sendRegformRequest());
    const {region, area, city, locality, structure, street, house, apartment, ...sendData} = regformData;

    sendData.fiasAddressObjectId =
      (structure && structure.value) || (locality && locality.value) || (city && city.value) || (area && area.value);

    sendData.fiasStreetName = street.formalName;
    sendData.fiasStreetType = street.streetType;
    sendData.fiasHouse = house.housenum;
    sendData.fiasBuild = house.buildnum || "";
    sendData.fiasStruc = house.strucnum || "";
    sendData.fiasAppartment = apartment || "";
    sendData.fiasPostIndex = parseInt(house.postalCode || street.postalCode);

    const res = await axios.post(`${API}/user/e-reg-form`, {
      ...sendData
    });
    dispatch(sendRegformSuccess({regformData: (regformData.parentId) ? sendData : null, link: res.data.link}));
    dispatch(showInfoMessage(res.data.message));
  } catch (error) {
    if (error.response && error.response.status === 400) {
      dispatch(
        sendRegformError({
          errors: error.response.data.message,
          error: "Проверьте правильность введенных данных",
          regformData: (regformData.parentId) ? regformData : null
        })
      );
    } else {
      dispatch(handleApiError(error, sendRegformError({regformData: (regformData.parentId) ? regformData : null})));
    }
  }
};

export const fetchArchive = link => async dispatch => {
  try {
    dispatch(fetchArchiveRequest());
    window.open(link);
    dispatch(fetchArchiveSuccess());
  } catch (error) {
    dispatch(handleApiError(error, fetchArchiveError()));
  }
};
