import React from 'react';
import { withStyles } from '@material-ui/core/styles';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';

const styles = theme => ({
  card: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    maxWidth: 350,
    minWidth: 250,
    boxShadow: 'none',
    textAlign: 'center',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      maxWidth: '100%',
      background: 'rgb(236, 236, 236)'
    }
  },
  media: {
    width: 200,
    maxWidth: 240,
    alignSelf: 'center',
    height: 'auto',
    [theme.breakpoints.down('xs')]: {
      paddingTop: 20,
      width: '50%'
    }
  }
});

const AccountPhoto = props => {
  const { classes } = props;
  return (
    <Card className={classes.card} square>
      <CardMedia
        component="img"
        classes={{ media: classes.media }}
        src={props.url ? props.url : '/no-photo.png'}
        title="Нет фото"
      />
      <CardContent>
        <Typography variant="h4" gutterBottom>
          {props.fio}
        </Typography>
      </CardContent>
    </Card>
  );
};

export default withStyles(styles)(AccountPhoto);
