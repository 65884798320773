import React from "react";
import PropTypes from "prop-types";

import Typography from "@material-ui/core/Typography";
import withStyles from "@material-ui/core/styles/withStyles";

const styles = () => ({
  panel: {
    width: "calc(100% - 50px)",
    maxHeight: 280,
    overflow: "auto",
    minWidth: 300,
    maxWidth: 600,
    position: "absolute",
    background: "white",
    opacity: 0.9,
    top: 80,
    left: "50%",
    transform: "translateX(-50%)",
    zIndex: 100,
    padding: 5,
    boxShadow: "0 0 2px 2px grey"
  }
});

const HelpText = (props) => {
  const { classes, ymaps, devices } = props;
  if (!ymaps) {
    return (
      <div className={classes.panel}>
        <Typography color="inherit" align="center">
          Загрузка карты. Пожалуйста, подождите.
        </Typography>
      </div>
    );
  }
  if (!devices || devices.length === 0) {
    return (
      <div className={classes.panel}>
        <Typography color="inherit">С услугой «GPS мониторинг» Вы сможете:</Typography>
        <ul>
          <li>
            <Typography>отслеживать местоположение ребенка в реальном времени</Typography>
          </li>
          <li>
            <Typography>просматривать передвижения ребенка в течении дня</Typography>
          </li>
          <li>
            <Typography>
              получать SMS-оповещения при пересечении ребенком областей отмеченных на карте
            </Typography>
          </li>
        </ul>
        <Typography color="inherit" align="justify">
          Для активации услуги необходимо добавить отслеживающее устройство, для этого нажмите на
          пункт &quot;Устройства&quot; внизу и следуйте дальнейшим инструкциям.
        </Typography>
      </div>
    );
  }
  return null;
};

HelpText.propTypes = {
  classes: PropTypes.object,
  ymaps: PropTypes.object,
  devices: PropTypes.array
};

export default withStyles(styles)(HelpText);
