import React, {Component} from "react";
import {connect} from "react-redux";
import withStyles from "@material-ui/core/styles/withStyles";
import PropTypes from "prop-types";
import AutosuggestField from "../AutosuggestField";
import InputField from "../InputField";
import Typography from "@material-ui/core/Typography";

import {
  getRegionsSelector,
  getAreasSelector,
  getCitiesSelector,
  getLocalitiesSelector,
  getStructuresSelector,
  getStreetsSelector,
  getHousesSelector
} from "selectors/fias";

import {
  getFiasRegions,
  getFiasAreas,
  getFiasCities,
  getFiasLocalities,
  getFiasStructures,
  getFiasStreets,
  getFiasHouses,
  clearFiasData
} from "actions/fiasActions";

const styles = theme => ({
  root: {
    zIndex: theme.zIndex.drawer + 100 + ' !important',
  }

})

class FiasForm extends Component {

  componentDidMount() {
    this.props.getFiasRegions(this.props.parentDilerIds, this.props.regionId);
  }

  clearFromField = fieldId => {
    this.props.setFieldValue("city", null);
  };

  componentDidUpdate(prevProps, prevState) {
    const {
      region: oldRegion,
      area: oldArea,
      city: oldCity,
      locality: oldLocality,
      structure: oldStructure,
      street: oldStreet
    } = prevProps.values;
    const {region, area, city, locality, structure, street} = this.props.values;
    const {clearFiasData} = this.props;

    // если изменился регион
    if (oldRegion !== region) {
      // если указан новый регион запрашиваем районы и города
      if (region) {
        const {value, AOGuid} = region;
        this.props.getFiasAreas(value, AOGuid, "");
        this.props.getFiasCities(value, AOGuid, "");
      } else {
        clearFiasData("areas");
        clearFiasData("cities");
        clearFiasData("localities");
        clearFiasData("structures");
        clearFiasData("streets");
        clearFiasData("houses");
      }
      this.props.setFieldValue("area", null);
      this.props.setFieldValue("city", null);
      this.props.setFieldValue("locality", null);
      this.props.setFieldValue("structure", null);
      this.props.setFieldValue("street", null);
      this.props.setFieldValue("house", null);
      this.props.setFieldValue("apartment", null);
    }

    // если изменился район
    if (oldArea !== area) {
      if (area) {
        this.props.getFiasCities(region.value, area.AOGuid, "");
        this.props.getFiasLocalities(region.value, area.AOGuid, "");
      } else {
        if (region) this.props.getFiasCities(region.value, region.AOGuid, "");
        clearFiasData("cities");
        clearFiasData("localities");
        clearFiasData("structures");
        clearFiasData("streets");
        clearFiasData("houses");
      }
      this.props.setFieldValue("city", null);
      this.props.setFieldValue("locality", null);
      this.props.setFieldValue("structure", null);
      this.props.setFieldValue("street", null);
      this.props.setFieldValue("house", null);
      this.props.setFieldValue("apartment", null);
    }

    // если изменился город
    if (oldCity !== city) {
      if (city) {
        this.props.getFiasLocalities(region.value, city.AOGuid, "");
        this.props.getFiasStructures(region.value, city.AOGuid, "");
        this.props.getFiasStreets(city.AOGuid, "");
      } else {
        clearFiasData("localities");
        clearFiasData("structures");
        clearFiasData("streets");
        clearFiasData("houses");
      }
      this.props.setFieldValue("locality", null);
      this.props.setFieldValue("structure", null);
      this.props.setFieldValue("street", null);
      this.props.setFieldValue("house", null);
      this.props.setFieldValue("apartment", null);
    }

    // если изменился населенный пункт
    if (oldLocality !== locality) {
      if (locality) {
        this.props.getFiasStructures(region.value, locality.AOGuid, "");
        this.props.getFiasStreets(locality.AOGuid, "");
      } else {
        // если удалили населенный пункт, но город выбран
        if (city) {
          this.props.getFiasStructures(region.value, city.AOGuid, "");
          this.props.getFiasStreets(city.AOGuid, "");
        } else {
          clearFiasData("structures");
          clearFiasData("streets");
          clearFiasData("houses");
        }
      }
      this.props.setFieldValue("structure", null);
      this.props.setFieldValue("street", null);
      this.props.setFieldValue("house", null);
      this.props.setFieldValue("apartment", null);
    }
    // если изменилась планировочная структура
    if (oldStructure !== structure) {
      if (structure) {
        this.props.getFiasStreets(structure.AOGuid, "");
      } else {

        if(locality) {
          this.props.getFiasStreets(locality.AOGuid, "");
        } else if (city) {// если удалили населенный пункт, но город выбран
          this.props.getFiasStreets(city.AOGuid, "");
        } else {
          clearFiasData("streets");
          clearFiasData("houses");
        }
      }
      this.props.setFieldValue("street", null);
      this.props.setFieldValue("house", null);
      this.props.setFieldValue("apartment", null);
    }
    // если изменилась улица
    if (oldStreet !== street) {
      if (street) {
        this.props.getFiasHouses((structure && structure.AOGuid) || (locality && locality.AOGuid) || (city && city.AOGuid), "%");
      } else {
        clearFiasData("houses");
      }
      this.props.setFieldValue("house", null);
      this.props.setFieldValue("apartment", null);
    }
  }
  onInputChange = (id, value) => {
    const {region, area, city, locality, structure, street} = this.props.values;
    if (value) {
      if (id === "city") {
        this.props.getFiasCities(
          region.value,
          (area && area.AOGuid) || (region && region.AOGuid),
          value
        );
      } else if (id === "locality") {
        this.props.getFiasLocalities(
          region.value,
          (city && city.AOGuid) || (area && area.AOGuid) || (region && region.AOGuid),
          value
        );
      } else if (id === "structure") {
        this.props.getFiasStructures(
          region.value,
          (locality && locality.AOGuid) || (city && city.AOGuid), value);
      } else if (id === "street") {
        this.props.getFiasStreets((structure && structure.AOGuid) || (locality && locality.AOGuid) || (city && city.AOGuid), value);
      } else if (id === "house") {
        this.props.getFiasHouses(
          (street && street.AOGuid) || (structure && structure.AOGuid) || (locality && locality.AOGuid) || (city && city.AOGuid),
          value
        );
      }
    }
  };

  render() {
    const {
      classes,
      values,
      regions,
      areas,
      cities,
      localities,
      structures,
      streets,
      houses,
      handleChange,
      setFieldValue,
      errors,
      showPartly,
      onKeyUp,
    } = this.props;

    const postalCode = (values.house && values.house.postalCode) ? values.house.postalCode
      : (values.street && values.street.postalCode) ? values.street.postalCode
        : null;

    return (
      <div className={classes.root}>
        <AutosuggestField
          classNamePrefix="fias"
          suggestions={regions}
          fullWidth
          isClearable
          id="region"
          label="Регион"
          required
          error={errors.fiasRegion}
          onChange={setFieldValue}
          selected={values.region}
          onInputChange={this.onInputChange}
        />
        {(!showPartly || !!values.region) && (
          <>
            <AutosuggestField
              classNamePrefix="fias"
              suggestions={areas}
              fullWidth
              isClearable
              id="area"
              label="Район"
              required
              disabled={!values.region}
              error={errors.fiasArea}
              onChange={setFieldValue}
              selected={values.area}
              onInputChange={this.onInputChange}
            />
            <AutosuggestField
              classNamePrefix="fias"
              suggestions={cities}
              fullWidth
              isClearable
              id="city"
              label="Город"
              required
              disabled={!values.region}
              error={errors.fiasCity}
              onChange={setFieldValue}
              selected={values.city}
              onInputChange={this.onInputChange}
            />
            <Typography>и/или</Typography>
            <AutosuggestField
              classNamePrefix="fias"
              suggestions={localities}
              fullWidth
              isClearable
              id="locality"
              label="Населенный пункт"
              disabled={!values.region}
              error={errors.fiasLocality}
              onChange={setFieldValue}
              selected={values.locality}
              onInputChange={this.onInputChange}
            />
          </>)}
        {(!showPartly || (!!values.city || !!values.locality)) && (
          <>
            <AutosuggestField
              classNamePrefix="fias"
              suggestions={structures}
              fullWidth
              isClearable
              id="structure"
              label="Планеровочная структура"
              // required
              disabled={!values.city && !values.locality}
              error={errors.fiasStructure}
              onChange={setFieldValue}
              selected={values.structure}
              onInputChange={this.onInputChange}
            />
            <AutosuggestField
              classNamePrefix="fias"
              suggestions={streets}
              fullWidth
              isClearable
              id="street"
              label="Улица"
              required
              disabled={!values.city && !values.locality}
              error={errors.fiasStreet}
              onChange={setFieldValue}
              selected={values.street}
              onInputChange={this.onInputChange}
            />
          </>)}
        {(!showPartly || (values.city || values.locality || values.street)) && (
          <AutosuggestField
            classNamePrefix="fias"
            suggestions={houses}
            fullWidth
            isClearable
            id="house"
            label="Дом"
            required
            disabled={!values.city && !values.locality}
            error={errors.fiasHouse}
            onChange={setFieldValue}
            selected={values.house}
            onInputChange={this.onInputChange}
          />)}
        <div style={{display: "flex", alignItems: 'center'}}>
          {(!showPartly || !!values.house) && (
            <InputField
              id="apartment"
              label="Квартира"
              disabled={!values.house}
              error={errors.fiasAppartment}
              onChange={handleChange}
              value={values.apartment || ""}
              onKeyUp={onKeyUp}
            />)}
          <Typography variant="button">Индекс: {(postalCode) ? postalCode : 'не определен'}</Typography>&#32;
        </div>
      </div>
    )
  }
}

FiasForm.propTypes = {
  regionId: PropTypes.number.isRequired,
  parentDilerId: PropTypes.oneOfType([PropTypes.oneOf([null]), PropTypes.number, PropTypes.array]).isRequired,
  getFiasRegions: PropTypes.func.isRequired,
  getFiasAreas: PropTypes.func.isRequired,
  getFiasCities: PropTypes.func.isRequired,
  getFiasLocalities: PropTypes.func.isRequired,
  getFiasHouses: PropTypes.func.isRequired,
  getFiasStreets: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  values: PropTypes.shape({
    region: PropTypes.object,
    area: PropTypes.object,
    city: PropTypes.object,
    locality: PropTypes.object,
    street: PropTypes.object,
    house: PropTypes.object
  }),
  errors: PropTypes.shape({
    fiasRegion: PropTypes.string,
    fiasArea: PropTypes.string,
    fiasCity: PropTypes.string,
    fiasLocality: PropTypes.string,
    fiasStructure: PropTypes.string,
    fiasStreet: PropTypes.string,
    fiasHouse: PropTypes.string,
    fiasAppartment: PropTypes.string,
  }).isRequired
}

const mapStateToProps = state => ({
  regions: getRegionsSelector(state),
  areas: getAreasSelector(state),
  cities: getCitiesSelector(state),
  localities: getLocalitiesSelector(state),
  structures: getStructuresSelector(state),
  streets: getStreetsSelector(state),
  houses: getHousesSelector(state),
})

const mapDispatchToProps = {
  getFiasRegions,
  getFiasAreas,
  getFiasCities,
  getFiasLocalities,
  getFiasStructures,
  getFiasStreets,
  getFiasHouses,
  clearFiasData
}

export default connect(
  mapStateToProps,
  mapDispatchToProps)
(withStyles(styles)(FiasForm))
