import React from 'react';
import PropTypes from 'prop-types';
import {OutlinedInput, withStyles} from "@material-ui/core";
import {FormHelperText, InputLabel, MenuItem, Select, FormControl} from "@material-ui/core";


const style = theme => ({
  formControl: {
    margin: theme.spacing.unit,
    minWidth: 120,
  },
  formControlFull: {
    margin: theme.spacing.unit,
    width: "100%",
    minWidth: 120,
  },

});

const ComboBox = props => {
  const {
    classes,
    label, labelWidth, helperText, items, selectedValue, handleChange,
    fullWidth
  } = props;

  return (
    <FormControl className={fullWidth ? classes.formControlFull: classes.formControl}
                 variant="outlined"
    >
      {!!label ? (<InputLabel id="combobox-input">{label}</InputLabel>) : null}
      <Select
        value={selectedValue !== null ? selectedValue : -1}
        onChange={handleChange}
        input={<OutlinedInput labelWidth={!!labelWidth ? labelWidth : 0} name="combobox-outlined"
                              id="combobox-outlined"/>}
        label={label}
        displayEmpty
      >
        {items.map(item => (
          <MenuItem value={item.value} key={item.value}>{item.label}</MenuItem>
        ))}
      </Select>
      {!!helperText ? (<FormHelperText>{helperText}</FormHelperText>) : null}
    </FormControl>
  )
};

ComboBox.propTypes = {
  label: PropTypes.string,
  labelWidth: PropTypes.number,
  helperText: PropTypes.string,
  items: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.node.isRequired,
    label: PropTypes.string.isRequired,
  })),
  selectedValue: PropTypes.node,
  handleChange: PropTypes.func.isRequired,
  fullWidth: PropTypes.bool,
}

export default withStyles(style)(ComboBox);