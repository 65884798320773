import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import Typography from "@material-ui/core/Typography";
import SelectPeriod from "./SelectPeriod";

import Button from "@material-ui/core/Button";
import withStyles from "@material-ui/core/styles/withStyles";

import panelsStyles from "../styles";

const styles = theme => ({
  ...panelsStyles(theme),
  row: {
    display: "flex",
    alignItems: "flex-end"
  }
});

const History = props => {
  const {
    classes,
    activeButton,
    historyStart,
    historyEnd,
    selectedDevice,
    selectDateAndTime,
    getDeviceHistory
  } = props;
  return (
    <div
      className={classNames(classes.panelHistory, {
        [classes.panelHidden]: !activeButton,
        [classes.panelActive]: activeButton
      })}
    >
      <Typography color="inherit" variant="h6">История местоположения</Typography>
      {selectedDevice ? (
        <div className={classes.row}>
          <SelectPeriod
            historyStart={historyStart}
            historyEnd={historyEnd}
            selectDateAndTime={selectDateAndTime}
          />
          <Button color="primary" size="small" onClick={getDeviceHistory}>
            OK
          </Button>
        </div>
      ) : (
        <Typography color="inherit">
          Для отображения истории перемещений выберите устройство, по которому
          требуется загрузка истории.
        </Typography>
      )}
    </div>
  );
};

export default withStyles(styles)(History);

History.propTypes = {
  activeButton: PropTypes.bool.isRequired,
  historyStart: PropTypes.instanceOf(Date).isRequired,
  historyEnd: PropTypes.instanceOf(Date).isRequired,
  selectDateAndTime: PropTypes.func.isRequired,
  getDeviceHistory: PropTypes.func.isRequired
};
