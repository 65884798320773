import React, {Component} from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";

import Button from "@material-ui/core/Button";
// import CheckIcon from '@material-ui/icons/Check';
import {sendConfirmCode, changeStep} from "actions/registrationActions";

import SmsCode from "./SmsCode";

import {withStyles} from "@material-ui/core/styles";
import {List, ListItem, Typography} from "@material-ui/core";
import {registrationRes} from "../resources";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";

const styles = theme => ({
  buttons: {
    position: "relative",
    display: "flex",
    justifyContent: "flex-start",
    marginTop: 15
  },
  nextButton: {
    flexGrow: 1
  },
  aLink: {
    color: "#00F",
    ":visited": {
      color: "#00F",
    }
  }
});

class Step5 extends Component {
  sendCode = () => {
    const {phone, children} = this.props.values;
    if (phone && typeof children[0].schoolId === "number") {
      this.props.sendConfirmCode(phone, children[0].schoolId);
    }
  };

  handleInputChange = (id) => event => {
    const {setFieldValue} = this.props;
    const value = event.target.value === id
      ? event.target.checked
      : event.target.value;
    setFieldValue(id, value);
  };

  render() {
    const {classes, values, handleChange, errors, codeSendDate, link, changeStep, parentDilerId, handleEnter} = this.props;
    const regData = registrationRes[parentDilerId] || registrationRes[registrationRes.default];
    const {contractLink, privatePolicyLink} = regData
    return (
      <>
        <Typography>Нажимая кнопку "Отправить заполненные данные", Вы:</Typography>
        <List dense={true}>
          <ListItem>
            <FormControlLabel
              control={
                <Checkbox
                  id="grantData"
                  checked={values.grantData}
                  onChange={this.handleInputChange("grantData")}
                  onKeyUp={handleEnter}
                  value="grantData"
                  color="primary"
                />
              }
              label={<Typography>даете свое согласие на обработку и хранение указанных ПДн
                (Прочитать <a className={classes.aLink}
                              href={privatePolicyLink}
                              target="_blank" rel="noopener noreferrer">политику
                  в области обеспечения безопасности</a>)
              </Typography>}
            />
          </ListItem>
          <ListItem>
            <FormControlLabel
              control={
                <Checkbox
                  id="acceptLegal"
                  checked={values.acceptLegal}
                  onChange={this.handleInputChange("acceptLegal")}
                  onKeyUp={handleEnter}
                  value="acceptLegal"
                  color="primary"
                />
              }
              label={<Typography>соглашаетесь с условиями договора (оферты) (Прочитать <a className={classes.aLink}
                                                                                          href={contractLink} target="_blank"
                                                                                          rel="noopener noreferrer">договор
                (оферту)</a>) </Typography>}
            />
          </ListItem>
        </List>
        {!values.parentId && (<SmsCode {...{values, handleChange, errors, sendCode: this.sendCode, codeSendDate}} />)}
        {(
          <div className={classes.buttons}>
            <Button
              className={classes.nextButton}
              variant="outlined"
              color="secondary"
              size="large"
              type="submit"
              disabled={((!values.parentId && !values.smsCode) || (!values.grantData || !values.acceptLegal))}
            >
              Отправить заполненные данные
            </Button>
          </div>
        )}
        <br/>
        {link && (
          <>
            <Button color="primary" size="large" onClick={() => changeStep(5)}>
              Сохранить регистрационную форму
            </Button>
            <Typography variant="caption">Просим сохранить регистрационную форму, во избежание спорных
              вопросов.</Typography>
          </>
        )}
      </>
    );
  }
}

Step5.propTypes = {
  classes: PropTypes.object,
  changeStep: PropTypes.func.isRequired,
  values: PropTypes.object,
  errors: PropTypes.object,
  handleChange: PropTypes.func.isRequired,
  sendConfirmCode: PropTypes.func.isRequired,
  codeSendDate: PropTypes.instanceOf(Date),
  link: PropTypes.string
};

const mapStateToProps = state => ({
  codeSendDate: state.registration.codeSendDate,
  link: state.registration.link,
  errors: state.registration.errors
});

const mapDispatchToProps = {
  sendConfirmCode,
  changeStep
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(Step5));
