import React from "react";

import Typography from "@material-ui/core/Typography";
import Call from "@material-ui/icons/Call";
import EMail from "@material-ui/icons/Email";

import { withStyles } from "@material-ui/core/styles";

const styles = theme => ({
    phoneBlock: {
        flexGrow: 1,
        display: "flex",
        alignItems: "center",
        paddingLeft: 50,
        color: "white"
      },
      phone: {
        fontSize: 24,
        fontWeight: 500,
        marginLeft: 10,
        cursor: "pointer",
      },
      phoneCrimea: {
        fontSize: 18,
        marginLeft: 10
      }
})

const PhoneBlock = props => {
  const { classes, dilerId } = props;
  return (
    <span className={classes.phoneBlock}>
      {[7, 26].indexOf(dilerId) >= 0
        ? (<Call fontSize="large" />)
      : (<EMail fontSize="large" />)}
      <Typography align="left" variant="h5" color="inherit">
        {dilerId === 7 ? (
          "8(8452)24-44-04"
        ) : dilerId === 26 ? (
          "8(8452)24-44-04"
        ) : (
          <React.Fragment>
            <div className={classes.phone} onClick={() => { document.location.href="mailto:support@ueshka.ru"}}>support@ueshka.ru</div>
            {/*<div className={classes.phone} onClick={() => { document.location.href="tel:8-804-333-01-53"}}>8-804-333-01-53</div>*/}
            {/*<div className={classes.phoneCrimea}>Крым: 8-800-333-2953</div>*/}
          </React.Fragment>
        )}
      </Typography>
    </span>
  );
};

export default withStyles(styles)(PhoneBlock);
