import React, { PureComponent } from "react";
import classNames from "classnames";
import Typography from "@material-ui/core/Typography";
import withStyles from "@material-ui/core/styles/withStyles";

const styles = theme => ({
  row: {
    width: "100%",
    position: "absolute",
    background: "black",
    opacity: 0.8,
    bottom: 0,
    left: 0,
    zIndex: 100,
    display: "flex",
    justifyContent: "space-around"
  },
  iconButtonBase: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    color: "white",
    cursor: "pointer",
    padding: "5px 0",
    "&:hover": {
      color: theme.palette.primary.main
    }
  },
  iconButton: {
    minWidth: 100,
    [theme.breakpoints.down("xs")]: {
      minWidth: 70
    }
  },
  iconButtonBaseColor: {
    color: "white"
  },
  iconButtonBlueColor: {
    color: theme.palette.primary.main
  }
});

class ButtonsPanel extends PureComponent {
  render() {
    const { classes, buttons, activeButton, toggleActiveButton, ymaps } = this.props;

    if (ymaps) {
      return (
        <div className={classes.row}>
          {buttons.map(button => {
            return (
              <div
                key={button.id}
                className={classNames(classes.iconButtonBase, classes.iconButton, {
                  [classes.iconButtonBaseColor]: !activeButton,
                  [classes.iconButtonBlueColor]: activeButton === button.id
                })}
                onClick={toggleActiveButton(button.id)}
              >
                {button.icon}
                <Typography color="inherit" variant="caption">
                  {button.name}
                </Typography>
              </div>
            );
          })}
          {this.props.children}
        </div>
      );
    } else {
      return null;
    }
  }
}

export default withStyles(styles)(ButtonsPanel);
