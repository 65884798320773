import React from 'react';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';

import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
  listItem: {
    padding: 0
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-start',
    marginTop: 15
  },
  nextButton: {
    flexGrow: 1,
    margin: '0 5px'
  }
});

const Step3 = props => {
  const { classes, packetModules, selectedModules, selectModule } = props;
  return (
    <>
      <List>
        {packetModules.map(item => (
          <ListItem key={item.id} button className={classes.listItem} onClick={selectModule(item.id)}>
            <Checkbox
              tabIndex={-1}
              disableRipple
              checked={selectedModules.length > 0 && selectedModules.includes(item.id)}
            />
            <ListItemText primary={item.name} />
          </ListItem>
        ))}
      </List>
      <Divider />
      <div className={classes.buttons}>
        <Button variant="outlined" size="small" onClick={e => props.prevStep()}>
          Назад
        </Button>
        <Button
          className={classes.nextButton}
          disabled={selectedModules.length === 0}
          variant="outlined"
          color='secondary'
          size="large"
          onClick={e => props.nextStep()}
        >
          Далее
        </Button>
      </div>
    </>
  );
};

export default withStyles(styles)(Step3);
