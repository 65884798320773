import React, {Component} from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";

import withStyles from "@material-ui/core/styles/withStyles";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent
} from "@material-ui/core";


const mapStateToProps = state => ({
  user: state.user,
  shop: state.shop,
});

const mapDispatchToProps = {};

const styles = theme => ({
  dialog: {
    height: '100%',
    width: '100%',
    maxWidth: theme.breakpoints.values.sm,
    maxHeight: theme.breakpoints.values.sm,
  },
  iframe:{
    width: '100%',
    height: '100%',
    maxHeight: theme.breakpoints.values.sm - 120,
  },
  dialogContext: {
    height: 'calc(100% - 115px)',
    width: 'calc(100% - 30px)',
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing.unit,
    top: theme.spacing.unit,
  }
})

class ShopOrderPaymentDlg extends Component {

  handleClose = () => {
    if (typeof this.props.onToggle !== 'function') return;
    this.props.onToggle();
  }
  render() {
    const {classes, shopPayUrl} = this.props;
    return (
      <Dialog
        id="order-payment-dlg"
        open={(!!shopPayUrl)}
        fullWidth
        maxWidth="sm"
        PaperProps={{className: classes.dialog}}
        onClose={this.handleClose}>
        {!!shopPayUrl ? (
          <DialogContent
            className={classes.dialogContext}
            id="order-payment-dlg-content">
            <iframe title="Оплата услуг УЭШКа" src={shopPayUrl} className={classes.iframe} />
          </DialogContent>
        ) : null}
        <DialogActions
          id="order-payment-dlg-actions"
        >
          <Button
            variant="contained"
            color="primary"
            onClick={this.handleClose}>Закрыть</Button>
        </DialogActions>
      </Dialog>
    )
  }
}


ShopOrderPaymentDlg.propTypes = {
  shopPayUrl: PropTypes.string,
  onToggle: PropTypes.func.isRequired,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(ShopOrderPaymentDlg));
