import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import { Typography } from "@material-ui/core";

const styles = theme => ({
  container: {
    padding: "130px 10px 10px 10px",
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.down("xs")]: {
      padding: "70px 10px 10px 10px"
    }
  },
  row: {
    display: "flex",
    alignItems: "flex-start"
  },
  buys: {
    // красный
    color: "rgba(153, 0, 0, 0.7)"
  },
  pays: {
    // зеленый
    color: "rgb(0, 153, 0, 0.7)"
  },
  transfers: {
    // синий
    color: "rgb(0, 153, 255, 0.7)"
  }
});

class CalendarInfo extends React.Component {
  state = { paddingTop: 130 };

  componentDidMount() {
    const calendarHeight = document.querySelector(".CalendarMonthGrid").clientHeight;
    const infoPadding = 130 + (calendarHeight - 440);
    this.setState({ paddingTop: infoPadding < 130 ? 130 : infoPadding });
  }

  componentDidUpdate(prevProps) {
    const calendarHeight = document.querySelector(".CalendarMonthGrid").clientHeight;
    const infoPadding = 130 + (calendarHeight - 440);
    if (this.state.paddingTop !== (infoPadding < 130 ? 130 : infoPadding)) {
      this.setState({ paddingTop: infoPadding < 130 ? 130 : infoPadding });
    }
  }

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.container} style={{ paddingTop: this.state.paddingTop }}>
        <Typography variant="body1">Обозначения:</Typography>
        <span>
          <Typography>
            - Красным цветом обозначены суммы, относящиеся непосредственно к покупке питания, сюда
            же входят возвраты за неверно начисленное питание, например,
            <span className={classes.buys}> -67</span>
          </Typography>
        </span>
        <span>
          <Typography>
            - Зеленым цветом обозначены суммы, относящиеся к платежам и пополнению счета питания
            ученика, например,
            <span className={classes.pays}> 300</span>
          </Typography>
        </span>
        <span>
          <Typography>
            - Синим цветом обозначены корректировки, т.е. переводы на питание или с питания на
            другие услуги, например,
            <span className={classes.transfers}> 150</span>
          </Typography>
        </span>
        <span>
          <Typography>
            - Красный <span className={classes.buys}>X</span> означает, что ученик находился на
            нулевом тарифе, информация по покупкам недоступна, при этом доступна информация о
            пополнениях и переводах
          </Typography>
        </span>
      </div>
    );
  }
}

export default withStyles(styles)(CalendarInfo);
