import React, { Component } from 'react';

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import withStyles from '@material-ui/core/styles/withStyles';

const styles = theme => ({
  modal: {
    [theme.breakpoints.down('xs')]: {
      alignItems: 'flex-start'
    }
  },
  paper: {
    margin: 10
  }
});

class ChangeEmail extends Component {
  state = { email: '' };

  submitButtonClick = () => {
    this.props.changeEmailSubmit(this.props.parentId, this.state.email);
    this.props.changeEmailToggle();
    this.setState({ email: '' });
  };

  emailOnChange = name => event => {
    this.setState({
      [name]: event.target.value
    });
  };

  onClose = () => {
    this.props.changeEmailToggle();
    this.setState({ email: '' });
  };

  render() {
    const { classes, infoText = '' } = this.props;
    return (
      <Dialog
        open={this.props.changeEmailIsOpen}
        onClose={this.onClose}
        classes={{ root: classes.modal, paper: classes.paper }}
      >
        <DialogTitle>Изменение электронной почты</DialogTitle>
        <DialogContent>
          <DialogContentText>{infoText ? infoText : 'Введите адрес электронной почты.'}</DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            name="email"
            type="email"
            label="Электронная почта"
            fullWidth
            value={this.state.email}
            onChange={this.emailOnChange('email')}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={this.onClose} color="primary">
            Отмена
          </Button>
          <Button onClick={this.submitButtonClick} color="primary" disabled={!this.state.email}>
            Сохранить
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default withStyles(styles)(ChangeEmail);
