import React from "react";
import Typography from "@material-ui/core/Typography";

import InputField from "../../../../InputField";
// import {List} from "@material-ui/core";
// import ListItem from "@material-ui/core/ListItem";
// import Radio from "@material-ui/core/Radio";
import {withStyles} from "@material-ui/core/styles";
// import ListItemText from "@material-ui/core/ListItemText";
// import Divider from "@material-ui/core/Divider";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import MenuItem from "@material-ui/core/MenuItem";
import Grid from "@material-ui/core/Grid";

const styles = theme => ({
  list: {
    padding: 0
  },
  radio: {
    paddingRight: 0,
    paddingLeft: 0
  },
  genders: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center"
  },
  formControl: {
    minWidth: 200,
    width: 200,
    margin: "10px 10px 10px 0"
  }
});

const ParentFio = props => {
  const {classes, insuranceOrgId, parent, parentData, changeParentData, changeParentBirthDate, validateError} = props;
  return (
    <>
      <Typography variant="button">ФИО страхователя</Typography>
      <div>
        <InputField
          label="Фамилия"
          required={true}
          error={validateError.notEmpty("insurerFam", parent, parentData)}
          defaultValue={parent.insurerFam ? parent.insurerFam : parentData.insurerFam}
          onChange={changeParentData("insurerFam")}
        />

        <InputField
          label="Имя"
          required={true}
          error={validateError.notEmpty("insurerIm", parent, parentData)}
          defaultValue={parent.insurerIm ? parent.insurerIm : parentData.insurerIm}
          onChange={changeParentData("insurerIm")}
        />

        <InputField
          label="Отчество"
          required={true}
          error={validateError.notEmpty("insurerOtch", parent, parentData)}
          defaultValue={parent.insurerOtch ? parent.insurerOtch : parentData.insurerOtch}
          onChange={changeParentData("insurerOtch")}
        />
      </div>
      {(insuranceOrgId === 2) && (
        <Grid container spacing={8} alignItems="flex-end">
          <FormControl variant="outlined" className={classes.formControl}>
            <InputLabel error={validateError.notEmpty("insurerGender", parent, parentData)}>Пол страхователя
              *</InputLabel>
            <Select
              value={parent.insurerGender ? parent.insurerGender : "Выберите пол"}
              onChange={changeParentData("insurerGender")}
              input={<OutlinedInput labelWidth={170} name="parent" id="insurerGender"/>}
              error={validateError.notEmpty("insurerGender", parent, parentData)}
            >
              {[{label: "Мужской", value: "M"}, {label: "Женский", value: "F"}].map(gender => (
                <MenuItem key={`insurer-gender-${gender.value}`} value={gender.value}>
                  {gender.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <InputField
            label="Дата рождения *"
            required
            datePicker
            maxDate={new Date().setFullYear(new Date().getFullYear() - 18)}
            error={validateError.notEmpty("insurerBirthDay", parent, parentData)}
            value={
              parent && parent.insurerBirthDay ? parent.insurerBirthDay : parentData.insurerBirthDay
            }
            onChange={changeParentBirthDate}
          />

          {/*<Typography variant="subtitle2">Пол страхователя</Typography>*/}
          {/*<Divider/>*/}
          {/*<List>*/}
          {/*  {[{label: "Мужской", value: "M"}, {label: "Женский", value: "F"}].map(gender => (*/}
          {/*    <React.Fragment key={`insurer-gender-${gender.value}`}>*/}
          {/*      <ListItem*/}
          {/*        className={classes.list}*/}
          {/*        button*/}
          {/*        onClick={e => changeParentData("insurerGender")({...e, target: {value: gender.value}})}*/}
          {/*      >*/}
          {/*        <Radio*/}
          {/*          className={classes.radio}*/}
          {/*          checked={parent.insurerGender === gender.value}*/}
          {/*        />*/}
          {/*        <ListItemText>*/}
          {/*          <div className={classes.genders}>*/}
          {/*            <Typography variant="subtitle1">*/}
          {/*              {gender.label}*/}
          {/*            </Typography>*/}
          {/*          </div>*/}
          {/*        </ListItemText>*/}
          {/*      </ListItem>*/}
          {/*    </React.Fragment>*/}
          {/*  ))}*/}
          {/*</List>*/}
        </Grid>
      )}
      <br/>
    </>
  );
};

export default withStyles(styles)(ParentFio);
