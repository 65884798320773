import { handleActions } from "redux-actions";
import { helpSuccess } from "actions/helpActions";

const initialState = {};

const help = handleActions(
  {
    [helpSuccess]: (state, action) => ({ ...state, ...action.payload.helpData })
  },
  initialState
);

export default help;
