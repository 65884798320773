import { handleActions } from "redux-actions";
import {
  availableInsurancesSuccess,
  completedInsurancesSuccess,
  selectedInsuranceRequest,
  selectedInsuranceSuccess,
  insuranceParentDataSuccess,
  insurancePupilDataRequest,
  insurancePupilDataSuccess
} from "actions/insuranceActions";

const initialState = {};

const insurance = handleActions(
  {
    [availableInsurancesSuccess]: (state, action) => {
      // переделываем массивы в объекты

      const available = action.payload.available;

      let accountDocTypes = {};

      accountDocTypes.ids = available.accountDocTypes.map(docType => {
        accountDocTypes[docType.id] = {
          name: docType.name
        };
        return docType.id;
      });

      let policies = {};

      policies.ids = available.policies.map(policy => {
        policies[policy.policyId] = {
          name: policy.policyName,
          insurances: {},
          description: ""
        };

        policies[policy.policyId].insurances.ids = policy.insurances.map((insurance, index) => {
          if (index === 0) {
            policies[policy.policyId].description = insurance.description;
          }
          policies[policy.policyId].insurances[insurance.id] = {
            insuredFee: insurance.insuredFee,
            insuredSum: insurance.insuredSum,
            insuredText: insurance.insuredText,
            orgId: insurance.orgId
          };
          return insurance.id;
        });

        return policy.policyId;
      });

      return {
        ...state,
        available: { accountDocTypes: accountDocTypes, policies: policies }
      };
    },
    [completedInsurancesSuccess]: (state, action) => ({
      ...state,
      completed: action.payload.completed
    }),
    [selectedInsuranceRequest]: state => ({
      ...state,
      selected: null
    }),
    [selectedInsuranceSuccess]: (state, action) => ({
      ...state,
      selected: action.payload.selected
    }),
    [insuranceParentDataSuccess]: (state, action) => ({
      ...state,
      parentData: action.payload.parentData
    }),
    [insurancePupilDataRequest]: state => ({
      ...state,
      pupilData: undefined
    }),
    [insurancePupilDataSuccess]: (state, action) => ({
      ...state,
      pupilData: action.payload.pupilData
    })
  },
  initialState
);

export default insurance;
