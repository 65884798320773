import axios from "axios";
import {createAction} from "redux-actions";
import {API, handleApiError} from "api";
import {showInfoMessage} from "./infoMessageActions";
import {getPupilProfile} from "./userActions";

export const currentTariffRequest = createAction("CURRENT_TARIFF_REQUEST");
export const currentTariffSuccess = createAction("CURRENT_TARIFF_SUCCESS");
export const currentTariffError = createAction("CURRENT_TARIFF_ERROR");

export const availableTariffsRequest = createAction("AVAILABLE_TARIFFS_REQUEST");
export const availableTariffsSuccess = createAction("AVAILABLE_TARIFFS_SUCCESS");
export const availableTariffsError = createAction("AVAILABLE_TARIFFS_ERROR");

export const tariffPriceRequest = createAction("TARIFF_PRICE_REQUEST_LOCAL");
export const tariffPriceSuccess = createAction("TARIFF_PRICE_SUCCESS");
export const tariffPriceError = createAction("TARIFF_PRICE_ERROR");

export const changeTariffRequest = createAction("CHANGE_TARIFF_REQUEST");
export const changeTariffSuccess = createAction("CHANGE_TARIFF_SUCCESS");
export const changeTariffError = createAction("CHANGE_TARIFF_ERROR");

export const getPayUrlReferralRequest = createAction("GET_PAY_URL_REFERRAL_REQUEST");
export const getPayUrlReferralSuccess = createAction("GET_PAY_URL_REFERRAL_SUCCESS");
export const getPayUrlReferralClear = createAction("GET_PAY_URL_REFERRAL_CLEAR");
export const getPayUrlReferralError = createAction("GET_PAY_URL_REFERRAL_ERROR");

export const tariffHistoryRequest = createAction("TARIFF_HISTORY_REQUEST");
export const tariffHistorySuccess = createAction("TARIFF_HISTORY_SUCCESS");
export const tariffHistoryError = createAction("TARIFF_HISTORY_ERROR");

const getCurrentTariff = pupilId => async dispatch => {
  try {
    dispatch(currentTariffRequest());
    return await axios.get(`${API}/tarif/${pupilId}`);
  } catch (error) {
    return dispatch(handleApiError(error, currentTariffError()));
  }
};

const getAvailableTariffs = pupilId => async dispatch => {
  try {
    dispatch(availableTariffsRequest());
    return await axios.get(`${API}/tarif/available/${pupilId}`);
  } catch (error) {
    return dispatch(handleApiError(error, availableTariffsError()));
  }
};

export const getCurrentAndAvailableTariffs = pupilId => dispatch => {
  Promise.all([dispatch(getCurrentTariff(pupilId)), dispatch(getAvailableTariffs(pupilId))]).then(
    res => {
      dispatch(
        currentTariffSuccess({
          current: res[0].data
        })
      );
      dispatch(
        availableTariffsSuccess({
          available: res[1].data
        })
      );
    }
  );
};

export const getTariffPrice = (
  pupilId,
  tariffId,
  deliveryTypes,
  packetModulesIds,
  referralCode
) => async dispatch => {
  try {
    dispatch(tariffPriceRequest());
    const res = await axios.get(
      `${API}/tarif/price/${pupilId}?tarifId=${tariffId}&deliveryTypes=${deliveryTypes}&packetModulesIds=${packetModulesIds}` +
      ((referralCode) ? `&referralCode=${referralCode}` : '')
    );
    dispatch(
      tariffPriceSuccess({
        selectedTariffProps: res.data
      })
    );
  } catch (error) {
    dispatch(handleApiError(error, tariffPriceError()));
  }
};

export const changeTariff = (
  pupilId,
  tariffId,
  deliveryTypes,
  packetModulesIds,
  referralCode
) => async dispatch => {
  try {
    dispatch(changeTariffRequest());
    const reqData = {
      tarifId: tariffId,
      deliveryTypes,
      packetModuleIds: packetModulesIds
    }
    if (referralCode) {
      reqData.referralCode = parseFloat(referralCode)
    }
    await axios.post(`${API}/tarif/${pupilId}`, reqData);
    dispatch(changeTariffSuccess());
    dispatch(showInfoMessage("Тариф успешно изменен"));
    dispatch(getPupilProfile(pupilId));
  } catch (error) {
    dispatch(handleApiError(error, changeTariffError()));
  }
};

export const getPayUrlReferral = (
  pupilId,
  parentId,
  tariffId,
  deliveryTypes,
  packetModulesIds,
  referralCode,
  amount,
) => async dispatch => {
  try {
    dispatch(getPayUrlReferralRequest());
    const res = await axios.get(
      `${API}/tarif/referral/${pupilId}?tarifId=${tariffId}` +
          `&deliveryTypes=${deliveryTypes}` +
          `&packetModulesIds=${packetModulesIds}` +
          `&parentId=${parentId}` +
          `&referralCode=${referralCode}` +
          `&amount=${amount}`
    );
    dispatch(getPayUrlReferralSuccess(
      {
        payUrlReferral: res.data.message
      }
    ));
  } catch (error) {
    dispatch(handleApiError(error, getPayUrlReferralError()));
  }
}

export const clearReferralPayUrl = () => dispatch => dispatch(getPayUrlReferralClear());

export const getTariffHistory = pupilId => async dispatch => {
  try {
    dispatch(tariffHistoryRequest());
    const res = await axios.get(`${API}/tarif/history/${pupilId}`);
    dispatch(tariffHistorySuccess({tariffHistory: res.data}));
  } catch (error) {
    dispatch(handleApiError(error, tariffHistoryError()));
  }
};
