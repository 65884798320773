import React, { Component } from "react";
import moment from "moment";

import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import Button from "@material-ui/core/Button";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import { withStyles } from "@material-ui/core/styles";

const styles = theme => ({
  container: {
    width: 280,
    display: "block",
    paddingRight: 5,
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      paddingRight: 0
    }
  },
  expansion: {
    border: "1px solid lightgrey"
  },
  invoiceContainer: {
    padding: 10
  },
  expansionTitle: {
    padding: "0 0 0 10px"
  },
  expansionHeading: {
    fontWeight: 500
  },
  finalSumm: {
    fontWeight: 500,
    padding: "10px 0"
  }
});

//TODO: добавить индикатор загрузки, пока не прогрузится последний счет

class LastInvoice extends Component {
  state = {
    expanded: false
  };

  componentWillMount() {
    if (this.props.defaultExpanded) {
      this.setState({ expanded: true });
    } else {
      this.setState({ expanded: false });
    }
  }

  finalSumm = () =>
    this.props.defaultPayType.balance < 0 ? (
      <Typography variant="subtitle1" className={this.props.classes.finalSumm} color="error">
        Задолженность: {Math.abs(this.props.defaultPayType.balance)} руб.
      </Typography>
    ) : this.props.defaultPayType.balance > 0 ? (
      <Typography variant="subtitle1" className={this.props.classes.finalSumm} color="secondary">
        Переплата: {Math.abs(this.props.defaultPayType.balance)} руб.
      </Typography>
    ) : (
      <Typography variant="subtitle1" className={this.props.classes.finalSumm}>
        Итого: 0.00 руб.
      </Typography>
    );

  payButtonClick = () => {
    this.props.lastInvoicePay(Math.abs(this.props.defaultPayType.balance));
    if (!this.props.defaultExpanded) {
      this.setState({ expanded: false });
    }
  };

  changeExpand = () => {
    this.setState({ expanded: !this.state.expanded });
  };

  render() {
    const { classes, lastInvoice, defaultPayType, parentCode, lastOperations } = this.props;

    if (!lastInvoice) {
      return (
        <div className={classes.container}>
          <Typography variant="subtitle2">Последний счет</Typography>
          <Typography variant="body1">Отсутствует</Typography>
        </div>
      );
    } else {
      return (
        <div className={classes.container}>
          <ExpansionPanel
            expanded={this.state.expanded}
            onChange={this.changeExpand}
            elevation={0}
            className={classes.expansion}
          >
            <ExpansionPanelSummary
              expandIcon={<ExpandMoreIcon />}
              className={classes.expansionTitle}
            >
              <Typography variant="subtitle2" className={classes.expansionHeading}>
                Последние операции
              </Typography>
            </ExpansionPanelSummary>
            <div className={classes.invoiceContainer}>
              <Typography variant="subtitle2" className={classes.expansionHeading}>
                Лицевой счет: {parentCode}
              </Typography>
              <Typography variant="subtitle2" className={classes.expansionHeading}>
                Последний счет за {moment(lastInvoice.invoicesPeriod, "YYYY-MM").format("MM.YYYY")}:
              </Typography>
              <Typography variant="body1">На начало: {lastInvoice.income} руб.</Typography>
              <Typography variant="body1">Списания: {lastInvoice.buys} руб.</Typography>
              <Typography variant="body1">Поступления: {lastInvoice.pays} руб.</Typography>
              <Typography variant="body1">На окончание: {lastInvoice.outcome} руб.</Typography>
              {lastOperations ? (
                <React.Fragment>
                  <Divider />
                  <Typography variant="subtitle2" className={classes.expansionHeading}>
                    Текущие операции:
                  </Typography>
                  <Typography variant="body1">Списания: {lastOperations.buys} руб.</Typography>
                  <Typography variant="body1">Поступления: {lastOperations.pays} руб.</Typography>
                </React.Fragment>
              ) : null}
              <Divider />
              {this.finalSumm()}
              {defaultPayType.balance < 0 ? (
                <Button
                  fullWidth
                  color="primary"
                  variant="contained"
                  onClick={e => this.payButtonClick()}
                >
                  Оплатить
                </Button>
              ) : null}
            </div>
          </ExpansionPanel>
        </div>
      );
    }
  }
}

export default withStyles(styles)(LastInvoice);
