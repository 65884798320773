import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import classNames from "classnames";
import Snackbar from "@material-ui/core/Snackbar";
import SnackbarContent from "@material-ui/core/SnackbarContent";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";

import messageStyles from "./styles";
import withStyles from "@material-ui/core/styles/withStyles";

import * as errorsActions from "actions/errorsActions";

const mapStateToProps = state => {
  return {
    errors: state.errors
  };
};

const mapDispatchToProps = dispatch => {
  return {
    errorsActions: bindActionCreators(errorsActions, dispatch)
  };
};

const styles = theme => ({
  ...messageStyles(theme),
  error: {
    backgroundColor: theme.palette.error.dark
  }
});

class ErrorMessage extends Component {
  render() {
    const { classes } = this.props;
    const { error } = this.props.errors;
    const { clearError } = this.props.errorsActions;
    return (
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={error ? true : false}
        onClose={clearError}
      >
        <SnackbarContent
          classes={{
            root: classNames(classes.content, classes.error),
            action: classes.action
          }}
          aria-describedby="message-id"
          message={<span id="message-id">{error}</span>}
          action={
            <IconButton
              key="close"
              aria-label="Close"
              color="inherit"
              className={classes.close}
              onClick={clearError}
            >
              <CloseIcon />
            </IconButton>
          }
        />
      </Snackbar>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(ErrorMessage));
