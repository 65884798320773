import React from "react";
import Tooltip from "@material-ui/core/Tooltip";
import Settings from "@material-ui/icons/Settings";

import withStyles from "@material-ui/core/styles/withStyles";
import PropTypes from "prop-types";

import GpsWatchSettingsWindow from "./GpsWatchSettingsWindow";

const styles = () => ({
  settingsIcon: {
    cursor: "pointer",
    paddingRight: 12,
    "&:hover": {
      opacity: 0.7
    }
  }
});

const settingsDescription = {
  profile: {
    title: "Режим оповещения",
    value: ["вибро и звук", "только звук", "только вибро", "без звука и вибро"]
  },
  upload: { title: "Время обновления координат в сек." },
  center: { title: "Номер основного телефона" },
  slave: { title: "Номер дополнительного телефона" },
  lowChargeSms: { title: "Оповещение по СМС о низком заряде" },
  sosSms: { title: "Оповещение по СМС при сигнале SOS" },
  removeSms: { title: "Оповещение по СМС при снятии часов с руки" },
  langNum: { title: "Язык интерфейса" },
  timeZone: { title: "Часовой пояс" },
  sosPhones: { title: "Список телефонов SOS" },
  silenceTimes: { title: "Список интервалов времени не беспокоить" }
};

class GpsWatchSettings extends React.PureComponent {
  state = {
    isSettingsShown: false,
    settings: null
  };

  componentDidUpdate = prevProps => {
    if (prevProps.watchSettings !== this.props.watchSettings) {
      this.setState({ settings: this.props.watchSettings });
    }
  };

  toggleSettingsModal = () => {
    this.setState({ isSettingsShown: !this.state.isSettingsShown }, () => {
      if (this.state.isSettingsShown) {
        this.props.getWatchSettings();
      }
    });
  };

  errorOnPhoneValidate = phone => {
    if (!phone) {
      return false;
    }
    if (phone && /^\d{10}$/.test(phone)) {
      return false;
    }
    return true;
  };

  handleSelectChange = (name, value) => {
    this.setState({ settings: { ...this.state.settings, [name]: value } });
  };

  handleInputChange = name => event => {
    let { value } = event.target;
    if (name === "upload") {
      value = Number(value);
      // eslint-disable-next-line no-restricted-globals
      value = isNaN(value) ? 0 : value;
    }
    this.setState({
      settings: { ...this.state.settings, [name]: value }
    });
  };

  handleSosPhonesInputChange = index => event => {
    const { value } = event.target;
    const phones = [...this.state.settings.sosPhones];
    phones[index] = value;
    this.setState({
      settings: {
        ...this.state.settings,
        sosPhones: phones
      }
    });
  };

  handleSubmitSettings = () => {
    this.props.saveWatchSettings(this.state.settings);
    this.setState({ isSettingsShown: false });
  };

  render() {
    const {
      classes,
      selectedDeviceIsWatch,
      checkWatchConnection,
      gpsWatchReboot,
      gpsWatchPowerOff,
      gpsWatchFind
    } = this.props;
    const { isSettingsShown, settings } = this.state;
    return (
      <>
        {selectedDeviceIsWatch && (
          <Tooltip title="Настройки gps-часов">
            <Settings
              className={classes.settingsIcon}
              fontSize="large"
              color="secondary"
              onClick={this.toggleSettingsModal}
            />
          </Tooltip>
        )}
        <GpsWatchSettingsWindow
          {...{
            isSettingsShown,
            toggleSettingsModal: this.toggleSettingsModal,
            settingsDescription,
            settings,
            checkWatchConnection,
            gpsWatchReboot,
            gpsWatchPowerOff,
            gpsWatchFind,
            handleSelectChange: this.handleSelectChange,
            handleInputChange: this.handleInputChange,
            handleSosPhonesInputChange: this.handleSosPhonesInputChange,
            errorOnPhoneValidate: this.errorOnPhoneValidate,
            handleSubmitSettings: this.handleSubmitSettings
          }}
        />
      </>
    );
  }
}

GpsWatchSettings.propTypes = {
  classes: PropTypes.object,
  selectedDeviceIsWatch: PropTypes.bool,
  getWatchSettings: PropTypes.func.isRequired,
  watchSettings: PropTypes.object,
  saveWatchSettings: PropTypes.func.isRequired,
  checkWatchConnection: PropTypes.func.isRequired,
  gpsWatchReboot: PropTypes.func.isRequired,
  gpsWatchPowerOff: PropTypes.func.isRequired,
  gpsWatchFind: PropTypes.func.isRequired
};

export default withStyles(styles)(GpsWatchSettings);
