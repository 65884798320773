import React, {Component} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import moment from 'moment';

import Typography from '@material-ui/core/Typography';
import * as mealsActions from 'actions/mealsActions';
import {addFoodDataOrder} from 'actions/userActions';
import LoadingMessage from 'components/messages/LoadingMessage';
import MealsView from './MealsView.jsx';
import {unbindMeal} from "actions/mealsActions";

const mapStateToProps = state => ({
  loading: state.loading,
  user: state.user,
  meals: state.meals
});

const mapDispatchToProps = dispatch => ({
  mealsActions: bindActionCreators(mealsActions, dispatch),
  addFoodDataOrder: (pupilId, foodDataId) => {
    dispatch(addFoodDataOrder(pupilId, foodDataId))
  }
});

class Meals extends Component {
  state = {
    tabIndex: 0,
    selectedMonth: moment(),
    selectedDate: moment(),
    dayLimit: null,
    tabMealTypesIndex: 0,
    selectedMeal:{},
    infoWindowIsOpen: false,
    infoText: ""
  };

  componentDidMount() {
    // eslint-disable-next-line react/prop-types
    const {schools, selectedSchool, selectedPupil} = this.props.user;
    const dayLimit = schools[selectedSchool].pupils[selectedPupil].dayLimit;
    const isMealsActive = schools[selectedSchool].menu.filter(item => item.id === 4).length !== 0;
    const isParentPreOrder = !!schools[selectedSchool].parent.isPreOrderMeals;

    if (isMealsActive) {
      this.props.mealsActions.getMonthlyMeals(
        selectedPupil,
        this.getFirstDayOfMonth(this.state.selectedMonth),
        this.getLastDayOfMonth(this.state.selectedMonth)
      );
      this.props.mealsActions.getDailyMeals(
        selectedPupil,
        this.state.selectedDate.format('YYYY-MM-DD')
      );
      this.setState({dayLimit});

      if (isParentPreOrder) {
        this.props.mealsActions.getMeals(
          selectedPupil);
      }
    }
  }

  componentDidUpdate(prevProps) {
    const {schools, selectedSchool, selectedPupil} = this.props.user;
    const {mealTypes} = this.props.meals
    const dayLimit = schools[selectedSchool].pupils[selectedPupil].dayLimit;
    const isParentPreOrder = !!schools[selectedSchool].parent.isPreOrderMeals;
    if (prevProps.user.selectedPupil !== selectedPupil) {
      // после переключения школы переходим на вкладку родителя
      if (prevProps.user.selectedSchool !== this.props.user.selectedSchool) {
        this.setState({tabIndex: 0, dayLimit});
      }
      this.props.mealsActions.getMonthlyMeals(
        selectedPupil,
        this.getFirstDayOfMonth(this.state.selectedMonth),
        this.getLastDayOfMonth(this.state.selectedMonth)
      );
      this.props.mealsActions.getDailyMeals(
        selectedPupil,
        this.state.selectedDate.format('YYYY-MM-DD')
      );
      if (isParentPreOrder) {
        this.props.mealsActions.getMeals(
          selectedPupil);
        this.setState({
          tabMealTypesIndex: 0,
          selectedMeal:{}
        })
      }
    }
    // if (prevProps.meals.mealTypes.timestamp !== mealTypes.timestamp ){
    //   const {selectedMeal} = this.state
    //   mealTypes.ids.map(typeId => {
    //     selectedMeal[typeId] = mealTypes[typeId].mealEnabledId;
    //   })
    //   this.setState({...{selectedMeal}})
    // }
  }

  getFirstDayOfMonth = date => moment(date).format('YYYY-MM-01');

  getLastDayOfMonth = date => moment(date).format('YYYY-MM-') + moment(date).daysInMonth();

  tabClick = (event, value) => {
    this.setState({tabIndex: value});
  };

  tabSwipe = index => {
    this.setState({tabIndex: index});
  };

  tabMealTypesClick = (event, value) => {
    this.setState({tabMealTypesIndex: value})
  }

  tabMealTypesSwipe = index => {
    this.setState({tabMealTypesIndex: index})
  }

  setDate = day => {
    const selectedPupil = this.props.user.selectedPupil;
    this.setState({selectedDate: day, tabIndex: 1});
    this.props.mealsActions.getDailyMeals(selectedPupil, day.format('YYYY-MM-DD'));
  };

  setMonth = date => {
    const selectedPupil = this.props.user.selectedPupil;
    this.setState({selectedMonth: date});
    this.props.mealsActions.getMonthlyMeals(
      selectedPupil,
      this.getFirstDayOfMonth(date),
      this.getLastDayOfMonth(date)
    );
  };

  changeDayLimitValue = event => {
    const dayLimit = event.target.value;
    this.setState({dayLimit});
  };

  submitDayLimit = () => {
    const selectedPupil = this.props.user.selectedPupil;
    const dayLimit = this.state.dayLimit;
    this.props.mealsActions.setMealsDayLimit(selectedPupil, dayLimit);
  };
  handleSelectedMealChange = typeId => event => {
    const {selectedMeal} = this.state
    selectedMeal[typeId] = Number(event.target.value)

    this.setState({selectedMeal})
  }

  submitBindMealAccount = typeId => () => {
    const selectedPupil = this.props.user.selectedPupil;
    const {selectedMeal} = this.state
    const mealId = selectedMeal[typeId]
    this.props.mealsActions.bindMeal(selectedPupil, mealId);
  }

  submitUnbindMealAccount = typeId => () => {
    const selectedPupil = this.props.user.selectedPupil;
    const {mealTypes} = this.props.meals
    const mealId = mealTypes[typeId].mealEnabledId
    this.props.mealsActions.unbindMeal(selectedPupil, mealId);
  }

  infoWindowOpen = infoText => {
    this.setState({infoText, infoWindowIsOpen: true})
  }

  infoWindowClose = infoText => {
    this.setState({infoText: "", infoWindowIsOpen: false})
  }

  render() {
    const {monthMeals, dayMeals, mealTypes} = this.props.meals;
    const {isLoading} = this.props.loading;
    const {
      tabIndex, tabMealTypesIndex, selectedMonth, selectedDate, dayLimit, selectedMeal, infoWindowIsOpen, infoText
    } = this.state;
    const {schools, selectedSchool, selectedPupil} = this.props.user;
    const isMealsActive = schools[selectedSchool].menu.filter(item => item.id === 4).length !== 0;
    const isParentPreOrder = !!schools[selectedSchool].parent.isPreOrderMeals;
    const isMealsDetailPossible = schools[selectedSchool].pupils[selectedPupil].isMealsDetailPossible;
    const {addFoodDataOrder} = this.props;
    if (!isMealsActive) {
      return (
        <Typography variant="h6" align="center">
          Модуль питания в школе отсутствует
        </Typography>
      );
    }
    if (monthMeals === undefined || dayMeals === undefined || dayLimit === undefined) {
      return <LoadingMessage/>;
    }
    return (
      <>
        <LoadingMessage/>
        <MealsView
          {...{
            monthMeals,
            dayMeals,
            mealTypes,
            tabIndex,
            tabMealTypesIndex,
            selectedMonth,
            selectedDate,
            selectedMeal,
            isLoading,
            dayLimit,
            isMealsDetailPossible,
            selectedPupil,
            isParentPreOrder,
            addFoodDataOrder,
            infoWindowIsOpen,
            infoText,
            tabClick: this.tabClick,
            tabSwipe: this.tabSwipe,
            tabMealTypesClick: this.tabMealTypesClick,
            tabMealTypesSwipe: this.tabMealTypesSwipe,
            setDate: this.setDate,
            setMonth: this.setMonth,
            changeDayLimitValue: this.changeDayLimitValue,
            submitDayLimit: this.submitDayLimit,
            handleSelectedMealChange: this.handleSelectedMealChange,
            infoWindowOpen: this.infoWindowOpen,
            infoWindowClose: this.infoWindowClose,
            submitBindMealAccount: this.submitBindMealAccount,
            submitUnbindMealAccount: this.submitUnbindMealAccount,
          }}
        />
      </>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Meals);
