import React from "react";
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";

import InputField from "../../InputField";

import { withStyles } from "@material-ui/core/styles";

const styles = () => ({
  row: {
    display: "flex",
    alignItems: "center"
  }
});

const SmsCode = ({ classes, values, handleChange, errors, sendCode, codeSendDate }) => {
  return (
    <div className={classes.row}>
      {codeSendDate && (
        <InputField
          id="smsCode"
          label="Код из смс"
          required={true}
          error={errors.smsCode}
          value={values.smsCode}
          onChange={handleChange}
        />
      )}
      <Button
        disabled={!values.phone || typeof values.children[0].schoolId !== "number"}
        variant="text"
        color="primary"
        size="medium"
        onClick={sendCode}
      >
        {!codeSendDate ? "Получить код" : "Отправить код повторно"}
      </Button>
    </div>
  );
};

SmsCode.propTypes = {
  classes: PropTypes.object,
  values: PropTypes.shape({
    phone: PropTypes.string,
    children: PropTypes.arrayOf(
      PropTypes.shape({
        schoolId: PropTypes.number
      })
    ),
    smsCode: PropTypes.string
  }),
  codeSendDate: PropTypes.instanceOf(Date),
  handleChange: PropTypes.func.isRequired,
  errors: PropTypes.object,
  sendCode: PropTypes.func.isRequired
};

export default withStyles(styles)(SmsCode);
