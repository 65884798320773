import React from "react";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import PropTypes from "prop-types";

const AppBarWithTabs = props => {
  return (
    <AppBar color="default" position="sticky">
      <Tabs value={props.tabIndex} onChange={props.tabClick} indicatorColor="primary" scrollable>
        {props.tabs.map(tab => (
          <Tab key={tab} label={tab}/>
        ))}
      </Tabs>
    </AppBar>
  );
};

AppBarWithTabs.propTypes = {
  tabIndex: PropTypes.number.isRequired,
  tabClick: PropTypes.func.isRequired,
  tabs: PropTypes.array.isRequired
}

export default AppBarWithTabs;
