import React from "react";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Radio from "@material-ui/core/Radio";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";

import { withStyles } from "@material-ui/core/styles";

const styles = theme => ({
  list: {
    padding: 0
  },
  payType: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center"
  },
  radio: {
    paddingRight: 0,
    paddingLeft: 0
  },
  buttons: {
    position: "relative",
    display: "flex",
    justifyContent: "flex-start",
    marginTop: 15
  },
  nextButton: {
    flexGrow: 1,
    margin: "0 5px"
  }
});
const Step1 = props => {
  const { classes, profile, payTypeFrom, changeStep, selectPayTypeFrom, pupils } = props;
  return (
    <React.Fragment>
      <List>
        {profile.balances.ids.map(id => {
          if (profile.balances[id].balance > 0) {
            return (
              <React.Fragment key={id}>
                <ListItem
                  className={classes.list}
                  button
                  onClick={e =>
                    selectPayTypeFrom({
                      id: profile.balances[id].id,
                      name: profile.balances[id].name
                    })
                  }
                >
                  <Radio
                    className={classes.radio}
                    checked={payTypeFrom !== undefined && payTypeFrom.id === id}
                  />
                  <ListItemText>
                    <div className={classes.payType}>
                      <Typography variant="subtitle1">{profile.balances[id].name}</Typography>
                      <Typography variant="body2">
                        баланс: {profile.balances[id].balance} руб.
                      </Typography>
                    </div>
                  </ListItemText>
                </ListItem>
                <Divider />
              </React.Fragment>
            );
          } else return null;
        })}
        {pupils.ids.map(id => {
          if (pupils[id].foodBalance > 0) {
            return (
              <React.Fragment key={id}>
                <ListItem
                  className={classes.list}
                  button
                  onClick={e =>
                    selectPayTypeFrom({
                      id: 0,
                      name: "Питание",
                      pupil: {
                        id: id,
                        name: `${pupils[id].im} ${pupils[id].className}`
                      }
                    })
                  }
                >
                  <Radio
                    className={classes.radio}
                    checked={
                      payTypeFrom !== undefined &&
                      payTypeFrom.id === 0 &&
                      payTypeFrom.pupil.id === id
                    }
                  />
                  <ListItemText>
                    <div className={classes.payType}>
                      <Typography variant="subtitle1">
                        Питание, {pupils[id].im} {pupils[id].className}
                      </Typography>
                      <Typography variant="body2">баланс: {pupils[id].foodBalance} руб.</Typography>
                    </div>
                  </ListItemText>
                </ListItem>
                <Divider />
              </React.Fragment>
            );
          } else return null;
        })}
        {(profile.parent.foodModuleActive || profile.parent.foodBalance > 0) && (
          <React.Fragment key={profile.parent.id}>
            <ListItem
              className={classes.list}
              button
              onClick={e =>
                selectPayTypeFrom({
                  id: 0,
                  name: "Питание",
                  pupil: {
                    id: profile.parent.id,
                    name: `${profile.parent.fam.slice(0,1)}. ${profile.parent.im}`
                  }
                })
              }
            >
              <Radio
                className={classes.radio}
                checked={
                  payTypeFrom !== undefined &&
                  payTypeFrom.id === 0 &&
                  payTypeFrom.pupil.id === profile.parent.id
                }
              />
              <ListItemText>
                <div className={classes.payType}>
                  <Typography variant="subtitle1">
                    Питание, {profile.parent.fam.slice(0,1) +". " + profile.parent.im}
                  </Typography>
                  <Typography variant="body2">баланс: {profile.parent.foodBalance} руб.</Typography>
                </div>
              </ListItemText>
            </ListItem>
            <Divider />
          </React.Fragment>
        )}
      </List>
      <div className={classes.buttons}>
        <Button
          className={classes.nextButton}
          variant="outlined"
          color="secondary"
          size="large"
          onClick={e => changeStep("forward")}
        >
          Далее
        </Button>
      </div>
    </React.Fragment>
  );
};

export default withStyles(styles)(Step1);
