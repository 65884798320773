import React from "react";
import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Input from "@material-ui/core/Input";
import InputAdornment from "@material-ui/core/InputAdornment";

import { withStyles } from "@material-ui/core/styles";

const styles = theme => ({
  input: {
    fontSize: 25
  },
  buttons: {
    position: "relative",
    display: "flex",
    justifyContent: "flex-start",
    marginTop: 15
  },
  nextButton: {
    flexGrow: 1,
    margin: "0 5px"
  }
});

const Step3 = props => {
  const { classes, changeStep, errorOnValidateAmount, setAmount, amount, onEnterPress } = props;
  return (
    <>
      <FormControl required>
        <InputLabel htmlFor="amount">Сумма платежа</InputLabel>
        <Input
          className={classes.input}
          type="number"
          value={amount}
          error={errorOnValidateAmount(amount)}
          onChange={e => setAmount(e.target.value)}
          onKeyDown={onEnterPress()}
          autoFocus
          endAdornment={<InputAdornment position="end">руб.</InputAdornment>}
        />
      </FormControl>
      <div className={classes.buttons}>
        <Button variant="outlined" size="small" onClick={e => changeStep("backward")}>
          Назад
        </Button>
        <Button
          className={classes.nextButton}
          disabled={errorOnValidateAmount(amount)}
          variant="outlined"
          color="secondary"
          size="large"
          onClick={e => changeStep("forward")}
        >
          Далее
        </Button>
      </div>
    </>
  );
};

export default withStyles(styles)(Step3);
