import React from "react";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import moment from "moment";
import {Formik} from "formik";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import FormHelperText from "@material-ui/core/FormHelperText";
import Button from "@material-ui/core/Button";

import InputField from "../InputField";
import AutosuggestField from "../AutosuggestField";

const styles =  ({
  paper: {
    margin: "auto auto 30px",
    maxWidth: 1300,
    minWidth: "70%",
    display: "flex",
    flexDirection: "column",
    flexWrap: "wrap",
    justifyContent: "space-around",
    alignItems: "center",
    padding: "15px 30px 15px",
    background: "#FFF"
  },
  formContent: {
    maxWidth: 620
  },
  // rowContainer: {
  //   display: "flex",
  //   flexDirection: "row",
  //   justifyContent: "space-between",
  //   alignItems: "flex-start",
  // },
  element: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
    alignItems: "center",
  },
  aLink: {
    color: "blue",
    ":visited": {
      color: "blue",
    }
  },
  button: {
    // flexGrow: 1,
    margin: "auto",
    height: 60,
    maxWidth: 220,
    backgroundImage: "linear-gradient(143deg, #f36e50 0%, #e4404c 100%)",
    color: "white"
  }
})

const KhalvaPaper = props => {
  const {classes, initValues, regions, handleSendOrder, errors, success} = props;

  return (
    <Paper className={classes.paper}>
      <Typography variant="h4" color="textPrimary" align="center">Получи Халву сейчас!</Typography>
      <Formik
        initialValues={initValues}
        onSubmit={values => {
          handleSendOrder(values)
        }}
      >{val => {
        const {values, handleChange, setFieldValue, handleSubmit} = val;

        const handleDateChange = id => date => {
          setFieldValue(id, moment(date).format("YYYY-MM-DD"));
        };

        const handleInputChange = (id) => event => {
          const value = event.target.value === id
            ? event.target.checked
            : event.target.value;
          setFieldValue(id, value);
        };

        return (
          <form className={classes.formContent} onSubmit={handleSubmit}>
            <div className={classes.element}>
              <InputField
                id="lastName"
                label="Фамилия"
                fullWidth
                required
                value={values.lastName}
                onChange={handleChange}
                // onKeyUp={this.handleEnter}
              />
              <InputField
                id="firstName"
                label="Имя"
                fullWidth
                required
                value={values.firstName}
                error={errors.firstName}
                onChange={handleChange}
                // onKeyUp={this.handleEnter}
              />
              <InputField
                id="patronymic"
                label="Отчество"
                required
                fullWidth
                value={values.patronymic}
                error={errors.patronymic}
                onChange={handleChange}
                // onKeyUp={this.handleEnter}
              />
            </div>
            <InputField
              id="birthDate"
              label="Дата рождения *"
              fullWidth
              datePicker
              maxDate={(new Date()).setFullYear((new Date()).getFullYear() - 20)}
              value={values.birthDate}
              error={errors.birthDate}
              onChange={handleDateChange("birthDate")}
              // onKeyUp={this.handleEnter}
            />
            <InputField
              id="phone"
              label="Телефон"
              fullWidth
              required
              phone
              // error={(!isNewPhone && phone === isNewPhoneChecked && !values.parentId) ? "Данный номер имеется в системе, ввойдите в Личный кабинет используя его"
              //                : (values.phone && !rePhone.test(values.phone)) ? 'Неверно указан номер': ''}
              onChange={handleChange}
              value={values.phone}
              error={errors.phone}
              // onKeyUp={this.handleEnter}
            />
            <AutosuggestField
              id="region"
              fullWidth
              suggestions={regions}
              label="Регион"
              selected={values.region}
              onChange={setFieldValue}
              error={errors.regionId}
              required
              // onKeyUp={this.handleEnter}
            />
            <FormControl
              error={!!errors.consent}>
              <FormControlLabel
                control={
                  <Checkbox
                    id="consent"
                    checked={!!values.consent}
                    onChange={handleInputChange("consent")}
                    value="consent"
                    color="primary"
                  />
                }
                label={
                  <div>Даю согласие на обработку моих персональных данных в соответствии с <a
                    href="https://app.sovcombank.ru/policy/" target="_blank" rel="noopener noreferrer"
                    className={classes.aLink}>Правилами</a>
                  </div>}
              />
              {!!errors.consent && (<FormHelperText>{errors.consent}</FormHelperText>)}
            </FormControl>
            {!success && (<div className={classes.element}>
              <Button variant="contained" className={classes.button} type="submit">Заказать карту</Button>
            </div>)}
          </form>)
      }}
      </Formik>
    </Paper>
  )
}

KhalvaPaper.propTypes = {
  regions: PropTypes.array.isRequired,
  initValues: PropTypes.object.isRequired,
  handleSendOrder: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
  success: PropTypes.bool.isRequired
}

export default withStyles(styles)(KhalvaPaper)
