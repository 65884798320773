import React from "react";
import PropTypes from "prop-types";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";

import PupilSelect from "./PupilSelect";
import PupilBirthday from "./PupilBirthday";
import PupilDocs from "./PupilDocs";

import {withStyles} from "@material-ui/core/styles";
// import InsuranceSelectList from "../step1/InsuranceSelectList";

const styles = theme => ({
  buttons: {
    position: "relative",
    display: "flex",
    justifyContent: "flex-start",
    marginTop: 15,
  },
  nextButton: {
    flexGrow: 1,
    margin: "0 5px",
  },
});

const isPupilNextButtonDisabled = (pupilData, pupil, orgId) => {
  if (
    (orgId !== 2 && ((pupilData.accountDocSeries === null && !pupil.accountDocSeries) ||
        (pupilData.accountDocNumber === null && !pupil.accountDocNumber) ||
        (pupilData.accountDocIssuer === null && !pupil.accountDocIssuer) ||
        (pupilData.accountDocDate === null && !pupil.accountDocDate))
    ) ||
    (pupilData.accountBirthDate === null && !pupil.accountBirthDate) ||
    (orgId === 2 && pupilData.accountGender === null && !pupil.accountGender)
  ) {
    return true;
  } else return false;
};

const Step3 = props => {
  const {
    classes,
    validateError,
    changeStep,
    changePupilData,
    available,
    selectedPolicy,
    selectedInsurance,
    pupilData,
    pupil,
    pupils,
    selectPupil,
    selectedPupil,
    pupilDocsType,
    changePupilDocDate,
    changePupilDocsType,
    changePupilBirthday
  } = props;
  const orgId = !selectedInsurance ? 1 : available.policies[selectedPolicy].insurances[selectedInsurance].orgId;

  return (
    <div>
      <Typography variant="caption">
        Застрахованное лицо - ребенок, в отношении которого заключается договор страхования.
      </Typography>
      <br/>
      <PupilSelect {...{pupils, selectPupil, selectedPupil}} />
      <br/>

      {pupilData ? (
        <>
          <Typography variant="caption" color="error">
            Данные должны быть заполнены в точном соответствии с данными в свидетельстве о рождении
            или паспорте ученика.
          </Typography>
          <Typography variant="caption" color="error" paragraph>
            *Поля, отмеченные звездочкой, обязательны к заполнению.
          </Typography>
        </>
      ) : null}
      {pupilData ? (
        <PupilBirthday {...{pupil, pupilData, changePupilData, changePupilBirthday, validateError}}
                       insurances={available.policies[selectedPolicy].insurances}
                       selectedInsurance={selectedInsurance}
        />
      ) : null}
      <br/>
      {(pupilData && orgId !== 2) ? (
        <PupilDocs
          {...{
            pupil,
            pupilData,
            changePupilData,
            pupilDocsType,
            changePupilDocsType,
            changePupilDocDate,
            validateError
          }}
        />
      ) : null}
      <div className={classes.buttons}>
        <Button variant="outlined" size="small" onClick={e => changeStep("backward")}>
          Назад
        </Button>
        <Button
          disabled={pupilData !== undefined ? isPupilNextButtonDisabled(pupilData, pupil, orgId) : true}
          className={classes.nextButton}
          variant="outlined"
          color="secondary"
          size="large"
          onClick={e => changeStep("forward")}
        >
          Далее
        </Button>
      </div>
    </div>
  );
};

export default withStyles(styles)(Step3);
