import { handleActions } from "redux-actions";

import {
  fetchGpsStatusSuccess,
  fetchGpsDeliveryTypesSuccess,
  gpsChangeStatusSuccess,
  fetchGpsDevicesSuccess,
  gpsDevicesDeleteSuccess,
  gpsDevicesChangeStatusSuccess,
  gpsDevicesChangeNameSuccess,
  fetchGpsZonesSuccess,
  gpsZonesDeleteSuccess,
  gpsZonesChangeStatusSuccess,
  gpsZonesChangeNameSuccess,
  fetchGpsLastCoordinatesRequest,
  fetchGpsLastCoordinatesSuccess,
  fetchGpsLastCoordinatesError,
  fetchGpsLastCoordinatesSilentSuccess,
  fetchGpsCoordinatesHistoryRequest,
  fetchGpsCoordinatesHistorySuccess,
  fetchGpsCoordinatesHistoryError,
  gpsCoordinatesHistoryClear,
  gpsRegisterSuccess,
  fetchGpsWatchSettingsSuccess,
  gpsWatchSettingsAddSuccess
} from "actions/gpsActions";

const initialState = {
  lastCoordinates: null,
  history: []
};

const gps = handleActions(
  {
    [fetchGpsStatusSuccess]: (state, action) => ({ ...state, status: action.payload.status }),
    [fetchGpsDeliveryTypesSuccess]: (state, action) => ({
      ...state,
      deliveryTypes: action.payload.deliveryTypes
    }),
    [gpsChangeStatusSuccess]: (state, action) => ({
      ...state,
      status: { ...state.status, active: action.payload.status }
    }),
    [fetchGpsDevicesSuccess]: (state, action) => ({ ...state, devices: action.payload.devices }),
    [gpsDevicesDeleteSuccess]: (state, action) => {
      const newDevices = state.devices.filter(device => device.id !== action.payload.deviceId);
      return {
        ...state,
        devices: newDevices
      };
    },
    [gpsDevicesChangeStatusSuccess]: (state, action) => {
      const { deviceId, status } = action.payload;

      const newDevices = state.devices
        .filter(device => device.id === deviceId)
        .map(device => ({ ...device, active: status }));

      return {
        ...state,
        devices: newDevices
      };
    },
    [gpsDevicesChangeNameSuccess]: (state, action) => {
      const { deviceId, name } = action.payload;
      const newDevices = state.devices
        .filter(device => device.id === deviceId)
        .map(device => ({ ...device, name }));
      return {
        ...state,
        devices: newDevices
      };
    },
    [fetchGpsZonesSuccess]: (state, action) => ({ ...state, zones: action.payload.zones }),
    [gpsZonesDeleteSuccess]: (state, action) => {
      const newZones = state.zones.filter(zone => zone.id !== action.payload.zoneId);
      return {
        ...state,
        zones: newZones
      };
    },
    [gpsZonesChangeStatusSuccess]: (state, action) => {
      const { zoneId, status } = action.payload;
      const newZones = state.zones.map(zone => {
        if (zone.id === zoneId) {
          const newZone = zone;
          newZone.state = status;
          return newZone;
        }
        return zone;
      });
      return {
        ...state,
        zones: newZones
      };
    },
    [gpsZonesChangeNameSuccess]: (state, action) => {
      const { zoneId, name } = action.payload;
      const newZones = state.zones
        .filter(zone => zone.id === zoneId)
        .map(zone => ({ ...zone, name }));
      return {
        ...state,
        zones: newZones
      };
    },
    [fetchGpsLastCoordinatesRequest]: state => ({ ...state, lastCoordinates: null }),
    [fetchGpsLastCoordinatesSuccess]: (state, action) => ({
      ...state,
      lastCoordinates: action.payload.lastCoordinates
    }),
    [fetchGpsLastCoordinatesError]: state => ({
      ...state,
      lastCoordinates: null
    }),
    [fetchGpsLastCoordinatesSilentSuccess]: (state, action) => ({
      ...state,
      lastCoordinates: action.payload.lastCoordinates
    }),
    [fetchGpsCoordinatesHistoryRequest]: state => ({
      ...state,
      history: []
    }),
    [fetchGpsCoordinatesHistorySuccess]: (state, action) => ({
      ...state,
      history: action.payload.history
    }),
    [fetchGpsCoordinatesHistoryError]: state => ({
      ...state,
      history: []
    }),
    [gpsCoordinatesHistoryClear]: state => ({
      ...state,
      history: []
    }),
    [gpsRegisterSuccess]: state => ({
      ...state,
      status: {
        ...state.status,
        active: 0
      }
    }),
    [fetchGpsWatchSettingsSuccess]: (state, action) => ({
      ...state,
      watchSettings: action.payload.watchSettings
    }),
    [gpsWatchSettingsAddSuccess]: (state, action) => ({
      ...state,
      watchSettings: action.payload.watchSettings
    })
  },
  initialState
);

export default gps;
