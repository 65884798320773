import React, {Component} from "react";

import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

import withStyles from "@material-ui/core/styles/withStyles";

const styles = theme => ({
  input: {
    marginTop: 10,
    fontSize: 25
  },
  positionStart: {
    fontSize: 20
  },
  modal: {
    [theme.breakpoints.down("xs")]: {
      alignItems: "flex-start"
    }
  },
  paper: {
    margin: 10
  }
});

class LoseCard extends Component {

  unlinkCardClick = () => {
    this.props.unlinkCard(this.props.accountId);
    this.props.loseCardToggle();
  };

  onClose = () => {
    this.props.loseCardToggle();
  };

  render() {
    const {classes, cardNum, loseCardIsOpen} = this.props;
    return (
      <Dialog
        open={!!loseCardIsOpen}
        onClose={this.onClose}
        classes={{root: classes.modal, paper: classes.paper}}
      >
        <DialogTitle>Блокировка идентификатора (карта/брелок/браслет) в связи с утерей</DialogTitle>
        <DialogContent>
          <DialogContentText>Пропуск с номером <b>{cardNum}</b> будет заблокирован, доступ в ОУ (общеобразовательное
            учреждение) по этому идентификатору будет прекращен! Если Вы найдете этот пропуск до получения нового, то
            для его активации необходимо обратиться в ОУ или контактный центр.<br/><br/>
            Важно: <br/>
            Блокировка пропуска не является отказом от информационных услуг, после получения нового пропуска,
            действующий тарифный план будет восстановлен автоматически.<br/>
            Информация о получении нового идентификатора размещена в разделе <a href={"/help"}>помощь</a>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.onClose} color="primary">
            Отмена
          </Button>
          <Button
            onClick={this.unlinkCardClick}
            color="primary"
          >
            Подтвердить
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default withStyles(styles)(LoseCard);
