import React from "react";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import withStyles from "@material-ui/core/styles/withStyles";

const styles = theme => ({
  link: {
    color: "#0000ff",
    '&visited': {color: "#0000ff"},
    '&active': {color: "#0000ff"},
  }
});

const DocumentsVSK = (props) => {
  const {classes} = props;
  return (
    <div style={{padding: 5}}>
      <Typography variant="button" paragraph>
        Документы по страхованию «ВСК»
      </Typography>
      <Divider />
      <Typography>
        <a className={classes.link} href="/docs/regulations.pdf" target="_blank">
          Правила № 83 добровольного страхования граждан от несчастных случаев и болезней
        </a>
      </Typography>
      <Typography>
        <a className={classes.link} href="/docs/amount.pdf" target="_blank">Таблица размеров страховых выплат </a>
      </Typography>
    </div>
  );
};

export default withStyles(styles)(DocumentsVSK);
