import React from "react";

import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";

import withStyles from "@material-ui/core/styles/withStyles";

const styles = theme => ({
  modal: {
    [theme.breakpoints.down("xs")]: {
      alignItems: "flex-start"
    }
  },
  paper: {
    margin: 10
  }
});

const InfoWindow = props => {
  const { classes, infoWindowIsOpen, infoText, infoWindowClose } = props;

  const textToHtmlBlocks = text =>
    text.split("\n").map((item, key) => {
      return (
        <span key={key}>
          {item}
          <br />
        </span>
      );
    });

  return (
    <Dialog
      open={infoWindowIsOpen}
      onClose={e => infoWindowClose()}
      classes={{ root: classes.modal, paper: classes.paper }}
    >
      <DialogContent>
        <DialogContentText>{textToHtmlBlocks(infoText)}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={e => infoWindowClose()} color="primary">
          Закрыть
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default withStyles(styles)(InfoWindow);
