import React from "react";
import Typography from "@material-ui/core/Typography";
import Hidden from "@material-ui/core/Hidden";
import LoadingMessage from "components/messages/LoadingMessage";

import withStyles from "@material-ui/core/styles/withStyles";

import InsuranceCard from "./InsuranceCard";
import DocumentsVSK from "../DocumentsVSK";
import Details from "./Details";
import DocumentsKL from "../DocumentsKL";

const styles = theme => ({
  completedContainer: {
    padding: 5,
    overflow: "auto",
    height: "calc(100% - 10px)",
    [theme.breakpoints.down("xs")]: {
      padding: 0
    }
  }
});

const Completed = props => {
  const {
    classes,
    completed,
    selected,
    downloadPdf,
    deleteInsuranceInvoice,
    detailsIsOpen,
    detailsPopupOpen,
    detailsPopupClose,
    pupils
  } = props;
  if (!completed) {
    return <LoadingMessage />;
  } else {
    return (
      <section className={classes.completedContainer}>
        <Typography variant="h6" align="center" paragraph>
          Оформленные полисы
        </Typography>
        {completed.map(insurance => (
          <InsuranceCard
            key={insurance.id}
            {...{ insurance, downloadPdf, deleteInsuranceInvoice, detailsPopupOpen }}
          />
        ))}
        <br />
        <DocumentsVSK />
        <DocumentsKL />
        <Hidden xsDown>
          <Details {...{ detailsIsOpen, detailsPopupClose, selected, pupils }} />
        </Hidden>
        <Hidden smUp>
          <Details mobile={true} {...{detailsIsOpen, detailsPopupClose, selected, pupils }} />
        </Hidden>
      </section>
    );
  }
};

export default withStyles(styles)(Completed);
