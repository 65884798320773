import React, {Component} from "react";
import PropTypes from "prop-types";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {Switch, Route} from "react-router-dom";

import ErrorMessage from "../messages/ErrorMessage";
import InfoMessage from "../messages/InfoMessage";

import * as userActions from "../../actions/userActions";
import * as errorsActions from "../../actions/errorsActions";
import RecoveryForm from "./RecoveryForm.jsx";
import LoginForm from "./LoginForm.jsx";
import RecoveryAccessForm from "./RecoveryAccessForm";

const mapStateToProps = state => {
  return {
    user: state.user,
    loading: state.loading,
    errors: state.errors
  };
};

const mapDispatchToProps = dispatch => {
  return {
    userActions: bindActionCreators(userActions, dispatch),
    errorsActions: bindActionCreators(errorsActions, dispatch)
  };
};

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      login: "",
      password: "",

      mapCenter: [],
      mapZoom: 3,
      ymaps: null,
      mapPoints: []
    };

    this.mapRef = null;
    this._isMounted = false;
  }

  setYmaps = ymaps => {
    this.setState({ymaps});
  };

  setMapPoints = mapPoints => {
    if (this._isMounted) {
      this.setState({mapPoints});
    }
  };

  addMapPoint = mapPoint => {
    let mapPoints = this.state.mapPoints
    if (mapPoint.hasOwnProperty('latitude') && mapPoint.hasOwnProperty('longitude')) {
      mapPoints.push(mapPoint)
      this.setMapPoints(mapPoints);
    }
  };

  setMapRef = element => {
    this.mapRef = element;
  };

  changeMapCenter = coordinates => {
    this.setState({mapCenter: coordinates});
  };

  changeMapZoom = zoom => {
    this.mapRef.setZoom(zoom);
  };


  componentDidMount() {
    this.props.userActions.signOut();
    this._isMounted = true;
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.errors.error &&
      prevProps.errors.error !== this.props.errors.error
    ) {
      this.setState({password: ""});
    }
  }
  componentWillUnmount() {
    this._isMounted = false;
  }

  changeInput = name => event => {
    this.setState({
      [name]: event.target.value
    });
  };

  errorOnValidate = login => {
    if (!login) {
      return false;
    } else if (login && /^\d{10}$/.test(login)) {
      return false;
    } else {
      return true;
    }
  };

  render() {
    let {login, password, mapCenter, mapZoom, ymaps} = this.state;
    const {signIn, generateNewPassword, newPhoneRecoverySendCode, newPhoneRecoveryCheckCode} = this.props.userActions;
    const {isLoading} = this.props.loading;
    const {auth, isRecoveryCodeSend, recoveryErrors} = this.props.user;

    return (
      <>
        <ErrorMessage/>
        <InfoMessage/>
        <Switch>
          <Route
            path="/login"
            render={() => (
              <LoginForm
                {...{
                  auth, login, password, isLoading, signIn, mapCenter, mapZoom, ymaps
                }}
                changeInput={this.changeInput}
                errorOnValidate={this.errorOnValidate}
                changeMapCenter={this.changeMapCenter}
                setYmaps={this.setYmaps}
                setMapRef={this.setMapRef}
              />
            )}
          />
          <Route
            path="/recovery"
            render={() => (
              <RecoveryForm
                {...{login, isLoading, generateNewPassword}}
                changeInput={this.changeInput}
                errorOnValidate={this.errorOnValidate}
              />
            )}
          />
          <Route
            path="/recovery-access"
            render={() => (
              <RecoveryAccessForm
                {...{
                  login,
                  isLoading,
                  isRecoveryCodeSend,
                  newPhoneRecoverySendCode,
                  newPhoneRecoveryCheckCode,
                  errors: recoveryErrors
                }}
                changeInput={this.changeInput}
                errorOnValidate={this.errorOnValidate}
              />
            )}
          />
        </Switch>
      </>
    );
  }
}

Login.propTypes = {
  userActions: PropTypes.shape({
    signOut: PropTypes.func.isRequired,
    signIn: PropTypes.func.isRequired,
    generateNewPassword: PropTypes.func.isRequired,
  }),
  errors: PropTypes.shape({
    error: PropTypes.string,
  }),
  loading: PropTypes.shape({
    isLoading: PropTypes.bool.isRequired,
  }),
  user: PropTypes.shape({
    auth: PropTypes.bool.isRequired,
  }),
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Login);
