import React from "react";

import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";

import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";

const styles = () => ({
  listInput: {
    flexDirection: "column",
    alignItems: "flex-start"
  },
  fullWidth: {
    width: "100%"
  }
});

const ProfileSettings = ({
  settings, settingsDescription, handleSelectChange, classes
}) => (
  <ListItem disableGutters className={classes.listInput}>
    <ListItemText primary={settingsDescription.profile.title} />
    <FormControl variant="outlined" className={classes.fullWidth}>
      <Select
        name="profile"
        value={settings.profile}
        onChange={event => handleSelectChange(event.target.name, event.target.value)}
      >
        {settingsDescription.profile.value.map((item, index) => (
          <MenuItem key={index} value={index + 1}>
            {item}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  </ListItem>
);

ProfileSettings.propTypes = {
  settings: PropTypes.object,
  settingsDescription: PropTypes.object,
  handleSelectChange: PropTypes.func.isRequired,
  classes: PropTypes.object
};

export default withStyles(styles)(ProfileSettings);
