import React, {Component} from 'react';
import {connect} from 'react-redux';

import {
  withStyles
} from "@material-ui/core";

import ProductCategory from "./ProductCategory";
import AltCardProperties from "../altCard";
import LoadingMessage from "components/messages/LoadingMessage";

import {
  getShopDirectories,
  getShopPayUrl,
  clearShopPayUrl,
  clearShopOrderDetails,
  getShopAltCardsTypes,
  getShopAltCardsKinds,
  getShopAltCardsColors,
  getShopAltCardSetPrice,
  setShopAltCardAddOrder,
  clearShopAltCard,
  getShopTrackDevicesTypes,
  getShopTrackDeviceModels,
  clearShopTrackDevices,
  clearShopTrackDevicesOrder,
  addShopTrackDevicesOrderItem,
} from "actions/shopActions";
import OrderDetailsDlg from "../OrderDetailsDlg";
import TrackDeviceProperties from "../trackDeviceProperties";
import {Redirect} from "react-router-dom";

const mapStateToProps = state => ({
  user: state.user,
  shop: state.shop,
});

const mapDispatchToProps = ({
  getShopDirectories,
  getShopPayUrl,
  clearShopPayUrl,
  clearShopOrderDetails,
  getShopAltCardsTypes,
  getShopAltCardsKinds,
  getShopAltCardsColors,
  getShopAltCardSetPrice,
  setShopAltCardAddOrder,
  clearShopAltCard,
  getShopTrackDevicesTypes,
  getShopTrackDeviceModels,
  clearShopTrackDevices,
  clearShopTrackDevicesOrder,
  addShopTrackDevicesOrderItem
});

const styles = () => ({
  root: {
    width: "100%"
  },
  gridContainer: {
    flexGrow: 1,
  },
  products: {
    width: "100%",
  },
  paper: {
    height: 140,
    width: 100,
  }
});

const initState = {
  expanded: {
    altCards: true,
    trackDevices: true,
  },
  altCardPropertiesIsOpen: undefined,
  trackDevicePropertiesIsOpen: undefined,
  trackDeviceConfirmOrderIsOpen: undefined,
};

class Products extends Component {
  constructor(props) {
    super(props)

    this.selectedSchool = this.props.user.selectedSchool;
    this.profile = this.props.user.schools[this.selectedSchool];

    this.state = initState
  }

  componentDidMount() {
    const selectedSchool = this.props.user.selectedSchool;
    const profile = this.props.user.schools[selectedSchool];
    const parentId = profile.parent.id;

    if (this.props.active) {
      this.setState(initState);

      this.props.clearShopAltCard();
      this.props.clearShopPayUrl();
      // this.props.clearShopOrderDetails();
      this.props.getShopDirectories(parentId);
      this.props.getShopAltCardsTypes(parentId);

      this.props.clearShopTrackDevices();
      this.props.getShopTrackDevicesTypes(parentId);
    }
  };

  componentDidUpdate(prevProps, prevState) {
    // после переключения школы сбрасываем на начальное состояние
    if ((prevProps.user.selectedSchool !== this.props.user.selectedSchool) ||
      (this.props.active && prevProps.active !== this.props.active)) {
      const selectedSchool = this.props.user.selectedSchool;
      const profile = this.props.user.schools[selectedSchool];
      const parentId = profile.parent.id;

      this.setState(initState);

      this.props.clearShopAltCard();
      this.props.clearShopPayUrl();
      // this.props.clearShopOrderDetails();
      this.props.getShopDirectories(parentId);
      this.props.getShopAltCardsTypes(parentId);

      this.props.clearShopTrackDevices();
      this.props.getShopTrackDevicesTypes(parentId);
    }
  }

  altCardPropertiesToggle = (cardTypeId) => {
    this.setState({
      altCardPropertiesIsOpen: (this.state.altCardPropertiesIsOpen === undefined ? cardTypeId : undefined)
    });
  };

  trackDevicePropertiesToggle = (trackDeviceTypeId) => {
    this.setState({
      trackDevicePropertiesIsOpen: (this.state.trackDevicePropertiesIsOpen === undefined ? trackDeviceTypeId : undefined)
    })
  };

  trackDeviceConfirmOrderToggle = (newState) => {
    this.setState({
      trackDeviceConfirmOrderIsOpen: (this.state.trackDeviceConfirmOrderIsOpen === undefined ? newState : undefined)
    })
    // console.log(this.history)
  };

  handleOrderPayment = order => {
    if (!!order && !!order['requestPayPath'])
      this.props.getShopPayUrl(order['requestPayPath'], order['accountId'], order['id']);
  }
  requestOrderPayDlgToggle = () => {
    const {
      shopOrderDetails,
    } = this.props.shop;
    if (shopOrderDetails)
      this.props.clearShopOrderDetails();
  };

  render() {
    const {classes} = this.props;
    const {
      directories,
      altCardTypes,
      trackDevicesTypes,
      shopOrderDetails,
      trackDevicesOrderItems,
    } = this.props.shop;
    const {expanded, altCardPropertiesIsOpen, trackDevicePropertiesIsOpen, trackDeviceConfirmOrderIsOpen} = this.state;

    const handleChange = (panel) => (event, isExpanded) => {
      let newExpanded = this.state.expanded;
      newExpanded[panel] = isExpanded
      this.setState({expanded: newExpanded});
    };

    const selectedSchool = this.props.user.selectedSchool;
    const profile = this.props.user.schools[selectedSchool];

    if (directories === undefined || profile === undefined) {
      return (<LoadingMessage/>)
    } else {
      return (
        <div className={classes.root}>
          <LoadingMessage/>
          <AltCardProperties
            altCardTypeId={altCardPropertiesIsOpen}
            onToggle={this.altCardPropertiesToggle}
            getShopAltCardsKinds={this.props.getShopAltCardsKinds}
            getShopAltCardsColors={this.props.getShopAltCardsColors}
            getShopAltCardSetPrice={this.props.getShopAltCardSetPrice}
            setShopAltCardAddOrder={this.props.setShopAltCardAddOrder}
            clearShopAltCard={this.props.clearShopAltCard}
          />
          <TrackDeviceProperties
            trackDevicesTypeId={trackDevicePropertiesIsOpen}
            onToggle={this.trackDevicePropertiesToggle}
            getShopTrackDevicesModels={this.props.getShopTrackDeviceModels}
            clearShopTrackDevices={this.props.clearShopTrackDevices}
            setShopTrackDevicesAddItemToOrder={this.props.addShopTrackDevicesOrderItem}
            setShopTrackDevicesOrderConfirm={(orderItem)=> {
              this.props.addShopTrackDevicesOrderItem(orderItem);
              this.trackDeviceConfirmOrderToggle(-1);
            }}
          />
          {!!trackDeviceConfirmOrderIsOpen && (
            <Redirect to='/shop/confirm-order' />
          )}
          <OrderDetailsDlg
            shopOrderDetails={shopOrderDetails}
            onToggle={this.requestOrderPayDlgToggle}
            onPayment={this.handleOrderPayment}
          />
          {directories.map(directory => {
            if (directory.key === 'altCards') {
              return (<ProductCategory
                key={directory.key}
                category={directory.key}
                title={directory.name}
                expanded={expanded}
                onChange={handleChange}
                onToggle={this.altCardPropertiesToggle}
                items={altCardTypes}
              />)
            }
            if (directory.key === 'trackDevices' ) {
              return (<ProductCategory
                key={directory.key}
                category={directory.key}
                title={directory.name}
                expanded={expanded}
                onChange={handleChange}
                onToggle={this.trackDevicePropertiesToggle}
                items={trackDevicesTypes}
                showCart
                cartItems={trackDevicesOrderItems}
                onConfirmOrder={this.trackDeviceConfirmOrderToggle}
              />)
            }
          })}
        </div>
      );
    }
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withStyles(styles)(Products));
