import React from "react";
import ArrowRightAlt from "@material-ui/icons/ArrowRightAlt";
import Clear from "@material-ui/icons/Clear";
import MonthCalendar from "components/MonthCalendar";
import MonthCaption from "components/MonthCaption";
import CalendarInfo from "./CalendarInfo";

import withStyles from "@material-ui/core/styles/withStyles";

const styles = theme => ({
  container: {
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.down("xs")]: {
      display: "block"
    }
  },
  dayContainer: {
    display: "flex",
    minHeight: 65,
    flexDirection: "column",
    position: "relative",
    justifyContent: "space-between",
    alignItems: "center",
    height: "100%",
    [theme.breakpoints.down("xs")]: {
      minHeight: 50
    }
  },
  dayContainerHighlight: {
    display: "flex",
    minHeight: 65,
    flexDirection: "column",
    position: "relative",
    justifyContent: "space-between",
    alignItems: "center",
    height: "100%",
    background: "#f3f3f3",
    border: "1px solid grey",
    [theme.breakpoints.down("xs")]: {
      minHeight: 50
    }
  },
  day: {
    fontSize: 23,
    fontWeight: 500,
    color: "#949494"
  },
  icons: {
    display: "flex",
    alignItems: "center"
  },
  enter: {
    color: "green",
    fontSize: 18,
    fontWeight: 600,
    transform: "rotate(-90deg)",
    [theme.breakpoints.up("sm")]: {
      fontSize: 22
    }
  },
  exit: {
    color: "red",
    fontSize: 18,
    fontWeight: 600,
    transform: "rotate(90deg)",
    [theme.breakpoints.up("sm")]: {
      fontSize: 22
    }
  }
});

const AttendanceCalendar = props => {
  const { classes, selectedDate, selectedMonth, attendanceMonthly, setMonth, setDate } = props;

  const renderDays = day => {
    const index = day.format("D") - 1;
    if (
      attendanceMonthly[index] &&
      day.format("YYYY-MM-DD") === attendanceMonthly[index].calendarDate
    ) {
      if (attendanceMonthly[index].inetShow === 0) {
        return (
          <div className={classes.dayContainer}>
            <span className={classes.day}>{day.format("D")}</span>
            <div className={classes.icons}>
              <Clear color="error" />
            </div>
          </div>
        );
      } else {
        return (
          <div
            className={
              attendanceMonthly[index].goIn !== null || attendanceMonthly[index].goOut !== null
                ? classes.dayContainerHighlight
                : classes.dayContainer
            }
          >
            <span className={classes.day}>{day.format("D")}</span>
            <div className={classes.icons}>
              {attendanceMonthly[index].goIn !== null ? (
                <ArrowRightAlt className={classes.enter} />
              ) : null}
              {attendanceMonthly[index].goOut !== null ? (
                <ArrowRightAlt className={classes.exit} />
              ) : null}
            </div>
          </div>
        );
      }
    } else {
      return (
        <div className={classes.dayContainer}>
          <span className={classes.day}>{day.format("D")}</span>
          <div className={classes.icons} />
        </div>
      );
    }
  };

  //TODO: использовать отдельный календарь с передаваемыми параметрами
  const renderMonthCaption = ({ month, onMonthSelect, onYearSelect }) => (
    <MonthCaption {...{ selectedMonth, month, onMonthSelect, onYearSelect, setMonth }} />
  );

  if (attendanceMonthly) {
    return (
      <>
        <MonthCalendar
          {...{ renderMonthCaption, renderDays, setMonth, selectedDate, setDate, CalendarInfo }}
        />
      </>
    );
  } else {
    return null;
  }
};

export default withStyles(styles)(AttendanceCalendar);
