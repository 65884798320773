import React from "react";

import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";

import moment from "moment";
import { withStyles } from "@material-ui/core/styles";

const styles = theme => ({
  dialog: {
    padding: 5,
    minHeight: 100,
    width: 450
  },
  dialogMobile: {
    padding: 5,
    minHeight: 100
  },
  tableRow: {
    height: 30
  },
  cell: {
    fontSize: 16
  }
});

const Details = props => {
  const { classes, mobile, detailsIsOpen, detailsPopupClose, selected, pupils } = props;
  if (selected) {
    const insuranceData = [
      { name: "Страхователь", value: "row" },
      { name: "Фамилия", value: selected.insurerFam },
      { name: "Имя", value: selected.insurerIm },
      { name: "Отчество", value: selected.insurerOtch },
      { name: "Телефон", value: selected.insurerPhone },
      { name: "Паспорт", value: "row" },
      { name: "Серия", value: selected.insurerDocSeries },
      { name: "Номер", value: selected.insurerDocNumber },
      { name: "Дата выдачи", value: moment(selected.insurerDocDate).format("DD.MM.YYYY") },
      { name: "Кем выдан", value: selected.insurerDocIssuer },
      { name: "Адрес регистрации", value: "row" },
      { name: "Индекс", value: selected.insurerPostIndex },
      { name: "Город", value: selected.insurerCityName },
      { name: "Населенный пункт", value: selected.insurerLocalityName },
      { name: "Улица", value: selected.insurerStreetName },
      { name: "Дом", value: selected.insurerHouseNum },
      { name: "Строение/корпус", value: selected.insurerBuildNum },
      { name: "Квартира", value: selected.insurerApartmentNum },
      { name: "Ученик", value: "row" },
      { name: "Фамилия", value: pupils[selected.accountId].fam },
      { name: "Имя", value: pupils[selected.accountId].im },
      { name: "Отчество", value: pupils[selected.accountId].otch },
      { name: "Дата рождения", value: moment(selected.accountBirthDate).format("DD.MM.YYYY") },
      {
        name: selected.accountDocType === 1 ? "Свидетельство о рождении" : "Паспорт",
        value: "row"
      },
      { name: "Серия", value: selected.accountDocSeries },
      { name: "Номер", value: selected.accountDocNumber },
      { name: "Дата выдачи", value: moment(selected.accountDocDate).format("DD.MM.YYYY") },
      { name: "Кто выдал", value: selected.accountDocIssuer }
    ];
    return (
      <Dialog
        fullScreen={mobile}
        maxWidth="sm"
        open={detailsIsOpen}
        onClose={e => detailsPopupClose()}
      >
        <DialogContent className={mobile ? classes.dialogMobile : classes.dialog}>
          <Table padding="none">
            <TableBody>
              {insuranceData.map((item, index) => {
                if (item.value === "row") {
                  return (
                    <TableRow key={index} className={classes.tableRow}>
                      <TableCell className={classes.cell} colSpan={2}>
                        <b>{item.name}</b>
                      </TableCell>
                    </TableRow>
                  );
                } else {
                  return (
                    <TableRow key={index} className={classes.tableRow}>
                      <TableCell className={classes.cell}>{item.name}</TableCell>
                      <TableCell className={classes.cell} numeric>
                        {item.value}
                      </TableCell>
                    </TableRow>
                  );
                }
              })}
            </TableBody>
          </Table>
        </DialogContent>
        <DialogActions>
          <Button onClick={e => detailsPopupClose()} color="primary">
            Закрыть
          </Button>
        </DialogActions>
      </Dialog>
    );
  } else return null;
};

export default withStyles(styles)(Details);
