import React from "react";

import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";

import InvoiceDetails from "./InvoiceDetails";

const InvoiceDetailsPopup = props => {
  const { detailIsOpen, month, invoiceDetails, detailsPopupClose } = props;

  return (
    <Dialog
      fullScreen
      open={detailIsOpen}
      onClose={e => detailsPopupClose()}
    >
      <DialogContent>
          <InvoiceDetails month={month} invoiceDetails={invoiceDetails} />
      </DialogContent>
      <DialogActions>
        <Button onClick={e => detailsPopupClose()} color="primary">
          Закрыть
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default InvoiceDetailsPopup;
