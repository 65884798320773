import React from "react";

import Button from "@material-ui/core/Button";
import {withStyles} from "@material-ui/core/styles";
import {List, ListItem, Typography} from "@material-ui/core";

import DocumentsVSK from '../../DocumentsVSK'
import DocumentsKL from "../../DocumentsKL";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

const styles = theme => ({
  buttons: {
    position: "relative",
    display: "flex",
    justifyContent: "flex-start",
    margin: "15px 0"
  },
  nextButton: {
    flexGrow: 1,
    margin: "0 5px"
  },
  aLink: {
    color: "#0000ff",
    '&visited': {color: "#0000ff"},
    '&active': {color: "#0000ff"},
  }
});


const privatePolicyLink = 'https://ueshka.ru/index.php/roditelyam/politika-v-oblasti-obespecheniya-bezopasnosti-personalnykh-dannykh';
const contractLink = '/docs/regulationsKL.pdf'

const Step4 = props => {
  const {
    classes,
    changeStep,
    available,
    selectedPolicy,
    selectedInsurance,
    isLoading,
    addInsuranceInvoice
  } = props;
  const orgId = !selectedInsurance ? 1 : available.policies[selectedPolicy].insurances[selectedInsurance].orgId || 1;
  return (
    <div>
      <Typography>Нажимая кнопку "Отправить заполненные данные", Вы:</Typography>
      <List dense={true}>
        <ListItem>
          <FormControlLabel
            control={
              <Checkbox
                id="grantData"
                checked={true}
                value="grantData"
                color="primary"
              />
            }
            label={<Typography>даете свое согласие на обработку и хранение указанных ПДн
              (Прочитать <a className={classes.aLink}
                            href={privatePolicyLink}
                            target="_blank" rel="noopener noreferrer">политику
                в области обеспечения безопасности</a>)
            </Typography>}
          />
        </ListItem>
        <ListItem>
          <FormControlLabel
            control={
              <Checkbox
                id="acceptLegal"
                checked={true}
                value="acceptLegal"
                color="primary"
              />
            }
            label={<Typography>соглашаетесь с условиями страхования (Прочитать <a className={classes.aLink}
                                                                                  href={contractLink} target="_blank"
                                                                                  rel="noopener noreferrer">условия</a>)
            </Typography>}
          />
        </ListItem>
      </List>
      <div className={classes.buttons}>
        <Button variant="outlined" size="small" onClick={e => changeStep("backward")}>
          Назад
        </Button>
        <Button
          className={classes.nextButton}
          variant="outlined"
          color="secondary"
          size="large"
          disabled={isLoading}
          onClick={e => addInsuranceInvoice()}
        >
          Создать счет *
        </Button>
      </div>

      {/*<Typography variant="caption" paragraph align="justify">*/}
      {/*  * Нажав на кнопку Создать счет» Страхователь подтверждает, что Застрахованное лицо не имеет*/}
      {/*  статуса «ребенок-инвалид», а также действующего направления на медико-социальную экспертизу.*/}
      {/*  Полученные Страховщиком и компанией «УЭШКА» сведения используются в соответствии с нормами*/}
      {/*  Федерального закона от 27.07.2006 года № 152-ФЗ «О персональных данных» исключительно для*/}
      {/*  заключения и исполнения соответствующего договора страхования. Страхователь согласен на*/}
      {/*  обработку Страховщиком и компанией «УЭШКА» вышеперечисленных персональных данных*/}
      {/*  Страхователя, Застрахованного лица на срок заключения и исполнения договора страхования.*/}
      {/*</Typography>*/}
      {(orgId === 1) && (
        <>
          {/*    <Typography variant="caption" paragraph align="justify">*/}
          {/*      Страхователь подтверждает, что ознакомился с «Правилами № 83 добровольного страхования*/}
          {/*      граждан от несчастных случаев и болезней (в редакции от 25.11.2013г.)» и согласен с их*/}
          {/*      содержанием (см. Документы по страхованию).*/}
          {/*    </Typography>*/}
          {/*    <Typography variant="caption" paragraph align="justify">*/}
          {/*      Счет необходимо оплатить в течении трех календарных дней, иначе он будет аннулирован. После*/}
          {/*      оплаты счета данные будут отправлены в страховую компанию, а страховой полис будет*/}
          {/*      сформирован в ближайшее время. Вы получите sms-уведомление, когда полис появится в личном*/}
          {/*      кабинете. Вам необходимо распечатать и подписать полис, после этого он вступает в силу.*/}
          {/*    </Typography>*/}
          {/*    <Typography variant="caption" paragraph align="justify">*/}
          {/*      <b>Причинение вреда здоровью</b> Застрахованного лица в результате несчастного случая*/}
          {/*      (размер страховой выплаты определяется по «Таблице страховых выплат СОАО «ВСК»).*/}
          {/*    </Typography>*/}
          {/*    <Typography variant="caption" paragraph align="justify">*/}
          {/*      <b>Установление группы инвалидности</b> Застрахованному лицу в результате несчастного случая*/}
          {/*      (выплата производится в размере 100% от страховой суммы при установлении статуса*/}
          {/*      «ребенок-инвалид» для Застрахованного лица).*/}
          {/*    </Typography>*/}
          {/*    <Typography variant="caption" paragraph align="justify">*/}
          {/*      <b>Смерть Застрахованного лица</b> в результате несчастного случая (выплата производится в*/}
          {/*      размере 100% от страховой суммы).*/}
          {/*    </Typography>*/}
          {/*    <Typography variant="subtitle2" paragraph>*/}
          {/*      Выгодоприобретатель* назначается согласно действующему законодательству РФ.*/}
          {/*    </Typography>*/}
          {/*    <Typography variant="caption" paragraph align="justify">*/}
          {/*      *Выгодоприобретатель - родители или опекуны застрахованного ребенка, которые имеют право на*/}
          {/*      получение страховой выплаты при наступлении несчастного случая.*/}
          {/*    </Typography>*/}
          <DocumentsVSK/>
        </>)}
      {(orgId === 2) && (
        <>
          {/*    <Typography variant="caption" paragraph align="justify">*/}
          {/*      Страхователь подтверждает, что ознакомился с «Программой добровольного индивидуального страхования учащихся*/}
          {/*      общеобразовательных организаций №1» ООО «Капитал Лайф Страхование Жизни» (разработанной на основании и*/}
          {/*      условиях «Правил страхования от несчастных случаев и болезней № 5») и согласен с её содержанием (см.*/}
          {/*      Документы по страхованию).*/}
          {/*    </Typography>*/}
          {/*    <Typography variant="caption" paragraph align="justify">*/}
          {/*      Счет необходимо оплатить в течении трех календарных дней, иначе он будет аннулирован. После*/}
          {/*      оплаты счета данные будут отправлены в страховую компанию, а страховой полис будет*/}
          {/*      сформирован в ближайшее время. Вы получите sms-уведомление, когда полис появится в личном*/}
          {/*      кабинете. Вам необходимо распечатать и подписать полис, после этого он вступает в силу.*/}
          {/*      <br/>*/}
          {/*      Уплатой страховой премии по договору страхования Страхователь подтверждает заключение Договора страхования.*/}
          {/*    </Typography>*/}
          {/*    <Typography variant="subtitle2" paragraph>*/}
          {/*      Страховые риски/Страховые выплаты:*/}
          {/*    </Typography>*/}
          {/*    <Typography variant="caption" paragraph align="justify">*/}
          {/*      <b>Телесные повреждения</b> (травма, случайное острое отравление), полученные Застрахованным лицом в*/}
          {/*      результате несчастного случая (размер страховой выплаты определяется по «Таблице размеров страховых*/}
          {/*      выплат №2»).*/}
          {/*    </Typography>*/}
          {/*    <Typography variant="caption" paragraph align="justify">*/}
          {/*      <b>Диагностирование заболевания COVID-19</b> у Застрахованного лица (выплата производится в размере 10 000*/}
          {/*      руб.).*/}
          {/*    </Typography>*/}
          {/*    <Typography variant="caption" paragraph align="justify">*/}
          {/*      <b>Стационарное лечение</b> Застрахованного лица <b>в результате болезни COVID-19</b> (выплата производится*/}
          {/*      в размере 2 000 руб. в день, но не более чем за 14 дней).*/}
          {/*    </Typography>*/}
          {/*    <Typography variant="caption" paragraph align="justify">*/}
          {/*      <b>Смерть Застрахованного лица в результате болезни COVID-19</b> (выплата производится в размере 100% от*/}
          {/*      страховой суммы).*/}
          {/*    </Typography>*/}
          {/*    <Typography variant="subtitle2" paragraph>*/}
          {/*      Выгодоприобретатель* назначается согласно действующему законодательству РФ.*/}
          {/*    </Typography>*/}
          {/*    <Typography variant="caption" paragraph align="justify">*/}
          {/*      *Выгодоприобретатель - родители или опекуны застрахованного ребенка, которые имеют право на*/}
          {/*      получение страховой выплаты при наступлении несчастного случая.*/}
          {/*    </Typography>*/}
          <DocumentsKL/>
        </>)}
    </div>
  );
};

export default withStyles(styles)(Step4);
