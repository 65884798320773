import React from "react";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";

import { withStyles } from "@material-ui/core/styles";

const styles = theme => ({
  root: {
    padding: "7px 5px 5px 5px",
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center"
  },
  text: {
    marginRight: 5
  }
});

const RefreshProfile = props => {
  const { classes, getProfile, profileDate} = props;
  return (
    <>
      <div className={classes.root}>
        <Typography variant="subtitle1" className={classes.text}>
          Время обновления: {profileDate}
        </Typography>
        <Button
          variant="outlined"
          color="primary"
          size="small"
          onClick={() => getProfile()}
        >
          Обновить
        </Button>
      </div>
    </>
  );
};

export default withStyles(styles)(RefreshProfile);
