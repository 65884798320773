import { combineReducers } from "redux";
import errors from "./errors";
import info from "./info";
import loading from "./loading";
import user from "./user";
import tariffs from "./tariffs";
import attendance from "./attendance";
import pays from "./pays";
import meals from "./meals";
import archive from "./archive";
import help from "./help";
import insurance from "./insurance";
import gps from "./gps";
import fias from "./fias";
import registration from "./registration";
import shop from "./shop";
import promo from "./promo";
import transportCard from "./transportCard";
import khalva from "./khalva";

export default combineReducers({
  errors,
  info,
  loading,
  user,
  tariffs,
  attendance,
  pays,
  meals,
  archive,
  help,
  insurance,
  gps,
  fias,
  registration,
  shop,
  promo,
  transportCard,
  khalva,
});
