import React from "react";
import LoadingMessage from "components/messages/LoadingMessage";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ConnectionIcon from "@material-ui/icons/SwapVert";
import RebootIcon from "@material-ui/icons/Replay";
import PowerIcon from "@material-ui/icons/PowerSettingsNew";
import SearchIcon from "@material-ui/icons/Search";

import withStyles from "@material-ui/core/styles/withStyles";
import PropTypes from "prop-types";
import ProfileSettings from "./ProfileSettings";
import UploadSettings from "./UploadSettings";
import CenterPhoneSettings from "./CenterPhoneSettings";
import SlavePhoneSettings from "./SlavePhoneSettings";
import LowChargeAlarmSettings from "./LowChargeAlarmSettings";
import SosSmsSettings from "./SosSmsSettings";
import LanguageSettings from "./LanguageSettings";
import RemoveSmsSettings from "./RemoveSmsSettings";
import TimeZoneSettings from "./TimeZoneSettings";
import SosPhonesSettings from "./SosPhonesSettings";

const styles = theme => ({
  modal: {
    [theme.breakpoints.down("xs")]: {
      alignItems: "flex-start"
    }
  },
  content: {
    padding: "0 5px"
  },
  paper: {
    margin: 8
  },
  listInput: {
    flexDirection: "column",
    alignItems: "flex-start"
  },
  fullWidth: {
    width: "100%"
  },
  button: {
    width: "100%",
    justifyContent: "space-between"
  }
});

const GpsWatchSettingsWindow = ({
  classes,
  isSettingsShown,
  toggleSettingsModal,
  settingsDescription,
  settings,
  checkWatchConnection,
  gpsWatchReboot,
  gpsWatchPowerOff,
  gpsWatchFind,
  handleSelectChange,
  handleInputChange,
  handleSosPhonesInputChange,
  errorOnPhoneValidate,
  handleSubmitSettings
}) => {
  // disable save button if one of field not valid
  const validateSettingsSaveDisabled = () => {
    if (
      settings
      && !errorOnPhoneValidate(settings.center)
      && !errorOnPhoneValidate(settings.slave)
      && !errorOnPhoneValidate(settings.slave)
      && !errorOnPhoneValidate(settings.sosPhones[0])
      && !errorOnPhoneValidate(settings.sosPhones[1])
      && !errorOnPhoneValidate(settings.sosPhones[2])
    ) {
      return false;
    }
    return true;
  };
  return (
    <Dialog
      open={isSettingsShown}
      onClose={toggleSettingsModal}
      classes={{ root: classes.modal, paper: classes.paper }}
    >
      <LoadingMessage />
      <DialogTitle>Настройки часов</DialogTitle>
      <DialogContent className={classes.content}>
        <List dense={true}>
          {!settings && <ListItemText primary="Загрузка" />}
          {// Режим оповещения
          settings && (
            <ProfileSettings {...{ settings, settingsDescription, handleSelectChange }} />
          )}
          {// Время обновления координат в сек.
          settings && <UploadSettings {...{ settings, settingsDescription, handleInputChange }} />}
          {// Номер основного телефона
          settings && (
            <CenterPhoneSettings
              {...{
                settings,
                settingsDescription,
                handleInputChange,
                errorOnPhoneValidate
              }}
            />
          )}
          {// Номер дополнительного телефона
          settings && (
            <SlavePhoneSettings
              {...{
                settings,
                settingsDescription,
                handleInputChange,
                errorOnPhoneValidate
              }}
            />
          )}
          {// Оповещение по СМС о низком заряде
          settings && (
            <LowChargeAlarmSettings {...{ settings, settingsDescription, handleSelectChange }} />
          )}
          {// Оповещение по СМС при сигнале SOS
          settings && <SosSmsSettings {...{ settings, settingsDescription, handleSelectChange }} />}
          {// Оповещение по СМС при снятии часов с руки
          settings && (
            <RemoveSmsSettings {...{ settings, settingsDescription, handleSelectChange }} />
          )}
          {// Языки интерфейса
          settings && (
            <LanguageSettings {...{ settings, settingsDescription, handleSelectChange }} />
          )}
          {// Часовой пояс
          settings && (
            <TimeZoneSettings {...{ settings, settingsDescription, handleSelectChange }} />
          )}
          {// Список телефонов SOS
          settings && (
            <SosPhonesSettings
              {...{
                settings,
                settingsDescription,
                handleSosPhonesInputChange,
                errorOnPhoneValidate
              }}
            />
          )}
          <hr />
          {
            // Проверка связи
            <ListItem disableGutters>
              <Button
                className={classes.button}
                variant="outlined"
                size="small"
                onClick={checkWatchConnection}
              >
                Проверить связь с часами
                <ConnectionIcon />
              </Button>
            </ListItem>
          }
          {
            // Перезагрузка
            <ListItem disableGutters>
              <Button
                className={classes.button}
                variant="outlined"
                size="small"
                onClick={gpsWatchReboot}
              >
                Перезагрузить часы
                <RebootIcon />
              </Button>
            </ListItem>
          }
          {
            // Выключение
            <ListItem disableGutters>
              <Button
                className={classes.button}
                variant="outlined"
                size="small"
                onClick={gpsWatchPowerOff}
              >
                Выключить часы
                <PowerIcon />
              </Button>
            </ListItem>
          }
          {
            // Поиск
            <ListItem disableGutters>
              <Button
                className={classes.button}
                variant="outlined"
                size="small"
                onClick={gpsWatchFind}
              >
                Поиск часов
                <SearchIcon />
              </Button>
            </ListItem>
          }
        </List>
      </DialogContent>
      <DialogActions>
        <Button onClick={toggleSettingsModal} color="primary">
          Закрыть
        </Button>
        <Button
          onClick={handleSubmitSettings}
          disabled={validateSettingsSaveDisabled()}
          color="primary"
        >
          Сохранить
        </Button>
      </DialogActions>
    </Dialog>
  );
};

GpsWatchSettingsWindow.propTypes = {
  classes: PropTypes.object,
  isSettingsShown: PropTypes.bool.isRequired,
  toggleSettingsModal: PropTypes.func.isRequired,
  settingsDescription: PropTypes.object,
  settings: PropTypes.object,
  checkWatchConnection: PropTypes.func.isRequired,
  gpsWatchReboot: PropTypes.func.isRequired,
  gpsWatchPowerOff: PropTypes.func.isRequired,
  gpsWatchFind: PropTypes.func.isRequired,
  handleSelectChange: PropTypes.func.isRequired,
  handleInputChange: PropTypes.func.isRequired,
  handleSosPhonesInputChange: PropTypes.func.isRequired,
  errorOnPhoneValidate: PropTypes.func.isRequired,
  handleSubmitSettings: PropTypes.func.isRequired
};

export default withStyles(styles)(GpsWatchSettingsWindow);
