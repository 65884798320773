// reducer для глобальных уведомлений
import { handleActions } from "redux-actions";
import { showInfoMessage, clearInfoMessage } from "actions/infoMessageActions";
const initialState = {
  infoMessage: ""
};
const info = handleActions(
  {
    [showInfoMessage]: (state, action) => ({ ...state, infoMessage: action.payload }),
    [clearInfoMessage]: state => ({ ...state, infoMessage: "" })
  },
  initialState
);

export default info;
