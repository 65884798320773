import { handleActions } from "redux-actions";

import {
  registrationClear,
  docTypesSuccess,
  relationDegreeSuccess,
  schoolsByRegionSuccess,
  classesBySchoolSuccess,
  changeStepSuccess,
  sendConfirmCodeSuccess,
  checkPhoneSuccess,
  getRegformSuccess,
  sendRegformRequest,
  sendRegformSuccess,
  sendRegformError
} from "actions/registrationActions";

const initialState = {
  step: 0,
  isNewPhone: false,
  isNewPhoneChecked: null,
  errors: {},
  codeSendDate: null,
  regformData: null,
  link: null,
  schools: null,
  classes: null
};

const registration = handleActions(
  {
    [registrationClear]: () => ({...initialState}),
    [changeStepSuccess]: (state, { payload: step }) => ({ ...state, step: step }),
    [docTypesSuccess]: (state, action) => ({ ...state, docTypes: action.payload.docTypes }),
    [relationDegreeSuccess]: (state, action) => ({
      ...state,
      relationDegree: action.payload.relationDegree
    }),
    [schoolsByRegionSuccess]: (state, action) => ({ ...state, schools: action.payload.schools }),
    [classesBySchoolSuccess]: (state, { payload: { schoolId, classes } }) => {
      const transformedClasses = classes.map(item => ({
        value: item.id,
        label: item.classNumber
      }));
      return {
        ...state,
        classes: {
          ...state.classes,
          [schoolId]: transformedClasses
        }
      };
    },
    [sendConfirmCodeSuccess]: state => ({
      ...state,
      codeSendDate: new Date()
    }),
    [checkPhoneSuccess]: (state, {payload : {isNewPhone, phone}}) => ({ ...state, isNewPhone, isNewPhoneChecked: phone }),
    [getRegformSuccess]: (state, {payload: {regformData}}) => ({
      ...state,
      regformData: regformData
    }),
    [sendRegformRequest]: state => ({ ...state, link: null }),
    [sendRegformSuccess]: (state, action) => ({ ...state, errors: {}, regformData: action.payload.regformData, link: action.payload.link }),
    [sendRegformError]: (state, action) => ({
      ...state,
      errors: action.payload.errors ? action.payload.errors : state.errors,
      regformData: action.payload.regformData
    })
  },
  initialState
);

export default registration;
