import { createAction } from "redux-actions";

export const clearErrorSuccess = createAction("CLEAR_ERROR_SUCCESS");
export const generateError = createAction("GENERATE_ERROR");

export const clearError = () => dispatch => {
  dispatch(clearErrorSuccess());
};

export const generateErrorMessage = (errorText, errorStatus) => dispatch => {
  dispatch(generateError({ error: errorText, errorStatus }));
};
