import React from "react";

import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";

import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";

const styles = () => ({
  listInput: {
    flexDirection: "column",
    alignItems: "flex-start"
  },
  fullWidth: {
    width: "100%"
  }
});

const timeZones = [
  { gmt: "0.00", title: "Лондон, GMT+0:00" },
  { gmt: "1.00", title: "Амстердам, GMT+1:00" },
  { gmt: "2.00", title: "Калининград, GMT+2:00" },
  { gmt: "3.00", title: "Москва, GMT+3:00" },
  { gmt: "4.00", title: "Самара, GMT+4:00" },
  { gmt: "5.00", title: "Екатеринбург, GMT+5:00" },
  { gmt: "6.00", title: "Омск, GMT+6:00" },
  { gmt: "7.00", title: "Новосибирск, GMT+7:00" },
  { gmt: "8.00", title: "Иркутск, GMT+8:00" },
  { gmt: "9.00", title: "Чита, GMT+9:00" },
  { gmt: "10.00", title: "Владивосток, GMT+10:00" },
  { gmt: "11.00", title: "Южно-Сахалинск, GMT+11:00" }
];

const TimeZoneSettings = ({
  settings, settingsDescription, handleSelectChange, classes
}) => (
  <ListItem disableGutters className={classes.listInput}>
    <ListItemText primary={settingsDescription.timeZone.title} />
    <FormControl variant="outlined" className={classes.fullWidth}>
      <Select
        name="timeZone"
        value={settings.timeZone}
        onChange={event => handleSelectChange(event.target.name, event.target.value)}
      >
        {timeZones.map(item => (
          <MenuItem key={item.gmt} value={item.gmt}>
            {item.title}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  </ListItem>
);

TimeZoneSettings.propTypes = {
  settings: PropTypes.object,
  settingsDescription: PropTypes.object,
  handleSelectChange: PropTypes.func.isRequired,
  classes: PropTypes.object
};

export default withStyles(styles)(TimeZoneSettings);
