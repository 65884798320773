import React from "react";
import PropTypes from "prop-types";
import MomentUtils from "@date-io/moment";
import { MuiPickersUtilsProvider } from "material-ui-pickers";
import { DateTimePicker } from "material-ui-pickers";

import { withStyles } from "@material-ui/core/styles";
const styles = theme => ({
  inputLabel: {
    color: "white"
  },
  input: {
    backgroundColor: "white",
    color: "black",
    marginTop: 5,
    paddingLeft: 5
  },
  picker: {
    marginTop: 5,
    width: 160,
    color: "white",
    marginRight: 10,
    [theme.breakpoints.down("xs")]: {
      width: 130
    }
  }
});

const SelectPeriod = props => {
  const { classes, historyStart, historyEnd, selectDateAndTime } = props;
  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <DateTimePicker
        className={classes.picker}
        label="Период с"
        format="DD.MM.YYYY HH:mm"
        showTodayButton
        ampm={false}
        showTabs={false}
        autoOk
        disableFuture
        maxDateMessage="Дата не должна быть больше текущей"
        clearLabel="Очистить"
        cancelLabel="Отмена"
        todayLabel="Сегодня"
        value={historyStart}
        onChange={selectDateAndTime("historyStart")}
        InputLabelProps={{
          classes: { root: classes.inputLabel }
        }}
        InputProps={{ classes: { input: classes.input } }}
      />
      <DateTimePicker
        className={classes.picker}
        label="Период по"
        format="DD.MM.YYYY HH:mm"
        showTodayButton
        ampm={false}
        showTabs={false}
        autoOk
        disableFuture
        maxDateMessage="Дата не должна быть больше текущей"
        clearLabel="Очистить"
        cancelLabel="Отмена"
        todayLabel="Сегодня"
        value={historyEnd}
        onChange={selectDateAndTime("historyEnd")}
        InputLabelProps={{
          classes: { root: classes.inputLabel }
        }}
        InputProps={{ classes: { input: classes.input } }}
      />
    </MuiPickersUtilsProvider>
  );
};

export default withStyles(styles)(SelectPeriod);

SelectPeriod.propTypes = {
  classes: PropTypes.object,
  historyStart: PropTypes.instanceOf(Date).isRequired,
  historyEnd: PropTypes.instanceOf(Date).isRequired,
  selectDateAndTime: PropTypes.func.isRequired
};
