import React from "react";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import CircularProgress from "@material-ui/core/CircularProgress";
import PropTypes from "prop-types";
import {withStyles} from "@material-ui/core/styles";

const styles = theme => ({
  listItem: {
    padding: 0
  },
  buttons: {
    display: "flex",
    justifyContent: "flex-start",
    marginTop: 15,
    marginBottom: 15
  },
  nextButton: {
    flexGrow: 1,
    margin: "0 5px"
  },
  buttonProgress: {
    color: "white",
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12
  }
});

const TariffNameAndPrice = props => {
  const {
    selectedTariff,
    isLocalLoading,
    selectedTariffProps: {name, price, referralMessage, amountCent},
    // referralCode,
    // changeReferral,
    // profile
  } = props;

  // const needReferralCode = profile.menu.filter(item => item.id === 10).length !== 0;
  return selectedTariff !== undefined && name ? (
    <>
      <Typography variant="subtitle1">Тариф: {isLocalLoading ? "загрузка" : name}</Typography>
      <Typography variant="subtitle1">
        Стоимость: {isLocalLoading ? "загрузка" : price + " руб./мес."}
      </Typography>
      {(!isLocalLoading && referralMessage && referralMessage.length > 0)
        ? (
          (referralMessage !== 'OK') ? (
            <Typography variant="subtitle1" color="error">Реферальный код не будет применен: {referralMessage}</Typography>
          ) : (
            <>
              <Typography variant="subtitle1" color="error">Вы ввели реферальный код, а значит изъявили желание в участии в акции "Пригласи друга", для чего необходимо внести предоплату в размере
                указанном ниже</Typography>
              <Typography variant="subtitle1" color="error">Размер предоплаты (без учета комиссии): {(amountCent / 100).toFixed(2)} руб.</Typography>
              {(amountCent > 0) ? (<Typography variant="caption">Примечание: Выбранный тариф будет применен после оплаты.</Typography>) : null}
            </>
          )
        )
        : null}
      <Divider/>
    </>
  ) : (
    <>
      <Typography variant="subtitle1">Тариф: загрузка</Typography>
      <Typography variant="subtitle1">Стоимость: загрузка</Typography>
      <Divider/>
    </>
  );
};

const Step4 = props => {
  const {classes, setTariff, prevStep, isLocalLoading,
    selectedTariffProps: {referralMessage, amountCent}} = props;
  return (
    <>
      <TariffNameAndPrice {...props} />

      <div className={classes.buttons}>
        <Button variant="outlined" size="small" onClick={e => prevStep()}>
          Назад
        </Button>
        <Button
          className={classes.nextButton}
          variant="outlined"
          color="secondary"
          size="large"
          onClick={setTariff()}
        >
          {(referralMessage === 'OK' && amountCent > 0) ? 'Оплатить' :'Применить'}
        </Button>
        {isLocalLoading && <CircularProgress size={24} className={classes.buttonProgress}/>}
      </div>
    </>
  );
};

TariffNameAndPrice.propTypes = {
  selectedTariff: PropTypes.number,
  isLocalLoading: PropTypes.bool,
  selectedTariffProps: PropTypes.shape({
    name: PropTypes.string,
    price: PropTypes.string
  }),
  referralCode: PropTypes.string,
  changeReferral: PropTypes.func.isRequired,
  profile: PropTypes.shape({
    menu: PropTypes.array
  })
};

Step4.propTypes = {
  classes: PropTypes.object,
  selectedTariff: PropTypes.number,
  isLocalLoading: PropTypes.bool,
  selectedTariffProps: PropTypes.shape({
    name: PropTypes.string,
    price: PropTypes.string
  }),
  referralCode: PropTypes.string,
  setTariff: PropTypes.func.isRequired,
  prevStep: PropTypes.func.isRequired
};

export default withStyles(styles)(Step4);
