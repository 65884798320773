import React from "react";

import withStyles from "@material-ui/core/styles/withStyles";
import {Button, Card, CardActionArea, CardActions, CardContent, CardMedia, Typography} from "@material-ui/core";

const styles = theme => ({
  paper: {

  },
  media: {
    width: "auto",
    height: "auto",
    maxHeight: 200,
    maxWidth: 200,
  },
  actions: {
    display: "flex",
    justifyContent: "space-between"
  }
});

const ProductItem = props => {
  const { classes, altCardTypeId, name, price, picture} = props;
  const onClick = () => {
    if (typeof props.onClick === 'function') props.onClick(altCardTypeId);
    else console.log('ProductItem> onClick undefined')
  }

  return (

    <Card className={classes.paper}>
      <CardActionArea onClick={onClick}>
        <CardMedia
          className={classes.media}
          src={picture}
          title={name}
          component='img'
        />
        <CardContent>
          <Typography gutterBottom variant="h5" component="h2">{name}</Typography>
          <Typography>Цена: от {(price || 0).toFixed(2)} р.</Typography>
        </CardContent>
      </CardActionArea>
      <CardActions className={classes.actions}>
        <Button size="small" color="primary" onClick={onClick}>Купить</Button>
      </CardActions>
    </Card>
  )
}

export default withStyles(styles)(ProductItem);