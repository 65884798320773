import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Radio from "@material-ui/core/Radio";
import ListItemText from "@material-ui/core/ListItemText";
import Typography from "@material-ui/core/Typography";
import InputAdornment from "@material-ui/core/InputAdornment";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Input from "@material-ui/core/Input";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

import { withStyles } from "@material-ui/core/styles";
const styles = theme => ({
  root: {
    width: "100%",
    height: 380,
    overflowY: "scroll"
  },
  purchaseTitle: {
    display: "flex",
    flexDirection: "column"
  },
  list: {
    padding: 0
  },
  radio: {
    paddingRight: 0,
    paddingLeft: 0
  },
  services: {
    display: "flex",
    justifyContent: "space-between"
  },
  panelDetails: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
  },
  description: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center"
  },
  form: {
    margin: 5
  },
  input: {
    minWidth: 200,
    width: 280
  },
  counterAmountContainer: {
    display: "flex",
    justifyContent: "space-between"
  },
  counterAmountColumn: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end"
  },
  purchaseButton: {
    marginBottom: 5
  }
});

class Purchase extends React.Component {
  state = { selectedService: null, amount: "", error: "", isCounterAmount: false };

  // при изменении выбранной услуги сбрасываем галку частичного пополнения и сумму
  componentDidUpdate(prevProps, prevState) {
    if (prevState.selectedService !== this.state.selectedService) {
      this.setState({
        amount: "",
        error: "",
        isCounterAmount: false
      });
    }

    // при раскрытии другой панели сбрасываем все состояние
    if (prevProps.expanded !== this.props.expanded) {
      this.setState({
        selectedService: null,
        amount: "",
        error: "",
        isCounterAmount: false
      });
    }
  }

  onChange = name => event => {
    this.checkSum(event.target.value);
    this.setState({
      [name]: event.target.value
    });
  };

  counterAmountChange = () => {
    this.setState({ isCounterAmount: !this.state.isCounterAmount }, () =>
      this.checkSum(this.state.amount)
    );
  };

  changeService = id => {
    this.setState({ selectedService: id });
  };

  formatedDateAction = actionRange => {
    return `Срок с ${moment(actionRange.startDate).format("DD.MM.YYYY")} по ${moment(
      actionRange.endDate
    ).format("DD.MM.YYYY")}`;
  };

  checkSum = checkValue => {
    const { transportCardServices, transportCardOperations } = this.props;
    const { selectedService } = this.state;
    const restrictionSum = Math.round(
      parseFloat(transportCardServices[selectedService].cost) / 100 / 2
    );
    const availableCounterAmount =
      parseFloat(transportCardOperations.purchase.availableCounterAmount) / 100;

    if (checkValue === "") {
      this.setState({
        error: ""
      });
      return false;
    }

    const value = parseFloat(checkValue);

    if (value < 1 || value > restrictionSum || value > availableCounterAmount) {
      this.setState({
        error: `Сумма перевода может быть между 1 руб. и ${Math.min(
          restrictionSum,
          availableCounterAmount
        )} руб.`
      });
      return false;
    }
    this.setState({
      error: ""
    });
    return true;
  };

  getRestriction = counterAmount => {
    const { transportCardOperations } = this.props;
    const restrictionSum = Math.round(parseFloat(counterAmount) / 2);
    const availableCounterAmount =
      parseFloat(transportCardOperations.purchase.availableCounterAmount) / 100;
    return `Оплатить с карты можно до ${Math.min(restrictionSum, availableCounterAmount)} руб.`;
  };

  render() {
    const {
      classes,
      transportCardOperations,
      transportCardServices,
      expanded,
      changeExpand,
      pupilId,
      fetchTransportCardPurchaseUrl
    } = this.props;
    const { selectedService, isCounterAmount, amount } = this.state;
    const availableCounterAmount =
      parseFloat(transportCardOperations.purchase.availableCounterAmount) / 100;
    return (
      <ExpansionPanel
        key="purchase"
        expanded={expanded === "purchase"}
        onChange={changeExpand("purchase")}
      >
        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
          <div className={classes.purchaseTitle}>
            <Typography variant="subtitle1">Доступные услуги</Typography>
            <Typography variant="subtitle2">
              Доступная для списания со счета карты сумма: {availableCounterAmount} руб.
            </Typography>
          </div>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <div className={classes.panelDetails}>
            <List className={classes.root}>
              {transportCardServices.ids.map(serviceId => (
                <React.Fragment key={serviceId}>
                  <ListItem
                    className={classes.list}
                    button
                    onClick={e => this.changeService(serviceId)}
                  >
                    <Radio className={classes.radio} checked={selectedService === serviceId} />
                    <ListItemText>
                      <div className={classes.services}>
                        <div className={classes.description}>
                          <Typography variant="subtitle1">
                            {transportCardServices[serviceId].description.textNote}
                          </Typography>
                          <Typography variant="body2">
                            {this.formatedDateAction(transportCardServices[serviceId].actionRange)}
                          </Typography>
                        </div>
                        <Typography variant="body2">
                          {parseFloat(transportCardServices[serviceId].cost) / 100} руб.
                        </Typography>
                      </div>
                    </ListItemText>
                  </ListItem>
                  <div className={classes.counterAmountContainer}>
                    <div className={classes.counterAmountColumn}>
                      {selectedService === serviceId && (
                        <FormControlLabel
                          control={
                            <Checkbox
                              color="primary"
                              checked={isCounterAmount}
                              onChange={this.counterAmountChange}
                            />
                          }
                          label="Частично оплатить со счета карты (до 50%)"
                        />
                      )}
                      {selectedService === serviceId && isCounterAmount && (
                        <FormControl required className={classes.form}>
                          <InputLabel htmlFor="amount">Сумма</InputLabel>
                          <Input
                            className={classes.input}
                            type="number"
                            value={this.state.amount}
                            onChange={this.onChange("amount")}
                            error={!!this.state.error}
                            endAdornment={<InputAdornment position="end">руб.</InputAdornment>}
                          />
                          {this.state.error ? (
                            <Typography color="error">{this.state.error}</Typography>
                          ) : (
                            <Typography>
                              {this.getRestriction(
                                parseFloat(transportCardServices[serviceId].cost) / 100
                              )}
                            </Typography>
                          )}
                        </FormControl>
                      )}
                    </div>
                  </div>
                  <Divider />
                </React.Fragment>
              ))}
            </List>
            <Button
              disabled={(!!this.state.error || !this.state.amount) && (isCounterAmount || !selectedService)}
              variant="outlined"
              color="secondary"
              size="large"
              className={classes.purchaseButton}
              onClick={e =>
                fetchTransportCardPurchaseUrl(pupilId, {
                  serviceId: selectedService,
                  usedCounterAmount: isCounterAmount ? parseFloat(amount, 2) * 100 : 0
                })
              }
            >
              Оплатить
            </Button>
          </div>
        </ExpansionPanelDetails>
      </ExpansionPanel>
    );
  }
}

Purchase.propTypes = {
  classes: PropTypes.object,
  transportCardOperations: PropTypes.object,
  transportCardServices: PropTypes.object,
  expanded: PropTypes.string,
  changeExpand: PropTypes.func.isRequired,
  pupilId: PropTypes.number,
  fetchTransportCardPurchaseUrl: PropTypes.func.isRequired
};

export default withStyles(styles)(Purchase);
