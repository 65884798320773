import React, {Component} from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";

import withStyles from "@material-ui/core/styles/withStyles";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography
} from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close';

import {Formik} from "formik";

import {
  getPupilsSelector,
  getTrackDevicesTypesSelector,
  getTrackDevicesModelsSelector,
  getTrackDevicesModelsByIdSelector,
} from "selectors/shop";
import TrackDeviceData from "./TrackDeviceData";


const mapStateToProps = state => ({
  user: state.user,
  shop: state.shop,
  pupils: getPupilsSelector(state),
  trackDevicesTypes: getTrackDevicesTypesSelector(state),
  trackDevicesModels: getTrackDevicesModelsSelector(state),
  trackDevicesModelsById: getTrackDevicesModelsByIdSelector(state),
  // altCardKinds: getAltCardKindsSelector(state),
  // altCardColors: getAltCardColorsSelector(state),
});

const mapDispatchToProps = {};

const styles = theme => ({
  dialog: {},
  dialogPaper: {
    width: '100%',
    maxWidth: theme.breakpoints.values.sm,
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing.unit,
    top: theme.spacing.unit,
  },
})

class TrackDeviceProperties extends Component {
  state = {
    trackOrderItems: []
  }

  componentDidMount() {
    const {trackDevicesTypeId} = this.props;

    const selectedSchool = this.props.user.selectedSchool;
    const profile = this.props.user.schools[selectedSchool];
    const parentId = profile.parent.id

    if (!!trackDevicesTypeId) this.props.getShopTrackDevicesModels(parentId, trackDevicesTypeId);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.trackDevicesTypeId !== this.props.trackDevicesTypeId) {
      const {trackDevicesTypeId} = this.props;

      const selectedSchool = this.props.user.selectedSchool;
      const profile = this.props.user.schools[selectedSchool];
      const parentId = profile.parent.id

      if (!!trackDevicesTypeId) this.props.getShopTrackDevicesModels(parentId, trackDevicesTypeId);
    }
  }

  handleSubmit = (values) => {
    const {trackDevicesModelsById} = this.props;
    if (trackDevicesModelsById.hasOwnProperty(values.trackDevicesModelId))
      // this.props.setShopTrackDevicesAddItemToOrder(trackDevicesModelsById[values.trackDevicesModelId]);
      this.handleClose();
    // this.props.onToggle();
  }

  handleClose = () => {
    this.props.clearShopTrackDevices();
    this.props.onToggle();
  }

  trackDevicesOrderItemAdd = (values) => {
    const trackDevicesModel = this.props.trackDevicesModelsById[values.trackDevicesModelId];

    if (!!this.props.setShopTrackDevicesAddItemToOrder) {
      this.props.setShopTrackDevicesAddItemToOrder(trackDevicesModel)
      this.handleClose();
    }
  }

  trackDevicesOrderConfirm = (values) => {
    const trackDevicesModel = this.props.trackDevicesModelsById[values.trackDevicesModelId];

    if (!!this.props.setShopTrackDevicesOrderConfirm)
      this.props.setShopTrackDevicesOrderConfirm(trackDevicesModel)
    this.handleClose();
  }

  render() {
    const {classes, trackDevicesTypes, trackDevicesTypeId, trackDevicesModels, trackDevicesModelsById} = this.props;
    const trackDevicesType = (!!trackDevicesTypes ? trackDevicesTypes[trackDevicesTypeId] : undefined) || {name: ''};

    const selectedSchool = this.props.user.selectedSchool;
    const profile = this.props.user.schools[selectedSchool];
    const parentId = profile.id

    const initOrder = {
      parentId: parentId,
      trackDevicesTypeId: trackDevicesTypeId,
      trackDevicesModelId: null
    }
    return (
      <Dialog open={Number(trackDevicesTypeId) >= 0}
              fullWidth
              maxWidth="xs"
              PaperProps={{className: classes.dialogPaper}}
              onClose={this.handleClose}>
        <DialogTitle
          onClose={this.handleClose}>
          <Typography>Выбор устройства</Typography>
          <IconButton aria-label="close" className={classes.closeButton} onClick={this.handleClose}>
            <CloseIcon/>
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Formik
            onSubmit={(values, { resetForm}) => {
              this.handleSubmit(values);
              resetForm(initOrder);
            }}

            initialValues={{...initOrder}}>
            {val => {
              const {values, handleChange, setFieldValue, handleSubmit} = val;
              return (
                <form onSubmit={handleSubmit}>
                  <TrackDeviceData
                    {...{
                      values,
                      handleChange,
                      setFieldValue,
                      handleSubmit,
                      trackDevicesType,
                      trackDevicesModels,
                      trackDevicesModelsById
                    }}
                    getShopTrackDevicesModels={this.props.getShopTrackDevicesModels}
                    trackDevicesOrderItemAdd={this.trackDevicesOrderItemAdd}
                    trackDevicesOrderConfirm={this.trackDevicesOrderConfirm}
                  />
                </form>
              )
            }}
          </Formik>
        </DialogContent>
      </Dialog>
    )
  }
}

TrackDeviceProperties.propTypes = {
  trackDevicesTypeId: PropTypes.number,
  onToggle: PropTypes.func.isRequired,
  getShopTrackDevicesModels: PropTypes.func.isRequired,
  // getShopTrackDevicesSetPrice: PropTypes.func.isRequired,
  setShopTrackDevicesAddItemToOrder: PropTypes.func.isRequired,
  setShopTrackDevicesOrderConfirm: PropTypes.func.isRequired,
  clearShopTrackDevices: PropTypes.func.isRequired,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(TrackDeviceProperties));
