import React from 'react';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Input from '@material-ui/core/Input';
import Typography from "@material-ui/core/Typography";
import InputAdornment from '@material-ui/core/InputAdornment';

import { withStyles } from '@material-ui/core/styles';
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

const styles = theme => ({
  input: {
    fontSize: 25
  },
  buttons: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'flex-start',
    marginTop: 15
  },
  nextButton: {
    flexGrow: 1,
    margin: '0 5px'
  }
});

const Step3 = props => {
  const {
    classes,
    changeStep,
    payType,
    promoCode,
    autoPayment,
    autoPaymentAvailable,
    autoPaymentAvailableServices,
    step,
    errorOnValidateAmount,
    setAmount,
    setAutoPayment,
    amount,

    onEnterPress
  } = props;
  return (
    <>
      <FormControl required>
        <InputLabel htmlFor="amount">Сумма платежа</InputLabel>
        <Input
          className={classes.input}
          disabled={payType.id === 1 && promoCode.length > 0}
          type="number"
          value={amount}
          error={errorOnValidateAmount(amount)}
          onChange={e => setAmount(e.target.value)}
          onKeyDown={onEnterPress()}
          autoFocus
          endAdornment={<InputAdornment position="end">руб.</InputAdornment>}
        />
        {(autoPaymentAvailable && autoPaymentAvailableServices.indexOf(payType.id) >= 0) ? (
        <FormControlLabel
          control={
            <Checkbox
              checked={autoPayment === payType.id && autoPaymentAvailable}
              onChange={e => {setAutoPayment((e.target.checked) ? payType.id : -1)}}
              value="autoPayment"
            />
          } label="Подключить автоплатеж" />
          ) : null}
      </FormControl>
      {(payType.id === 1 && promoCode.length > 0) ? (<Typography align="justify" className={classes.bottomText}>
        * Конечная сумма состоит из суммы акции в соответствии с промокодом "{promoCode.toUpperCase()}" и имеющейся
        задолженности.
      </Typography>) : null}
      <div className={classes.buttons}>
        <Button
          variant="outlined"
          size="small"
          onClick={e => changeStep('backward', payType.id, step)}
        >
          Назад
        </Button>
        <Button
          className={classes.nextButton}
          disabled={errorOnValidateAmount(amount)}
          variant="outlined"
          color="secondary"
          size="large"
          onClick={e => changeStep('forward')}
        >
          Далее
        </Button>
      </div>
    </>
  );
};

export default withStyles(styles)(Step3);
