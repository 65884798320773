import React from "react";

import withStyles from "@material-ui/core/styles/withStyles";
import {ExpansionPanel, ExpansionPanelDetails, ExpansionPanelSummary, Grid, Typography} from "@material-ui/core";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import ProductItem from "./ProductItem";
import Button from "@material-ui/core/Button";

const styles = () => ({
  products: {
    width: "100%",
  },
  paper: {
    height: 140,
    width: 100,
  }
});

const ProductCategory = props => {
  const {classes, expanded, category, title, items, showCart, cartItems} = props;

  return (
    <ExpansionPanel expanded={expanded[category]} onChange={props.onChange(category)}>
      <ExpansionPanelSummary
        expandIcon={<ExpandMoreIcon/>}
        aria-label="Expand"
      ><Typography>{title}</Typography></ExpansionPanelSummary>
      <ExpansionPanelDetails>
        <Grid item className={classes.products}>
          <Grid container justify="flex-end">
            {!!showCart && (
              <Button
                variant="contained" size="small"
                disabled={!(!!cartItems && cartItems.length)}
                onClick={() => {
                  if (!!props.onConfirmOrder) props.onConfirmOrder(-1);
                }}
              >
                <ShoppingCartIcon color="primary"/>
                Оформить заказ
              </Button>)}
          </Grid>
          <Grid container justify="space-around">
            {!!items ? items.map(value => (
              <Grid key={category + '-' + value.id} item>
                <ProductItem
                  className={classes.paper}
                  altCardTypeId={value.id}
                  name={value.name}
                  picture={value.picture}
                  price={value.price || (value.priceCent / 100)}
                  onClick={props.onToggle}
                />
              </Grid>
            )) : null}
          </Grid>
        </Grid>
      </ExpansionPanelDetails>
    </ExpansionPanel>
  );
};

export default withStyles(styles)(ProductCategory);
