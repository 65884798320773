import React from "react";
import PropTypes from "prop-types";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import Typography from "@material-ui/core/Typography";

import InputField from "../../InputField";

import { withStyles } from "@material-ui/core/styles";

const styles = theme => ({
  formControl: {
    width: "100%",
    maxWidth: 620,
    minWidth: 150,
    margin: "8px 10px 4px 0"
  },
  input: {
    padding: "10px 15px"
  }
});

const ParentDocs = props => {
  const {
    values,
    handleChange,
    setFieldValue,
    handleDateChange,
    classes,
    docTypes,
    errors,
    onKeyUp
  } = props;
  const handleDocTypeChange = event => {
    setFieldValue("parentDocTypeId", event.target.value);
  };

  return (
    <>
      <Typography variant="button">Документ представителя обучающегося</Typography>
      <Typography variant="caption" color="error">Важно вносить персональные данные строго в соответствии с документами, для возможности в дальнейшем получения консультации в КЦ.</Typography>
      <>
        <FormControl variant="outlined" className={classes.formControl}>
          <InputLabel>Тип документа</InputLabel>
          <Select
            value={values.parentDocTypeId || 1}
            onChange={handleDocTypeChange}
            input={<OutlinedInput labelWidth={135} name="docType" id="docType" />}
            inputProps={{
              classes: { select: classes.input }
            }}
          >
            {docTypes.map(type => (
              <MenuItem key={type.value} value={type.value}>
                {type.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <br />

        <InputField
          id="parentDocSeries"
          label="Серия"
          required
          error={errors.parentDocSeries}
          value={values.parentDocSeries}
          onChange={handleChange}
          onKeyUp={onKeyUp}
        />

        <InputField
          id="parentDocNumber"
          label="Номер"
          required
          error={errors.parentDocNumber}
          value={values.parentDocNumber}
          onChange={handleChange}
          onKeyUp={onKeyUp}
        />

        <InputField
          id="parentDocDate"
          label="Дата выдачи *"
          datePicker
          error={errors.parentDocDate}
          value={values.parentDocDate}
          onChange={handleDateChange("parentDocDate")}
          onKeyUp={onKeyUp}
        />

        <InputField
          id="parentDocIssuer"
          label="Кем выдан"
          fullWidth
          required
          error={errors.parentDocIssuer}
          value={values.parentDocIssuer}
          onChange={handleChange}
          onKeyUp={onKeyUp}
        />
      </>
      <br />
    </>
  );
};

ParentDocs.propTypes = {
  classes: PropTypes.object,
  docTypes: PropTypes.array,
  values: PropTypes.shape({
    parentDocSeries: PropTypes.string,
    parentDocNumber: PropTypes.string,
    parentDocDate: PropTypes.string,
    parentDocIssuer: PropTypes.string,
    parentDocTypeId: PropTypes.number
  }),
  handleChange: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  handleDateChange: PropTypes.func.isRequired,
  errors: PropTypes.object
};

export default withStyles(styles)(ParentDocs);
