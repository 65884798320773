import React from "react";
import PropTypes from "prop-types";
import Typography from "@material-ui/core/Typography";
import InputField from "./InputField";
import AutosuggestField from "./AutosuggestField";

const ParentAddress = props => {
  const {
    onInputChange,
    values,
    regions,
    areas,
    cities,
    localities,
    structures,
    streets,
    houses,
    handleChange,
    setFieldValue,
    errors,
    onKeyUp
  } = props;

  return (
    <>
      <Typography variant="button">Фактический адрес проживания</Typography>
      <div>
        <AutosuggestField
          id="region"
          fullWidth
          isClearable
          suggestions={regions}
          label="Регион"
          required
          error={errors.region}
          onChange={setFieldValue}
          selected={values.region}
          onKeyUp={onKeyUp}
        />

        {values.region && (
          <AutosuggestField
            id="area"
            fullWidth
            isClearable
            suggestions={areas}
            label="Район"
            error={errors.area}
            disabled={!values.region}
            onChange={setFieldValue}
            selected={values.area}
            onKeyUp={onKeyUp}
          />
        )}

        {values.region && (
          <AutosuggestField
            id="city"
            fullWidth
            isClearable
            suggestions={cities}
            label="Город"
            disabled={!values.region}
            error={errors.city}
            onChange={setFieldValue}
            onInputChange={onInputChange}
            selected={values.city}
            onKeyUp={onKeyUp}
          />
        )}

        {values.region && (
          <AutosuggestField
            id="locality"
            fullWidth
            isClearable
            suggestions={localities}
            label="Населенный пункт"
            disabled={!values.region}
            error={errors.locality}
            onChange={setFieldValue}
            onInputChange={onInputChange}
            selected={values.locality}
            onKeyUp={onKeyUp}
          />
        )}

        {/* <AutosuggestField
          id="structure"
          fullWidth
          isClearable
          suggestions={structures}
          label="Планеровочная структура"
          required
          disabled={!values.region}
          error=""
          onChange={setFieldValue}
          selected={values.structure}
        /> */}

        {(values.city || values.locality) && (<>
            <AutosuggestField
            id="structure"
            fullWidth
            isClearable
            suggestions={structures}
            label="Планеровочная структура"
            // required
            disabled={!values.region}
            error={errors.fiasStructureName}
            onChange={setFieldValue}
            onInputChange={onInputChange}
            selected={values.structure}
          />
          <AutosuggestField
            id="street"
            fullWidth
            isClearable
            suggestions={streets}
            label="Улица"
            required
            error={errors.fiasStreetName}
            disabled={!values.city && !values.locality}
            onChange={setFieldValue}
            onInputChange={onInputChange}
            selected={values.street}
            onKeyUp={onKeyUp}
          /></>
        )}

        {(values.city || values.locality || values.street) && (
          <AutosuggestField
            id="house"
            fullWidth
            isClearable
            suggestions={houses}
            label="Дом"
            required
            disabled={!values.city && !values.locality}
            error={errors.fiasHouse}
            onChange={setFieldValue}
            onInputChange={onInputChange}
            selected={values.house}
            onKeyUp={onKeyUp}
          />
        )}

        {values.house && (
          <InputField
            id="apartment"
            label="Квартира"
            disabled={!values.house}
            error={errors.fiasAppartment}
            onChange={handleChange}
            value={values.apartment || ""}
            onKeyUp={onKeyUp}
          />
        )}
      </div>
    </>
  );
};

ParentAddress.propTypes = {
  onInputChange: PropTypes.func.isRequired,
  values: PropTypes.shape({
    region: PropTypes.object,
    area: PropTypes.object,
    city: PropTypes.object,
    locality: PropTypes.object,
    street: PropTypes.object,
    house: PropTypes.object,
    apartment: PropTypes.string
  }),
  regions: PropTypes.array,
  areas: PropTypes.array,
  cities: PropTypes.array,
  localities: PropTypes.array,
  streets: PropTypes.array,
  houses: PropTypes.array,
  handleChange: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  errors: PropTypes.object
};

export default ParentAddress;
