import axios from "axios";
import { createAction } from "redux-actions";
import { API, handleApiError } from "api";
import { updateUserDayLimit } from "./userActions";
import { showInfoMessage } from "./infoMessageActions";

export const monthlyMealsRequest = createAction("MONTHLY_MEALS_REQUEST");
export const monthlyMealsSuccess = createAction("MONTHLY_MEALS_SUCCESS");
export const monthlyMealsError = createAction("MONTHLY_MEALS_ERROR");

export const dailyMealsRequest = createAction("DAILY_MEALS_REQUEST");
export const dailyMealsSuccess = createAction("DAILY_MEALS_SUCCESS");
export const dailyMealsError = createAction("DAILY_MEALS_ERROR");

export const setMealsDayLimitRequest = createAction("SET_MEALS_DAY_LIMIT_REQUEST");
export const setMealsDayLimitSuccess = createAction("SET_MEALS_DAY_LIMIT_SUCCESS");
export const setMealsDayLimitError = createAction("SET_MEALS_DAY_LIMIT_ERROR");

export const mealsRequest = createAction("MEALS_REQUEST");
export const mealsSuccess = createAction("MEALS_SUCCESS");
export const mealsError = createAction("MEALS_ERROR");

export const bindMealRequest = createAction("BIND_MEAL_REQUEST");
export const bindMealSuccess = createAction("BIND_MEAL_SUCCESS");
export const bindMealError = createAction("BIND_MEAL_ERROR");

export const unbindMealRequest = createAction("UNBIND_MEAL_REQUEST");
export const unbindMealSuccess = createAction("UNBIND_MEAL_SUCCESS");
export const unbindMealError = createAction("UNBIND_MEAL_ERROR");

export const getMonthlyMeals = (pupilId, dateStart, dateEnd) => async dispatch => {
  try {
    dispatch(monthlyMealsRequest());
    const res = await axios.get(
      `${API}/meals/calendar-stat/${pupilId}?dateStart=${dateStart}&dateEnd=${dateEnd}`
    );
    dispatch(
      monthlyMealsSuccess({
        month: res.data
      })
    );
  } catch (error) {
    dispatch(handleApiError(error, monthlyMealsError()));
  }
};

export const getDailyMeals = (pupilId, date) => async dispatch => {
  try {
    dispatch(dailyMealsRequest());
    const res = await axios.get(`${API}/meals/daily-stat/${pupilId}?date=${date}`);
    dispatch(
      dailyMealsSuccess({
        day: res.data
      })
    );
  } catch (error) {
    dispatch(handleApiError(error, dailyMealsError()));
  }
};

export const setMealsDayLimit = (pupilId, dayLimit) => async dispatch => {
  try {
    dispatch(setMealsDayLimitRequest());
    await axios.post(`${API}/meals/day-limit/${pupilId}`, {
      dayLimit: dayLimit === "0" ? "0.00" : dayLimit
    });
    dispatch(setMealsDayLimitSuccess());
    dispatch(showInfoMessage("Суточный лимит успешно установлен"));
    dispatch(updateUserDayLimit(dayLimit));
  } catch (error) {
    dispatch(handleApiError(error, setMealsDayLimitError()));
  }
};

export const getMeals = pupilId => async dispatch => {
  try {
    dispatch(mealsRequest())
    const res = await Promise.all([axios.get(
      `${API}/meals/types/${pupilId}`
    ),axios.get(
      `${API}/meals/${pupilId}`
    )]);
    dispatch(mealsSuccess({
      mealTypes: res[0].data,
      meals: res[1].data
      }
    ))
  } catch (error) {
    dispatch(handleApiError(error, mealsError()));
  }
};

export const bindMeal = (pupilId, mealId) => async dispatch => {
  try {
    dispatch(bindMealRequest());
    await axios.post(`${API}/meals/${mealId}/bind/${pupilId}`);
    dispatch(bindMealSuccess());
    dispatch(showInfoMessage("Изменение комплекса питания успешно сохранено"));
    dispatch(getMeals(pupilId));
  } catch (error) {
    dispatch(handleApiError(error, bindMealError()));
  }
}

export const unbindMeal = (pupilId, mealId) => async dispatch => {
  try {
    dispatch(unbindMealRequest());
    await axios.post(`${API}/meals/${mealId}/unbind/${pupilId}`);
    dispatch(unbindMealSuccess());
    dispatch(showInfoMessage("Изменение комплекса питания успешно сохранено"));
    dispatch(getMeals(pupilId));
  } catch (error) {
    dispatch(handleApiError(error, unbindMealError()));
  }
}
