import React from "react";
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import TextField from "@material-ui/core/TextField";

import Clear from "@material-ui/icons/Clear";

import withStyles from "@material-ui/core/styles/withStyles";

const styles = theme => ({
  row: {
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "space-between"
  },
  input: {
    flexGrow: 1,
    backgroundColor: "white",
    color: "black",
    paddingLeft: 5,
    marginTop: 5
  },
  textField: {
    width: "100%",
    margin: "5px 0"
  },
  label: {
    color: "white"
  },
  button: {
    marginBottom: 3
  }
});

const EditDeviceName = props => {
  const { classes, deviceName, changeInputValue, editDeviceNameClose, editDeviceName } = props;
  return (
    <div className={classes.row}>
      <TextField
        autoFocus
        className={classes.textField}
        label="Изменение имени"
        name="deviceName"
        value={deviceName}
        onChange={changeInputValue}
        InputLabelProps={{
          classes: { root: classes.label },
          shrink: true
        }}
        InputProps={{ classes: { input: classes.input } }}
      />
      <IconButton>
        <Clear color="error" onClick={editDeviceNameClose} />
      </IconButton>
      <Button color="primary" className={classes.button} onClick={editDeviceName}>
        ОК
      </Button>
    </div>
  );
};

export default withStyles(styles)(EditDeviceName);

EditDeviceName.propTypes = {
  classes: PropTypes.object.isRequired,
  deviceName: PropTypes.string.isRequired,
  changeInputValue: PropTypes.func.isRequired,
  editDeviceNameClose: PropTypes.func.isRequired,
  editDeviceName: PropTypes.func.isRequired
};
