import React from "react";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Radio from "@material-ui/core/Radio";
import ListItemText from "@material-ui/core/ListItemText";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";

import { withStyles } from "@material-ui/core/styles";
const styles = theme => ({
  list: {
    padding: 0
  },
  radio: {
    paddingRight: 0,
    paddingLeft: 0
  },
  insurances: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center"
  }
});

const InsuranceSelectList = props => {
  const { classes, insurances, selectedInsurance, changeInsurance } = props;
  return (
    <List>
      {insurances.ids.map(insuranceId => (
        <React.Fragment key={insuranceId}>
          <ListItem
            className={classes.list}
            button
            onClick={e => changeInsurance(insuranceId)}
          >
            <Radio
              className={classes.radio}
              checked={selectedInsurance === insuranceId}
            />
            <ListItemText>
              <div className={classes.insurances}>
                <Typography variant="subtitle1">
                  {insurances[insuranceId].insuredSum} руб. {insurances[insuranceId].insuredText || ""}
                </Typography>
                { !!insurances[insuranceId].insuredFee && (
                  <Typography variant="body2">
                    стоимость: {insurances[insuranceId].insuredFee} руб.
                  </Typography>
                ) }
              </div>
            </ListItemText>
          </ListItem>
          <Divider />
        </React.Fragment>
      ))}
    </List>
  );
};

export default withStyles(styles)(InsuranceSelectList);
