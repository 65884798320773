import React from "react";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import { withStyles } from "@material-ui/core/styles";

const styles = theme => ({
  answer: {
    position: "inherit"
  },
  info: {
    backgroundColor: "lightgrey",
    padding: 20
  },
  desc: {
    fontWeight: 500
  }
});

const Answers = props => {
  const { classes, answers, description, expanded, changeExpand } = props;
  return (
    <>
      {answers !== undefined &&
        answers.map(answer => {
          // заменяем переход строки на <br/> для переноса в html
          const description = answer.description.replace(/\n/g, "<br/>");

          return (
            <ExpansionPanel
              expanded={expanded === answer.id}
              onChange={changeExpand(answer.id)}
              key={answer.id}
              elevation={expanded === answer.id ? 2 : 0}
              className={classes.answer}
            >
              <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                <Typography>{answer.title}</Typography>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                <Typography>
                  <span
                    dangerouslySetInnerHTML={{
                      // вставляем прямой html в разметку
                      __html: description
                    }}
                  />
                </Typography>
              </ExpansionPanelDetails>
            </ExpansionPanel>
          );
        })}

      {description !== undefined ? (
        <Typography className={classes.info}>
          <span
            className={classes.desc}
            dangerouslySetInnerHTML={{
              // вставляем прямой html в разметку
              __html: description
            }}
          />
        </Typography>
      ) : null}
    </>
  );
};

export default withStyles(styles)(Answers);
