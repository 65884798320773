import React from "react";
import PropTypes from "prop-types";
import {withStyles} from "@material-ui/core/styles";

import Button from "@material-ui/core/Button";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";

const styles = theme => ({
  root: {
    flexGrow: 1,
    maxWidth: 600,
    minWidth: 470,
    [theme.breakpoints.down("xs")]: {
      minWidth: 100,
      maxWidth: "100%",
      width: "100%"
    }
  },
  rightCell: {
    padding: "5px !important"
  },
  indentButton: {
    marginLeft: 5
  },
  buttonWrapper: {
    position: "relative"
  },
  buttonProgress: {
    position: "absolute",
    top: "50%",
    right: "35px",
    marginTop: -12,
    marginLeft: -12
  }
});

const formatTransportCard = str => {
  // разбиваем номер пробелами по 5 символов с конца
  return str
    .split("")
    .reverse()
    .join("")
    .match(/(.{5})|(.{4})|(.{3})|(.{2})|(.{1})/g)
    .join(" ")
    .split("")
    .reverse()
    .join("")
};

const PupilData = props => {
  const typographyStyle = "subtitle1";
  const {classes, transportCardOperationsToggle, loseCardToggle, addFoodDataOrder} = props;
  const {
    id,
    className,
    cardNum,
    foodBalance,
    dayLimit,
    tarifName,
    tarifPrice,
    hasTransportCard,
    foreignCardNum
  } = props.accountData;

  const formatedTransportCard = formatTransportCard(foreignCardNum);

  return (
    <div className={classes.root}>
      <Table padding="checkbox">
        <TableBody>
          <TableRow>
            <TableCell>
              <Typography variant={typographyStyle}>Класс</Typography>
            </TableCell>
            <TableCell numeric className={classes.rightCell}>
              <Typography variant={typographyStyle}>{className || "Отсутствует"}</Typography>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <Typography variant={typographyStyle}>Пропуск УЭШКа</Typography>
            </TableCell>
            <TableCell numeric className={classes.rightCell}>
              <Typography variant={typographyStyle}>{cardNum}<br/>
                {(Number(cardNum) > 0) && (<Button
                  className={classes.indentButton}
                  variant="outlined"
                  size="small"
                  onClick={() => loseCardToggle(id)}
                >
                  Заблокировать по утере
                </Button>)}
              </Typography>
            </TableCell>
          </TableRow>
          {hasTransportCard && (
            <TableRow>
              <TableCell>
                <Typography variant={typographyStyle}>Транспортная карта</Typography>
              </TableCell>
              <TableCell numeric className={classes.rightCell}>
                <Typography variant={typographyStyle}>
                  {formatedTransportCard}
                  <div className={classes.buttonWrapper}>
                    <Button
                      className={classes.indentButton}
                      variant="outlined"
                      size="small"
                      onClick={() => transportCardOperationsToggle(id)}
                    >
                      Операции
                    </Button>
                  </div>
                </Typography>
              </TableCell>
            </TableRow>
          )}
          <TableRow>
            <TableCell>
              <Typography variant={typographyStyle}>Баланс питания</Typography>
            </TableCell>
            <TableCell numeric className={classes.rightCell}>
              {(foodBalance !== null && foodBalance.length > 0)
                ? (<Typography variant={typographyStyle}>{foodBalance} р.</Typography>)
                : (<Button
                  className={classes.indentButton}
                  variant="outlined"
                  size="small"
                  onClick={() => addFoodDataOrder(id, 1)}
                >Показать</Button>)}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <Typography variant={typographyStyle}>Суточный лимит (буфет)</Typography>
            </TableCell>
            <TableCell numeric className={classes.rightCell}>
              <Typography variant={typographyStyle}>
                {dayLimit === "0.00" ? "Не установлен" : `${dayLimit} р.`}
              </Typography>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <Typography variant={typographyStyle}>Тариф</Typography>
            </TableCell>
            <TableCell numeric className={classes.rightCell}>
              <Typography variant={typographyStyle}>{tarifName}</Typography>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <Typography variant={typographyStyle}>Стоимость тарифа</Typography>
            </TableCell>
            <TableCell numeric className={classes.rightCell}>
              <Typography variant={typographyStyle}>{tarifPrice} р.</Typography>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </div>
  );
};

PupilData.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string),
  accountData: PropTypes.shape({
    id: PropTypes.number,
    className: PropTypes.string.isRequired,
    cardNum: PropTypes.string.isRequired,
    foodBalance: PropTypes.string.isRequired,
    dayLimit: PropTypes.string.isRequired,
    tarifName: PropTypes.string.isRequired,
    tarifPrice: PropTypes.string.isRequired,
    hasTransportCard: PropTypes.bool.isRequired,
    foreignCardNum: PropTypes.string.isRequired
  }),
  transportCardOperationsToggle: PropTypes.func.isRequired,
  addFoodDataOrder: PropTypes.func.isRequired
};

export default withStyles(styles)(PupilData);
