import React from "react";

import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import OutlinedInput from "@material-ui/core/OutlinedInput";

import { withStyles } from "@material-ui/core/styles";
const styles = theme => ({
  formControl: {
    minWidth: 220,
    width: 220,
    margin: "10px 10px 10px 0"
  }
});

const PaperInvoicePupilsList = props => {
  const { classes, paperInvoice, paperInvoiceMethods, profile } = props;
  return (
      <FormControl variant="outlined" className={classes.formControl}>
        <InputLabel>Ученик</InputLabel>
        <Select
          value={paperInvoice.pupil || "Ученик отсутствует"}
          onChange={paperInvoiceMethods.selectPupil}
          input={
            <OutlinedInput
              labelWidth={100}
              name="pupil"
              id="pupil"
            />
          }
        >
          {profile.pupils.ids.map(pupilId => (
            <MenuItem key={pupilId} value={pupilId}>
              {`${profile.pupils[pupilId].im} ${profile.pupils[pupilId].className}`}
            </MenuItem>
          ))}
          {profile.pupils.ids.length === 0 ? <MenuItem key={-1} value={-1}>
              Ученик отсутствует
            </MenuItem> : null}
        </Select>
      </FormControl>
  );
};

export default withStyles(styles)(PaperInvoicePupilsList);
