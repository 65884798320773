const panelsStyles = theme => ({
  panel: {
    width: "100%",
    minWidth: 250,
    maxWidth: 500,
    height: 400,
    top: -411,
    position: "absolute",
    background: "black",
    padding: 5,
    color: "white",
    overflow: "hidden",
    opacity: 1,
    cursor: "auto",
    transition: "all 0.5s ease 0s",
    [theme.breakpoints.down("sm")]: {
      width: "calc(100% - 10px)",
      height: 250,
      top: -261
    }
  },
  panelHistory: {
    width: "calc(100% - 10px)",
    height: 90,
    top: -101,
    position: "absolute",
    background: "black",
    padding: 5,
    color: "white",
    overflow: "hidden",
    opacity: 1,
    cursor: "auto",
    transition: "all 0.5s ease 0s"
  },
  panelSmall: {
    top: -76,
    height: 65,
    [theme.breakpoints.down("xs")]: {
      top: -71,
      height: 60
    }
  },
  panelHidden: {
    height: 0,
    top: -10,
    opacity: 0,
    visibility: "hidden",
    overflow: "hidden"
  },
  panelSelected: {
    top: -101,
    height: 90,
    transition: "all 0.5s ease 0.2s"
  },
  panelActive: {
    opacity: 1,
    visibility: "visible",
    overflow: "hidden"
  },
  itemsScroller: {
    height: 355,
    overflow: "auto",
    [theme.breakpoints.down("sm")]: {
      height: 205,
      overflow: "auto"
    }
  },
  header: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    paddingBottom: 5
  },
  item: {
    display: "flex",
    height: 40,
    background: "white",
    color: "black",
    boxShadow: "0 0 0px 2px black",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "3px 0 3px 3px"
  },
  itemName: {
    height: "100%",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "center",
    cursor: "pointer",
    whiteSpace: "nowrap",
    overflow: "hidden"
  },
  iconsRow: {
    display: "flex",
    justifyContent: "space-between"
  },
  icon: {
    cursor: "pointer",
    "&:hover": {
      opacity: 0.7
    }
  }
});

export default panelsStyles;
