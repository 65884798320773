import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import withStyles from "@material-ui/core/styles/withStyles";
import AddDeviceForm from "../devices/AddDeviceForm";
import EditDeviceName from "../devices/EditDeviceName";

import DeviceItem from "./DeviceItem";
import PanelTitle from "./PanelTitle";
import NoDevicesText from "./NoDevicesText";

import panelsStyles from "../styles";

const styles = theme => ({
  ...panelsStyles(theme)
});

const Devices = props => {
  const {
    classes,
    activeButton,
    devices,
    addDeviceOpen,
    addDeviceIsOpen,
    deviceName,
    deviceCode,
    selectedDevice,
    submitAddDeviceForm,
    changeInputValue,
    clearAddDeviceForm,
    deleteDevice,
    changeDeviceStatus,
    editDeviceId,
    editDeviceName,
    editDeviceNameOpen,
    editDeviceNameClose,
    selectDevice,
    changeDeviceType,
    isWatch,
    selectedDeviceIsWatch,
    getWatchSettings,
    saveWatchSettings,
    watchSettings,
    checkWatchConnection,
    gpsWatchReboot,
    gpsWatchPowerOff,
    gpsWatchFind
  } = props;

  return (
    <div
      className={classNames(classes.panel, {
        [classes.panelHidden]: !activeButton,
        [classes.panelActive]: activeButton,
        [classes.panelSelected]: selectedDevice && !editDeviceId,
        [classes.panelSmall]: editDeviceId
      })}
      onClick={e => e.stopPropagation()}
    >
      {!editDeviceId ? (
        <PanelTitle
          {...{
            addDeviceIsOpen,
            addDeviceOpen,
            selectedDevice,
            selectedDeviceIsWatch,
            getWatchSettings,
            watchSettings,
            saveWatchSettings,
            checkWatchConnection,
            gpsWatchReboot,
            gpsWatchPowerOff,
            gpsWatchFind
          }}
        />
      ) : null}
      <div className={classes.itemsScroller}>
        {addDeviceIsOpen && (
          <AddDeviceForm
            {...{
              deviceName,
              deviceCode,
              submitAddDeviceForm,
              changeInputValue,
              clearAddDeviceForm,
              changeDeviceType,
              isWatch
            }}
          />
        )}
        {editDeviceId && (
          <EditDeviceName
            {...{
              editDeviceId,
              deviceName,
              changeInputValue,
              editDeviceNameClose,
              editDeviceName
            }}
          />
        )}
        {(!devices || devices.length === 0) && <NoDevicesText />}
        {!addDeviceIsOpen
          && !editDeviceId
          && devices
          && devices.map(device => {
            if (selectedDevice && selectedDevice !== device.id) {
              return null;
            }

            return (
              <DeviceItem
                key={device.id}
                device={device}
                selectedDevice={selectedDevice}
                changeDeviceStatus={changeDeviceStatus}
                deleteDevice={deleteDevice}
                selectDevice={selectDevice}
                editDeviceNameOpen={editDeviceNameOpen}
              />
            );
          })}
      </div>
    </div>
  );
};

export default withStyles(styles)(Devices);

Devices.propTypes = {
  classes: PropTypes.object,
  activeButton: PropTypes.bool.isRequired,
  devices: PropTypes.arrayOf(PropTypes.object),
  addDeviceOpen: PropTypes.func.isRequired,
  addDeviceIsOpen: PropTypes.bool.isRequired,
  deviceName: PropTypes.string.isRequired,
  deviceCode: PropTypes.string.isRequired,
  selectedDevice: PropTypes.number,
  submitAddDeviceForm: PropTypes.func.isRequired,
  changeInputValue: PropTypes.func.isRequired,
  clearAddDeviceForm: PropTypes.func.isRequired,
  deleteDevice: PropTypes.func.isRequired,
  changeDeviceStatus: PropTypes.func.isRequired,
  selectDevice: PropTypes.func.isRequired,
  editDeviceId: PropTypes.number,
  editDeviceName: PropTypes.func.isRequired,
  editDeviceNameOpen: PropTypes.func.isRequired,
  editDeviceNameClose: PropTypes.func.isRequired,
  changeDeviceType: PropTypes.func.isRequired,
  isWatch: PropTypes.bool.isRequired,
  selectedDeviceIsWatch: PropTypes.bool,
  getWatchSettings: PropTypes.func.isRequired,
  saveWatchSettings: PropTypes.func.isRequired,
  watchSettings: PropTypes.object,
  checkWatchConnection: PropTypes.func.isRequired,
  gpsWatchReboot: PropTypes.func.isRequired,
  gpsWatchPowerOff: PropTypes.func.isRequired,
  gpsWatchFind: PropTypes.func.isRequired
};
