import {createSelector} from "reselect";

const getPupils = state => {
  const selectedSchool = state.user.selectedSchool;
  return state.user.schools[selectedSchool].pupils;
}

export const getPupilsSelector = createSelector(
  [getPupils],
  pupils => {
    if (!pupils) return undefined;
    let newPupils = [];
    for (let pupilId in pupils) {
      if (pupils.hasOwnProperty(pupilId) && pupils[pupilId] && pupils[pupilId].hasOwnProperty('id'))
        newPupils.push({
          value: pupils[pupilId].id,
          label: pupils[pupilId].im
        })
    }
    return newPupils;
  }
)

const getAltCardTypes = state => state.shop.altCardTypes;

export const getAltCardTypesSelector = createSelector(
  [getAltCardTypes],
  altCardTypes => {
    if (!altCardTypes) return undefined;
    let res = {}
    for (let altCardType of altCardTypes) {
      res[altCardType.id] = altCardType
    }
    return res;
  }
)

const getAltCardKinds = state => state.shop.altCardKinds;

export const getAltCardKindsSelector = createSelector(
  [getAltCardKinds],
  altCardKinds => {
    if (!altCardKinds) return [];
    return altCardKinds.map(altCardKind => ({
      value: altCardKind.id,
      label: altCardKind.name
    }))
  }
)

const getAltCardColors = state => state.shop.altCardColors;

export const getAltCardColorsSelector = createSelector(
  [getAltCardColors],
  altCardColors => {
    if (!altCardColors) return [];
    return altCardColors.map(altCardColor => ({
      value: altCardColor.id,
      label: altCardColor.name
    }))
  }
)

const getTrackDevicesTypes = state => state.shop.trackDevicesTypes;

export const getTrackDevicesTypesSelector = createSelector(
  [getTrackDevicesTypes],
  trackDevicesTypes => {
    let res = {}
    if (!trackDevicesTypes) return res;
    for (let trackDevicesType of trackDevicesTypes) {
      if (!!trackDevicesType && trackDevicesType.hasOwnProperty('id'))
        res[trackDevicesType.id] = trackDevicesType
    }
    return res;
  }
)

const getTrackDevicesModels = state => state.shop.trackDevicesModels;

export const getTrackDevicesModelsSelector = createSelector(
  [getTrackDevicesModels],
  trackDevicesModels => {
    if (!trackDevicesModels) return [];
    return trackDevicesModels.map(trackDevicesModel => ({
      value: trackDevicesModel.id,
      label: trackDevicesModel.modelName
    }))
  }
)

export const getTrackDevicesModelsByIdSelector = createSelector(
  [getTrackDevicesModels],
  trackDevicesModels => {
    let modelsById = {}
    if (!trackDevicesModels) return modelsById;

    for (let trackDevicesModel of trackDevicesModels) {
      if (!!trackDevicesModel && trackDevicesModel.hasOwnProperty('id'))
        modelsById[trackDevicesModel.id] = trackDevicesModel
    }
    return modelsById
  }
)