import React, {Component} from "react";

import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

import withStyles from "@material-ui/core/styles/withStyles";

const styles = theme => ({
  input: {
    marginTop: 10,
    fontSize: 25
  },
  positionStart: {
    fontSize: 20
  },
  modal: {
    [theme.breakpoints.down("xs")]: {
      alignItems: "flex-start"
    }
  },
  paper: {
    margin: 10
  }
});

class AutoPaymentOff extends Component {

  parentAutoPaymentOffClick = () => {
    this.props.parentAutoPaymentOff(this.props.parentId, this.props.autoPaymentServiceId);
    this.props.autoPaymentToggle();
  };

  onClose = () => {
    this.props.autoPaymentToggle();
  };

  render() {
    const {classes, autoPaymentIsOpen, balances, autoPaymentServiceId} = this.props;
    const serviceName = (balances[autoPaymentServiceId]) ? balances[autoPaymentServiceId].name : '';
    // console.log('AutoPaymentOff.render',autoPaymentIsOpen)
    return (
      <Dialog
        open={!!autoPaymentIsOpen}
        onClose={this.onClose}
        classes={{root: classes.modal, paper: classes.paper}}
      >
        <DialogTitle>Отмена автоплатежа по услуге {serviceName}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Для отмены автоплатежей по услуге "{serviceName}" подтвердите это действие.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.onClose} color="primary">
            Отмена
          </Button>
          <Button
            onClick={this.parentAutoPaymentOffClick}
            color="primary"
          >
            Подтвердить
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default withStyles(styles)(AutoPaymentOff);
