import React from "react";
import PropTypes from "prop-types";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Typography from "@material-ui/core/Typography";
import Radio from "@material-ui/core/Radio";

import PaperInvoiceServicesList from "./PaperInvoiceServicesList";
import PaperInvoicePupilsList from "./PaperInvoicePupilsList";
import PaperInvoiceAmount from "./PaperInvoiceAmount";
import PaperInvoiceButton from "./PaperInvoiceButton";

import { withStyles } from "@material-ui/core/styles";

const styles = () => ({
  container: {
    padding: 5
  },
  expansion: {
    border: "1px solid lightgrey",
    padding: 3
  },
  expansionTitle: {
    padding: "0 0 0 10px"
  },
  details: {
    display: "flex",
    flexDirection: "column",
    padding: 0
  },
  row: {
    width: "100%",
    display: "flex"
  },
  invoiceType: {
    cursor: "pointer"
  },
  radio: {
    padding: 10
  }
});

const PaperInvoiceCard = props => {
  const {
    classes,
    paperInvoice,
    paperInvoiceMethods,
    profile,
    errorOnValidateAmount
  } = props;
  return (
    <div className={classes.container}>
      <ExpansionPanel elevation={0} className={classes.expansion}>
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon />}
          className={classes.expansionTitle}
        >
          <Typography variant="subtitle2">Сформировать квитанцию</Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails className={classes.details}>
          <div className={classes.row}>
            <Typography
              className={classes.invoiceType}
              onClick={paperInvoiceMethods.changePaperInvoiceType("service")}
            >
              <Radio
                className={classes.radio}
                checked={paperInvoice.invoiceType === "service"}
              />
              Оплата услуг
            </Typography>

            <Typography
              className={classes.invoiceType}
              onClick={paperInvoiceMethods.changePaperInvoiceType("card")}
            >
              <Radio
                className={classes.radio}
                checked={paperInvoice.invoiceType === "card"}
              />
              Замена карты
            </Typography>
          </div>
          {paperInvoice.invoiceType === "service" ? (
            <PaperInvoiceServicesList
              {...{ paperInvoice, paperInvoiceMethods, profile }}
            />
          ) : null}
          {paperInvoice.invoiceType === "card" ? (
            <PaperInvoicePupilsList
              {...{ paperInvoice, paperInvoiceMethods, profile }}
            />
          ) : null}
          {paperInvoice.invoiceType === "service" ? (
            <PaperInvoiceAmount
              {...{ paperInvoice, paperInvoiceMethods, errorOnValidateAmount }}
            />
          ) : null}
          <PaperInvoiceButton
            {...{ paperInvoice, paperInvoiceMethods, errorOnValidateAmount }}
          />
        </ExpansionPanelDetails>
      </ExpansionPanel>
    </div>
  );
};

PaperInvoiceCard.propTypes = {
  classes: PropTypes.object.isRequired,
  paperInvoiceType: PropTypes.string,
  paperInvoices: PropTypes.object
};

export default withStyles(styles)(PaperInvoiceCard);
