import React from "react";
import PropTypes from "prop-types";
import Typography from "@material-ui/core/Typography";
import InputField from "../../InputField";
import CheckCircleOutlinedIcon from '@material-ui/icons/CheckCircleOutlined';
import HighlightOffOutlinedIcon from '@material-ui/icons/HighlightOffOutlined';

const ParentPhone = props => {
  const { handleChange, values, onKeyUp, isNewPhone, isNewPhoneChecked } = props;
  let phone = values.phone
  const rePhone = /^(?:8|\+7|7)?(9[0-9]{9})$/ism
  const reEmail = /^(?:[a-z0-9!#$%&'*+\/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+\/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/ims
  const matches = rePhone.exec(phone);
  if (matches && matches[1]) {
    phone = matches[1];
  }
  return (
    <>
      <Typography variant="button">Телефон и email</Typography>
      <InputField
        id="phone"
        label="Телефон"
        required={true}
        phone
        disabled={!!values.parentId}
        error={(!isNewPhone && phone === isNewPhoneChecked && !values.parentId) ? "Данный номер имеется в системе, ввойдите в Личный кабинет используя его"
          : (values.phone && !rePhone.test(values.phone)) ? 'Неверно указан номер': ''}
        onChange={handleChange}
        value={values.phone}
        onKeyUp={onKeyUp}
      />
      {(!!values.phone && isNewPhone) ? (<CheckCircleOutlinedIcon color={"primary"} fontSize={"large"}/>):
        (phone === isNewPhoneChecked && !values.parentId) ? (<HighlightOffOutlinedIcon color={"error"} fontSize={"large"}/> ) : null}
      <br />
      <InputField
        id="email"
        label="Email"
        required={false}
        error={(values.email && ! reEmail.test(values.email))? 'Неверно указан E-Mail' : ''}
        onChange={handleChange}
        value={values.email}
        onKeyUp={onKeyUp}
      />
      <br />
    </>
  );
};

ParentPhone.propTypes = {
  values: PropTypes.shape({
    phone: PropTypes.string,
    email: PropTypes.string
  }),
  handleChange: PropTypes.func.isRequired
};

export default ParentPhone;
