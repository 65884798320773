import React from "react";

import Clear from "@material-ui/icons/Clear";

import MonthCalendar from "components/MonthCalendar";
import MonthCaption from "components/MonthCaption";
import CalendarInfo from "./CalendarInfo";

import withStyles from "@material-ui/core/styles/withStyles";

const styles = theme => ({
  calendarContainer: {
    width: "55%",
    overflow: "visible",
    [theme.breakpoints.down("xs")]: {
      width: "100%"
    }
  },
  dayContainer: {
    display: "flex",
    height: "100%",
    minHeight: 65,
    flexDirection: "column",
    alignItems: "center",
    [theme.breakpoints.down("xs")]: {
      width: "unset",
      minHeight: 50
    }
  },
  day: {
    fontSize: 23,
    fontWeight: 500,
    color: "#949494"
  },
  buys: {
    color: "rgba(153, 0, 0, 0.7)",
    alignSelf: "center"
  },
  pays: {
    color: "rgb(0, 153, 0, 0.7)",
    alignSelf: "center"
  },
  transfers: {
    color: "rgb(0, 153, 255, 0.7)",
    alignSelf: "center"
  }
});

const MealsCalendar = props => {
  const { classes, monthMeals, setMonth, setDate, selectedMonth, selectedDate } = props;

  const renderMonthCaption = ({ month, onMonthSelect, onYearSelect }) => (
    <MonthCaption {...{ selectedMonth, month, onMonthSelect, onYearSelect, setMonth }} />
  );

  const renderDays = day => {
    const index = day.format("D") - 1;
    if (monthMeals[index] && day.format("YYYY-MM-DD") === monthMeals[index].calendarDate) {
      if (monthMeals[index].inetShow === 0) {
        return (
          <div className={classes.dayContainer}>
            <span className={classes.day}>{day.format("D")}</span>
            <Clear color="error" />
            {monthMeals[index].transfers !== null && monthMeals[index].transfers !== "0" ? (
              <span className={classes.transfers}>{monthMeals[index].transfers}</span>
            ) : null}

            {monthMeals[index].pays !== null && monthMeals[index].pays !== "0" ? (
              <span className={classes.pays}>{monthMeals[index].pays}</span>
            ) : null}
          </div>
        );
      } else {
        return (
          <div className={classes.dayContainer}>
            <span className={classes.day}>{day.format("D")}</span>
            {monthMeals[index].buys !== null && monthMeals[index].buys !== "0" ? (
              <span className={classes.buys}>{monthMeals[index].buys}</span>
            ) : null}
            {monthMeals[index].transfers !== null && monthMeals[index].transfers !== "0" ? (
              <span className={classes.transfers}>{monthMeals[index].transfers}</span>
            ) : null}

            {monthMeals[index].pays !== null && monthMeals[index].pays !== "0" ? (
              <span className={classes.pays}>{monthMeals[index].pays}</span>
            ) : null}
          </div>
        );
      }
    } else {
      return (
        <div className={classes.dayContainer}>
          <span className={classes.day}>{day.format("D")}</span>
        </div>
      );
    }
  };

  return (
    <div className={classes.calendarContainer}>
      <MonthCalendar
        {...{ renderMonthCaption, renderDays, setMonth, selectedDate, setDate, CalendarInfo }}
      />
    </div>
  );
};

export default withStyles(styles)(MealsCalendar);
