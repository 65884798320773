import {handleActions} from "redux-actions";
import {monthlyMealsSuccess, dailyMealsSuccess, mealsSuccess} from "actions/mealsActions";

const initialState = {
  mealTypes: {
    ids: [],
    timestamp: 0
  }
};

const meals = handleActions(
  {
    [monthlyMealsSuccess]: (state, action) => {
      const {month} = action.payload;
      month.map(item => {
        let newItem = {};
        if (item.buys !== null && item.buys.endsWith(".00")) {
          newItem.buys = item.buys.slice(0, -3);
        } else if (item.buys !== null && item.buys.endsWith("0")) {
          newItem.buys = item.buys.slice(0, -1);
        }
        if (item.pays !== null && item.pays.endsWith(".00")) {
          newItem.pays = item.pays.slice(0, -3);
        } else if (item.pays !== null && item.pays.endsWith("0")) {
          newItem.pays = item.pays.slice(0, -1);
        }
        if (item.transfers !== null && item.transfers.endsWith(".00")) {
          newItem.transfers = item.transfers.slice(0, -3);
        } else if (item.buys !== null && item.transfers.endsWith("0")) {
          newItem.transfers = item.transfers.slice(0, -1);
        }
        return newItem;
      });

      return {...state, monthMeals: month, isLocalLoading: false};
    },
    [dailyMealsSuccess]: (state, action) => ({
      ...state,
      dayMeals: action.payload.day,
      isLocalLoading: false
    }),
    [mealsSuccess]: (state, action) => {
      const types = action.payload.mealTypes
      const meals = action.payload.meals

      const mealTypes = {}

      mealTypes.ids = types
        .sort((a, b) => {
          if (a.mealTypeId > b.mealTypeId) {
            return 1
          }
          if (a.mealTypeId < b.mealTypeId) {
            return -1
          }
          return 0
        })
        .map(type => {
          const mealType = {
            typeId: type.mealTypeId,
            typeName: type.mealTypeName,
            mealDefaultId: undefined,
            mealEnabledId: undefined,
            mealNextId: undefined
          }

          mealType.mealIds = meals
            .filter(meal => meal.mealTypeId === type.mealTypeId)
            .sort((a, b) => {
              if (a.id > b.id) {
                return 1
              }
              if (a.id < b.id) {
                return -1
              }
              return 0
            })
            .map(meal => {
              mealType[meal.id] = meal
              if (meal.isDefault) {
                mealType.mealDefaultId = meal.id
              }
              if (meal.isSelected) {
                mealType.mealEnabledId = meal.id
              }
              if (meal.isNext) {
                mealType.mealNextId= meal.id
              }
              return meal.id
            })
          if(mealType.mealEnabledId === undefined) {
            mealType.mealEnabledId = mealType.mealDefaultId
          }
          if (mealType.mealNextId === undefined) {
            mealType.mealNextId = mealType.mealDefaultId
          }
          mealTypes[type.mealTypeId] = mealType
          return type.mealTypeId
        })

      mealTypes.timestamp = Date.now()

      return {
        ...state,
        mealTypes
      }
    }
  },
  initialState
);

export default meals;
