import React from "react";

import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import OutlinedInput from "@material-ui/core/OutlinedInput";

import { withStyles } from "@material-ui/core/styles";
const styles = theme => ({
  formControl: {
    minWidth: 220,
    width: 600,
    margin: "10px 10px 10px 0"
  }
});

const PolicyTypeSelect = props => {
  const { classes, policies, selectedPolicy, changePolicy } = props;
  return (
    <FormControl variant="outlined" className={classes.formControl}>
      <InputLabel>Тип полиса</InputLabel>
      <Select
        value={selectedPolicy}
        onChange={changePolicy}
        input={<OutlinedInput labelWidth={100} name="policy" id="policy" />}
      >
        {policies.ids.map(policyId => (
          <MenuItem key={policyId} value={policyId}>
            {policies[policyId].name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default withStyles(styles)(PolicyTypeSelect);
