import {handleActions} from "redux-actions";
import {formatTime} from "helpers/formatDateTime";
import {
  loginSuccess,
  loginError,
  logoutSuccess,
  changeSchoolStart,
  changePupilStart,
  newPasswordSuccess,
  newPhoneCheckCodeRequest,
  newPhoneSendCodeSuccess,
  newPhoneCheckCodeClear,
  newPhoneSuccess,
  newPhoneRecoveryCheckCodeRequest,
  newPhoneRecoverySendCodeSuccess,
  newPhoneRecoveryCheckCodeClear,
  newPhoneRecoverySuccess,
  newPhoneRecoveryError,
  pupilProfileSuccess,
  loadAvatarRequest,
  loadAvatarSuccess,
  updatePupilDayLimit,
  advertisingSuccess,
  advertisingClear,
} from "actions/userActions";
import moment from "moment";

const initialState = {
  auth: false,
  isCodeSend: false,
  isRecoveryCodeSend: false,
  selectedSchool: undefined,
  selectedPupil: undefined,
  profileDate: undefined,
  advertImg: undefined,
  advertLinks: undefined,
  recoveryErrors: {}
};

const setParentProfile = parentProfile => {
  /*
    parent: {
    id: profile.parentId,
    parentCode: profile.parentCode,
    fam: profile.fam !== null ? profile.fam : "",
    im: profile.im !== null ? profile.im : "",
    otch: profile.otch !== null ? profile.otch : "",
    email: profile.email !== null ? profile.email : "",
    payLockedDate: profile.payLockedDate !== null ? profile.payLockedDate : "",
    needToPay: profile.needToPay !== null ? profile.needToPay : "",
    phone: profile.phone
  },

   */

  let newParentProfile = {}
  // eslint-disable-next-line
  for (let key in parentProfile) {
    if (parentProfile.hasOwnProperty(key) &&
      ['balances', 'schoolMenu', 'pupils'].indexOf(key) < 0
    ) {
      if (key === 'parentId') {
        newParentProfile['id'] = parentProfile[key];
      }
      if (key === 'foreignCardId') {
        newParentProfile['foreignCardNum'] = parentProfile[key];
      } else {
        if (['fam', 'im', 'otch', 'email', 'payLockedDate', 'needToPay'].indexOf(key) >= 0) {
          newParentProfile[key] = (parentProfile[key] !== null) ? parentProfile[key] : "";
        } else {
          newParentProfile[key] = parentProfile[key]
        }
      }
    }
  }
  newParentProfile['hasTransportCard'] = newParentProfile.hasOwnProperty('cardIsTC') && newParentProfile['cardIsTC'] === 1
  return newParentProfile
}

const setPupilProfile = newPupilProfile => ({
  id: newPupilProfile.pupilId,
  fam: newPupilProfile.fam,
  im: newPupilProfile.im !== null ? newPupilProfile.im : "",
  otch: newPupilProfile.otch !== null ? newPupilProfile.otch : "",
  classId: newPupilProfile.classId,
  className: newPupilProfile.className !== null ? newPupilProfile.className : "",
  cardNum: newPupilProfile.cardNum !== null ? newPupilProfile.cardNum : "",
  foreignCardNum: newPupilProfile.foreignCardId,
  hasTransportCard: ((newPupilProfile.cardIsTC === 1) ? true : false),
  foodBalance: newPupilProfile.foodBalance !== null ? newPupilProfile.foodBalance : "",
  foodModuleActive: newPupilProfile.foodModuleActive,
  tarifName: newPupilProfile.tarifName,
  tarifPrice: newPupilProfile.tarifPrice,
  dayLimit: newPupilProfile.dayLimit,
  isMealsDetailPossible: (newPupilProfile.isMealsDetailPossible === 1)
});

const user = handleActions(
  {
    [loginSuccess]: (state, action) => {
      const parentProfile = action.payload.profile;

      const schools = {};

      // формируем массив из id школ, предварительно сортируя по названию школы
      schools.ids = parentProfile
        .sort((a, b) => {
          if (a.schoolName > b.schoolName) {
            return 1;
          }
          if (a.schoolName < b.schoolName) {
            return -1;
          }
          return 0;
        })
        .map(profile => {
          // и выносим каждую школу в отдельный объект
          schools[profile.schoolId] = {
            parent: setParentProfile(profile),
            menu: profile.schoolMenu,
            dilerId: profile.dilerId,
            regionId: profile.regionId,
            parentDilerId: profile.parentDilerId,
            autoPaymentAvailable: profile.parentDilerId !== 7,
            schoolName: profile.schoolName,
            schoolParentId: profile.schoolParentId,
            balances: {},
            autoPaymentAvailableServices: [],
            pupils: {},
            avatars: {}
          };

          // разделяем балансы
          schools[profile.schoolId].balances.ids = profile.balances
            .sort((a, b) => {
              if (a.serviceId > b.serviceId) {
                return 1;
              }
              if (a.serviceId < b.serviceId) {
                return -1;
              }
              return 0;
            })
            .filter(balance => (!([1, 3].indexOf(balance.serviceId) >= 0 && profile.pupils.length < 1) || Number(balance.balance) !== 0))
            .map(balance => {
              if (balance.autoPay === 1 && schools[profile.schoolId].autoPaymentAvailableServices.indexOf(balance.serviceId) < 0) {
                schools[profile.schoolId].autoPaymentAvailableServices.push(balance.serviceId);
              }
              schools[profile.schoolId].balances[balance.serviceId] = {
                id: balance.serviceId,
                name: balance.serviceName,
                balance: balance.balance,
                autopayCard: (balance.autopayCard) ? balance.autopayCard : '',
                payDate: (balance.payDate) ? moment(balance.payDate).format('DD.MM.YYYY') : ''
              };
              return balance.serviceId;
            });

          // формируем массив из id учеников, предварительно сортируя их по имени ученика
          schools[profile.schoolId].pupils.ids = profile.pupils
            .sort((a, b) => {
              if (a.im > b.im) {
                return 1;
              }
              if (a.im < b.im) {
                return -1;
              }
              return 0;
            })
            .map(pupil => {
              //  и выносим каждого ученика в отдельный бъект
              schools[profile.schoolId].pupils[pupil.pupilId] = setPupilProfile(pupil);
              return pupil.pupilId;
            });

          return profile.schoolId;
        });

      // eslint-disable-next-line max-len
      const defaultSelectedSchool =
        state.schools && state.selectedSchool ? state.selectedSchool : schools.ids[0];

      const defaultSelectedPupil =
        state.schools && state.selectedPupil
          ? state.selectedPupil
          : schools[defaultSelectedSchool].pupils.ids[0];


      return {
        ...state,
        schools,
        auth: true,
        selectedSchool: defaultSelectedSchool,
        selectedPupil: defaultSelectedPupil,
        profileDate: formatTime(new Date())
      };
    },
    [advertisingSuccess]: (state, action) => ({
      ...state,
      advertImg: action.payload.advertImg,
      advertLinks: action.payload.advertLinks
    }),
    [advertisingClear]: (state) => ({
      ...state,
      advertImg: undefined,
      advertLinks: undefined
    }),
    [loginError]: state => ({
      ...state,
      ...initialState
    }),
    [logoutSuccess]: (state, action) => ({
      ...state,
      ...initialState
    }),
    [changeSchoolStart]: (state, action) => {
      const {selectedSchool} = action.payload;
      const defaultSelectedPupil = state.schools[selectedSchool].pupils.ids[0];
      return {
        ...state,
        selectedSchool: action.payload.selectedSchool,
        selectedPupil: defaultSelectedPupil
      };
    },
    [changePupilStart]: (state, action) => {
      return {
        ...state,
        selectedPupil: action.payload.selectedPupil
      };
    },
    [newPasswordSuccess]: state => ({
      ...state,
      auth: false
    }),
    [newPhoneCheckCodeRequest]: state => ({
      ...state,
      isCodeSend: false
    }),
    [newPhoneSendCodeSuccess]: state => ({
      ...state,
      isCodeSend: true
    }),
    [newPhoneCheckCodeClear]: state => ({
      ...state,
      isCodeSend: false
    }),
    [newPhoneSuccess]: (state, action) => ({
      ...state,
      auth: false,
      isCodeSend: false
    }),
    [newPhoneRecoveryCheckCodeRequest]: state => ({
      ...state
    }),
    [newPhoneRecoverySendCodeSuccess]: state => ({
      ...state,
      isRecoveryCodeSend: true,
      recoveryErrors: {}
    }),
    [newPhoneRecoveryCheckCodeClear]: state => ({
      ...state,
      isRecoveryCodeSend: false,
      recoveryErrors: {}
    }),
    [newPhoneRecoverySuccess]: (state, action) => ({
      ...state,
      auth: false,
      isRecoveryCodeSend: false,
      recoveryErrors: {}
    }),
    [newPhoneRecoveryError]: (state, { payload }) => ({
        ...state,
        auth: state.auth,
        isRecoveryCodeSend: state.isRecoveryCodeSend,
        recoveryErrors: (payload.errors) ? payload.errors : state.recoveryErrors,
      }),
    [pupilProfileSuccess]: (state, action) => {
      const pupilProfile = setPupilProfile(action.payload.pupilProfile);
      const {pupilId} = action.payload;
      const {selectedSchool} = state;

      // изменяем профиль только конкретного ученика
      return {
        ...state,
        schools: {
          ...state.schools,
          [selectedSchool]: {
            ...state.schools[selectedSchool],
            pupils: {
              ...state.schools[selectedSchool].pupils,
              [pupilId]: pupilProfile
            }
          }
        }
      };
    },
    [loadAvatarRequest]: (state, action) => {
      const {picture, accountId} = action.payload;
      const selectedSchool = action.payload.schoolId;

      // изменяем профиль только конкретного ученика
      return {
        ...state,
        schools: {
          ...state.schools,
          [selectedSchool]: {
            ...state.schools[selectedSchool],
            avatars: {
              ...state.schools[selectedSchool].avatars,
              [accountId]: (picture || '')
            }
          }
        }
      };
    },
    [loadAvatarSuccess]: (state, action) => {
      const {picture, accountId} = action.payload;
      const selectedSchool = action.payload.schoolId;

      // изменяем профиль только конкретного ученика
      return {
        ...state,
        schools: {
          ...state.schools,
          [selectedSchool]: {
            ...state.schools[selectedSchool],
            avatars: {
              ...state.schools[selectedSchool].avatars,
              [accountId]: (picture || '')
            }
          }
        }
      };
    },
    [updatePupilDayLimit]: (state, action) => {
      let {dayLimit} = action.payload;
      dayLimit = dayLimit === "0" ? "0.00" : dayLimit;
      const {selectedSchool, selectedPupil} = state;

      // изменяем только дневной лимит после успешного сохранения на сервере
      return {
        ...state,
        schools: {
          ...state.schools,
          [selectedSchool]: {
            ...state.schools[selectedSchool],
            pupils: {
              ...state.schools[selectedSchool].pupils,
              [selectedPupil]: {
                ...state.schools[selectedSchool].pupils[selectedPupil],
                dayLimit
              }
            }
          }
        }
      };
    }
  },
  initialState
);

export default user;
