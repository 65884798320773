import React from "react";
import Typography from "@material-ui/core/Typography";

import InputField from "../../../../InputField";

const ParentAddress = props => {
  const { parent, parentData, changeParentData, validateError } = props;
  return (
    <>
      <Typography variant="button">Адрес регистрации</Typography>
      <div>
        <InputField
          label="Индекс"
          required
          error={validateError.insurerPostIndex(parent, parentData)}
          defaultValue={
            parent.insurerPostIndex ? parent.insurerPostIndex : parentData.insurerPostIndex
          }
          onChange={changeParentData("insurerPostIndex")}
        />

        <InputField
          label="Город"
          required
          error={validateError.notEmpty("insurerCityName", parent, parentData)}
          defaultValue={
            parent.insurerCityName ? parent.insurerCityName : parentData.insurerCityName
          }
          onChange={changeParentData("insurerCityName")}
        />

        <InputField
          label="Населенный пункт"
          defaultValue={
            parent.insurerLocalityName ? parent.insurerLocalityName : parentData.insurerLocalityName
          }
          onChange={changeParentData("insurerLocalityName")}
        />

        <InputField
          label="Улица"
          required
          error={validateError.notEmpty("insurerStreetName", parent, parentData)}
          defaultValue={
            parent.insurerStreetName ? parent.insurerStreetName : parentData.insurerStreetName
          }
          onChange={changeParentData("insurerStreetName")}
        />

        <InputField
          label="Дом"
          required
          error={validateError.notEmpty("insurerHouseNum", parent, parentData)}
          defaultValue={
            parent.insurerHouseNum ? parent.insurerHouseNum : parentData.insurerHouseNum
          }
          onChange={changeParentData("insurerHouseNum")}
        />

        <InputField
          label="Корпус"
          defaultValue={
            parent.insurerBuildNum ? parent.insurerBuildNum : parentData.insurerBuildNum
          }
          onChange={changeParentData("insurerBuildNum")}
        />

        <InputField
          label="Квартира"
          defaultValue={
            parent.insurerApartmentNum ? parent.insurerApartmentNum : parentData.insurerApartmentNum
          }
          onChange={changeParentData("insurerApartmentNum")}
        />
      </div>
      <br />
    </>
  );
};

export default ParentAddress;
