import React from "react";
import Typography from "@material-ui/core/Typography";

import InputField from "../../../../InputField";

const ParentDocs = props => {
  const {
    parent,
    parentData,
    insuranceOrgId,
    changeParentData,
    changeParentDocDate,
    validateError
  } = props;
  return (
    <>
      <Typography variant="button">Паспорт гражданина РФ</Typography>
      <div>
        <InputField
          label="Серия и номер"
          required={true}
          error={validateError.notEmpty("insurerDocSeriesNumber", parent, parentData)}
          defaultValue={
            parent.insurerDocSeries ? parent.insurerDocSeries : parentData.insurerDocSeries
          }
          onChange={changeParentData("insurerDocSeriesNumber")}
        />

        {/*<InputField*/}
        {/*  label="Серия"*/}
        {/*  required={true}*/}
        {/*  error={validateError.notEmpty("insurerDocSeries", parent, parentData)}*/}
        {/*  defaultValue={*/}
        {/*    parent.insurerDocSeries ? parent.insurerDocSeries : parentData.insurerDocSeries*/}
        {/*  }*/}
        {/*  onChange={changeParentData("insurerDocSeries")}*/}
        {/*/>*/}

        {/*<InputField*/}
        {/*  label="Номер"*/}
        {/*  required={true}*/}
        {/*  error={validateError.notEmpty("insurerDocNumber", parent, parentData)}*/}
        {/*  defaultValue={*/}
        {/*    parent.insurerDocNumber ? parent.insurerDocNumber : parentData.insurerDocNumber*/}
        {/*  }*/}
        {/*  onChange={changeParentData("insurerDocNumber")}*/}
        {/*/>*/}
        {(insuranceOrgId !== 2) && (
          <InputField
            label="Дата выдачи *"
            datePicker
            error={validateError.notEmpty("insurerDocDate", parent, parentData)}
            value={
              parent && parent.insurerDocDate ? parent.insurerDocDate : parentData.insurerDocDate
            }
            onChange={changeParentDocDate}
          />
        )}
        {(insuranceOrgId !== 2) && (
          <InputField
            label="Кем выдан"
            fullWidth
            required={true}
            error={validateError.notEmpty("insurerDocIssuer", parent, parentData)}
            defaultValue={
              parent.insurerDocIssuer ? parent.insurerDocIssuer : parentData.insurerDocIssuer
            }
            onChange={changeParentData("insurerDocIssuer")}
          />
        )}
      </div>
      <br/>
    </>
  );
};

export default ParentDocs;
