import React, {Component} from "react";

import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
// import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

import withStyles from "@material-ui/core/styles/withStyles";

const styles = theme => ({
  positionStart: {
    fontSize: 20
  },
  modal: {
    [theme.breakpoints.down("xs")]: {
      alignItems: "flex-start"
    }
  },
  paper: {
    margin: 10
  },
  img: {
    maxWidth: "100%"
  }
});

class Advertising extends Component {

  onClose = () => {
    this.props.clearAdvertising();
  };

  onLink = (link, blank) => {
    if (!!blank) {
      window.open(link, '_blank');
    } else {
      window.location.href = link
    }
  }

  render() {
    const {classes, advertImg, advertLinks} = this.props;

    const addLinks = (links) => {
      if (!links) {
        return null;
      } else if (typeof links === 'string') {
        return (
          <Button
            key={`adv-desc-button`}
            onClick={() => {
              this.onLink(links)
            }} color="primary">
            Подробнее
          </Button>
        )
      } else {
        return links.sort((a, b) => {
          if (a.id > b.id) return 1
          else if (a.id < b.id) return -1
          else return 0
        }).map((link,index) => {
          return (
            <Button
              key={`adv-${index}-button`}
              onClick={() => {
              this.onLink(link.url, link.blank)
            }} color="primary">
              {link.label}
            </Button>
          )
        })
      }
    }

    return (
      <Dialog
        open={!!advertImg}
        onClose={this.onClose}
        classes={{root: classes.modal, paper: classes.paper}}
      >
        <DialogTitle> </DialogTitle>
        <DialogContent>
          <div>
            <img className={classes.img} src={advertImg} alt=""/>
          </div>
        </DialogContent>
        <DialogActions>
          {addLinks(advertLinks)}
          <Button
            key={`adv-close-button`} onClick={this.onClose} color="primary">
            Закрыть
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default withStyles(styles)(Advertising);
