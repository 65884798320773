import React, {Component} from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {
  getRegionsSelector,
  getAreasSelector,
  getCitiesSelector,
  getLocalitiesSelector,
  getStructuresSelector,
  getStreetsSelector,
  getHousesSelector
} from "selectors/fias";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import ParentAddress from "../../ParentAddress";

import {
  getFiasRegions,
  getFiasAreas,
  getFiasCities,
  getFiasLocalities,
  getFiasStructures,
  getFiasStreets,
  getFiasHouses,
  clearFiasData
} from "actions/fiasActions";

import {withStyles} from "@material-ui/core/styles";

const styles = () => ({
  buttons: {
    display: "flex",
    flexDirection: "column",
    marginTop: 15
  },
  nextButton: {
    flexGrow: 1
  }
});

class Step3 extends Component {
  componentDidMount() {
    this.props.getFiasRegions(this.props.parentDilerIds, this.props.regionId);
  }

  clearFromField = fieldId => {
    this.props.setFieldValue("city", null);
  };

  onInputChange = (id, value) => {
    const {region, area, city, locality, structure, street} = this.props.values;
    if (value) {
      if (id === "city") {
        this.props.getFiasCities(
          region.value,
          (area && area.AOGuid) || (region && region.AOGuid),
          value
        );
      } else if (id === "locality") {
        this.props.getFiasLocalities(
          region.value,
          (city && city.AOGuid) || (area && area.AOGuid) || (region && region.AOGuid),
          value
        );
      } else if (id === "structure") {
        this.props.getFiasStructures(
          region.value,
          (locality && locality.AOGuid) || (city && city.AOGuid), value);
      } else if (id === "street") {
        this.props.getFiasStreets((structure && structure.AOGuid) || (locality && locality.AOGuid) || (city && city.AOGuid), value);
      } else if (id === "house") {
        this.props.getFiasHouses(
          (street && street.AOGuid) || (structure && structure.AOGuid) || (locality && locality.AOGuid) || (city && city.AOGuid),
          value
        );
      }
    }
  };

  componentDidUpdate(prevProps, prevState) {
    const {
      region: oldRegion,
      area: oldArea,
      city: oldCity,
      locality: oldLocality,
      structure: oldStructure,
      street: oldStreet
    } = prevProps.values;
    const {region, area, city, locality, structure, street} = this.props.values;
    const {clearFiasData} = this.props;

    // если изменился регион
    if (oldRegion !== region) {
      // если указан новый регион запрашиваем районы и города
      if (region) {
        const {value, AOGuid} = region;
        this.props.getFiasAreas(value, AOGuid, "");
        this.props.getFiasCities(value, AOGuid, "");
      } else {
        clearFiasData("areas");
        clearFiasData("cities");
        clearFiasData("localities");
        clearFiasData("structures");
        clearFiasData("streets");
        clearFiasData("houses");
      }
      this.props.setFieldValue("area", null);
      this.props.setFieldValue("city", null);
      this.props.setFieldValue("locality", null);
      this.props.setFieldValue("structure", null);
      this.props.setFieldValue("street", null);
      this.props.setFieldValue("house", null);
      this.props.setFieldValue("apartment", null);
    }

    // если изменился район
    if (oldArea !== area) {
      if (area) {
        this.props.getFiasCities(region.value, area.AOGuid, "");
        this.props.getFiasLocalities(region.value, area.AOGuid, "");
      } else {
        if (region) this.props.getFiasCities(region.value, region.AOGuid, "");
        clearFiasData("cities");
        clearFiasData("localities");
        clearFiasData("structures");
        clearFiasData("streets");
        clearFiasData("houses");
      }
      this.props.setFieldValue("city", null);
      this.props.setFieldValue("locality", null);
      this.props.setFieldValue("structure", null);
      this.props.setFieldValue("street", null);
      this.props.setFieldValue("house", null);
      this.props.setFieldValue("apartment", null);
    }

    // если изменился город
    if (oldCity !== city) {
      if (city) {
        this.props.getFiasLocalities(region.value, city.AOGuid, "");
        this.props.getFiasStructures(region.value, city.AOGuid, "");
        this.props.getFiasStreets(city.AOGuid, "");
      } else {
        clearFiasData("localities");
        clearFiasData("structures");
        clearFiasData("streets");
        clearFiasData("houses");
      }
      this.props.setFieldValue("locality", null);
      this.props.setFieldValue("structure", null);
      this.props.setFieldValue("street", null);
      this.props.setFieldValue("house", null);
      this.props.setFieldValue("apartment", null);
    }

    // если изменился населенный пункт
    if (oldLocality !== locality) {
      if (locality) {
        this.props.getFiasStructures(region.value, locality.AOGuid, "");
        this.props.getFiasStreets(locality.AOGuid, "");
      } else {
        // если удалили населенный пункт, но город выбран
        if (city) {
          this.props.getFiasStructures(region.value, city.AOGuid, "");
          this.props.getFiasStreets(city.AOGuid, "");
        } else {
          clearFiasData("structures");
          clearFiasData("streets");
          clearFiasData("houses");
        }
      }
      this.props.setFieldValue("structure", null);
      this.props.setFieldValue("street", null);
      this.props.setFieldValue("house", null);
      this.props.setFieldValue("apartment", null);
    }
    // если изменилась планировочная структура
    if (oldStructure !== structure) {
      if (structure) {
        this.props.getFiasStreets(structure.AOGuid, "");
      } else {

        if(locality) {
          this.props.getFiasStreets(locality.AOGuid, "");
        } else if (city) {// если удалили населенный пункт, но город выбран
          this.props.getFiasStreets(city.AOGuid, "");
        } else {
          clearFiasData("streets");
          clearFiasData("houses");
        }
      }
      this.props.setFieldValue("street", null);
      this.props.setFieldValue("house", null);
      this.props.setFieldValue("apartment", null);
    }
    // если изменилась улица
    if (oldStreet !== street) {
      if (street) {
        this.props.getFiasHouses((structure && structure.AOGuid) || (locality && locality.AOGuid) || (city && city.AOGuid), "%");
      } else {
        clearFiasData("houses");
      }
      this.props.setFieldValue("house", null);
      this.props.setFieldValue("apartment", null);
    }
  }

  isParentAddressIncomplete = values => {
    return !values.house;
  };

  render() {
    const {classes, values, changeStep, handleEnter} = this.props;
    return (
      <>
        <ParentAddress {...this.props} onInputChange={this.onInputChange} onKeyUp={handleEnter}/>
        <div className={classes.buttons}>
          {this.isParentAddressIncomplete(values) && (
            <Typography variant="body2" color="error">
              Для продолжения заполните все поля, отмеченные звездочкой
            </Typography>
          )}
          <Button
            className={classes.nextButton}
            disabled={this.isParentAddressIncomplete(values)}
            variant="outlined"
            color="secondary"
            size="large"
            onClick={() => changeStep(3)}
          >
            Далее
          </Button>
        </div>
      </>
    );
  }
}

const mapStateToProps = state => ({
  regions: getRegionsSelector(state),
  areas: getAreasSelector(state),
  cities: getCitiesSelector(state),
  localities: getLocalitiesSelector(state),
  structures: getStructuresSelector(state),
  streets: getStreetsSelector(state),
  houses: getHousesSelector(state),
  errors: state.registration.errors
});

const mapDispatchToProps = {
  getFiasRegions,
  getFiasAreas,
  getFiasCities,
  getFiasLocalities,
  getFiasStructures,
  getFiasStreets,
  getFiasHouses,
  clearFiasData
};

Step3.propTypes = {
  regionId: PropTypes.number,
  parentDilerIds: PropTypes.oneOfType([PropTypes.oneOf([null]), PropTypes.number, PropTypes.array]).isRequired,
  getFiasRegions: PropTypes.func.isRequired,
  getFiasAreas: PropTypes.func.isRequired,
  getFiasCities: PropTypes.func.isRequired,
  getFiasLocalities: PropTypes.func.isRequired,
  getFiasHouses: PropTypes.func.isRequired,
  getFiasStreets: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  values: PropTypes.shape({
    region: PropTypes.object,
    area: PropTypes.object,
    city: PropTypes.object,
    locality: PropTypes.object,
    street: PropTypes.object,
    house: PropTypes.object
  }),
  clearFiasData: PropTypes.func.isRequired,
  classes: PropTypes.object,
  changeStep: PropTypes.func.isRequired,
  errors: PropTypes.object
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(Step3));
