import React from "react";
import Hidden from "@material-ui/core/Hidden";
import { withStyles } from "@material-ui/core/styles";

import FooterElements from "components/FooterElements";

const styles = theme => ({
  container: {
    minHeight: 50,
    display: "fixed",
    bottom: 0,
    zIndex: theme.zIndex.drawer + 1,
    flexShrink: 0
  }
});

const Footer = props => {
  const { classes, profile } = props;
  return (
    <Hidden xsDown>
      <footer className={classes.container}>
        {profile ? <FooterElements dilerId={profile.dilerId} showAddress /> : null}
      </footer>
    </Hidden>
  );
};

export default withStyles(styles)(Footer);
