import React from "react";
import Typography from "@material-ui/core/Typography";
import Radio from "@material-ui/core/Radio";

import InputField from "../../../../InputField";

const PupilDocs = props => {
  const {
    pupil,
    pupilData,
    changePupilData,
    changePupilDocDate,
    pupilDocsType,
    changePupilDocsType,
    validateError
  } = props;
  return (
    <>
      <Typography
        variant="button"
        onClick={e => changePupilDocsType(e, 1)}
        style={{ cursor: "pointer" }}
      >
        <Radio
          checked={parseInt(pupilDocsType) === 1}
          onChange={e => changePupilDocsType(e)}
          value={1}
        />
        Свидетельство о рождении
      </Typography>
      <Typography
        variant="button"
        onClick={e => changePupilDocsType(e, 2)}
        style={{ cursor: "pointer" }}
      >
        <Radio
          checked={parseInt(pupilDocsType) === 2}
          onChange={e => changePupilDocsType(e)}
          value={2}
        />
        Паспорт
      </Typography>
      <div>
        <InputField
          label="Серия"
          required
          error={validateError.notEmpty("accountDocSeries", pupil, pupilData)}
          defaultValue={
            pupil.accountDocSeries ? pupil.accountDocSeries : pupilData.accountDocSeries
          }
          onChange={changePupilData("accountDocSeries")}
        />

        <InputField
          label="Номер"
          required
          error={validateError.notEmpty("accountDocNumber", pupil, pupilData)}
          defaultValue={
            pupil.accountDocNumber ? pupil.accountDocNumber : pupilData.accountDocNumber
          }
          onChange={changePupilData("accountDocNumber")}
        />

        <InputField
          label="Дата выдачи *"
          datePicker
          error={validateError.notEmpty("accountDocDate", pupil, pupilData)}
          value={
            pupil && pupil.accountDocDate ? pupil.accountDocDate : pupilData.accountDocDate
          }
          onChange={changePupilDocDate}
        />

        <InputField
          label="Кем выдан документ"
          required
          fullWidth
          error={validateError.notEmpty("accountDocIssuer", pupil, pupilData)}
          defaultValue={
            pupil.accountDocIssuer ? pupil.accountDocIssuer : pupilData.accountDocIssuer
          }
          onChange={changePupilData("accountDocIssuer")}
        />
      </div>
      <br />
    </>
  );
};

export default PupilDocs;
