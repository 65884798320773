import React from "react";
import Typography from "@material-ui/core/Typography";
import AppBar from "@material-ui/core/AppBar";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import StepButton from "@material-ui/core/StepButton";
import StepContent from "@material-ui/core/StepContent";
import Button from "@material-ui/core/Button";

import LoadingMessage from "components/messages/LoadingMessage";
import Step1 from "./Step1";
import Step2 from "./Step2";
import Step3 from "./Step3";
import Step4 from "./Step4";

import { withStyles } from "@material-ui/core/styles";

const styles = theme => ({
  container: {
    display: "flex",
    flexDirection: "column",
    height: "100%"
  },
  currentTariff: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    padding: 5
  },
  stepper: {
    width: "calc(100% - 270px)",
    height: "100%",
    [theme.breakpoints.down("xs")]: {
      padding: "10px 5px",
      width: "calc(100% - 10px)",
      overflow: "hidden"
    }
  },
  deliveryList: {
    padding: 0
  },
  deliveryItem: {
    padding: 0
  },
  stepButton: {
    textAlign: "left"
  }
});

const SetTariff = props => {
  const {
    classes,
    pupil,
    step,
    nextStep,
    prevStep,
    available,
    infoWindowIsOpen,
    infoText,
    infoWindowOpen,
    infoWindowClose,
    isLocalLoading,
    cancelStepsClick,
    selectedTariff,
    selectTariff,
    selectedDeliveries,
    selectDelivery,
    selectedModules,
    selectModule,
    selectedTariffProps,
    setTariff,
    referralCode,
    changeReferral,
    profile
  } = props;
  if (!available) {
    return <LoadingMessage />;
  } else {
    return (
      <div className={classes.container}>
        <AppBar color="default" position="static" elevation={1} className={classes.currentTariff}>
          <Typography variant="subtitle2" color="inherit">
            {pupil.im} {pupil.className}, текущий тариф: {pupil.tarifName}, {pupil.tarifPrice}{" "}
            руб./мес.
          </Typography>
          <Button variant="outlined" size="small" onClick={e => props.cancelStepsClick()}>
            Отмена
          </Button>
        </AppBar>
        <div>
          <Stepper className={classes.stepper} activeStep={step} orientation="vertical">
            <Step>
              <StepButton onClick={props.goToStep(0)}>{props.getStepLabel(0)}</StepButton>
              <StepContent>
                <Step1
                  {...{
                    profile,
                    referralCode,
                    selectedTariff,
                    selectTariff,
                    nextStep,
                    changeReferral,
                    cancelStepsClick,
                    infoWindowIsOpen,
                    infoWindowOpen,
                    infoWindowClose,
                    infoText
                  }}
                  tariffs={available.tarifs}
                />
              </StepContent>
            </Step>
            <Step>
              <StepButton className={classes.stepButton} onClick={props.goToStep(1)}>
                {props.getStepLabel(1)}
              </StepButton>
              <StepContent>
                <Step2
                  {...{
                    selectedDeliveries,
                    selectDelivery,
                    nextStep,
                    prevStep,
                    cancelStepsClick,
                    infoWindowIsOpen,
                    infoText,
                    infoWindowOpen,
                    infoWindowClose
                  }}
                  deliveryTypes={available.deliveryTypes}
                />
              </StepContent>
            </Step>
            <Step>
              <StepLabel>{props.getStepLabel(2)}</StepLabel>
              <StepContent>
                <Step3
                  {...{ nextStep, prevStep, selectedModules, selectModule, cancelStepsClick }}
                  packetModules={available.packetModules}
                />
              </StepContent>
            </Step>
            <Step>
              <StepLabel>{props.getStepLabel(3)}</StepLabel>
              <StepContent>
                <Step4
                  {...{
                    selectedTariff,
                    nextStep,
                    prevStep,
                    selectedTariffProps,
                    setTariff,
                    isLocalLoading,
                    cancelStepsClick,
                    referralCode,
                    changeReferral,
                    profile
                  }}
                />
              </StepContent>
            </Step>
          </Stepper>
        </div>
      </div>
    );
  }
};

export default withStyles(styles)(SetTariff);
