import React, { PureComponent } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";

import * as userActions from "actions/userActions";

const mapStateToProps = state => {
  return {
    user: state.user
  };
};

const mapDispatchToProps = dispatch => {
  return {
    userActions: bindActionCreators(userActions, dispatch)
  };
};

const styles = theme => ({
  form: {
    marginTop: 5,
    height: 40
  },
  input: {
    color: "white"
  }
});

export class SchoolSelect extends PureComponent {
  schoolChange = event => {
    if (event.target.value !== this.props.user.selectedSchool) {
       this.props.userActions.changeSchool(event.target.value);
     if (this.props.closeMobileMenu) {
        this.props.closeMobileMenu();
      }
    }
  };
  render() {
    const { user, classes } = this.props;
    if (!user.schools) {
      return (
        <FormControl className={classes.root}>
          <InputLabel>Текущая школа</InputLabel>
          <TextField disabled value="Загрузка школы" margin="normal" />
        </FormControl>
      );
    } else {
      return (
        <FormControl className={classes.form}>
          <InputLabel focused={false}>Текущая школа</InputLabel>
          <Select
            className={classes.input}
            disableUnderline
            value={user.selectedSchool}
            name="school"
            onChange={this.schoolChange}
          >
            {user.schools.ids.map(schoolId => (
              <MenuItem key={schoolId} value={schoolId}>
                {user.schools[schoolId].schoolName}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      );
    }
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(SchoolSelect));
