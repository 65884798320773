import React from "react";
import { Polygon } from "react-yandex-maps";

const NewZone = props => {
  const { zonePoints } = props;
  return (
    <Polygon
      geometry={([zonePoints], [zonePoints])}
      options={{
        fillColor: "#4fc3f7",
        strokeColor: "#0000FF",
        opacity: 0.8,
        strokeWidth: zonePoints.length > 2 ? 2 : 5
      }}
    />
  );
};

export default NewZone;
