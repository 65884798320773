import React from "react";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";

import TextField from "@material-ui/core/TextField";

import withStyles from "@material-ui/core/styles/withStyles";
import PropTypes from "prop-types";

const styles = () => ({
  listInput: {
    flexDirection: "column",
    alignItems: "flex-start"
  },
  fullWidth: {
    width: "100%"
  }
});

const UploadSettings = ({
  classes, settings, settingsDescription, handleInputChange
}) => (
  <ListItem disableGutters className={classes.listInput}>
    <ListItemText primary={settingsDescription.upload.title} />
    <TextField
      className={classes.fullWidth}
      value={settings.upload}
      onChange={handleInputChange("upload")}
      margin="none"
    />
  </ListItem>
);

UploadSettings.propTypes = {
  settings: PropTypes.object,
  settingsDescription: PropTypes.object,
  handleInputChange: PropTypes.func.isRequired,
  classes: PropTypes.object
};

export default withStyles(styles)(UploadSettings);
