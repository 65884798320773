import React, {Component} from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
// import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Divider from "@material-ui/core/Divider";
import {Typography} from "@material-ui/core";
import LoadingMessage from "components/messages/LoadingMessage";
import withStyles from "@material-ui/core/styles/withStyles";

import {getTransportCardServices} from "selectors/transportCard";

import {
  transportCardPayUrlClear,
  fetchTransportCardReplenishmentUrl,
  fetchTransportCardPurchaseUrl,
  fetchTransportCardOperations
} from "actions/transportCardActions";

import Purchase from "./Purchase";
import Replenishment from "./Replenishment";
import PayUrl from "./PayUrl";

const mapStateToProps = state => ({
  transportCardServices: getTransportCardServices(state),
  transportCardOperations: state.transportCard.operations,
  payUrl: state.transportCard.payUrl
});

const mapDispatchToProps = {
  transportCardPayUrlClear,
  fetchTransportCardReplenishmentUrl,
  fetchTransportCardPurchaseUrl,
  fetchTransportCardOperations
};

const styles = theme => ({
  modal: {
    [theme.breakpoints.down("xs")]: {
      alignItems: "flex-start"
    }
  },
  paper: {
    maxHeight: 800,
    margin: 10,
    [theme.breakpoints.down("sm")]: {
      minHeight: 450,
      maxHeight: 600,
    }
  },
  paperLoading: {
    margin: 10
  }
});

class TransportCardOperations extends Component {
  state = {expanded: null};

  componentDidMount() {
    const {fetchTransportCardOperations, transportCardOperationsIsOpen, pupilId} = this.props;
    if (transportCardOperationsIsOpen === pupilId) {
      fetchTransportCardOperations(pupilId);
    }
  }

  componentWillUnmount() {
    this.onClose();
  }

  onClose = () => {
    this.props.transportCardPayUrlClear();
    this.props.transportCardOperationsToggle(null);
  };

  changeExpand = panel => (event, expanded) => {
    this.setState({
      expanded: expanded ? panel : null
    });
  };

  render() {
    const {
      classes,
      transportCardOperationsIsOpen,
      payUrl,
      transportCardOperations,
      fetchTransportCardReplenishmentUrl,
      fetchTransportCardPurchaseUrl,
      transportCardPayUrlClear,
      transportCardServices
    } = this.props;
    return (
      <Dialog
        open={!!transportCardOperationsIsOpen}
        onClose={this.onClose}
        maxWidth="md"
        fullWidth
        classes={{
          root: classes.modal,
          paper: transportCardServices ? classes.paper : classes.paperLoading
        }}
      >
        <LoadingMessage/>
        <DialogTitle>Операции по транспортной карте</DialogTitle>
        {!payUrl && transportCardOperations && (
          <DialogContent>
            {/* <DialogContentText>Описание</DialogContentText> */}
            <Divider/>
            <>
              {transportCardOperations.replenishment.allowed ? (
                <Replenishment
                  transportCardOperations={transportCardOperations}
                  expanded={this.state.expanded}
                  changeExpand={this.changeExpand}
                  pupilId={transportCardOperationsIsOpen}
                  fetchTransportCardReplenishmentUrl={fetchTransportCardReplenishmentUrl}
                />
              ) : (
                <>
                  <Typography>Операции пополнения счета карты отсутствуют</Typography>
                  <br/>
                </>
              )}
              {transportCardOperations.purchase.allowed ? (
                <Purchase
                  transportCardOperations={transportCardOperations}
                  expanded={this.state.expanded}
                  changeExpand={this.changeExpand}
                  pupilId={transportCardOperationsIsOpen}
                  fetchTransportCardPurchaseUrl={fetchTransportCardPurchaseUrl}
                  transportCardServices={transportCardServices}
                />
              ) : (
                <>
                  <br/>
                  <Typography>Доступные услуги по транспортной карте отсутствуют</Typography>
                </>
              )}
            </>
          </DialogContent>
        )}
        {payUrl && <PayUrl payUrl={payUrl} transportCardPayUrlClear={transportCardPayUrlClear}/>}
        <DialogActions>
          <Button onClick={this.onClose} color="primary">
            Отмена
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

TransportCardOperations.propTypes = {
  classes: PropTypes.object.isRequired,
  transportCardPayUrlClear: PropTypes.func.isRequired,
  transportCardOperationsToggle: PropTypes.func.isRequired,
  transportCardOperationsIsOpen: PropTypes.number,
  payUrl: PropTypes.string,
  transportCardOperations: PropTypes.object,
  pupilId: PropTypes.number,
  fetchTransportCardReplenishmentUrl: PropTypes.func.isRequired,
  fetchTransportCardPurchaseUrl: PropTypes.func.isRequired,
  transportCardServices: PropTypes.object,
  fetchTransportCardOperations: PropTypes.func.isRequired
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(TransportCardOperations));
