import React from "react";
import PropTypes from "prop-types";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpansionPanelActions from "@material-ui/core/ExpansionPanelActions";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Typography from "@material-ui/core/Typography";
import InputAdornment from "@material-ui/core/InputAdornment";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Input from "@material-ui/core/Input";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

import { withStyles } from "@material-ui/core/styles";

const styles = () => ({
  input: {
    minWidth: 200,
    width: 280
  },
  panelDetails: {
    display: "flex",
    flexDirection: "column"
  }
});

class Replenishment extends React.Component {
  state = {
    amount: "",
    isUpTo: false,
    error: ""
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.expanded !== this.props.expanded) {
      this.setState({
        amount: "",
        isUpTo: false,
        error: ""
      });
    }
  }
  

  onChange = name => event => {
    this.checkSum(event.target.value);
    this.setState({
      [name]: event.target.value
    });
  };

  isUpToChange = () => {
    this.setState({ isUpTo: !this.state.isUpTo }, () => this.checkSum(this.state.amount));
  };

  checkSum = checkValue => {
    const {
      transportCardOperations: {
        replenishment: { restriction }
      }
    } = this.props;

    if (checkValue === "") {
      this.setState({
        error: ""
      });
      return false;
    }

    const value = parseFloat(checkValue);

    if (this.state.isUpTo) {
      if (value < restriction.upTo.minAmount || value > restriction.upTo.maxAmount) {
        this.setState({
          error: `Сумма платежа должна быть между ${restriction.upTo.minAmount} руб. и ${restriction.upTo.maxAmount} руб.`
        });
        return false;
      }
      this.setState({
        error: ""
      });
      return true;
    } else {
      if (
        value < (restriction.value.minAmount === 0 ? 1 : restriction.value.minAmount) ||
        value > restriction.value.maxAmount
      ) {
        this.setState({
          error: `Сумма платежа должна быть между ${
            restriction.value.minAmount === 0 ? 1 : restriction.value.minAmount
          } руб. и ${restriction.value.maxAmount} руб.`
        });
        return false;
      }
      this.setState({
        error: ""
      });
    }
  };

  getRestriction = () => {
    const {
      transportCardOperations: {
        replenishment: { restriction }
      }
    } = this.props;
    const { isUpTo } = this.state;
    if (isUpTo) {
      return `Сумма платежа должна быть между ${restriction.upTo.minAmount / 100} руб. и ${restriction.upTo.maxAmount / 100} руб.`;
    } else {
      return `Сумма платежа должна быть между ${
        restriction.value.minAmount === 0 ? 1 : restriction.value.minAmount / 100
      } руб. и ${restriction.value.maxAmount / 100} руб.`;
    }
  };

  render() {
    const {
      classes,
      expanded,
      changeExpand,
      pupilId,
      fetchTransportCardReplenishmentUrl
    } = this.props;
    return (
      <ExpansionPanel
        key="replenishment"
        expanded={expanded === "replenishment"}
        onChange={changeExpand("replenishment")}
      >
        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="subtitle1">Пополнение счета</Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <div className={classes.panelDetails}>
            <FormControlLabel
              control={
                <Checkbox
                  color="primary"
                  checked={this.state.isUpTo}
                  onChange={this.isUpToChange}
                />
              }
              label="Пополнить до указанной суммы"
            />
            <FormControl required>
              <InputLabel htmlFor="amount">Сумма платежа</InputLabel>
              <Input
                className={classes.input}
                type="number"
                value={this.state.amount}
                onChange={this.onChange("amount")}
                error={!!this.state.error}
                endAdornment={<InputAdornment position="end">руб.</InputAdornment>}
              />
              {this.state.error ? (
                <Typography color="error">{this.state.error}</Typography>
              ) : (
                <Typography>{this.getRestriction()}</Typography>
              )}
            </FormControl>
          </div>
        </ExpansionPanelDetails>
        <Divider />
        <ExpansionPanelActions>
          <Button
            disabled={!!this.state.error || !this.state.amount}
            variant="outlined"
            color="secondary"
            size="large"
            onClick={e =>
              fetchTransportCardReplenishmentUrl(pupilId, {
                amount: parseFloat(this.state.amount, 2) * 100,
                isUpTo: this.state.isUpTo
              })
            }
          >
            Пополнить
          </Button>
        </ExpansionPanelActions>
      </ExpansionPanel>
    );
  }
}

Replenishment.propTypes = {
  classes: PropTypes.object,
  expanded: PropTypes.string,
  changeExpand: PropTypes.func,
  transportCardOperations: PropTypes.shape({
    replenishment: PropTypes.shape({
      restriction: PropTypes.object,
      allowed: PropTypes.bool
    })
  }),
  pupilId: PropTypes.number,
  fetchTransportCardReplenishmentUrl: PropTypes.func.isRequired
};

export default withStyles(styles)(Replenishment);