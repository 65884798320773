import React from "react";
import PropTypes from "prop-types";
import {withStyles} from "@material-ui/core/styles";
import Hidden from "@material-ui/core/Hidden";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Typography from "@material-ui/core/Typography";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Input from "@material-ui/core/Input";
import InputAdornment from "@material-ui/core/InputAdornment";
import Button from "@material-ui/core/Button";
import {Grid, Radio, Table, TableBody, TableCell, TableHead, TableRow} from "@material-ui/core";
import AppBarWithTabs from "../../AppBarWithTabs";
import SwipeableViews from "react-swipeable-views";
import InfoWindow from "../../InfoWindow";
import HelpOutline from "@material-ui/icons/HelpOutline";
import IconButton from "@material-ui/core/IconButton";
import {CheckCircleOutlined, Sync} from "@material-ui/icons"

const styles = () => ({
  container: {
    padding: 5,
  },
  expansion: {
    border: "1px solid lightgrey",
    padding: 3,
  },
  expansionTitle: {
    padding: "0 0 0 10px",
  },
  details: {
    display: "flex",
    flexDirection: "column",
    padding: 0,
  },
  row: {display: "flex", alignItems: "baseline"},
})

const HelpText = () => {
  return (
    <Typography variant="caption">
      Выберите желаемый комплекс питания.
      <br/>
      <b>ВАЖНО</b>. Изменения в выборе комплекса питания будут применены после 16:00
    </Typography>
  )
}

const TabMealType = props => {
  const {mealType, selectedMeal, infoWindowOpen} = props

  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell padding="checkbox"></TableCell>
          <TableCell padding="checkbox"></TableCell>
          <TableCell>Вид питания</TableCell>
          <TableCell>Перечень блюд</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {
          mealType.mealIds.map(mealId => {
            const meal = mealType[mealId]
            return (
              <TableRow key={mealId}>
                <TableCell padding="checkbox">
                  <Radio color="primary"
                         checked={selectedMeal[mealType.typeId] === meal.id}
                         value={meal.id}
                         onChange={props.handleSelectedMealChange(mealType.typeId)}
                  />
                </TableCell>
                <TableCell padding="checkbox">
                  {mealId === mealType.mealEnabledId &&
                    (<IconButton style={{color: "green"}} onClick={() => infoWindowOpen("Действующий комплекс")}>
                      <CheckCircleOutlined/>
                    </IconButton>)}
                  {(mealType.mealEnabledId !== mealType.mealNextId && mealId === mealType.mealNextId) &&
                    (<IconButton style={{}} onClick={() => infoWindowOpen("Будет активирован после 16:00")}>
                      <Sync/>
                    </IconButton>)}
                </TableCell>
                <TableCell>
                  <Grid container spacing={8} direction="row" key={`r-${mealId}`}>
                    <Grid item><Typography variant="body1" xs={12}>{meal.name}</Typography></Grid>
                    <Grid item xs={12}><Typography variant="caption">{meal.price} р.</Typography> </Grid>
                  </Grid>
                </TableCell>
                <TableCell>
                  {meal.dishes.length > 0
                    && meal.dishes.map(mealDish => (
                      <Grid container spacing={8} direction="row" key={`${mealId}-${mealDish.id}`}>
                        <Grid item xs={9}><Typography variant="body2">{mealDish.name}</Typography></Grid>
                        <Grid item xs={3}><Typography variant="body2" align="right">{mealDish.weight} г/мл</Typography></Grid>
                      </Grid>))
                    || (<Typography variant="body2">Данные не предоставлены школой. За подробным описанием обратитесь к
                      ответственному в школе</Typography>)}
                </TableCell>
              </TableRow>
            )
          })
        }
      </TableBody>
    </Table>
  )

  return (
    <Grid container spacing={8}>

      <Grid item>

      </Grid>
      <Grid item direction="row">
        {mealType.mealIds.map(mealId => {
          const meal = mealType[mealId]

          return (
            <Grid container spacing={8}>
              <Grid item direction="row" xs={6}>
                <Typography>{meal.name}</Typography>
              </Grid>
              <Grid item direction="row" xs>
                <Typography>Перечень блюд:</Typography>
                {meal.dishes.length > 0 && meal.dishes.map(mealDish =>
                  <Grid container>
                    <Grid item>{mealDish.name}</Grid>
                    <Grid item>{mealDish.weight}</Grid>
                  </Grid>) || (<Typography></Typography>)}
              </Grid>
            </Grid>
          )
        })}
      </Grid>
    </Grid>
  )
}

TabMealType.propTypes = {
  mealType: PropTypes.object.isRequired,
  selectedMeal: PropTypes.object.isRequired,
  handleSelectedMealChange: PropTypes.func.isRequired,
  infoWindowOpen: PropTypes.func.isRequired,
}

const MealOrdersDetails = props => {
  const {mealTypes, tabMealTypesIndex, selectedMeal} = props
  const typeId = mealTypes.ids[tabMealTypesIndex]
  const mealType = mealTypes[typeId]
  console.log(mealType)

  const tabNames = mealTypes.ids.map(typeId => mealTypes[typeId].typeName)
  return (
    <Grid container spacing={16} className={props.classes.row}>
      <Grid item xs={12}><HelpText/></Grid>
      <Grid item xs={12}>
        <AppBarWithTabs tabIndex={tabMealTypesIndex} tabClick={props.tabMealTypesClick} tabs={tabNames}/>
        <SwipeableViews
          // className={classes.swipeViews}
          index={tabMealTypesIndex}
          onChangeIndex={props.tabMealTypesSwipe}
          animateHeight
          threshold={10}
          springConfig={{
            easeFunction: "ease-in",
            delay: "0s",
            duration: "0.3s",
          }}
        >
          {mealTypes.ids.map(typeId => {
            const mealType = mealTypes[typeId]
            return (
              <TabMealType key={typeId} {...{mealType, selectedMeal}}
                           handleSelectedMealChange={props.handleSelectedMealChange}
                           infoWindowOpen={props.infoWindowOpen}
              />)
          })}
        </SwipeableViews>
      </Grid>
      <Grid item xs>
        <Grid container justify="flex-end" spacing={8}>
          <Grid item><Button
            variant="contained"
            color="primary"
            disabled={(mealType === undefined || selectedMeal[typeId] === undefined) || selectedMeal[typeId] === mealType.mealNextId}
            onClick={props.submitBindMealAccount(typeId)}
          >
            Изменить
          </Button></Grid>
          <Grid item><Button
            variant="contained"
            color="primary"
            disabled={mealType && [ mealType.mealNextId].indexOf(mealType.mealDefaultId) >= 0 }
            onClick={props.submitUnbindMealAccount(typeId)}
          >
            По умолчанию
          </Button></Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

MealOrdersDetails.propTypes = {
  classes: PropTypes.object,
  mealTypes: PropTypes.object.isRequired,
  selectedMeal: PropTypes.object.isRequired,
  tabMealTypesIndex: PropTypes.number.isRequired,
  tabMealTypesClick: PropTypes.func.isRequired,
  tabMealTypesSwipe: PropTypes.func.isRequired,
  submitBindMealAccount: PropTypes.func.isRequired,
  submitUnbindMealAccount: PropTypes.func.isRequired,
  handleSelectedMealChange: PropTypes.func.isRequired,
  infoWindowOpen: PropTypes.func.isRequired,
}

const MealOrders = props => {
  const {
    classes,
    infoWindowIsOpen,
    infoText,
    infoWindowClose,
  } = props;

  const mealOrderDetails = MealOrdersDetails(props)

  return (
    <div className={classes.container}>
      <Hidden xsDown>
        <ExpansionPanel elevation={0} className={classes.expansion}>
          <ExpansionPanelSummary expandIcon={<ExpandMoreIcon/>} className={classes.expansionTitle}>
            <Typography variant="subtitle2">Выбрать комплекс питания</Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails className={classes.details}>
            {mealOrderDetails}
          </ExpansionPanelDetails>
        </ExpansionPanel>
      </Hidden>
      <Hidden smUp>
        {mealOrderDetails}
      </Hidden>
      <InfoWindow {...{infoWindowIsOpen, infoText, infoWindowClose}} />
    </div>
  )
}

MealOrders.propTypes = {
  classes: PropTypes.object,
  mealTypes: PropTypes.object.isRequired,
  selectedMeal: PropTypes.object.isRequired,
  tabMealTypesIndex: PropTypes.number.isRequired,
  tabMealTypesClick: PropTypes.func.isRequired,
  tabMealTypesSwipe: PropTypes.func.isRequired,
  submitBindMealAccount: PropTypes.func.isRequired,
  submitUnbindMealAccount: PropTypes.func.isRequired,
  handleSelectedMealChange: PropTypes.func.isRequired,
  infoWindowIsOpen: PropTypes.bool.isRequired,
  infoText: PropTypes.string,
  infoWindowOpen: PropTypes.func.isRequired,
  infoWindowClose: PropTypes.func.isRequired,
}

export default withStyles(styles)(MealOrders);
