import "react-app-polyfill/ie9";
import React from "react";
import {render} from "react-dom";
import {Provider} from "react-redux";
import {BrowserRouter as Router, Route, Switch, Redirect} from "react-router-dom";
import {MuiThemeProvider, createMuiTheme} from "@material-ui/core/styles";
import lightBlue from "@material-ui/core/colors/lightBlue";
import orange from "@material-ui/core/colors/orange";
import green from "@material-ui/core/colors/green";

import "react-dates/initialize";
import * as serviceWorker from "./serviceWorker";

import Home from "./components/home";
import Login from "./components/login";
import Registration from "./components/registration";
import Promo201912 from "./components/promo-2019-12";

import configureStore from "./store/configureStore";

import "./index.css";
import "react-dates/lib/css/_datepicker.css";
import "./react_dates_horizontal_overrides.css";
import KhalvaForm from "./components/khalva-form";

const store = configureStore();

class App extends React.Component {
  state = {
    mainTheme: {
      logoUrl: "/logo-ueshka-192.png",
      breakpoints: {
        values: {
          xs: 0,
          sm: 760,
          md: 960,
          lg: 1280,
          xl: 1920
        }
      },
      typography: {
        useNextVariants: true,
        fontSize: 16,
        h6: {
          color: "rgb(120, 120, 120)",
          fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
          fontWeight: 500,
          fontSize: "1.25rem",
          lineHeight: 1.6,
          letterSpacing: "0.0075em"
        }
      },
      palette: {
        primary: {
          main: lightBlue[300],
          dark: lightBlue[500]
        },
        secondary: {
          main: green[500]
        }
      }
    },
    cyberTheme: {
      logoUrl: "http://cyber-card.ru/images/logo.png",
      breakpoints: {
        values: {
          xs: 0,
          sm: 760,
          md: 960,
          lg: 1280,
          xl: 1920
        }
      },
      typography: {
        useNextVariants: true,
        fontSize: 16,
        h6: {
          color: "rgb(255, 255, 255)",
          fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
          fontWeight: 500,
          fontSize: "1.25rem",
          lineHeight: 1.6,
          letterSpacing: "0.0075em"
        }
      },
      palette: {
        primary: {
          main: orange[700],
          dark: orange[900]
        },
        secondary: {
          main: green[500]
        }
      }
    },
    theme: "main"
  };

  cyberIds = [7, 26];

  changeTheme = dilerId => {
    if (this.cyberIds.indexOf(dilerId) >= 0) {
      this.setState({theme: "cyber"});
    } else {
      this.setState({theme: "main"});
    }
  };

  changeThemeFaviconAndTitle = (dilerId, title) => {
    const favicon = document.querySelector("link[rel*='icon']");
    const manifest = document.querySelector("link[rel*='manifest']");

    if (
      (this.cyberIds.indexOf(dilerId) >= 0 && this.state.theme !== 'cyber') ||
      (this.cyberIds.indexOf(dilerId) < 0 && this.state.theme !== 'main')
    ) {
      this.changeTheme(dilerId);
    }
    document.title = title;

    if (this.cyberIds.indexOf(dilerId) >= 0) {
      favicon.href = "/favicon-cyber.ico";
      manifest.setAttribute("href", "/manifest-cyber.json");
    } else {
      favicon.href = "/favicon-ueshka.ico";
      manifest.setAttribute("href", "/manifest-ueshka.json");
    }
  }

  render() {
    return (
      <Router>
        <Provider store={store}>
          <MuiThemeProvider
            theme={
              this.state.theme === "cyber"
                ? createMuiTheme(this.state.cyberTheme)
                : createMuiTheme(this.state.mainTheme)
            }
          >
            <Switch>
              <Route exact path="/login" component={Login}/>
              <Route exact path="/logout" render={() => <Redirect to="/login"/>}/>
              <Route exact path="/recovery" component={Login}/>
              <Route exact path="/recovery-access" component={Login}/>
              <Route exact path="/ueshka/register" component={() => <Registration parentDilerIds={[6,8,20,27,45,46,50]}
                                                                                  theme={this.state.theme}
                                                                                  changeThemeFaviconAndTitle={this.changeThemeFaviconAndTitle}/>} />
              <Route exact path="/cyber-card/register" component={() => <Registration parentDilerIds={[7]}
                                                                                  theme={this.state.theme}
                                                                                  changeThemeFaviconAndTitle={this.changeThemeFaviconAndTitle}/>} />

              <Route exact path="/khalva/ueshka" component={props => <KhalvaForm {...props} parentDilerId={6}
                                                                              changeTheme={this.changeTheme}/> } />
              <Route exact path="/khalva/cybercard" component={props => <KhalvaForm {...props} parentDilerId={7}
                                                                                 changeTheme={this.changeTheme}/> } />

              {(Date.now() >= Date.parse('2019-11-23') && Date.now() < Date.parse('2020-01-01')) ? (
                <Route exact path="/promo-2019-12" component={() => <Promo201912 dilerId={6} theme={this.state.theme}
                                                                                 changeTheme={this.changeTheme}/>}/>) : null}
              {(Date.now() >= Date.parse('2019-11-23') && Date.now() < Date.parse('2020-01-01')) ? (
                <Route exact path="/cyber/promo-2019-12"
                       component={() => <Promo201912 dilerId={7} theme={this.state.theme}
                                                     changeTheme={this.changeTheme}/>}/>) : null}
              <Route
                path="/"
                render={props => (
                  <Home {...props} changeTheme={this.changeTheme} theme={this.state.theme}/>
                )}
              />
            </Switch>
          </MuiThemeProvider>
        </Provider>
      </Router>
    );
  }
}

render(<App/>, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.register();
