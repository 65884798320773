import Cookies from "universal-cookie";
import axios from "axios";
import {createAction} from "redux-actions";
import {API, handleApiError} from "api";
import {showInfoMessage} from "./infoMessageActions";

export const loginRequest = createAction("LOGIN_REQUEST");
export const loginSuccess = createAction("LOGIN_SUCCESS");
export const loginError = createAction("LOGIN_ERROR");
export const logoutSuccess = createAction("LOGOUT_SUCCESS");

export const advertisingRequest = createAction("ADVERTISING_REQUEST");
export const advertisingSuccess = createAction("ADVERTISING_SUCCESS");
export const advertisingError = createAction("ADVERTISING_ERROR");
export const advertisingClear = createAction("ADVERTISING_CLEAR");

export const newPasswordRequest = createAction("NEW_PASSWORD_REQUEST");
export const newPasswordSuccess = createAction("NEW_PASSWORD_SUCCESS");
export const newPasswordError = createAction("NEW_PASSWORD_ERROR");

export const newPhoneSendCodeRequest = createAction("NEW_PHONE_SEND_CODE_REQUEST");
export const newPhoneSendCodeSuccess = createAction("NEW_PHONE_SEND_CODE_SUCCESS");
export const newPhoneCheckCodeRequest = createAction("NEW_PHONE_CHECK_CODE_REQUEST");
export const newPhoneCheckCodeClear = createAction("NEW_PHONE_CHECK_CODE_CLEAR");
export const newPhoneSuccess = createAction("NEW_PHONE_SUCCESS");
export const newPhoneError = createAction("NEW_PHONE_ERROR");

export const newEmailRequest = createAction("NEW_EMAIL_REQUEST");
export const newEmailSuccess = createAction("NEW_EMAIL_SUCCESS");
export const newEmailError = createAction("NEW_EMAIL_ERROR");

export const pupilProfileRequest = createAction("PUPIL_PROFILE_REQUEST");
export const pupilProfileSuccess = createAction("PUPIL_PROFILE_SUCCESS");
export const pupilProfileError = createAction("PUPIL_PROFILE_ERROR");

export const loadAvatarRequest = createAction("GET_AVATAR_REQUEST");
export const loadAvatarSuccess = createAction("GET_AVATAR_SUCCESS");
export const loadAvatarError = createAction("GET_AVATAR_ERROR");

export const updatePupilDayLimit = createAction("UPDATE_PUPIL_DAY_LIMIT");

export const unlinkCardRequest = createAction("UNLINK_CARD_REQUEST");
export const unlinkCardSuccess = createAction("UNLINK_CARD_SUCCESS");
export const unlinkCardError = createAction("UNLINK_CARD_ERROR");

export const parentAutoPaymentOffRequest = createAction("PARENT_AUTOPAYMENT_OFF_REQUEST");
export const parentAutoPaymentOffSuccess = createAction("PARENT_AUTOPAYMENT_OFF_SUCCESS");
export const parentAutoPaymentOffError = createAction("PARENT_AUTOPAYMENT_OFF_ERROR");

export const addFoodDataOrderRequest = createAction("ADD_FOOD_DATA_ORDER_REQUEST");
export const addFoodDataOrderSuccess = createAction("ADD_FOOD_DATA_ORDER_SUCCESS");
export const addFoodDataOrderError = createAction("ADD_FOOD_DATA_ORDER_ERROR");

export const changeSchoolStart = createAction("CHANGE_SCHOOL");
export const changePupilStart = createAction("CHANGE_PUPIL");

export const newPhoneRecoverySendCodeRequest = createAction("NEW_PHONE_RECOVERY_SEND_CODE_REQUEST");
export const newPhoneRecoverySendCodeSuccess = createAction("NEW_PHONE_RECOVERY_SEND_CODE_SUCCESS");
export const newPhoneRecoveryCheckCodeRequest = createAction("NEW_PHONE_RECOVERY_CHECK_CODE_REQUEST");
export const newPhoneRecoveryCheckCodeClear = createAction("NEW_PHONE_RECOVERY_CHECK_CODE_CLEAR");
export const newPhoneRecoverySuccess = createAction("NEW_PHONE_RECOVERY_SUCCESS");
export const newPhoneRecoveryError = createAction("NEW_PHONE_RECOVERY_ERROR");

const cookies = new Cookies();

const removeCookies = () => {
  cookies.remove("token");
  axios.defaults.headers.common.Authorization = "";
};

const changeFaviconAndTitle = dilerId => {
  const favicon = document.querySelector("link[rel*='icon']");
  const manifest = document.querySelector("link[rel*='manifest']");

  if (dilerId === 7 || dilerId === 26) {
    document.title = "Личный кабинет Кибер-Карта";
    favicon.href = "/favicon-cyber.ico";
    manifest.setAttribute("href", "/manifest-cyber.json");
  } else {
    document.title = "Личный кабинет УЭШКа";
    favicon.href = "/favicon-ueshka.ico";
    manifest.setAttribute("href", "/manifest-ueshka.json");
  }
};

export const getAdvertising = (advertName) => async dispatch => {
  try {
    dispatch(advertisingRequest());
    let res = await axios.get(`${API}/advertising/${advertName}` +
      `?screenWidth=800` +
      `&screenHeight=600`, {
        responseType: 'arraybuffer'
      }
    );
    let advertImg = undefined;
    let advertLinks = undefined;
    if (res.status === 200) {
      advertImg = `data:jmage/png;base64,` + Buffer.from(res.data, 'binary').toString('base64')
      res = await axios.get(`${API}/advertising/${advertName}` +
        `?screenWidth=800` +
        `&screenHeight=600` +
        `&linkUrl`
      );
      if (res.status === 200) {
        if (res.data.hasOwnProperty('linkUrl') && typeof res.data.linkUrl === 'string') {
          advertLinks = res.data.linkUrl
        }
        if (res.data.hasOwnProperty('links') && typeof res.data.links === 'object' && res.data.links.length) {
          advertLinks = res.data.links
        }
      }
    }

    dispatch(advertisingSuccess({
      advertImg: advertImg,
      advertLinks: advertLinks,
    }));
  } catch (e) {
    // dispatch(advertisingError({
    //   error: e
    // }));
  }
}

export const clearAdvertising = () => dispatch => dispatch(advertisingClear())

export const signIn = (login, password) => async dispatch => {
  if (!login || !password) {
    return dispatch(
      loginError({
        error: "Введите логин и пароль",
        errorStatus: 400
      })
    );
  }
  try {
    dispatch(loginRequest());

    const res = await axios.post(`${API}/user/login`, {
      login,
      password
    });

    const {token, profile} = res.data;
    cookies.set("token", token, {path: "/"});
    cookies.set('SameSite', 'lax');

    changeFaviconAndTitle(profile[0].dilerId);

    axios.defaults.headers.common.Authorization = `Bearer ${token}`;
    axios.defaults.headers.common.Pragma = "no-cache";
    dispatch(getAdvertising('action'));

    return dispatch(
      loginSuccess({
        profile
      })
    );
  } catch (error) {
    return dispatch(handleApiError(error, loginError()));
  }
};

export const signOut = () => dispatch => {
  const favicon = document.querySelector("link[rel*='icon']");
  const manifest = document.querySelector("link[rel*='manifest']");
  document.title = "Личный кабинет";
  favicon.href = "/favicon.ico";
  manifest.removeAttribute("href");
  removeCookies();
  dispatch(logoutSuccess());
};

export const generateNewPassword = phone => async dispatch => {
  try {
    dispatch(newPasswordRequest());

    await axios.post(`${API}/user/reset-password`, {
      login: phone
    });

    dispatch(newPasswordSuccess());
    dispatch(showInfoMessage("Новый пароль успешно отправлен на указанный номер телефона"));
  } catch (error) {
    dispatch(handleApiError(error, newPasswordError()));
  }
};

export const getProfile = () => async dispatch => {
  try {
    dispatch(loginRequest());
    const token = cookies.get("token");
    axios.defaults.headers.common.Authorization = `Bearer ${token}`;
    dispatch(getAdvertising('action'));
    const res = await axios.get(`${API}/user/profile`);
    const profile = res.data;
    changeFaviconAndTitle(profile[0].dilerId);
    dispatch(
      loginSuccess({
        profile
      })
    );
  } catch (error) {
    dispatch(handleApiError(error, loginError()));
  }
};

export const getPupilProfile = pupilId => async dispatch => {
  try {
    dispatch(pupilProfileRequest());
    const res = await axios.get(`${API}/user/profile/${pupilId}`);
    const pupilProfile = res.data;
    dispatch(
      pupilProfileSuccess({
        pupilProfile,
        pupilId
      })
    );
  } catch (error) {
    dispatch(handleApiError(error, pupilProfileError()));
  }
};

export const getAvatar = (schoolId, accountId) => async dispatch => {
  try {
    dispatch(loadAvatarRequest({
      picture: '',
      schoolId,
      accountId
    }));
    const res = await axios.get(`${API}/user/avatar/${accountId}` +
      `?base64`
    );
    const picture = res.data.message;
    dispatch(
      loadAvatarSuccess({
        picture,
        schoolId,
        accountId
      })
    );
  } catch (error) {
    dispatch(handleApiError(error, loadAvatarError()));
  }
};

export const changeSchool = schoolId => dispatch => {
  dispatch(
    changeSchoolStart({
      selectedSchool: schoolId
    })
  );
};

export const changePupil = pupilId => dispatch => {
  dispatch(
    changePupilStart({
      selectedPupil: pupilId
    })
  );
};

export const changePassword = newPassword => async dispatch => {
  try {
    dispatch(newPasswordRequest());

    await axios.post(`${API}/user/change-password`, {
      password: newPassword
    });

    dispatch(newPasswordSuccess());
    dispatch(showInfoMessage("Пароль успешно изменен, произведите повторный вход с новым паролем"));
  } catch (error) {
    dispatch(handleApiError(error, newPasswordError()));
  }
};

export const newPhoneSendCode = (parentId, newPhone) => async dispatch => {
  try {
    dispatch(newPhoneSendCodeRequest());

    await axios.patch(`${API}/user/phone/${parentId}`, {
      phone: newPhone
    });

    dispatch(newPhoneSendCodeSuccess());
  } catch (error) {
    dispatch(handleApiError(error, newPhoneError()));
  }
};

export const newPhoneCheckCode = (parentId, code) => async dispatch => {
  try {
    dispatch(newPhoneCheckCodeRequest());

    await axios.post(`${API}/user/phone/${parentId}`, {
      code
    });

    dispatch(newPhoneSuccess());
    dispatch(
      showInfoMessage("Номер телефона успешно изменен, произведите повторный вход с новым логином")
    );
  } catch (error) {
    dispatch(handleApiError(error, newPhoneError()));
  }
};

export const resetCheckCode = () => dispatch => {
  dispatch(newPhoneCheckCodeClear());
};


export const newPhoneRecoverySendCode = ({oldPhone, docSeries, docNumber, newPhone}) => async dispatch => {
  try {
    dispatch(newPhoneRecoverySendCodeRequest());

    await axios.patch(`${API}/user/recovery-access/`, {
      oldPhone, docSeries, docNumber, newPhone
    });

    dispatch(newPhoneRecoverySendCodeSuccess());
  } catch (error) {
    if (error.response && error.response.status === 400) {
      // console.log(error.response.data.message);
      if (typeof error.response.data.message === 'string') {
        dispatch(handleApiError(error, newPhoneRecoveryError()));
      } else {
        dispatch(newPhoneRecoveryError({
          errors: error.response.data.message,
          error: "Проверьте правильность введенных данных",
        }))
      }
    } else {
      dispatch(handleApiError(error, newPhoneRecoveryError()));
    }
  }
};

export const newPhoneRecoveryCheckCode = ({oldPhone, docSeries, docNumber, code, password}) => async dispatch => {
  try {
    dispatch(newPhoneRecoveryCheckCodeRequest());

    await axios.post(`${API}/user/recovery-access/check`, {
      oldPhone, docSeries, docNumber, code, password
    });

    dispatch(newPhoneRecoverySuccess());
    dispatch(
      showInfoMessage("Номер телефона успешно изменен, произведите повторный вход с новым логином")
    );
  } catch (error) {
    if (error.response && error.response.status === 400) {
      if (typeof error.response.data.message === 'string') {
        dispatch(handleApiError(error, newPhoneRecoveryError()));
      } else {
        dispatch(newPhoneRecoveryError({
          errors: error.response.data.message,
          error: "Проверьте правильность введенных данных",
        }))
      }
    } else {
      dispatch(handleApiError(error, newPhoneRecoveryError()));
    }
  }
};

export const resetRecoveryCheckCode = () => dispatch => {
  dispatch(newPhoneRecoveryCheckCodeClear());
};

export const changeEmail = (parentId, newEmail) => async dispatch => {
  try {
    dispatch(newEmailRequest());

    await axios.patch(`${API}/user/email/${parentId}`, {
      email: newEmail
    });

    dispatch(newEmailSuccess());
    dispatch(showInfoMessage("На указанный адрес отправлено письмо для подтверждения"));
  } catch (error) {
    dispatch(handleApiError(error, newEmailError()));
  }
};

export const updateUserDayLimit = dayLimit => dispatch => {
  dispatch(
    updatePupilDayLimit({
      dayLimit
    })
  );
};

export const unlinkCard = accountId => async dispatch => {
  try {
    dispatch(unlinkCardRequest());
    const res = await axios.post(`${API}/user/unlink-card/${accountId}`);

    dispatch(unlinkCardSuccess());
    dispatch(showInfoMessage(res.data.message));
  } catch (error) {
    dispatch(handleApiError(error, unlinkCardError()));
  }
}

export const parentAutoPaymentOff = (parentId, serviceId) => async dispatch => {
  try {
    dispatch(parentAutoPaymentOffRequest());
    const res = await axios.post(`${API}/pays/auto-payment-off/${parentId}?typeId=${serviceId}`);

    dispatch(parentAutoPaymentOffSuccess());
    dispatch(showInfoMessage(res.data.message));
    dispatch(getProfile());
  } catch (error) {
    dispatch(handleApiError(error, parentAutoPaymentOffError()));
  }
}

export const addFoodDataOrder = (pupilId, foodDataId) => async dispatch => {
  try {
    dispatch(addFoodDataOrderRequest());
    const reqBody = {pupilId, foodDataId};
    const res = await axios.post(`${API}/user/food-data-order`, reqBody)
    dispatch(addFoodDataOrderSuccess());
    dispatch(showInfoMessage(res.data.message));
    dispatch(getProfile());
  } catch (error) {
    dispatch(handleApiError(error, addFoodDataOrderError()));
  }
}
