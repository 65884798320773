import React, {Component} from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import moment from "moment";

import {withStyles} from "@material-ui/core/styles";

import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";

import ParentPhone from "./ParentPhone";
import ParentFio from "./ParentFio";
import ParentBirthDate from "./ParentBirthDate";
import ParentDocs from "./ParentDocs";

import {getDocTypes} from "actions/registrationActions";
import {getDocTypesSelector} from "selectors/registration";

const styles = theme => ({
  buttons: {
    display: "flex",
    flexDirection: "column",
    marginTop: 15
  },
  nextButton: {
    flexGrow: 1
  }
});

class Step2 extends Component {

  componentDidMount() {
    this.props.getDocTypes();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.docTypes.length === 0 && this.props.docTypes.length > 0) {
      this.props.setFieldValue("parentDocTypeId", 1);
    }
  }

  handleDateChange = id => date => {
    this.props.setFieldValue(id, moment(date).format("YYYY-MM-DD"));
  };

  handleCheckPhone = phone => {
    const matches = /^(?:8|\+7|7)?(9[0-9]{9})$/ism.exec(phone);
    if (matches && matches[1]) {
      this.props.checkPhone(matches[1]);
    }
  }

  isParentDataIncomplete = values => {
    const {
      isNewPhone, isNewPhoneChecked
    } = this.props;

    let phone = values.phone
    const matches = /^(?:8|\+7|7)?(9[0-9]{9})$/ism.exec(phone);
    if (matches && matches[1]) {
      phone = matches[1];
    }

    if (
      !((phone === isNewPhoneChecked && isNewPhone) || (!!values.parentId && !!phone)) ||
      !values.fam ||
      !values.im ||
      // !values.otch ||
      !values.birthPlace ||
      !values.birthDay ||
      !values.parentDocSeries ||
      !values.parentDocNumber ||
      !values.parentDocDate ||
      !values.parentDocIssuer
    ) {
      return true;
    } else return false;
  };

  render() {
    const {
      classes,
      values,
      handleChange,
      setFieldValue,
      docTypes,
      changeStep,
      errors,
      handleEnter,
      isNewPhone, isNewPhoneChecked
    } = this.props;
    return (
      <>
        <ParentPhone {...{values, errors, isNewPhone, isNewPhoneChecked}} onKeyUp={handleEnter}
                     handleChange={(e)=>{
                       this.handleCheckPhone(e.target.value);
                       handleChange(e);
                     }}
        />
        <ParentFio {...{values, handleChange, errors}} onKeyUp={handleEnter}/>
        <ParentBirthDate
          {...{values, handleChange, handleDateChange: this.handleDateChange, errors}}
          onKeyUp={handleEnter}
        />
        <ParentDocs
          {...{
            values,
            handleChange,
            setFieldValue,
            handleDateChange: this.handleDateChange,
            docTypes,
            errors
          }}
          onKeyUp={handleEnter}
        />
        <div className={classes.buttons}>
          {this.isParentDataIncomplete(values) && (
            <Typography variant="body2" color="error">
              Для продолжения заполните все поля, отмеченные звездочкой
            </Typography>
          )}
          <Button
            className={classes.nextButton}
            disabled={this.isParentDataIncomplete(values)}
            variant="outlined"
            color="secondary"
            size="large"
            onClick={e => changeStep(2)}
          >
            Далее
          </Button>
        </div>
      </>
    );
  }
}

Step2.propTypes = {
  classes: PropTypes.object,
  changeStep: PropTypes.func.isRequired,
  checkPhone: PropTypes.func.isRequired,
  getDocTypes: PropTypes.func.isRequired,
  docTypes: PropTypes.array,
  setFieldValue: PropTypes.func.isRequired,
  isNewPhone: PropTypes.bool,
  isNewPhoneChecked: PropTypes.string,
  values: PropTypes.object,
  handleChange: PropTypes.func.isRequired,
  errors: PropTypes.object
};

const mapStateToProps = state => ({
  docTypes: getDocTypesSelector(state),
  errors: state.registration.errors
});

const mapDispatchToProps = {
  getDocTypes
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(Step2));
