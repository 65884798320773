import React, { PureComponent } from "react";
import { withStyles } from "@material-ui/core/styles";
import Hidden from "@material-ui/core/Hidden";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";

import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";

import SchoolSelect from "components/SchoolSelect";

const styles = theme => ({
  drawerPaper: {
    width: 250,
    height: "100%",
    background: "white",
    [theme.breakpoints.up("md")]: {
      position: "relative",
      height: "100%",
      width: 210
    }
  },
  menuItem: {
    padding: "10px",
    color: "#CCC",
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
      color: "white"
    },
    "&:focus": {
      backgroundColor: theme.palette.primary.main,
      color: "white"
    }
  },
  menuItemSelected: {
    backgroundColor: theme.palette.primary.main + " !important",
    color: "white",
    fontWeight: 600
  },
  icon: {
    color: "inherit"
  },
  menuCard: {
    marginBottom: 5
  },
  menuTop: {
    backgroundColor: theme.palette.primary.main,

    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between"
  }
});

class MobileMenu extends PureComponent {
  render() {
    const { classes } = this.props;
    return (
      <>
        <Hidden mdUp>
          <SwipeableDrawer
            variant="temporary"
            swipeAreaWidth={20}
            open={this.props.open}
            onClose={this.props.toggle}
            onOpen={this.props.toggle}
            anchor="left"
            disableDiscovery
            classes={{
              paper: classes.drawerPaper
            }}
            ModalProps={{
              disablePortal: true,
              keepMounted: true // улучшает производительность на телефонах
            }}
          >
            <Card className={classes.menuCard}>
              <CardContent className={classes.menuTop}>
                <SchoolSelect closeMobileMenu={this.props.toggle} />
              </CardContent>
            </Card>
            {this.props.menuItems()}
          </SwipeableDrawer>
        </Hidden>
      </>
    );
  }
}

export default withStyles(styles)(MobileMenu);
