import React from "react";

import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";

import { withStyles } from "@material-ui/core/styles";
const styles = theme => ({
  formControl: {
    minWidth: 200,
    width: 200,
    margin: "10px 10px 10px 0"
  }
});

const PupilSelect = props => {
  const { classes, pupils, selectPupil, selectedPupil } = props;
  return (
    <FormControl variant="outlined" className={classes.formControl}>
      <InputLabel>Выберите ученика</InputLabel>
      <Select
        value={selectedPupil ? selectedPupil : "Выберите ученика"}
        onChange={selectPupil}
        input={<OutlinedInput labelWidth={150} name="pupil" id="pupil" />}
      >
        {pupils.ids.map(pupildId => (
          <MenuItem key={pupildId} value={pupildId}>
            {pupils[pupildId].im} {pupils[pupildId].className}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default withStyles(styles)(PupilSelect);
