import React from "react";

import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Input from "@material-ui/core/Input";
import InputAdornment from "@material-ui/core/InputAdornment";

import { withStyles } from "@material-ui/core/styles";

const styles = theme => ({
  amount: {
    paddingBottom: 5
  },
  input: {
    fontSize: 25
  }
});

const PaperInvoiceAmount = props => {
  const {
    classes,
    paperInvoice,
    paperInvoiceMethods,
    errorOnValidateAmount
  } = props;
  return (
    <div>
      <FormControl required className={classes.amount}>
        <InputLabel htmlFor="amount">Сумма квитанции</InputLabel>
        <Input
          className={classes.input}
          type="number"
          value={paperInvoice.amount || ""}
          error={errorOnValidateAmount(paperInvoice.amount)}
          onChange={paperInvoiceMethods.setAmount}
          autoFocus
          endAdornment={<InputAdornment position="end">руб.</InputAdornment>}
        />
      </FormControl>
    </div>
  );
};

export default withStyles(styles)(PaperInvoiceAmount);
