import React from "react";
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Checkbox from "@material-ui/core/Checkbox";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import IconButton from "@material-ui/core/IconButton";
import HelpOutline from "@material-ui/icons/HelpOutline";

import { withStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import InfoWindow from "../../InfoWindow.jsx";

const styles = () => ({
  listItem: {
    padding: 0
  },
  deliveryNameContainer: {
    display: "flex",
    flexDirection: "column"
  },
  deliveryName: {
    textTransform: "capitalize"
  },
  checkbox: {
    paddingRight: 0,
    paddingLeft: 0
  },
  buttons: {
    position: "relative",
    display: "flex",
    justifyContent: "flex-start",
    marginTop: 15,
    marginBottom: 15
  },
  nextButton: {
    flexGrow: 1,
    margin: "0 5px"
  }
});

const Step2 = (props) => {
  const {
    classes,
    deliveryTypes,
    selectedDeliveries,
    infoWindowIsOpen,
    infoText,
    infoWindowOpen,
    infoWindowClose,
    selectDelivery,
    prevStep,
    nextStep
  } = props;

  const styleMessenger = (item) => {
    if (item.name === "messenger") {
      return (item.dest.toLowerCase().indexOf('настроен') !== -1) ? {
        color: 'red',
        fontWeight: 'bold'
      } : null
    }
  }

  const deliveryName = (item) => {
    if (item.name === "messenger") {
      return (
        <>
          <span>Мессенджеры</span>
          <Typography style={styleMessenger(item)} variant="caption">{item.dest}</Typography>
        </>
      );
    }
    if (item.dest) {
      return (
        <>
          <span className={classes.deliveryName}>{item.name}</span>
          <Typography variant="caption">{item.dest}</Typography>
        </>
      );
    }
    return <span className={classes.deliveryName}>{item.name}</span>;
  };

  return (
    <>
      <List>
        {deliveryTypes
          .sort((a, b) => {
            if (a.name > b.name) {
              return 1;
            }
            if (a.name < b.name) {
              return -1;
            }
            return 0;
          })
          .map((item, index) => (
            <ListItem
              key={index}
              button
              className={classes.listItem}
              name={item.name}
              onClick={selectDelivery(item)}
            >
              <Checkbox
                className={classes.checkbox}
                tabIndex={-1}
                disableRipple
                checked={
                  !!(
                    selectedDeliveries.length > 0
                    && selectedDeliveries.filter(delivery => delivery.name === item.name).length !== 0
                  )
                }
              />
              <ListItemText>
                <div className={classes.deliveryNameContainer}>{deliveryName(item)}</div>
              </ListItemText>
              <ListItemSecondaryAction>
                <IconButton style={styleMessenger(item)} onClick={() => infoWindowOpen(item.description)}>
                  <HelpOutline />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          ))}
      </List>
      <Divider />
      <div className={classes.buttons}>
        <Button variant="outlined" size="small" onClick={() => prevStep()}>
          Назад
        </Button>
        <Button
          className={classes.nextButton}
          variant="outlined"
          color="secondary"
          size="large"
          onClick={() => nextStep()}
        >
          Далее
        </Button>
      </div>
      <InfoWindow {...{ infoWindowIsOpen, infoText, infoWindowClose }} />
    </>
  );
};

Step2.propTypes = {
  classes: PropTypes.object,
  deliveryTypes: PropTypes.array,
  selectedDeliveries: PropTypes.array,
  infoWindowIsOpen: PropTypes.bool.isRequired,
  infoText: PropTypes.string,
  infoWindowOpen: PropTypes.func.isRequired,
  infoWindowClose: PropTypes.func.isRequired,
  selectDelivery: PropTypes.func.isRequired,
  prevStep: PropTypes.func.isRequired,
  nextStep: PropTypes.func.isRequired
};

export default withStyles(styles)(Step2);
