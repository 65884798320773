import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import LoadingMessage from "components/messages/LoadingMessage";
import AppBarWithTabs from "components/AppBarWithTabs";
import SwipeableViews from "react-swipeable-views";
import Hidden from "@material-ui/core/Hidden";
import Typography from "@material-ui/core/Typography";

import AttendanceCalendar from "./AttendanceCalendar";
import AttendanceDetails from "./AttendanceDetails";

import withStyles from "@material-ui/core/styles/withStyles";
import moment from "moment";
import "moment/locale/ru";

import * as attendanceActions from "actions/attendanceActions";

const mapStateToProps = state => {
  return {
    user: state.user,
    attendance: state.attendance
  };
};

const mapDispatchToProps = dispatch => {
  return {
    attendanceActions: bindActionCreators(attendanceActions, dispatch)
  };
};

const styles = theme => ({
  container: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    height: "100%",
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column"
    }
  },
  swipeViews: {
    height: "100%"
  },
  calendarContainer: {
    width: "55%",
    position: "relative",
    [theme.breakpoints.down("sm")]: {
      padding: 0,
      marginTop: 10
    },
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      display: "flex",
      flexDirection: "column"
    }
  },
  detailsContainer: {
    width: "45%",
    height: "calc(100% - 50px)",
    overflow: "auto",
    padding: 10,
    [theme.breakpoints.down("sm")]: {
      marginTop: 10
    },
    [theme.breakpoints.down("xs")]: {
      width: "90%",
      maxWidth: 450,
      margin: "0 auto"
    }
  }
});

class Attendance extends Component {
  state = {
    tabIndex: 0,
    selectedMonth: moment(),
    selectedDate: moment()
  };

  componentDidMount() {
    const selectedSchool = this.props.user.selectedSchool;
    const profile = this.props.user.schools[selectedSchool];
    const isAttendanceActive = profile.menu.filter(item => item.id === 2).length !== 0 ? true : false;
    const selectedPupil = this.props.user.selectedPupil;
    if (isAttendanceActive) {
      this.props.attendanceActions.fetchMonthlyAttendance(
        selectedPupil,
        this.getFirstDayOfMonth(this.state.selectedMonth),
        this.getLastDayOfMonth(this.state.selectedMonth)
      );
      this.props.attendanceActions.fetchDailyAttendance(
        selectedPupil,
        this.state.selectedDate.format("YYYY-MM-DD"),
        this.state.selectedDate.format("YYYY-MM-DD")
      );
    }
  }

  componentDidUpdate(prevProps) {
    //после переключения ученика обновляем календарь и детализацию
    if (prevProps.user.selectedPupil !== this.props.user.selectedPupil) {
      //после переключения школы принудительно переходим на вкладку родителя
      if (prevProps.user.selectedSchool !== this.props.user.selectedSchool) {
        this.setState({ tabIndex: 0 });
      }
      this.props.attendanceActions.fetchMonthlyAttendance(
        this.props.user.selectedPupil,
        this.getFirstDayOfMonth(this.state.selectedMonth),
        this.getLastDayOfMonth(this.state.selectedMonth)
      );
      this.props.attendanceActions.fetchDailyAttendance(
        this.props.user.selectedPupil,
        this.state.selectedDate.format("YYYY-MM-DD"),
        this.state.selectedDate.format("YYYY-MM-DD")
      );
    }
  }

  getFirstDayOfMonth = date => {
    return moment(date).format("YYYY-MM-01");
  };

  getLastDayOfMonth = date => {
    return moment(date).format("YYYY-MM-") + moment(date).daysInMonth();
  };

  tabs = {
    click: (event, value) => {
      this.setState({ tabIndex: value });
    },
    swipe: index => {
      this.setState({ tabIndex: index });
    }
  };

  setDate = day => {
    const selectedPupil = this.props.user.selectedPupil;
    this.setState({ selectedDate: day, tabIndex: 1 });
    this.props.attendanceActions.fetchDailyAttendance(
      selectedPupil,
      day.format("YYYY-MM-DD"),
      day.format("YYYY-MM-DD")
    );
  };

  setMonth = date => {
    const selectedPupil = this.props.user.selectedPupil;
    this.setState({ selectedMonth: date });
    this.props.attendanceActions.fetchMonthlyAttendance(
      selectedPupil,
      this.getFirstDayOfMonth(date),
      this.getLastDayOfMonth(date)
    );
  };

  render() {
    const { classes } = this.props;
    const { month, day } = this.props.attendance;
    const { tabIndex, selectedDate, selectedMonth } = this.state;
    const { schools, selectedSchool } = this.props.user;
    const isAttendanceActive = schools[selectedSchool].menu.filter(item => item.id === 2).length !== 0
      ? true
      : false;
    if (!isAttendanceActive) {
      return (
        <Typography variant="h6" align="center">
          Модуль контроля посещаемости в школе отсутствует
        </Typography>
      );
    }
    if (!month || !day) {
      return <LoadingMessage />;
    } else {
      return (
        <>
          <LoadingMessage />
          <Hidden smUp>
            <div className={classes.container}>
              <AppBarWithTabs
                tabs={["Календарь", "Детализация"]}
                tabIndex={tabIndex}
                tabClick={this.tabs.click}
              />
              <SwipeableViews
                className={classes.swipeViews}
                index={tabIndex}
                onChangeIndex={this.tabs.swipe}
                animateHeight
                threshold={10}
                springConfig={{
                  easeFunction: "ease-in",
                  delay: "0s",
                  duration: "0.3s"
                }}
              >
                <div className={classes.calendarContainer}>
                  <AttendanceCalendar
                    setDate={this.setDate}
                    setMonth={this.setMonth}
                    attendanceMonthly={month}
                    selectedDate={selectedDate}
                    selectedMonth={selectedMonth}
                  />
                </div>

                <div className={classes.detailsContainer}>
                  <AttendanceDetails attendanceDaily={day} selectedDate={selectedDate} />
                </div>
              </SwipeableViews>
            </div>
          </Hidden>
          <Hidden xsDown>
            <div className={classes.container}>
              <div className={classes.calendarContainer}>
                <AttendanceCalendar
                  setDate={this.setDate}
                  setMonth={this.setMonth}
                  attendanceMonthly={month}
                  selectedDate={selectedDate}
                  selectedMonth={selectedMonth}
                />
              </div>
              <div className={classes.detailsContainer}>
                <AttendanceDetails attendanceDaily={day} selectedDate={selectedDate} />
              </div>
            </div>
          </Hidden>
        </>
      );
    }
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(Attendance));
