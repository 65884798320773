import React from "react";
import Typography from "@material-ui/core/Typography";

import withStyles from "@material-ui/core/styles/withStyles";

const styles = theme => ({
  textBlock: {
    paddingRight: 5
  }
});

const NoZonesText = props => {
  const { classes } = props;
  return (
    <div className={classes.textBlock}>
      <Typography color="inherit" align="justify" paragraph>
        У Вас еще нет добавленных gps-зон. Для добавления нового объекта нажмите на иконку плюс,
        расположенную выше.
      </Typography>
      <Typography color="inherit" align="justify" paragraph>
        Нажатием на карте указываются точки координат, между которыми будет нарисована зона
        мониторинга. Укажите минимум 3 точки координат, затем введите название нового объекта внизу
        и нажмите кнопку "Добавить".
      </Typography>
      <Typography color="inherit" align="justify" paragraph>
        При активной зоне мониторинга, при любых ее пересечениях будет отправлено уведомление.
      </Typography>
      <Typography color="inherit" align="justify" paragraph>
        После добавления зоны-мониторинга Вы можете ее удалить, нажав на красный крестик, а также
        активировать или деактивировать, нажав на иконку в виде блока (иконки расположены справа от
        названия зоны мониторинга). При деактивированной зоне пересечения через нее не учитываются.
      </Typography>
    </div>
  );
};

export default withStyles(styles)(NoZonesText);
