import React, {Component} from "react";
import {connect} from "react-redux";
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import OutlinedInput from "@material-ui/core/OutlinedInput";

import ParentPhone from "./ParentPhone";

import Typography from "@material-ui/core/Typography";
import FormControl from "@material-ui/core/FormControl";

import {withStyles} from "@material-ui/core/styles";

const styles = theme => ({
  buttons: {
    display: "flex",
    flexDirection: "column",
    marginTop: 15
  },
  nextButton: {
    maxWidth: 220,
    flexGrow: 1
  },
  listItem: {
    listStyle: "none",
    backgroundImage: "url(\"/marker-check.gif\")",
    backgroundSize: "14pt",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "left center",
    padding: "6pt 0 6pt 20pt",
    margin: "0"
  },
  deliveryInfoMethod: {
    border: "1px solid rgba(0, 0, 0, 0.2)",
    padding: "10px",
    marginLeft: "0px",
    display: "inline-block",
  },
  notes: {
    marginTop: "20px",
    color: "rgba(0, 0, 0, 0.45)",
    fontSize: "9pt",
  },
  formControl: {
    width: 200,
    minWidth: 150,
    margin: "8px 10px 4px 0"
  },
  input: {
    padding: "10px 15px"
  }
});

const checkPhone = phone => {
  phone = phone.replace(/[ ()-]/gi, '');
  return /^(?:8|\+7|7)?(9[0-9]{9})$/ism.test(phone)
}

class Step1 extends Component {

  componentDidUpdate(prevProps, prevState) {
    const {values: oldValues} = prevProps;
    const {values, getPupilList, clearPupilList, pupils} = this.props;
    if (oldValues.phone !== values.phone && typeof values.phone === 'string' && checkPhone(values.phone)) {
      getPupilList(values)
    }
    if (!checkPhone(values.phone) && pupils && pupils.length > 0) {
      clearPupilList()
    }
  }

  handleInputChange = (id) => event => {
    const { setFieldValue} = this.props;
    setFieldValue(id, event.target.value);
  }

  handleSelectChange = (id) => event => {
    const {setFieldValue} = this.props;
    setFieldValue(id, event.target.value);
  }

  isParentDataIncomplete = values => {
    if (
      !values.phone
      || values.pupilId < 0
    ) {
      return true;
    } else return false;
  };

  render() {
    const {
      classes, dilerId,
      values,
      handleChange,
      errors,
      pupils
    } = this.props;
    const startPromo = Date.UTC(2019, 10, 25, 3);
    const endPromo = Date.UTC(2020, 0, 1, 3);
    const now = Date.now();
    return (
      <>
        <div style={{fontSize: "14pt"}}>
          <ul>
            <li className={classes.listItem}>Получай информацию о местонахождении своего ребенка</li>
            <li className={classes.listItem}>Будь в курсе событий в школе</li>
            <li className={classes.listItem}>Актуальный баланс на счету</li>
          </ul>
          <div><p>Получай информацию через</p>
            <div className={classes.deliveryInfoMethod}>
              <ul style={{margin: "0px"}}>
                <li>Мобильное приложение «{(dilerId === 6) ? "УЭШКА" : "Кибер-Карта"}» для смартфонов с OS Android и
                  iOS
                </li>
                <li>Социальную сеть ВКонтакте</li>
                <li>Месенджеры Viber, Telegram</li>
              </ul>
            </div>
            <p>Все за 300* рублей на {(dilerId === 6) ? "4" : "3"} месяца для всей семьи</p>
          </div>
        </div>
        <>
          <ParentPhone {...{values, handleChange, errors, checkPhone}} />
          {((pupils !== null && pupils.length > 0) && (
            <>
            <InputLabel>Выберете обучающегося, которому будет предоставлен указанный в акции тариф *</InputLabel><br/>
              <FormControl variant="outlined" className={classes.formControl}>
                <Select
                  value={(values.pupilId !== null) ? values.pupilId : -1}
                  onChange={this.handleSelectChange("pupilId")}
                  input={<OutlinedInput labelWidth={0} name="pupilId" id="pupilId"/>}
                  inputProps={{
                    classes: {select: classes.input}
                  }}
                >{pupils.map(pupil => (
                  <MenuItem key={pupil.pupilId} value={pupil.pupilId}>
                    {pupil.fio}
                  </MenuItem>
                ))}
                </Select>
              </FormControl>
            </>
          )) || ((values.phone && checkPhone(values.phone)) && (
              <Typography variant="body2" color="error">
                Для данной учетной записи нет обучающихся, подходящих под условия акции
              </Typography>
            )
          )}
          <div className={classes.buttons}>
            {this.isParentDataIncomplete(values) && (
              <Typography variant="body2" color="error">
                Для продолжения заполните все поля, отмеченные звездочкой
              </Typography>
            )}
            <Button
              className={classes.nextButton}
              disabled={this.isParentDataIncomplete(values) || (now < startPromo || now > endPromo)}
              variant="outlined"
              color="secondary"
              size="large"
              type="submit"
            >
              Перейти к оплате
            </Button>
            {(now < startPromo) && (
              <Typography variant="body2" color="error">
                Акция еще не началась
              </Typography>
            )}
            {(now > endPromo) && (
              <Typography variant="body2" color="error">
                Акция завершена
              </Typography>
            )}
          </div>
        </>
        <div className={classes.notes}>
          <p>* При оплате может взиматься комиссия</p>
          <p>Срок акции "SMART за 300": с 25.11.2019 по 31.12.2019 (мск). Информация об организаторе Акции, правилах её
            проведения, получаемой скидке, порядке ее получения - на <a
              href={(dilerId === 6)
                ? "https://ueshka.ru/ob-ueshke/aktsii-i-konkursy/smart-za-300"
                : "http://cyber-card.ru/roditelyam/aktsii-konkursy/aktsii-i-konkursy"}
              target={"_blank"}>{(dilerId === 6) ? "ueshka.ru" : "cyber-card.ru"}</a></p>
        </div>
      </>
    );
  }
}

const mapStateToProps = state => ({
  pupils: state.promo.pupils,
});

Step1.propTypes = {
  classes: PropTypes.object,
  changeStep: PropTypes.func.isRequired
};

export default connect(
  mapStateToProps
)(withStyles(styles)(Step1));
