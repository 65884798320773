import React from "react";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Radio from "@material-ui/core/Radio";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";

import { withStyles } from "@material-ui/core/styles";

const styles = theme => ({
  list: {
    padding: 0
  },
  pupilBlock: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center"
  },
  radio: {
    paddingRight: 0,
    paddingLeft: 0
  },
  buttons: {
    position: "relative",
    display: "flex",
    justifyContent: "flex-start",
    marginTop: 15
  },
  nextButton: {
    flexGrow: 1,
    margin: "0 5px"
  }
});

const Step2 = props => {
  const { classes, pupil, pupils, payType, selectPupilPay, changeStep, profile } = props;
  return (
    <>
      <List component="div" disablePadding>
        {((payType.id === 0 ) &&(profile.parent.foodModuleActive || Number(profile.parent.foodBalance) < 0)) ? (
          <React.Fragment key={profile.parent.id}>
            <ListItem
              className={classes.list}
              button
              onClick={e =>
                selectPupilPay({
                  id: profile.parent.id,
                  name: profile.parent.fam.slice(0,1) +". " + profile.parent.im
                })
              }
            >
              <Radio
                className={classes.radio}
                checked={pupil && pupil.id === profile.parent.id ? true : false}
              />
              <ListItemText>
                <div className={classes.pupilBlock}>
                  <Typography variant="subtitle1">
                    {profile.parent.fam.slice(0,1) +". " + profile.parent.im}
                  </Typography>
                  <Typography variant="body2">
                    баланс: {profile.parent.foodBalance} руб.
                  </Typography>
                </div>
              </ListItemText>
            </ListItem>
            <Divider />
          </React.Fragment>
        ): null}
        {pupils.ids
          .map(pupilId => {
            if ((payType.id === 0 && pupils[pupilId].foodModuleActive) ||
              (payType.id === 1 )) {
              return (
                <React.Fragment key={pupilId}>
                  <ListItem
                    className={classes.list}
                    button
                    onClick={e =>
                      selectPupilPay({
                        id: pupilId,
                        name: pupils[pupilId].im + " " + pupils[pupilId].className
                      })
                    }
                  >
                    <Radio
                      className={classes.radio}
                      checked={pupil && pupil.id === pupilId ? true : false}
                    />
                    <ListItemText>
                      <div className={classes.pupilBlock}>
                        <Typography variant="subtitle1">
                          {pupils[pupilId].im} {pupils[pupilId].className}
                        </Typography>
                        {(payType.id === 0) ? (<Typography variant="body2">
                        баланс: {pupils[pupilId].foodBalance} руб.
                        </Typography>) : null}
                      </div>
                    </ListItemText>
                  </ListItem>
                  <Divider />
                </React.Fragment>
              );
            } else return null;
          })}
      </List>
      <Divider />
      <div className={classes.buttons}>
        <Button variant="outlined" size="small" onClick={e => changeStep("backward")}>
          Назад
        </Button>
        <Button
          className={classes.nextButton}
          disabled={!pupil || !pupil.id}
          variant="outlined"
          color="secondary"
          size="large"
          onClick={e => changeStep("forward")}
        >
          Далее
        </Button>
      </div>
    </>
  );
};

export default withStyles(styles)(Step2);
