import React from "react";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import InputAdornment from "@material-ui/core/InputAdornment";
import TextField from "@material-ui/core/TextField";

import withStyles from "@material-ui/core/styles/withStyles";
import PropTypes from "prop-types";

const styles = () => ({
  listInput: {
    flexDirection: "column",
    alignItems: "flex-start"
  },
  fullWidth: {
    width: "100%"
  }
});

const SlavePhoneSettings = ({
  classes,
  settings,
  settingsDescription,
  handleInputChange,
  errorOnPhoneValidate
}) => (
  <ListItem disableGutters className={classes.listInput}>
    <ListItemText primary={settingsDescription.slave.title} />
    <TextField
      className={classes.fullWidth}
      value={settings.slave}
      onChange={handleInputChange("slave")}
      margin="none"
      type="number"
      error={errorOnPhoneValidate(settings.slave)}
      InputProps={{
        startAdornment: <InputAdornment position="start">+7</InputAdornment>
      }}
    />
  </ListItem>
);

SlavePhoneSettings.propTypes = {
  settings: PropTypes.object,
  settingsDescription: PropTypes.object,
  handleInputChange: PropTypes.func.isRequired,
  classes: PropTypes.object,
  errorOnPhoneValidate: PropTypes.func.isRequired
};

export default withStyles(styles)(SlavePhoneSettings);
