import React from "react";
import InputField from "../../../../InputField";
import {Grid} from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import MenuItem from "@material-ui/core/MenuItem";
import {withStyles} from "@material-ui/core/styles";

const styles = theme => ({
  formControl: {
    minWidth: 200,
    width: 200,
    margin: "10px 10px 10px 0"
  }
});

const PupilBirthday = props => {
  const {classes, insurances, selectedInsurance, validateError, pupil, pupilData, changePupilData, changePupilBirthday} = props;
  return (
    <Grid container spacing={8} alignItems="flex-end">
      {(!!insurances[selectedInsurance] && insurances[selectedInsurance]["orgId"] === 2) && (
        <FormControl variant="outlined" className={classes.formControl}>
          <InputLabel error={validateError.notEmpty("accountGender", pupil, pupilData)}>Пол застрахованного *</InputLabel>
          <Select
            value={pupil.accountGender ? pupil.accountGender : "Выберите пол"}
            onChange={changePupilData("accountGender")}
            input={<OutlinedInput labelWidth={195} name="pupil" id="accountGender"/>}
            error={validateError.notEmpty("accountGender", pupil, pupilData)}
          >
            {[{label: "Мужской", value: "M"}, {label: "Женский", value: "F"}].map(gender => (
              <MenuItem key={`account-gender-${gender.value}`} value={gender.value}>
                {gender.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}
      <InputField
        label="Дата рождения *"
        datePicker
        error={validateError.notEmpty("accountBirthDate", pupil, pupilData)}
        value={
          pupil && pupil.accountBirthDate
            ? pupil.accountBirthDate
            : pupilData.accountBirthDate
        }
        onChange={changePupilBirthday}
      />
    </Grid>
  );
};

export default withStyles(styles)(PupilBirthday);
