import React from "react";
import moment from "moment";

import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

import withStyles from "@material-ui/core/styles/withStyles";

const styles = theme => ({
  container: {
    display: "flex",
    justifyContent: "center"
  },
  input: {
    fontSize: 24,
    paddingRight: 20,
    [theme.breakpoints.down("xs")]: {
      fontSize: 20
    }
  }
});

const MonthCaption = props => {
  const { classes, month, selectedMonth, setMonth, onMonthSelect, onYearSelect } = props;
  return (
    <div className={classes.container}>
      <FormControl className={classes.form}>
        <Select
          classes={{ select: classes.input }}
          disableUnderline
          value={selectedMonth.month()}
          onChange={e => {
            setMonth(selectedMonth.month(e.target.value));
            onMonthSelect(month, e.target.value);
          }}
        >
          {moment.months().map((label, value) => (
            <MenuItem key={value} value={value}>
              {label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl className={classes.form}>
        <Select
          classes={{ select: classes.input }}
          disableUnderline
          value={selectedMonth.year()}
          onChange={e => {
            setMonth(selectedMonth.year(e.target.value));
            onYearSelect(month, e.target.value);
          }}
        >
          <MenuItem value={moment().year()}>{moment().year()}</MenuItem>
          <MenuItem value={moment().year() - 1}>{moment().year() - 1}</MenuItem>
          <MenuItem value={moment().year() - 2}>{moment().year() - 2}</MenuItem>
          {selectedMonth.year() > moment().year() ? (
            <MenuItem value={selectedMonth.year()}>{selectedMonth.year()}</MenuItem>
          ) : null}
        </Select>
      </FormControl>
    </div>
  );
};

export default withStyles(styles)(MonthCaption);
