import React from "react";
import PropTypes from "prop-types";
import Typography from "@material-ui/core/Typography";
import InputField from "../InputField";

const ParentPhone = props => {
  const { handleChange, values, checkPhone} = props;
  return (
    <>
      <Typography variant="body1">Для принятия участия введите номер телефона, который указывали в регистрационной форме-заявке</Typography>
      <InputField
        id="phone"
        label="Телефон"
        required={true}
        error={(checkPhone(values.phone) || values.phone.length < 1) ? "": " "}
        onChange={handleChange}
        value={values.phone}
      />
      <br />
      <br />
    </>
  );
};

ParentPhone.propTypes = {
  values: PropTypes.shape({
    phone: PropTypes.string,
    email: PropTypes.string
  }),
  handleChange: PropTypes.func.isRequired
};

export default ParentPhone;
