import axios from "axios";
import { createAction } from "redux-actions";
import { API, handleApiError } from "../api";

export const pupilListRequest = createAction("PROMO_PUPIL_LIST_REQUEST");
export const pupilListSuccess = createAction("PROMO_PUPIL_LIST_SUCCESS");
export const pupilListError = createAction("PROMO_PUPIL_LIST_ERROR");
export const pupilListClear = createAction("PROMO_PUPIL_LIST_CLEAR");
export const payPromoUrlRequest = createAction("PAY_PROMO_URL_REQUEST");
export const payPromoUrlSuccess = createAction("PAY_PROMO_URL_SUCCESS");
export const payPromoUrlError = createAction("PAY_PROMO_URL_ERROR");
export const payPromoUrlClear = createAction("PAY_PROMO_URL_CLEAR");

export const changePromoStepSuccess = createAction("CHANGE_PROMO_STEP");

export const getPupilList = (values) => async dispatch => {
  try {
    dispatch(pupilListRequest);
    values.phone = values.phone.replace(/[ ()-]/gi, '');
    const res = await axios.get(
      `${API}/promo/7/pupils?phoneNumber=${encodeURIComponent(values.phone)}`
    );
    dispatch(
      pupilListSuccess({
        pupils: res.data.pupils || []
      })
    );
  } catch (e) {
    // console.log(e)
    dispatch(handleApiError(e, pupilListError()));
  }
}

export const clearPupilList = () => dispatch => dispatch(pupilListClear());

export const getPayUrl = (values) => async dispatch => {
  try {
    dispatch(payPromoUrlRequest());
    values.phone = values.phone.replace(/[ ()-]/gi, '');
    const res = await axios.get(
      `${API}/pays/promo-300?phoneNumber=${encodeURIComponent(values.phone)}&pupilId=${encodeURIComponent(values.pupilId)}`
    );
    dispatch(
      payPromoUrlSuccess({
        payUrl: res.data.message
      })
    );
  } catch (error) {
    dispatch(handleApiError(error, payPromoUrlError()));
  }
};

export const clearPayUrl = () => dispatch => dispatch(payPromoUrlClear());

export const changeStep = step => dispatch => dispatch(changePromoStepSuccess(step));
