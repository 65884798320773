import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import withStyles from "@material-ui/core/styles/withStyles";
import panelsStyles from "../styles";

import PanelTitle from "./PanelTitle";
import AddZoneForm from "./AddZoneForm";
import ZoneItem from "./ZoneItem";
import EditZoneName from "./EditZoneName";
import NoZonesText from "./NoZonesText";

const styles = theme => ({
  ...panelsStyles(theme)
});

const Zones = props => {
  const {
    classes,
    activeButton,
    zones,
    addZoneIsOpen,
    zoneName,
    zonePoints,
    addZoneOpen,
    deleteZone,
    changeZoneStatus,
    selectZone,
    selectedZone,
    changeInputValue,
    clearAddZoneForm,
    editZone,
    editZoneName,
    editZoneNameOpen,
    editZoneNameClose,
    submitAddZoneForm
  } = props;
  return (
    <div
      className={classNames(classes.panel, {
        [classes.panelHidden]: !activeButton,
        [classes.panelActive]: activeButton,
        [classes.panelSelected]: selectedZone && !editZone,
        [classes.panelSmall]: addZoneIsOpen || editZone
      })}
      onClick={e => e.stopPropagation()}
    >
      {!editZone ? <PanelTitle {...{ addZoneIsOpen, addZoneOpen, selectedZone }} /> : null}
      <div className={classes.itemsScroller}>
        {addZoneIsOpen ? (
          <AddZoneForm
            {...{
              zoneName,
              zonePoints,
              changeInputValue,
              clearAddZoneForm,
              submitAddZoneForm
            }}
          />
        ) : editZone ? (
          <EditZoneName
            {...{
              editZone,
              zoneName,
              changeInputValue,
              editZoneNameClose,
              editZoneName
            }}
          />
        ) : !zones || zones.length === 0 ? (
          <NoZonesText />
        ) : null}
        {!addZoneIsOpen &&
          !editZone &&
          zones &&
          zones.map(zone => {
            if (selectedZone && selectedZone !== zone.id) {
              return null;
            }
            return (
              <ZoneItem
                key={zone.id}
                zone={zone}
                changeZoneStatus={changeZoneStatus}
                deleteZone={deleteZone}
                selectZone={selectZone}
                selectedZone={selectedZone}                
                editZoneNameOpen={editZoneNameOpen}
              />
            );
          })}
      </div>
    </div>
  );
};

export default withStyles(styles)(Zones);

Zones.propTypes = {
  classes: PropTypes.object,
  activeButton: PropTypes.bool.isRequired,
  zones: PropTypes.array,
  addZoneIsOpen: PropTypes.bool.isRequired,
  zoneName: PropTypes.string.isRequired,
  zonePoints: PropTypes.array,
  addZoneOpen: PropTypes.func.isRequired,
  deleteZone: PropTypes.func.isRequired,
  changeZoneStatus: PropTypes.func.isRequired,
  selectZone: PropTypes.func.isRequired,
  selectedZone: PropTypes.number,
  changeInputValue: PropTypes.func.isRequired,
  clearAddZoneForm: PropTypes.func.isRequired,
  submitAddZoneForm: PropTypes.func.isRequired
};
