import { handleActions } from "redux-actions";

import {
  khalvaRegionsSuccess,
  khalvaSetBankCardOrderSuccess,
  khalvaSetBankCardOrderError
} from "../actions/khalvaActions";

const initialState = {
  regions: null,
  errors: {},
  success: false
}


const khalva = handleActions(
  {
    [khalvaRegionsSuccess]: (state, { payload: {regions} }) => {
      return {
        ...state,
        regions: regions
      }
    },
    [khalvaSetBankCardOrderSuccess]: (state) => ({...state, errors: {}, success: true}),
    [khalvaSetBankCardOrderError]: (state, { payload }) => {
      return {
        ...state,
        errors: (payload.errors) ? payload.errors : state.errors,
      }
    }
  },
  initialState
);
export default khalva;
