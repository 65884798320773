import React from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
// import AccessAlarm from "@material-ui/icons/AccessAlarm";
// import VerticalAlignTop from "@material-ui/icons/VerticalAlignTop";
// import VerticalAlignBottom from "@material-ui/icons/VerticalAlignBottom";

import Typography from "@material-ui/core/Typography";

import moment from "moment";
import {withStyles} from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";

const styles = theme => ({
  detailsContainer: {
    width: "45%",
    padding: 10,
    [theme.breakpoints.down("xs")]: {
      width: "90%",
      maxWidth: 550,
      margin: "0 auto"
    }
  },
  leftCell: {
    width: 70
  },
  price: {
    display: "flex",
    alignItems: "center"
  }
});

const MealsDetails = props => {
  const {classes, selectedDate, dayMeals, isLoading, isMealsDetailPossible, selectedPupil, addFoodDataOrder} = props;

  const rows =
    dayMeals &&
    dayMeals.map((item, index) => {
      return (
        <TableRow key={index}>
          <TableCell className={classes.leftCell}>
            <Typography variant="subtitle1">{item.createTime}</Typography>
          </TableCell>
          <TableCell>
            <Typography variant="subtitle1">
              {item.operationName[0].toUpperCase() + item.operationName.slice(1).toLowerCase()}
            </Typography>
            {
              <Typography variant="caption">
                {!isLoading && item.onDate !== moment(selectedDate).format("YYYY-MM-DD")
                  ? `на дату ${moment(item.onDate, "YYYY-MM-DD").format("DD.MM.YYYY")}`
                  : null}
              </Typography>
            }
          </TableCell>
          <TableCell numeric>
            <Typography
              variant="h6"
              color={item.price > 0 ? "secondary" : item.price < 0 ? "error" : "default"}
            >
              {item.price}
            </Typography>
          </TableCell>
        </TableRow>
      );
    });

  return (
    <div className={classes.detailsContainer}>
      <Table padding="none">
        <TableBody>
          {(isMealsDetailPossible)
            ? (
              <TableRow>
                <TableCell colSpan={3}>
                  <Button
                    variant="outlined"
                    size="small"
                    onClick={() => {
                      addFoodDataOrder(selectedPupil, 2)
                    }}
                  >Открыть доступ ко всем данным за прошедшие периоды</Button>
                </TableCell>
              </TableRow>)
            : null}
          <TableRow>
            <TableCell colSpan={3}>
              <Typography variant="h6">{moment(selectedDate).format("D MMMM, dddd")}</Typography>
            </TableCell>
          </TableRow>
          {rows}
        </TableBody>
      </Table>
    </div>
  );
};

export default withStyles(styles)(MealsDetails);
