import React from "react";
import moment from "moment";

import Typography from "@material-ui/core/Typography";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import TableHead from "@material-ui/core/TableHead";

import LoadingMessage from "components/messages/LoadingMessage";

import { withStyles } from "@material-ui/core/styles";

const styles = theme => ({
  row: {
    height: 40
  },
  longCell: {
    padding: "4px 10px 4px 15px"
  },
  detailsTitle: {
    [theme.breakpoints.down("xs")]: {
      fontSize: 18
    }
  }
});

const InvoiceDetails = props => {
  const { classes, month, invoiceDetails } = props;
  if (!invoiceDetails || !month) {
    return (
      <Typography align="center" variant="h6" className={classes.detailsTitle}>
        Не выбран счет для показа детализации
      </Typography>
    );
  } else {
    return (
      <>
        <LoadingMessage />
        <Typography align="center" variant="h6" className={classes.detailsTitle}>
          Детализация за {moment(month, "YYYY-MM").format("MMMM YYYY")}
        </Typography>
        <Table padding="none">
          <TableHead>
            <TableRow>
              <TableCell>
                <Typography>Дата</Typography>
              </TableCell>
              <TableCell className={classes.longCell}>
                <Typography>Операция</Typography>
              </TableCell>
              <TableCell numeric>
                <Typography>Сумма</Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {invoiceDetails.map((detail, index) => (
              <TableRow key={index} className={classes.row}>
                <TableCell>
                  <Typography variant="button">
                    {moment(detail.date, "YYYY-MM-DD").format("DD.MM")}
                  </Typography>
                </TableCell>
                <TableCell className={classes.longCell}>
                  <Typography>{detail.operationComment}</Typography>
                </TableCell>
                <TableCell numeric>
                  <Typography variant="button" color={detail.sum > 0 ? "secondary" : "error"}>
                    {detail.sum}
                  </Typography>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </>
    );
  }
};

export default withStyles(styles)(InvoiceDetails);
