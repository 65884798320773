import React from "react";
import {Link} from "react-router-dom";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";

import CircularProgress from "@material-ui/core/CircularProgress";

import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";

import withStyles from "@material-ui/core/styles/withStyles";
import {Formik} from "formik";
import InputField from "../InputField";

const styles = theme => ({
  container: {
    display: "block", // Fix IE11 issue.
    width: "95%",
    maxWidth: 400,
    minWidth: 200,
    paddingTop: 100,
    marginLeft: "auto",
    marginRight: "auto",
    [theme.breakpoints.down("sm")]: {
      paddingTop: 20
    }
  },
  paper: {
    marginTop: 20,
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
  title: {
    width: "100%",
    lineHeight: 3,
    verticalAlign: "middle",
    fontWeight: 500,
    textAlign: "center",
    color: "rgba(0, 0, 0, 0.70)",
    backgroundColor: theme.palette.primary.main
  },
  form: {
    width: "100%", // Fix IE11 issue.
    marginTop: 10,
    padding: 25
  },
  submit: {
    marginTop: 30,
    marginBottom: 30
  },
  input: {
    marginTop: 10,
    fontSize: 25
  },
  positionStart: {
    fontSize: 20
  },
  codeWrapper: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
    alignItems: "center",
  },
  buttonWrapper: {
    position: "relative"
  },
  buttonProgress: {
    color: "white",
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12
  }
});

const RecoveryAccessForm = props => {
  const {
    classes, isLoading, isRecoveryCodeSend,
    newPhoneRecoverySendCode, newPhoneRecoveryCheckCode, errors
  } = props;

  const isRecoveryDataValid = (values) => {
    const phonePattern = /^(?:8|\+7|7)?(9[0-9]{9})$/ism;
    return (
      phonePattern.test(values.oldPhone) &&
      values.docSeries.length > 0 &&
      values.docNumber.length > 0 &&
      phonePattern.test(values.newPhone) &&
      (!isRecoveryCodeSend || (
        isRecoveryCodeSend &&
        values.code.length > 0 &&
        values.password.length > 0
      ))
    );
  }

  const initValues = {
    oldPhone: '',
    docSeries: '',
    docNumber: '',
    newPhone: '',
    code: '',
    password: '',
  }

  return (
    <>
      <CssBaseline/>
      <main className={classes.container}>
        <Paper className={classes.paper}>
          <Typography variant="subtitle1" className={classes.title}>
            Восстановление доступа по данным РФз
          </Typography>
          <Formik
            initialValues={initValues}
          >
            {val => {
              const {values, handleChange, setFieldValue, handleSubmit} = val;

              return (
                <form className={classes.form} onSubmit={handleSubmit}>
                  <Typography variant="body2">
                    Для смены номера без авторизации в Личном кабинете Нам необходимо Вас идентифицировать, для этого
                    введите Серию и Номер документа, которые были введены вами при заполнении регистрацонной формы
                  </Typography>
                  <br/>
                  <InputField
                    id="oldPhone"
                    label="Предыдущий номер телефона"
                    fullWidth
                    required
                    disabled={isRecoveryCodeSend}
                    value={values.oldPhone}
                    error={errors.oldPhone}
                    onChange={handleChange}
                  />
                  <InputField
                    id="docSeries"
                    label="Серия"
                    fullWidth
                    required
                    disabled={isRecoveryCodeSend}
                    value={values.docSeries}
                    error={errors.docSeries}
                    onChange={handleChange}
                  />
                  <InputField
                    id="docNumber"
                    label="Номер"
                    fullWidth
                    required
                    disabled={isRecoveryCodeSend}
                    value={values.docNumber}
                    error={errors.docNumber}
                    onChange={handleChange}
                  />
                  <InputField
                    id="newPhone"
                    label="Новый номер телефона"
                    fullWidth
                    required
                    disabled={isRecoveryCodeSend}
                    value={values.newPhone}
                    error={errors.newPhone}
                    onChange={handleChange}
                  />
                  {!isRecoveryCodeSend && (
                    <div className={classes.buttonWrapper}>
                      <Button
                        disabled={!isRecoveryDataValid(values)}
                        onClick={e => newPhoneRecoverySendCode(values)}
                        fullWidth
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                      >
                        {isLoading ? "" : "Отправить Код подтвеждения"}
                      </Button>
                      {isLoading && <CircularProgress size={24} className={classes.buttonProgress}/>}
                    </div>
                  )}
                  {isRecoveryCodeSend && (
                    <>
                      <div className={classes.codeWrapper}>
                        <InputField
                          id="code"
                          label="Код подтвеждения"
                          fullWidth
                          required
                          value={values.code}
                          error={errors.code}
                          onChange={handleChange}
                        />
                        <div style={{marginLeft: 5}}>
                        <Button
                          onClick={e => newPhoneRecoverySendCode(values)}
                          size="small"
                          variant="text"
                          color="primary"
                          className={classes.submit}

                        >
                          {isLoading ? "" : "  Повторить  "}
                        </Button>
                        {isLoading && <CircularProgress size={24} className={classes.buttonProgress}/>}
                        </div>
                      </div>
                      <InputField
                        id="password"
                        label="Новый пароль"
                        fullWidth
                        required
                        value={values.password}
                        error={errors.password}
                        onChange={handleChange}
                      />
                      <div className={classes.buttonWrapper}>
                        <Button
                          disabled={!isRecoveryDataValid(values)}
                          onClick={e => newPhoneRecoveryCheckCode(values)}
                          fullWidth
                          variant="contained"
                          color="primary"
                          className={classes.submit}
                        >
                          {isLoading ? "" : "Сохранить изменения"}
                        </Button>
                        {isLoading && <CircularProgress size={24} className={classes.buttonProgress}/>}
                      </div>
                    </>
                  )}
                  <Button component={Link} to="/login" fullWidth color="default" size="small">
                    Назад к экрану логина
                  </Button>
                </form>
              )
            }}</Formik>

        </Paper>
      </main>
    </>
  );
};

export default withStyles(styles)(RecoveryAccessForm);
