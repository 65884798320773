import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import CheckCircle from "@material-ui/icons/CheckCircle";
import CheckCircleOutline from "@material-ui/icons/CheckCircleOutline";

import withStyles from "@material-ui/core/styles/withStyles";
import PropTypes from "prop-types";

const styles = theme => ({
  modal: {
    [theme.breakpoints.down("xs")]: {
      alignItems: "flex-start"
    }
  },
  paper: {
    margin: 10
  },
  deliveries: {
    display: "flex",
    padding: "8px 0",
    cursor: "pointer",
    alignItems: "center",
    "&:hover > svg": {
      opacity: 0.5
    }
  },
  icon: {
    paddingRight: 5
  }
});

const DeliveryTypesWindow = ({
  classes,
  deliveryTypes,
  changeGpsDeliveryType,
  isDeliveryShown,
  toggleDeliveryModal
}) => (
  <Dialog
    open={isDeliveryShown}
    onClose={toggleDeliveryModal}
    classes={{ root: classes.modal, paper: classes.paper }}
  >
    <DialogTitle>Изменение способа уведомлений GPS</DialogTitle>
    <DialogContent>
      {deliveryTypes.map(item => (
        <div
          key={item.deliveryTypeId}
          className={classes.deliveries}
          onClick={() => changeGpsDeliveryType(item.deliveryTypeId, +!item.state)}
        >
          {item.state && <CheckCircle className={classes.icon} color="secondary" />}
          {!item.state && <CheckCircleOutline className={classes.icon} color="error" />}
          <Typography>{item.deliveryTypeName}</Typography>
        </div>
      ))}
    </DialogContent>
    <DialogActions>
      <Button onClick={toggleDeliveryModal} color="primary">
        Закрыть
      </Button>
    </DialogActions>
  </Dialog>
);

DeliveryTypesWindow.propTypes = {
  classes: PropTypes.object,
  deliveryTypes: PropTypes.array.isRequired,
  changeGpsDeliveryType: PropTypes.func.isRequired,
  isDeliveryShown: PropTypes.bool.isRequired,
  toggleDeliveryModal: PropTypes.func.isRequired
};

export default withStyles(styles)(DeliveryTypesWindow);
