import React from "react";
import Typography from "@material-ui/core/Typography";

import withStyles from "@material-ui/core/styles/withStyles";
import panelsStyles from "../styles";

const styles = theme => ({
  ...panelsStyles(theme),
  textBlock: {
    paddingRight: 5
  }
});
const NoDevicesText = props => {
  const { classes } = props;
  return (
    <div className={classes.textBlock}>
      <Typography color="inherit" align="justify" paragraph>
        У Вас еще нет добавленных устройств. Для добавления нового отслеживающего устройства нажмите
        на иконку плюс, расположенную выше, введите название устройства и код устройства. Кодом
        устройства является imei из отдельного приложения ребенка - УЭШКа gps-мониторинг.
      </Typography>
      <Typography color="inherit" align="justify" paragraph>
        После добавления устройства Вы можете его удалить, нажав на красный крестик, а также
        активировать или деактивировать, нажав на иконку обозначающую координату (иконки расположены
        справа от названия устройства). При деактивации всех устройств списание абонентской платы за
        мониторинг приостанавливается.
      </Typography>
      <Typography color="inherit" align="justify" paragraph>
        После добавления устройства пополните баланс услуги через раздел "Оплата и финансы", если Вы
        не сделали этого ранее.
      </Typography>
    </div>
  );
};

export default withStyles(styles)(NoDevicesText);
