import React, { PureComponent } from "react";
import { withRouter } from "react-router-dom";
import Hidden from "@material-ui/core/Hidden";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";

import SchoolSelect from "components/SchoolSelect";
import PupilSelect from "components/PupilSelect";

import { menuList } from "components/menu";
import PhoneBlock from "./PhoneBlock";

import { withStyles } from "@material-ui/core/styles";

const styles = theme => ({
  appBar: {
    zIndex: theme.zIndex.drawer + 1, // чтобы меню не заходило на хедер
    [theme.breakpoints.down("sm")]: {
      position: "fixed"
    }
  },
  toolbar: {
    position: "flex",
    justifyContent: "space-between",
    [theme.breakpoints.down("xs")]: {
      minHeight: 50,
      height: 50
    }
  },
  title: {
    marginLeft: 30,
    [theme.breakpoints.down("xs")]: {
      fontSize: 16
    }
  }
});

class Header extends PureComponent {
  render() {
    const { classes, location, profile } = this.props;
    // ищем пункт меню, совпадающий с текущим адресом страницы
    const locationMenuItem = menuList.filter(item => "/" + item.id === location.pathname)[0];
    return (
      <AppBar position="absolute" className={classes.appBar}>
        <Toolbar className={classes.toolbar}>
          <Hidden smDown>
            <SchoolSelect />
          </Hidden>
          <Hidden mdUp>
            <Typography variant="h6" color="inherit" className={classes.title}>
              {//если текущий адрес страницы есть в меню - показываем заголовок меню
              locationMenuItem ? locationMenuItem.name : ""}
            </Typography>
          </Hidden>
          <Hidden smDown>
            <PhoneBlock dilerId={profile.dilerId} />
          </Hidden>
          <PupilSelect
            showPupils={
              //если текущий адрес страницы есть в меню - смотрим показывать ли учеников в хедере или нет
              locationMenuItem ? locationMenuItem.showPupils : undefined
            }
          />
        </Toolbar>
      </AppBar>
    );
  }
}

export default withRouter(withStyles(styles)(Header));
