import React from "react";
import PropTypes from "prop-types";
import Select from "react-select";
import {withStyles} from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Paper from "@material-ui/core/Paper";
import MenuItem from "@material-ui/core/MenuItem";
import NoSsr from "@material-ui/core/es/NoSsr/NoSsr";

const styles = theme => ({
  textField: {
    width: 200,
    minWidth: 150,
    margin: "8px 0 4px 0"
  },
  textFieldFullWidth: {
    width: "100%",
    maxWidth: 620,
    minWidth: 150,
    margin: "8px 0 4px 0"
  },
  input: {
    display: "flex",
    padding: "2px 0 3px 15px"
  },
  label: {
    transform: "translate(14px, 10px) scale(1)"
  },
  valueContainer: {
    display: "flex",
    // flexWrap: "wrap",
    flex: 1,
    alignItems: "center",
    overflow: "hidden"
  },
  noOptionsMessage: {
    padding: `${theme.spacing.unit}px ${theme.spacing.unit * 2}px`
  },
  singleValue: {
    fontSize: 16
  },
  paper: {
    position: "absolute",
    zIndex: 2,
    marginTop: theme.spacing.unit,
    left: 0,
    right: 0
  },
  divider: {
    height: theme.spacing.unit * 2
  }
});

function NoOptionsMessage(props) {
  return (
    <Typography
      color="textSecondary"
      className={props.selectProps.classes.noOptionsMessage}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  );
}

function inputComponent({inputRef, ...props}) {
  return <div ref={inputRef} {...props} />;
}

function Control(props) {
  return (
    <TextField
      margin="none"
      variant="outlined"
      InputProps={{
        inputComponent,
        inputProps: {
          className: props.selectProps.classes.input,
          inputRef: props.innerRef,
          children: props.children,
          ...props.innerProps
        }
      }}
      InputLabelProps={{
        classes: {outlined: props.selectProps.classes.label},
        shrink: true
      }}
      {...props.selectProps.textFieldProps}
    />
  );
}

function Placeholder(props) {
  return (
    <Typography
      color="textSecondary"
      className={props.selectProps.classes.placeholder}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  );
}

function Option(props) {
  return (
    <MenuItem
      buttonRef={props.innerRef}
      selected={props.isFocused}
      component="div"
      style={{
        fontWeight: props.isSelected ? 500 : 400
      }}
      {...props.innerProps}
    >
      {props.children}
    </MenuItem>
  );
}

function Menu(props) {
  return (
    <Paper square className={props.selectProps.classes.paper} {...props.innerProps}>
      {props.children}
    </Paper>
  );
}

function ValueContainer(props) {
  return <div className={props.selectProps.classes.valueContainer}>{props.children}</div>;
}

function SingleValue(props) {
  return (
    <Typography className={props.selectProps.classes.singleValue} {...props.innerProps}>
      {props.children}
    </Typography>
  );
}

const components = {
  Placeholder,
  Control,
  Menu,
  NoOptionsMessage,
  Option,
  SingleValue,
  ValueContainer
};

function AutosuggestField(props) {
  const {
    id,
    classes,
    name,
    required,
    label,
    placeholder,
    error,
    defaultValue,
    fullWidth,
    suggestions,
    selected,
    onChange,
    disabled,
    isClearable,
    onInputChange
  } = props;

  const handleChange = value => {
    onChange && onChange(id, value);
  };

  const handleInputChange = value => {
    onInputChange && onInputChange(id, value);
  };

  return (
    <NoSsr>
      <Select
        classes={classes}
        inputId="react-select-single"
        textFieldProps={{
          name: name,
          required: required,
          label: label,
          error: !!error,
          helperText: error,
          defaultValue: defaultValue,
          className: (fullWidth) ? classes.textFieldFullWidth : classes.textField
        }}
        isDisabled={disabled}
        placeholder={placeholder || ""}
        options={suggestions}
        components={components}
        value={selected}
        onChange={handleChange}
        onInputChange={handleInputChange}
        isClearable={isClearable}
        onKeyDown={e => {
          if (props.onKeyUp) props.onKeyUp(e)
        }}
      />
    </NoSsr>
  );
}

NoOptionsMessage.propTypes = {
  selectProps: PropTypes.shape({
    classes: PropTypes.object
  }),
  innerProps: PropTypes.object,
  children: PropTypes.node
};

inputComponent.propTypes = {
  inputRef: PropTypes.func
};

Control.propTypes = {
  innerRef: PropTypes.func,
  children: PropTypes.node,
  selectProps: PropTypes.shape({
    classes: PropTypes.object,
    textFieldProps: PropTypes.object
  }),
  innerProps: PropTypes.object
};

Placeholder.propTypes = {
  selectProps: PropTypes.shape({
    classes: PropTypes.object
  }),
  innerProps: PropTypes.object,
  children: PropTypes.node
};

Option.propTypes = {
  innerRef: PropTypes.func,
  isFocused: PropTypes.bool,
  isSelected: PropTypes.bool,
  innerProps: PropTypes.object,
  children: PropTypes.node
};

Menu.propTypes = {
  selectProps: PropTypes.shape({
    classes: PropTypes.object
  }),
  innerProps: PropTypes.object,
  children: PropTypes.node
};

ValueContainer.propTypes = {
  selectProps: PropTypes.shape({
    classes: PropTypes.object
  }),
  children: PropTypes.node
};

SingleValue.propTypes = {
  selectProps: PropTypes.shape({
    classes: PropTypes.object
  }),
  innerProps: PropTypes.object,
  children: PropTypes.node
};

AutosuggestField.propTypes = {
  id: PropTypes.string,
  classes: PropTypes.object,
  name: PropTypes.string,
  required: PropTypes.bool,
  label: PropTypes.string,
  error: PropTypes.string,
  defaultValue: PropTypes.string,
  fullWidth: PropTypes.bool,
  suggestions: PropTypes.array,
  selected: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  onInputChange: PropTypes.func
};

export default withStyles(styles)(AutosuggestField);
