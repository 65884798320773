import React from "react";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";

import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import ToggleOffIcon from "@material-ui/icons/ToggleOff";
import ToggleOnIcon from "@material-ui/icons/ToggleOn";

import PropTypes from "prop-types";

const SosSmsSettings = ({ settings, settingsDescription, handleSelectChange }) => (
  <ListItem disableGutters>
    <ListItemText
      style={{ padding: "0 30px 0 0" }}
      primary={settingsDescription.sosSms.title}
      secondary={settings.sosSms ? "Включено" : "Отключено"}
    />
    <ListItemSecondaryAction>
      <Tooltip title={settings.sosSms ? "Отключить" : "Включить"}>
        <IconButton onClick={() => handleSelectChange("sosSms", !settings.sosSms)}>
          {settings.sosSms ? (
            <ToggleOnIcon fontSize="large" color="secondary" />
          ) : (
            <ToggleOffIcon fontSize="large" color="error" />
          )}
        </IconButton>
      </Tooltip>
    </ListItemSecondaryAction>
  </ListItem>
);

SosSmsSettings.propTypes = {
  settings: PropTypes.object,
  settingsDescription: PropTypes.object,
  classes: PropTypes.object,
  handleSelectChange: PropTypes.func.isRequired
};

export default SosSmsSettings;
