import React, { Component } from "react";
import PropTypes from "prop-types";
import PupilBaseInfo from "./PupilBaseInfo";
import PupilDocs from "./PupilDocs";
import PupilSchoolInfo from "./PupilSchoolInfo";

class PupilData extends Component {
  componentDidMount() {
    const { values, setFieldValue, pupilIndex } = this.props;
    const newChildren = values.children;
    newChildren[pupilIndex] = {
      ...newChildren[pupilIndex],
      docTypeId: newChildren[pupilIndex].docTypeId || 11
    };
    setFieldValue("children", newChildren);
    for (let pupil of newChildren) {
      if (typeof pupil.schoolId === "number" && pupil.schoolId >= 0) {
        this.props.getClassesBySchool(pupil.schoolId);
      }
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { pupil: oldPupil } = prevProps;
    const { pupil, getClassesBySchool, values, setFieldValue, pupilIndex } = this.props;
    // при выборе школы грузим классы
    if (oldPupil.schoolId !== pupil.schoolId && typeof pupil.schoolId === "number") {
      getClassesBySchool(pupil.schoolId);
    }
    // при изменении школы удаляем класс выбранный ранее
    if (oldPupil.schoolId !== pupil.schoolId) {
      const newChildren = values.children;
      newChildren[pupilIndex] = {
        ...newChildren[pupilIndex],
        classId: null
      };
      setFieldValue("children", newChildren);
    }
  }

  render() {
    return (
      <>
        <PupilBaseInfo {...this.props} />
        <PupilDocs {...this.props} />
        <PupilSchoolInfo {...this.props} />
      </>
    );
  }
}

PupilData.propTypes = {
  values: PropTypes.object,
  setFieldValue: PropTypes.func.isRequired,
  pupilIndex: PropTypes.number.isRequired,
  getClassesBySchool: PropTypes.func.isRequired,
  pupil: PropTypes.object,
  parentDilerId: PropTypes.number
};

export default PupilData;
