import React from "react";

import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import Button from "@material-ui/core/Button";

import PolicyTypeSelect from "./PolicyTypeSelect";
import InsuranceSelectList from "./InsuranceSelectList";

import { withStyles } from "@material-ui/core/styles";
const styles = theme => ({
  buttons: {
    position: "relative",
    display: "flex",
    justifyContent: "flex-start",
    marginTop: 15
  },
  nextButton: {
    flexGrow: 1,
    margin: "0 5px"
  }
});

const Step1 = props => {
  const {
    classes,
    available,
    selectedPolicy,
    selectedInsurance,
    changePolicy,
    changeInsurance,
    changeStep
  } = props;
  // console.log(props)

  return (
    <div>
      <PolicyTypeSelect
        policies={available.policies}
        selectedPolicy={selectedPolicy}
        changePolicy={changePolicy}
      />
      <Typography variant="subtitle2">Описание</Typography>
      <Divider />
      <Typography align="justify" paragraph>
        {available.policies[selectedPolicy].description}
      </Typography>
      <Typography variant="subtitle2">Страховая сумма:</Typography>
      <Divider />
      <InsuranceSelectList
        insurances={available.policies[selectedPolicy].insurances}
        selectedInsurance={selectedInsurance}
        changeInsurance={changeInsurance}
      />
      <div className={classes.buttons}>
        <Button
          className={classes.nextButton}
          disabled={selectedInsurance === null}
          variant="outlined"
          color="secondary"
          size="large"
          onClick={e => changeStep("forward")}
        >
          Далее
        </Button>
      </div>
    </div>
  );
};

export default withStyles(styles)(Step1);
