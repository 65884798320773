import axios from "axios";
import { createAction } from "redux-actions";
import { API, handleApiError } from "api";
import { showInfoMessage } from "actions/infoMessageActions";

export const availableInsurancesRequest = createAction("AVAILABLE_INSURANCES_REQUEST");
export const availableInsurancesSuccess = createAction("AVAILABLE_INSURANCES_SUCCESS");
export const availableInsurancesError = createAction("AVAILABLE_INSURANCES_ERROR");

export const completedInsurancesRequest = createAction("COMPLETED_INSURANCES_REQUEST");
export const completedInsurancesSuccess = createAction("COMPLETED_INSURANCES_SUCCESS");
export const completedInsurancesError = createAction("COMPLETED_INSURANCES_ERROR");

export const selectedInsuranceRequest = createAction("SELECTED_INSURANCE_REQUEST_LOCAL");
export const selectedInsuranceSuccess = createAction("SELECTED_INSURANCE_SUCCESS_LOCAL");
export const selectedInsuranceError = createAction("SELECTED_INSURANCE_ERROR_LOCAL");

export const insuranceParentDataRequest = createAction("INSURANCE_PARENT_DATA_REQUEST");
export const insuranceParentDataSuccess = createAction("INSURANCE_PARENT_DATA_SUCCESS");
export const insuranceParentDataError = createAction("INSURANCE_PARENT_DATA_ERROR");

export const insurancePupilDataRequest = createAction("INSURANCE_PUPIL_DATA_REQUEST");
export const insurancePupilDataSuccess = createAction("INSURANCE_PUPIL_DATA_SUCCESS");
export const insurancePupilDataError = createAction("INSURANCE_PUPIL_DATA_ERROR");

export const completedInsurancePdfRequest = createAction("COMPLETED_INSURANCE_PDF_REQUEST");
export const completedInsurancePdfSuccess = createAction("COMPLETED_INSURANCE_PDF_SUCCESS");
export const completedInsurancePdfError = createAction("COMPLETED_INSURANCE_PDF_ERROR");

export const addInsuranceRequest = createAction("ADD_INSURANCE_REQUEST");
export const addInsuranceSuccess = createAction("ADD_INSURANCE_SUCCESS");
export const addInsuranceError = createAction("ADD_INSURANCE_ERROR");

export const deleteInsuranceRequest = createAction("DELETE_INSURANCE_REQUEST");
export const deleteInsuranceSuccess = createAction("DELETE_INSURANCE_SUCCESS");
export const deleteInsuranceError = createAction("DELETE_INSURANCE_ERROR");

export const getAvailableInsurances = () => async dispatch => {
  try {
    dispatch(availableInsurancesRequest());
    const res = await axios.get(`${API}/insurance/available`);
    dispatch(
      availableInsurancesSuccess({
        available: res.data
      })
    );
  } catch (error) {
    dispatch(handleApiError(error, availableInsurancesError()));
  }
};

export const getCompletedInsurances = parentId => async dispatch => {
  try {
    dispatch(completedInsurancesRequest());

    const res = await axios.get(`${API}/insurance/${parentId}`);
    dispatch(
      completedInsurancesSuccess({
        completed: res.data
      })
    );
  } catch (error) {
    dispatch(handleApiError(error, completedInsurancesError()));
  }
};

export const getInsurance = insuranceId => async dispatch => {
  try {
    dispatch(selectedInsuranceRequest());

    const res = await axios.get(`${API}/insurance/details/${insuranceId}`);
    dispatch(
      selectedInsuranceSuccess({
        selected: res.data
      })
    );
  } catch (error) {
    dispatch(handleApiError(error, selectedInsuranceError()));
  }
};

export const getParentData = parentId => async dispatch => {
  try {
    dispatch(insuranceParentDataRequest());

    const res = await axios.get(`${API}/insurance/insurer-info/${parentId}`);
    dispatch(
      insuranceParentDataSuccess({
        parentData: res.data
      })
    );
  } catch (error) {
    dispatch(handleApiError(error, insuranceParentDataError()));
  }
};

export const getPupilData = pupilId => async dispatch => {
  try {
    dispatch(insurancePupilDataRequest());

    const res = await axios.get(`${API}/insurance/account-info/${pupilId}`);
    dispatch(
      insurancePupilDataSuccess({
        pupilData: res.data
      })
    );
  } catch (error) {
    dispatch(handleApiError(error, insurancePupilDataError()));
  }
};

export const getCompletedPdf = insuranceId => async dispatch => {
  try {
    dispatch(completedInsurancePdfRequest());
    // возвращаем ответ как blob
    const res = await axios.get(`${API}/insurance/pdf/${insuranceId}`, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/pdf"
      },
      responseType: "blob"
    });

    // создаем локальный урл на файл
    const file = new Blob([res.data], { type: "application/pdf" });
    const fileName = "insurance.pdf";
    if (window.navigator.msSaveOrOpenBlob) {
      window.navigator.msSaveOrOpenBlob(file, fileName);
    } else {
      const fileURL = URL.createObjectURL(file);

      // создаем спрятанную ссылку на файл и открываем ее
      // иначе по window.open adblock блокирует открытие нового окна
      // после открытия удаляем ссылку из dom
      const a = document.createElement("a");
      a.style = "display: none";
      document.body.appendChild(a);
      a.href = fileURL;
      a.download = fileName;
      a.click();
      a.remove();
    }
    dispatch(completedInsurancePdfSuccess());
  } catch (error) {
    dispatch(handleApiError(error, completedInsurancePdfError()));
  }
};

export const addInsuranceInvoice = insuranceObj => async dispatch => {
  try {
    dispatch(addInsuranceRequest());
    await axios.post(`${API}/insurance`, insuranceObj);
    dispatch(addInsuranceSuccess());
    dispatch(
      showInfoMessage(
        "Счет на страховку успешно создан, перейдите в раздел с оформленными полисами, чтобы посмотреть подробную информацию"
      )
    );
  } catch (error) {
    dispatch(handleApiError(error, addInsuranceError()));
  }
};

export const deleteInsuranceInvoice = (insuranceId, parentId) => async dispatch => {
  try {
    dispatch(deleteInsuranceRequest());
    await axios.delete(`${API}/insurance/${insuranceId}`);
    dispatch(deleteInsuranceSuccess());
    dispatch(showInfoMessage("Счет на страховку успешно удален"));
    dispatch(getCompletedInsurances(parentId));
  } catch (error) {
    dispatch(handleApiError(error, deleteInsuranceError()));
  }
};
