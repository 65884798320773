import React from "react";
import InputField from "../../../../InputField";

const ParentPhone = props => {
  const {insuranceOrgId, parent, parentData, changeParentData, validateError} = props;
  return (
    <>
      <InputField
        label="Телефон"
        required={true}
        error={validateError.insurerPhone(parent, parentData)}
        defaultValue={parent.insurerPhone ? parent.insurerPhone : parentData.insurerPhone}
        onChange={changeParentData("insurerPhone")}
      />
      {insuranceOrgId === 2 && (
        <InputField
          label="E-Mail"
          required={true}
          error={validateError.notEmpty("insurerEmail", parent, parentData)}
          defaultValue={parent.insurerEmail ? parent.insurerEmail : parentData.insurerEmail}
          onChange={changeParentData("insurerEmail")}
        />
      )}
    </>
  );
};

export default ParentPhone;
