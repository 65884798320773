import React from "react";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";

import ParentFio from "./ParentFio";
import ParentDocs from "./ParentDocs";
import ParentAddress from "./ParentAddress";
import ParentPhone from "./ParentPhone";

import {withStyles} from "@material-ui/core/styles";
// import PupilBirthday from "../step3/PupilBirthday";

const styles = theme => ({
  buttons: {
    position: "relative",
    display: "flex",
    justifyContent: "flex-start",
    marginTop: 15
  },
  nextButton: {
    flexGrow: 1,
    margin: "0 5px"
  }
});

const isParentNextButtonDisabled = (parentData, parent, orgId) => {
  if (
    (!parentData.insurerFam && !parent.insurerFam) ||
    (!parentData.insurerIm && !parent.insurerIm) ||
    (!parentData.insurerOtch && !parent.insurerOtch) ||
    (!parentData.insurerPhone && !parent.insurerPhone) ||
    (!parentData.insurerDocSeries && !parent.insurerDocSeries) ||
    (!parentData.insurerDocNumber && !parent.insurerDocNumber) ||
    (orgId === 2 && (
      (!parentData.insurerGender && !parent.insurerGender) ||
      (!parentData.insurerBirthDay && !parent.insurerBirthDay)
      )
    ) ||
    (orgId !== 2 && (
        (!parentData.insurerDocIssuer && !parent.insurerDocIssuer) ||
        (!parentData.insurerDocDate && !parent.insurerDocDate) ||

        (!parentData.insurerPostIndex && !parent.insurerPostIndex) ||
        (!parentData.insurerCityName && !parent.insurerCityName) ||
        (!parentData.insurerStreetName && !parent.insurerStreetName) ||
        (!parentData.insurerHouseNum && !parent.insurerHouseNum))
    ) ||
    (orgId === 2 && !parentData.insurerEmail && !parent.insurerEmail)
  ) {
    return true;
  } else return false;
};

const Step2 = props => {
  const {
    classes,
    validateError,
    available,
    selectedPolicy,
    selectedInsurance,
    changeStep,
    parentData,
    changeParentData,
    changeParentDocDate,
    changeParentBirthDate,
    parent
  } = props;
  const orgId = !selectedInsurance ? 1 : available.policies[selectedPolicy].insurances[selectedInsurance].orgId;

  return (
    <div>
      <Typography variant="caption">
        Страхователь - родители или близкие родственники, от имени которых заключается договор
        страхования от несчастного случая в отношении ребенка.
      </Typography>
      <Typography variant="caption" color="error">
        Данные должны быть заполнены в точном соответствии с данными в паспорте.
      </Typography>
      <Typography variant="caption" color="error" paragraph>
        *Поля, отмеченные звездочкой, обязательны к заполнению.
      </Typography>
      <ParentFio {...{parent, parentData, changeParentData, changeParentBirthDate, validateError}}
                 // insurances={available.policies[selectedPolicy].insurances}
                 // selectedInsurance={selectedInsurance}
                 insuranceOrgId={orgId}
      />
      <ParentDocs
        {...{
          parent,
          parentData,
          changeParentData,
          changeParentDocDate,
          validateError
        }}
        insuranceOrgId={orgId}
      />
      {(orgId !== 2) && (<ParentAddress {...{parent, parentData, changeParentData, validateError}} />)}
      <ParentPhone {...{parent, parentData, changeParentData, validateError}}
                   insuranceOrgId={orgId}/>
      <div className={classes.buttons}>
        <Button variant="outlined" size="small" onClick={e => changeStep("backward")}>
          Назад
        </Button>
        <Button
          disabled={isParentNextButtonDisabled(parentData, parent, orgId)}
          className={classes.nextButton}
          variant="outlined"
          color="secondary"
          size="large"
          onClick={e => changeStep("forward")}
        >
          Далее
        </Button>
      </div>
    </div>
  );
};

export default withStyles(styles)(Step2);
