import React from "react";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Radio from "@material-ui/core/Radio";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";
import PromoCode from "./PromoCode";

import { withStyles } from "@material-ui/core/styles";

const styles = theme => ({
  list: {
    padding: 0
  },
  payType: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center"
  },
  radio: {
    paddingRight: 0,
    paddingLeft: 0
  },
  buttons: {
    position: "relative",
    display: "flex",
    justifyContent: "flex-start",
    marginTop: 15
  },
  nextButton: {
    flexGrow: 1,
    margin: "0 5px"
  }
});
const Step1 = props => {
  const { classes, profile, payType, changeStep, isFoodActive, selectPayType, commissions, setPromoCode, promoCode } = props;
  return (
    <>
      <List>
        {profile.balances.ids.map(id => (
          <React.Fragment key={id}>
            <ListItem
              className={classes.list}
              button
              onClick={e =>
                selectPayType({ id: profile.balances[id].id, name: profile.balances[id].name })
              }
            >
              <Radio className={classes.radio} checked={payType.id === id && !payType.school} />
              <ListItemText>
                <div className={classes.payType}>
                  <Typography variant="subtitle1">{profile.balances[id].name}</Typography>
                  <Typography variant="body2">
                    баланс: {profile.balances[id].balance} руб.
                  </Typography>
                </div>
              </ListItemText>
            </ListItem>
            <Divider />
          </React.Fragment>
        ))}
        {isFoodActive ? (
          <>
            <ListItem
              className={classes.list}
              button
              onClick={e => selectPayType({ id: 0, name: "Питание" })}
            >
              <Radio className={classes.radio} checked={payType.id === 0} />
              <ListItemText>
                <Typography variant="subtitle1">Питание</Typography>
              </ListItemText>
            </ListItem>
            <Divider />
          </>
        ) : null}
      </List>
      {payType.id === 1 ? (
        <PromoCode {...{setPromoCode, promoCode, changeStep}} />
      ) : null}
      {commissions[payType.id] !== 0 ? (
        <Typography variant="body2" color="error">
          При оплате взимается комиссия {commissions[payType.id]}%
        </Typography>
      ) : null}
      <Divider />
      <div className={classes.buttons}>
        <Button
          className={classes.nextButton}
          variant="outlined"
          color="secondary"
          size="large"
          onClick={e => changeStep("forward")}
        >
          Далее
        </Button>
      </div>
    </>
  );
};

export default withStyles(styles)(Step1);
