import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import moment from "moment";

import LoadingMessage from "components/messages/LoadingMessage";
import MyLocation from "@material-ui/icons/MyLocation";
import SettingsCell from "@material-ui/icons/SettingsCell";
import Category from "@material-ui/icons/Category";
import Timeline from "@material-ui/icons/Timeline";

import * as gpsActions from "actions/gpsActions";
import PropTypes from "prop-types";
import ButtonsPanel from "./ButtonsPanel";
import MapView from "./map/MapView";
import HelpText from "./HelpText";
import GpsStatus from "./GpsStatus";
import Devices from "./devices/Devices";
import Zones from "./zones/Zones";
import History from "./history/History";

import MapZones from "./map/MapZones";
import NewZone from "./map/NewZone";
import LastCoordinatesPlacemark from "./map/LastCoordinatesPlacemark";
import HistoryCoordinatesPlacemarks from "./map/HistoryCoordinatesPlacemarks";
import HistoryCoordinatesLines from "./map/HistoryCoordinatesLines";

const today = moment()
  .startOf("day")
  .add(1, "days")
  .subtract(1, "seconds")
  .toDate();
const yesterday = moment(today)
  .startOf("day")
  // .subtract(1, "days")
  .toDate();

let lastCoordinatesAutoupdate = null;

class Gps extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mapCenter: [],
      mapZoom: 14,
      activeButton: null,
      addDeviceIsOpen: false,
      deviceName: "",
      deviceCode: "",
      isWatch: false,
      selectedDevice: null,
      selectedDeviceIsWatch: false,
      selectedZone: null,
      addZoneIsOpen: false,
      zoneName: "",
      zonePoints: [],
      historyStart: yesterday,
      historyEnd: today,
      editDeviceId: null,
      editZone: null,
      ymaps: null
    };

    this.mapRef = null;
  }

  buttons = [
    {
      id: 1,
      name: "Тек. позиция",
      icon: <MyLocation />
    },
    {
      id: 2,
      name: "Устройства",
      icon: <SettingsCell />
    },
    {
      id: 3,
      name: "GPS-зоны",
      icon: <Category />
    },
    {
      id: 4,
      name: "История",
      icon: <Timeline />
    }
  ];

  setYmaps = ymaps => {
    this.setState({ ymaps });
  };

  setMapRef = element => {
    this.mapRef = element;
  };

  changeMapCenter = coordinates => {
    this.setState({ mapCenter: coordinates });
  };

  changeMapZoom = zoom => {
    this.mapRef.setZoom(zoom);
  };

  toggleActiveButton = panel => () => {
    if (panel === 1) {
      this.devicesMethods.getLastCoordinates();
    } else {
      this.setState({
        activeButton: !this.state.activeButton || this.state.activeButton !== panel ? panel : null,
        addDeviceIsOpen: false,
        selectedZone: null,
        addZoneIsOpen: false,
        deviceName: "",
        deviceCode: "",
        selectedDevice: panel === 3 || panel === 4 ? this.state.selectedDevice : null,
        selectedDeviceIsWatch: panel === 3 || panel === 4 ? this.state.selectedDeviceIsWatch : null,
        zoneName: "",
        zonePoints: [],
        editDeviceId: null,
        editZone: null
      });
    }
  };

  changeInputValue = event => {
    const { value } = event.target;
    this.setState({
      [event.target.name]: value
    });
  };

  changeGpsStatus = () => {
    const { schools, selectedSchool } = this.props.user;
    const parentId = schools[selectedSchool].parent.id;
    const status = this.props.gps.status.active;
    this.props.gpsActions.changeGpsStatus(parentId, +!status);
  };

  changeGpsDeliveryType = (deliveryTypeId, state) => {
    const { schools, selectedSchool } = this.props.user;
    const parentId = schools[selectedSchool].parent.id;
    this.props.gpsActions.changeGpsDeliveryType(parentId, deliveryTypeId, state);
  };

  devicesMethods = {
    addDeviceOpen: () => {
      this.setState({ addDeviceIsOpen: true, selectedDevice: null, selectedDeviceIsWatch: false });
    },
    addDeviceClose: () => {
      this.setState({ addDeviceIsOpen: false });
    },
    editDeviceNameOpen: (deviceId, deviceName) => () => {
      this.setState({ editDeviceId: deviceId, deviceName });
    },
    editDeviceNameClose: () => this.setState({ editDeviceId: null, deviceName: "" }),
    submitAddDeviceForm: () => {
      const { schools, selectedSchool } = this.props.user;
      const parentId = schools[selectedSchool].parent.id;
      const { deviceName, deviceCode, isWatch } = this.state;
      if (this.props.gps.status.active === -1) {
        this.props.gpsActions.addFirstDevice(
          parentId,
          {
            name: deviceName,
            code: deviceCode
          },
          isWatch
        );
      } else {
        this.props.gpsActions.addGpsDevice(
          parentId,
          {
            name: deviceName,
            code: deviceCode
          },
          isWatch
        );
      }
    },
    selectDevice: deviceId => () => {
      if (this.state.selectedDevice === deviceId) {
        this.setState({ selectedDevice: null, selectedDeviceIsWatch: false });
        return;
      }
      const { schools, selectedSchool } = this.props.user;
      const parentId = schools[selectedSchool].parent.id;
      const { devices } = this.props.gps;
      this.setState({ selectedDevice: deviceId, addDeviceIsOpen: false });
      this.props.gpsActions.getLastCoordinates(parentId, deviceId);
      lastCoordinatesAutoupdate = setInterval(() => {
        this.props.gpsActions.getLastCoordinatesAutoUpdated(parentId, deviceId);
      }, 30000);
      if (devices.find(device => device.id === deviceId && device.isWatch === 1)) {
        this.setState({ selectedDeviceIsWatch: true });
      } else {
        this.setState({ selectedDeviceIsWatch: false });
      }
    },
    deleteDevice: deviceId => () => {
      const { schools, selectedSchool } = this.props.user;
      const parentId = schools[selectedSchool].parent.id;
      this.props.gpsActions.deleteGpsDevice(parentId, deviceId);
    },
    changeDeviceStatus: (deviceId, status) => () => {
      const { schools, selectedSchool } = this.props.user;
      const parentId = schools[selectedSchool].parent.id;
      this.props.gpsActions.changeGpsDeviceStatus(parentId, deviceId, status);
    },
    clearAddDeviceForm: () => {
      this.setState({
        deviceName: "",
        deviceCode: "",
        addDeviceIsOpen: false
      });
    },
    getLastCoordinates: () => {
      if (this.state.selectedDevice) {
        const { schools, selectedSchool } = this.props.user;
        const parentId = schools[selectedSchool].parent.id;
        const { lastCoordinates } = this.props.gps;
        if (lastCoordinates && lastCoordinates.latitude) {
          this.changeMapZoom(17);
          this.changeMapCenter([lastCoordinates.latitude, lastCoordinates.longitude]);
        }
        this.props.gpsActions.getLastCoordinates(parentId, this.state.selectedDevice);
      } else {
        this.props.gpsActions.getLastCoordinates(null, null);
      }
    },
    selectDateAndTime: name => date => {
      this.setState({
        [name]: date.toDate()
      });
    },
    getDeviceHistory: () => {
      const { schools, selectedSchool } = this.props.user;
      const parentId = schools[selectedSchool].parent.id;
      const { selectedDevice, historyStart, historyEnd } = this.state;
      const dateStart = moment(historyStart, "DD.MM.YYYY HH:MM").format("YYYY-MM-DD HH:MM");
      const dateEnd = moment(historyEnd, "DD.MM.YYYY HH:MM").format("YYYY-MM-DD HH:MM");
      this.props.gpsActions.getCoordinatesHistory(parentId, selectedDevice, dateStart, dateEnd);
    },
    editDeviceName: () => {
      const { schools, selectedSchool } = this.props.user;
      const parentId = schools[selectedSchool].parent.id;
      const { editDeviceId, deviceName } = this.state;
      this.props.gpsActions.changeGpsDeviceName(parentId, editDeviceId, deviceName);
      this.setState({ editDeviceId: null, deviceName: "" });
    },
    changeDeviceType: isWatch => {
      this.setState({ isWatch });
    },
    getWatchSettings: () => {
      const { schools, selectedSchool } = this.props.user;
      const parentId = schools[selectedSchool].parent.id;
      this.props.gpsActions.getGpsWatchSettings(parentId, this.state.selectedDevice);
    },
    saveWatchSettings: watchSettings => {
      const { schools, selectedSchool } = this.props.user;
      const parentId = schools[selectedSchool].parent.id;
      this.props.gpsActions.changeGpsWatchSettings(parentId, this.state.selectedDevice, watchSettings);
    },
    checkWatchConnection: () => {
      const { schools, selectedSchool } = this.props.user;
      const parentId = schools[selectedSchool].parent.id;
      this.props.gpsActions.checkWatchConnection(parentId, this.state.selectedDevice);
    },
    gpsWatchPowerOff: () => {
      const { schools, selectedSchool } = this.props.user;
      const parentId = schools[selectedSchool].parent.id;
      this.props.gpsActions.gpsWatchPowerOff(parentId, this.state.selectedDevice);
    },
    gpsWatchReboot: () => {
      const { schools, selectedSchool } = this.props.user;
      const parentId = schools[selectedSchool].parent.id;
      this.props.gpsActions.gpsWatchReboot(parentId, this.state.selectedDevice);
    },
    gpsWatchFind: () => {
      const { schools, selectedSchool } = this.props.user;
      const parentId = schools[selectedSchool].parent.id;
      this.props.gpsActions.gpsWatchFind(parentId, this.state.selectedDevice);
    }
  };

  zonesMethods = {
    addZoneOpen: () => {
      this.setState({ addZoneIsOpen: true, selectedZone: null });
    },
    addZoneClose: () => {
      this.setState({ addZoneIsOpen: false });
    },
    editZoneNameOpen: (zoneId, zonePoints, zoneName) => () => {
      this.setState({ editZone: { id: zoneId, points: zonePoints }, zoneName });
    },
    editZoneNameClose: () => this.setState({ editZone: null, zoneName: "" }),
    addZoneCoordinates: event => {
      if (this.state.addZoneIsOpen) {
        const coordinates = event.get("coords");
        const { zonePoints } = this.state;
        zonePoints.push(coordinates);
        this.setState({ zonePoints });
      }
    },
    selectZone: (zoneId, zonePoints) => () => {
      if (this.state.selectedZone === zoneId) {
        this.setState({ selectedZone: null });
        return;
      }
      const points = zonePoints.map(point => [point.lat, point.lng]);
      this.mapRef
        .setBounds(this.state.ymaps.util.bounds.fromPoints(points), {
          useMapMargin: true,
          zoomMargin: 20
        })
        .then(() => {
          if (this.mapRef.getZoom() > 18) this.mapRef.setZoom(18);
        });
      this.setState({ selectedZone: zoneId, addZoneIsOpen: false });
    },
    submitAddZoneForm: () => {
      const { schools, selectedSchool } = this.props.user;
      const parentId = schools[selectedSchool].parent.id;
      const { zoneName, zonePoints } = this.state;
      this.props.gpsActions.addGpsZone(parentId, {
        zoneName,
        zonePoints
      });
    },
    deleteZone: zoneId => () => {
      const { schools, selectedSchool } = this.props.user;
      const parentId = schools[selectedSchool].parent.id;
      this.props.gpsActions.deleteGpsZone(parentId, zoneId);
    },
    changeZoneStatus: (zoneId, status) => () => {
      const { schools, selectedSchool } = this.props.user;
      const parentId = schools[selectedSchool].parent.id;
      this.props.gpsActions.changeGpsZoneStatus(parentId, zoneId, status);
    },
    clearAddZoneForm: () => {
      this.setState({
        zoneName: "",
        zonePoints: [],
        addZoneIsOpen: false,
        selectedZone: null
      });
    },
    editZoneName: () => {
      const { schools, selectedSchool } = this.props.user;
      const parentId = schools[selectedSchool].parent.id;
      const { editZone, zoneName } = this.state;
      this.props.gpsActions.changeGpsZoneName(parentId, editZone.id, zoneName, editZone.points);
      this.setState({ editZone: null, zoneName: "" });
    }
  };

  componentDidMount = () => {
    const { schools, selectedSchool } = this.props.user;
    const parentId = schools[selectedSchool].parent.id;
    this.props.gpsActions.getStatusDevicesAndZones(parentId);
  };

  componentDidUpdate = (prevProps, prevState) => {
    if (prevProps.user.selectedSchool !== this.props.user.selectedSchool) {
      const { schools, selectedSchool } = this.props.user;
      const parentId = schools[selectedSchool].parent.id;
      this.props.gpsActions.getStatusDevicesAndZones(parentId);
    }
    if (
      prevProps.gps
      && prevProps.gps.devices
      && prevProps.gps.devices.length < this.props.gps.devices.length
    ) {
      this.devicesMethods.clearAddDeviceForm();
    }

    if (prevProps.gps.zones && prevProps.gps.zones.length !== this.props.gps.zones.length) {
      this.zonesMethods.clearAddZoneForm();
    }
    // если меняем выбранную зону не закрываем панель
    if (prevState.selectedZone !== this.state.selectedZone && this.state.selectedZone) {
      this.setState({ activeButton: 3 });
    }
    // если получили текущее местоположение центрируем на нем карту и меняем масштаб
    if (prevProps.gps.lastCoordinates === null && this.props.gps.lastCoordinates) {
      const { lastCoordinates } = this.props.gps;
      this.changeMapZoom(17);
      this.changeMapCenter([lastCoordinates.latitude, lastCoordinates.longitude]);
    }
    // если загружена история закрываем панель, чтобы не мешалась
    if (prevProps.gps.history.length === 0 && this.props.gps.history.length > 0) {
      this.setState({ activeButton: null });
      clearInterval(lastCoordinatesAutoupdate);
      const historyPoints = this.props.gps.history.map(point => [point.latitude, point.longitude]);
      this.mapRef
        .setBounds(this.state.ymaps.util.bounds.fromPoints(historyPoints), {
          useMapMargin: true,
          zoomMargin: 20
        })
        .then(() => {
          if (this.mapRef.getZoom() > 18) this.mapRef.setZoom(18);
        });
    }
    // очищаем таймер обновления координат, если устройство не выбрано
    if (prevState.selectedDevice && this.state.selectedDevice === null) {
      clearInterval(lastCoordinatesAutoupdate);
    }
    // очищаем историю при выборе нового устройства
    if (
      prevProps.gps.history
      && prevProps.gps.history.length > 0
      && !prevState.selectedDevice
      && this.state.selectedDevice
      && this.props.gps.history.length > 0
    ) {
      this.props.gpsActions.clearCoordinatesHistory();
    }
  };

  componentWillUnmount = () => {
    clearInterval(lastCoordinatesAutoupdate);
    this.props.gpsActions.clearCoordinatesHistory();
  };

  render() {
    const {
      mapCenter,
      mapZoom,
      activeButton,
      addDeviceIsOpen,
      deviceName,
      deviceCode,
      selectedDevice,
      addZoneIsOpen,
      zoneName,
      zonePoints,
      selectedZone,
      historyStart,
      historyEnd,
      ymaps,
      editDeviceId,
      editZone,
      isWatch,
      selectedDeviceIsWatch
    } = this.state;
    const { schools, selectedSchool } = this.props.user;
    const {
      status,
      deliveryTypes,
      devices,
      zones,
      lastCoordinates,
      history,
      watchSettings
    } = this.props.gps;
    const { schoolName } = schools[selectedSchool];

    if (!status || !deliveryTypes || !devices) {
      return <LoadingMessage />;
    }
    return (
      <div style={{ height: "100%" }}>
        <LoadingMessage />
        <GpsStatus
          {...{
            status,
            deliveryTypes,
            changeGpsStatus: this.changeGpsStatus,
            changeGpsDeliveryType: this.changeGpsDeliveryType
          }}
        />
        <HelpText ymaps={ymaps} devices={devices} />
        <ButtonsPanel
          {...{
            activeButton,
            buttons: this.buttons,
            toggleActiveButton: this.toggleActiveButton,
            ymaps
          }}
        >
          <Devices
            {...{
              devices,
              addDeviceIsOpen,
              deviceName,
              deviceCode,
              selectedDevice,
              activeButton: activeButton === 2,
              editDeviceId,
              changeInputValue: this.changeInputValue,
              ...this.devicesMethods,
              isWatch,
              selectedDeviceIsWatch,
              getWatchSettings: this.devicesMethods.getWatchSettings,
              watchSettings,
              checkWatchConnection: this.devicesMethods.checkWatchConnection,
              saveWatchSettings: this.devicesMethods.saveWatchSettings,
              gpsWatchReboot: this.devicesMethods.gpsWatchReboot,
              gpsWatchPowerOff: this.devicesMethods.gpsWatchPowerOff,
              gpsWatchFind: this.devicesMethods.gpsWatchFind
            }}
          />
          <Zones
            {...{
              addZoneIsOpen,
              zoneName,
              zonePoints,
              zones,
              selectedZone,
              editZone,
              activeButton: activeButton === 3,
              changeInputValue: this.changeInputValue,
              ...this.zonesMethods
            }}
          />
          <History
            {...{
              historyStart,
              historyEnd,
              selectedDevice,
              activeButton: activeButton === 4,
              selectDateAndTime: this.devicesMethods.selectDateAndTime,
              getDeviceHistory: this.devicesMethods.getDeviceHistory
            }}
          />
        </ButtonsPanel>

        <MapView
          {...{
            mapCenter,
            mapZoom,
            schoolName,
            changeMapCenter: this.changeMapCenter,
            addZoneCoordinates: this.zonesMethods.addZoneCoordinates,
            setYmaps: this.setYmaps,
            setMapRef: this.setMapRef
          }}
        >
          <MapZones
            {...{
              zones,
              selectedZone,
              selectZone: this.zonesMethods.selectZone,
              addZoneIsOpen,
              addZoneCoordinates: this.zonesMethods.addZoneCoordinates
            }}
          />
          <NewZone {...{ zonePoints }} />
          <HistoryCoordinatesPlacemarks {...{ history, ymaps }} />
          <HistoryCoordinatesLines {...{ history }} />
          <LastCoordinatesPlacemark {...{ lastCoordinates, selectedDevice }} />
        </MapView>
      </div>
    );
  }
}

Gps.propTypes = {
  user: PropTypes.shape({
    schools: PropTypes.object.isRequired,
    selectedSchool: PropTypes.number.isRequired
  }).isRequired,
  gps: PropTypes.shape({
    deliveryTypes: PropTypes.array,
    devices: PropTypes.array,
    lastCoordinates: PropTypes.object,
    history: PropTypes.array,
    status: PropTypes.object,
    zones: PropTypes.array,
    watchSettings: PropTypes.object
  }).isRequired,
  gpsActions: PropTypes.object.isRequired,
  watchSettings: PropTypes.shape({
    profile: PropTypes.number,
    upload: PropTypes.number,
    center: PropTypes.string,
    slave: PropTypes.string,
    lowChargeSms: PropTypes.bool,
    sosSms: PropTypes.bool,
    removeSms: PropTypes.bool,
    langNum: PropTypes.number,
    timeZone: PropTypes.number,
    sosPhones: PropTypes.array,
    silenceTimes: PropTypes.array
  })
};

export default connect(
  state => ({ user: state.user, gps: state.gps }),
  dispatch => ({
    gpsActions: bindActionCreators(gpsActions, dispatch)
  })
)(Gps);
