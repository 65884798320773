import React from "react";
import { DayPickerSingleDateController } from "react-dates";

const MonthCalendar = props => {
  const { selectedDate, setMonth, setDate, renderDays, renderMonthCaption, CalendarInfo } = props;

  return (
    <React.Fragment>
      <DayPickerSingleDateController
        numberOfMonths={1}
        noBorder
        date={selectedDate}
        daySize={40}
        transitionDuration={0}
        hideKeyboardShortcutsPanel
        onPrevMonthClick={month => setMonth(month)}
        onNextMonthClick={month => setMonth(month)}
        onDateChange={day => setDate(day)}
        renderMonthElement={({ month, onMonthSelect, onYearSelect }) =>
          renderMonthCaption({ month, onMonthSelect, onYearSelect })
        }
        renderDayContents={day => renderDays(day)}
      />
      {CalendarInfo ? <CalendarInfo /> : null}
    </React.Fragment>
  );
};

export default MonthCalendar;
