import React from "react";
import PropTypes from "prop-types";
import Typography from "@material-ui/core/Typography";
import Add from "@material-ui/icons/Add";
import withStyles from "@material-ui/core/styles/withStyles";
import GpsWatchSettings from "./watchSettings/GpsWatchSettings";

import panelsStyles from "../styles";

const styles = theme => ({
  ...panelsStyles(theme)
});

const PanelTitle = props => {
  const {
    classes,
    addDeviceIsOpen,
    addDeviceOpen,
    selectedDevice,
    selectedDeviceIsWatch,
    getWatchSettings,
    saveWatchSettings,
    watchSettings,
    checkWatchConnection,
    gpsWatchReboot,
    gpsWatchPowerOff,
    gpsWatchFind
  } = props;
  return (
    <div className={classes.header}>
      {addDeviceIsOpen && (
        <Typography color="inherit" variant="h6">
          Добавление устройства
        </Typography>
      )}
      {!addDeviceIsOpen && selectedDevice && (
        <Typography color="inherit" variant="h6">
          Выбрано устройство
        </Typography>
      )}
      {!addDeviceIsOpen && !selectedDevice && (
        <Typography color="inherit" variant="h6">
          Устройства
        </Typography>
      )}
      {!addDeviceIsOpen && !selectedDeviceIsWatch ? (
        <Add
          fontSize="large"
          color="secondary"
          className={classes.icon}
          style={{ paddingRight: 12 }}
          onClick={() => addDeviceOpen()}
        />
      ) : (
        <GpsWatchSettings
          {...{
            selectedDeviceIsWatch,
            getWatchSettings,
            saveWatchSettings,
            watchSettings,
            checkWatchConnection,
            gpsWatchReboot,
            gpsWatchPowerOff,
            gpsWatchFind
          }}
        />
      )}
    </div>
  );
};

export default withStyles(styles)(PanelTitle);

PanelTitle.propTypes = {
  classes: PropTypes.object,
  addDeviceIsOpen: PropTypes.bool.isRequired,
  addDeviceOpen: PropTypes.func.isRequired,
  selectedDevice: PropTypes.number,
  isWatch: PropTypes.bool,
  selectedDeviceIsWatch: PropTypes.bool,
  getWatchSettings: PropTypes.func.isRequired,
  saveWatchSettings: PropTypes.func.isRequired,
  watchSettings: PropTypes.object,
  checkWatchConnection: PropTypes.func.isRequired,
  gpsWatchReboot: PropTypes.func.isRequired,
  gpsWatchPowerOff: PropTypes.func.isRequired,
  gpsWatchFind: PropTypes.func.isRequired
};
