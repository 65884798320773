import React from 'react';
import PropTypes from 'prop-types';
import Hidden from '@material-ui/core/Hidden';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Typography from '@material-ui/core/Typography';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Input from '@material-ui/core/Input';
import InputAdornment from '@material-ui/core/InputAdornment';
import Button from '@material-ui/core/Button';

import { withStyles } from '@material-ui/core/styles';
import { errorOnValidateAmount } from '../../../helpers/validate';

const styles = () => ({
  container: {
    padding: 5,
  },
  expansion: {
    border: '1px solid lightgrey',
    padding: 3,
  },
  expansionTitle: {
    padding: '0 0 0 10px',
  },
  details: {
    display: 'flex',
    flexDirection: 'column',
    padding: 0,
  },
  row: { display: "flex", alignItems: "baseline" },
  amount: {
    paddingBottom: 5,
    paddingRight: 15,
  },
  amountMobile: {
    paddingBottom: 5,
  },
  input: {
    fontSize: 25,
  },
});

const helpText = "Введите сумму, которой будут ограничены покупки в буфете в течении суток. Установите 0, чтобы отключить лимит.";

const DayLimit = props => {
  const {
    classes, dayLimit, changeDayLimitValue, submitDayLimit,
  } = props;
  return (
    <div className={classes.container}>
      <Hidden xsDown>
        <ExpansionPanel elevation={0} className={classes.expansion}>
          <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />} className={classes.expansionTitle}>
            <Typography variant="subtitle2">Установить суточный лимит</Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails className={classes.details}>
            <Typography variant="caption" paragraph>
              {helpText}
            </Typography>
            <div className={classes.row}>
              <FormControl required className={classes.amount}>
                <InputLabel htmlFor="dayLimit">Сумма лимита</InputLabel>
                <Input
                  className={classes.input}
                  type="number"
                  value={dayLimit === '0.00' ? '0' : dayLimit}
                  error={errorOnValidateAmount(dayLimit)}
                  onChange={changeDayLimitValue}
                  endAdornment={<InputAdornment position="end">руб.</InputAdornment>}
                />
              </FormControl>
              <Button
                variant="contained"
                color="primary"
                disabled={errorOnValidateAmount(dayLimit)}
                onClick={submitDayLimit}
              >
                Установить
              </Button>
            </div>
          </ExpansionPanelDetails>
        </ExpansionPanel>
      </Hidden>
      <Hidden smUp>
        <Typography variant="caption" paragraph>
          {helpText}
        </Typography>
        <FormControl required className={classes.amountMobile}>
          <InputLabel htmlFor="dayLimit">Сумма лимита</InputLabel>
          <Input
            className={classes.input}
            type="number"
            value={dayLimit === '0.00' ? '0' : dayLimit}
            error={errorOnValidateAmount(dayLimit)}
            onChange={changeDayLimitValue}
            endAdornment={<InputAdornment position="end">руб.</InputAdornment>}
          />
        </FormControl>
        <Button
          variant="contained"
          color="primary"
          disabled={errorOnValidateAmount(dayLimit)}
          onClick={submitDayLimit}
        >
          Установить
        </Button>
      </Hidden>
    </div>
  );
};

DayLimit.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string),
  dayLimit: PropTypes.string.isRequired,
  changeDayLimitValue: PropTypes.func.isRequired,
  submitDayLimit: PropTypes.func.isRequired,
};

export default withStyles(styles)(DayLimit);
