import React, {Component} from "react";

import withStyles from "@material-ui/core/styles/withStyles";
import TextField from "@material-ui/core/TextField";
import ComboBox from "components/ComboBox";
import {Button, Divider, Typography} from "@material-ui/core";

const styles = theme => ({
  price: {
    minHeight: 60,
  },
  cardTypeName: {
    margin: theme.spacing.unit,
    minWidth: 120,
  }});

class AltCardData extends Component {

  componentDidUpdate(prevProps, prevState, snapshot) {
    //перезагружаем цвета если изменили исполнение пропуска
    if (prevProps.values.altCardKindId !== this.props.values.altCardKindId) {
      const {values} = this.props;
      if(typeof values.altCardTypeId === 'number' && Number(values.altCardTypeId) >= 0 &&
        typeof values.altCardKindId === 'number' && Number(values.altCardKindId) >= 0) {
        this.props.getShopAltCardsColors(values.altCardTypeId, values.altCardKindId);
      }
      this.props.setFieldValue('altCardColorId', null);
    }

    if(
      prevProps.values.pupilId !== this.props.values.pupilId ||
      prevProps.values.altCardKindId !== this.props.values.altCardKindId ||
      prevProps.values.altCardColorId !== this.props.values.altCardColorId) {
      const {values} = this.props;
      if(typeof values.pupilId === 'number' && Number(values.pupilId) >= 0 &&
        typeof values.altCardTypeId === 'number' && Number(values.altCardTypeId) >= 0 &&
        typeof values.altCardKindId === 'number' && Number(values.altCardKindId) >= 0 &&
        typeof values.altCardColorId === 'number' && Number(values.altCardColorId) >= 0) {
        this.props.getShopAltCardSetPrice(values.pupilId, values.altCardTypeId, values.altCardKindId, values.altCardColorId);
      }

    }
  }

  render() {
    const {
      classes, pupils,
      values, handleChange, setFieldValue,
      altCardType, altCardKinds, altCardColors, altCardSetPriceCent
    } = this.props;


    const isAltCardOrderIncomplete = values => {
      return !(typeof values.pupilId === 'number' && Number(values.pupilId) >= 0 &&
        typeof values.altCardTypeId === 'number' && Number(values.altCardTypeId) >= 0 &&
        typeof values.altCardKindId === 'number' && Number(values.altCardKindId) >= 0 &&
        typeof values.altCardColorId === 'number' && Number(values.altCardColorId) >= 0)
    }

    return (
      <>
        <ComboBox
          id="pupilId"
          label="Обучающийся *"
          labelWidth={130}
          items={pupils}
          selectedValue={values.pupilId}
          handleChange={(event) => {
            setFieldValue("pupilId", event.target.value);
          }}
          fullWidth
        />
        <TextField
          className={classes.cardTypeName}
          id="altCardTypeId"
          label="Пропуск *"
          defaultValue={altCardType.name}
          InputProps={{
            readOnly: true,
          }}
          variant="outlined"
          fullWidth
        />
        <ComboBox
          id="altCardKindId"
          label="Исполнение *"
          labelWidth={110}
          items={altCardKinds}
          selectedValue={values.altCardKindId}
          handleChange={(event) => {
            setFieldValue("altCardKindId", event.target.value);
          }}
          fullWidth
        />
        <ComboBox
          id="altCardColorId"
          label="Цвет *"
          labelWidth={55}
          items={altCardColors}
          selectedValue={values.altCardColorId}
          fullWidth
          handleChange={(event) => {
            setFieldValue("altCardColorId", event.target.value);
          }}
        />
        <Divider variant="middle"/>
        <div className={classes.price}>
          <Typography variant="h6">Стоимость: {((altCardSetPriceCent || 0) / 100).toFixed(2) || '-'} р.</Typography>
          <Typography variant="caption">Заказ будет принят к исполнению, только после оплаты. Срок исполнения заказа от 3 недель со дня оплаты</Typography>
          <Typography variant="caption">Обращаем внимание. Так как пропуска выдаются через ответственное лицо в образовательном учреждении,
            то заказы выдаются только в учебные периоды года.</Typography>
        </div>
        <Button
          variant="contained"
          color="primary"
          type="submit"
          disabled={isAltCardOrderIncomplete(values)}
        >Купить</Button>
      </>
    )
  }
}

export default withStyles(styles)(AltCardData);
