import React, { PureComponent } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

import * as userActions from "actions/userActions";

const mapStateToProps = state => {
  return {
    user: state.user
  };
};

const mapDispatchToProps = dispatch => {
  return {
    userActions: bindActionCreators(userActions, dispatch)
  };
};

const styles = theme => ({
  form: {
    marginTop: 5,
    height: 40
  },
  pupilName: {
    fontSize: 20
  },
  input: {
    color: "white"
  }
});

export class PupilSelect extends PureComponent {
  pupilChange = event => {
    if (event.target.value !== this.props.user.selectedPupil) {
      this.props.userActions.changePupil(event.target.value);
    }
  };

  render() {
    const { user, classes } = this.props;
    const selectedSchool = user.selectedSchool;
    const profile = user.schools[selectedSchool];
    if (!this.props.showPupils) {
      return null;
    } else {
      if (!user.schools || !user.selectedPupil) {
        return "загрузка";
      } else {
        return (
          <FormControl className={classes.form}>
            <InputLabel focused={false}>Ученик</InputLabel>
            <Select
              className={classes.input}
              disableUnderline
              value={user.selectedPupil}
              onChange={this.pupilChange}
            >
              {profile.pupils.ids
                .map(pupilId => (
                  <MenuItem key={pupilId} value={pupilId}>
                    <span className={classes.pupilName}>
                      {profile.pupils[pupilId].im} {profile.pupils[pupilId].className}
                    </span>
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        );
      }
    }
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(PupilSelect));
