import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import MainMenu from "./MainMenu";
import MenuToggle from "./MenuToggle";

import Profile from "components/home/profile";
//import Events from "components/home/events";
import Attendance from "components/home/attendance";
import Meals from "components/home/meals";
import Tariffs from "components/home/tariffs";
import Archive from "components/home/archive";
import Pays from "components/home/pays";
import Insurance from "components/home/insurance";
import Gps from "components/home/gps";
import Shop from "components/home/shop";
import Help from "components/home/help";

// иконки для меню
import AccountBox from "@material-ui/icons/AccountBox";
import Place from "@material-ui/icons/Place";
// import NotificationImportant from "@material-ui/icons/NotificationImportant";
import TransferWithinAStation from "@material-ui/icons/TransferWithinAStation";
import Restaurant from "@material-ui/icons/Restaurant";
import VerifiedUser from "@material-ui/icons/VerifiedUser";
import Ballot from "@material-ui/icons/Ballot";
import DateRange from "@material-ui/icons/DateRange";
import CreditCard from "@material-ui/icons/CreditCard";
import HelpSharp from "@material-ui/icons/HelpSharp";
import Store from "@material-ui/icons/Store";
import PowerSettingsNew from "@material-ui/icons/PowerSettingsNew";

const mapStateToProps = state => {
  return {
    user: state.user
  };
};

export const menuList = [
  {
    id: "profile",
    name: "Профиль",
    icon: <AccountBox fontSize="large" />,
    showPupils: false,
    component: Profile
  },
  // {
  //   id: 'events',
  //   name: 'События',
  //   icon: <NotificationImportant fontSize="large" />,
  //   showPupils: false,
  //   component: Events
  // },
  {
    id: "attendance",
    name: "Посещаемость",
    icon: <TransferWithinAStation fontSize="large" />,
    showPupils: true,
    component: Attendance
  },
  {
    id: "meals",
    name: "Питание",
    icon: <Restaurant fontSize="large" />,
    showPupils: true,
    component: Meals
  },
  {
    id: "tariffs",
    name: "Тарифы",
    icon: <Ballot fontSize="large" />,
    showPupils: false,
    component: Tariffs
  },
  {
    id: "archive",
    name: "Архив сообщений",
    icon: <DateRange fontSize="large" />,
    showPupils: false,
    component: Archive
  },
  {
    id: "pays",
    name: "Оплата и финансы",
    icon: <CreditCard fontSize="large" />,
    showPupils: false,
    component: Pays
  },
  {
    id: "insurance",
    name: "Страхование",
    icon: <VerifiedUser fontSize="large" />,
    showPupils: false,
    component: Insurance
  },
  {
    id: "gps",
    name: "GPS-мониторинг",
    icon: <Place fontSize="large" />,
    showPupils: false,
    component: Gps
  },
  {
    id: "shop",
    name: "Магазин",
    icon: <Store fontSize="large" />,
    showPupils: false,
    component: Shop,
    availableDilers: [0, 6],
    subRoutes: [
      {
        id: "confirm-order",
      }
    ]
  },
  {
    id: "help",
    name: "Помощь",
    icon: <HelpSharp fontSize="large" />,
    showPupils: false,
    component: Help
  },
  {
    id: "logout",
    name: "Выход",
    icon: <PowerSettingsNew fontSize="large" />,
    showPupils: false
  }
];
class Menu extends Component {
  state = {
    open: false
  };

  handleDrawerToggle = () => {
    this.setState({ open: !this.state.open });
  };

  render() {
    const { schools, selectedSchool } = this.props.user;
    const needToPay = schools[selectedSchool].parent.needToPay;
    const schoolMenu = schools[selectedSchool].menu;
    const parent = schools[selectedSchool].parent;
    const pupilsIds = schools[selectedSchool].pupils.ids;
    const profile = schools[selectedSchool];
    const parentDilerId = profile.parentDilerId;
    return (
      <React.Fragment>
        <MenuToggle handleDrawerToggle={this.handleDrawerToggle} />
        <MainMenu
          settings={this.state}
          parentDilerId={parentDilerId}
          menuList={menuList}
          handleDrawerToggle={this.handleDrawerToggle}
          needToPay={needToPay}
          locationPath={this.props.locationPath}
          schoolMenu={schoolMenu}
          parent={parent}
          pupilsIds={pupilsIds}
        />
      </React.Fragment>
    );
  }
}

export default connect(mapStateToProps)(withRouter(Menu));
