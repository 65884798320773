import { createSelector } from "reselect";

const getKhalvaRegions = state => state.khalva.regions;

export const getKhalvaRegionsSelector = createSelector(
  [getKhalvaRegions],
  regions => {
    if (!regions) return [];
    return regions.map(region => ({
      value: region.id,
      label: region.name,
    }));
  }
);
