import React, { Component } from "react";
import { connect } from "react-redux";
import moment from "moment";

import ArchiveTable from "./ArchiveTable";
import Header from "./Header";
import Filter from "./Filter";

import LoadingMessage from "components/messages/LoadingMessage";

import { getArchiveMessages } from "actions/archiveActions";
import { generateErrorMessage } from "actions/errorsActions";

const mapStateToProps = ({ user, archive }) => ({
  user,
  archive
});

const mapDispatchToProps = {
  getArchiveMessages,
  generateErrorMessage
};

const eventTypes = [
  {
    id: 1,
    name: "Пополнение карты"
  },
  { id: 2, name: "Расход по карте" },
  { id: 3, name: "Вход в школу" },
  { id: 4, name: "Выход из школы" },
  // { id: 5, name: "Оценки" },
  //  { id: 6, name: "Отсутствие в шк. или опоздание в шк." },
  { id: 100, name: "Выставлен счет" },
  //  { id: 200, name: "Событие безопасности" },
  { id: 300, name: "Начало тестового периода" },
  { id: 400, name: "Платеж" },
  { id: 500, name: "Недостаточно денег на карте" },
  { id: 600, name: "Шкафы" },
  { id: 700, name: "Карты" },
  { id: 301, name: "Конец тестового периода" },
  { id: 900, name: "Страхование" },
  { id: 102, name: "Изменен тариф" },
  { id: 800, name: "Спутниковый трекинг" },
  { id: 101, name: "Информационное оповещение" }
];

eventTypes.sort((a, b) => {
  if (a.name > b.name) {
    return 1;
  } else if (a.name < b.name) {
    return -1;
  } else return 0;
});
class Archive extends Component {
  state = {
    startDate: new Date(),
    endDate: new Date(),
    filterIsOpen: false,
    pupil: -1,
    event: -1
  };

  componentDidMount() {
    const { selectedSchool, schools } = this.props.user;
    const parentId = schools[selectedSchool].parent.id;
    const startDate = moment(this.state.startDate).format("YYYY-MM-DD");
    const endDate = moment(this.state.endDate).format("YYYY-MM-DD");
    this.props.getArchiveMessages(parentId, startDate, endDate);
  }

  componentDidUpdate(prevProps) {
    // после переключения школы грузим сообщения по новой и сбрасываем фильтр
    if (prevProps.user.selectedSchool !== this.props.user.selectedSchool) {
      const { selectedSchool, schools } = this.props.user;
      const parentId = schools[selectedSchool].parent.id;
      const startDate = moment(this.state.startDate).format("YYYY-MM-DD");
      const endDate = moment(this.state.endDate).format("YYYY-MM-DD");
      this.props.getArchiveMessages(parentId, startDate, endDate);
      this.setState({
        pupil: -1,
        event: -1
      });
    }
  }

  changeDate = (type, newDate) => {
    this.setState({ [type]: newDate });
  };

  submitClick = () => {
    if (moment(this.state.endDate).diff(moment(this.state.startDate), "months", true) > 3) {
      this.props.generateErrorMessage("Нельзя выбирать период более 3-х месяцев", 400);
      return;
    }
    const { selectedSchool, schools } = this.props.user;
    const parentId = schools[selectedSchool].parent.id;
    const startDate = moment(this.state.startDate).format("YYYY-MM-DD");
    const endDate = moment(this.state.endDate).format("YYYY-MM-DD");
    this.props.getArchiveMessages(parentId, startDate, endDate);
  };

  filter = {
    open: () => {
      this.setState({ filterIsOpen: true });
    },
    close: () => {
      this.setState({ filterIsOpen: false });
    },
    pupilSelect: pupilId => {
      this.setState({ pupil: pupilId });
    },
    eventSelect: eventId => {
      this.setState({ event: eventId });
    },
    clear: () => {
      this.setState({
        pupil: -1,
        event: -1
      });
    }
  };

  render() {
    const { messages } = this.props.archive;
    const { selectedSchool, schools } = this.props.user;
    const { startDate, endDate, filterIsOpen, pupil, event } = this.state;
    const pupils = schools[selectedSchool].pupils;
    if (messages === undefined) {
      return <LoadingMessage />;
    } else {
      return (
        <>
          <LoadingMessage />
          <Header
            {...{ startDate, endDate, pupil, event }}
            filter={this.filter}
            changeDate={this.changeDate}
            submitClick={this.submitClick}
          />
          <ArchiveTable {...{ messages, pupil, event }} />
          <Filter {...{ filterIsOpen, pupil, event, pupils, eventTypes }} filter={this.filter} />
        </>
      );
    }
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Archive);
