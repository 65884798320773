import { handleActions } from "redux-actions";
import { fetchArchiveSuccess } from "actions/archiveActions";

const initialState = {};

const arhive = handleActions(
  {
    [fetchArchiveSuccess]: (state, { payload: messages }) => ({ ...state, messages: messages })
  },
  initialState
);

export default arhive;
