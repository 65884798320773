import React from "react";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import withStyles from "@material-ui/core/styles/withStyles";


const styles = theme => ({
  link: {
    color: "#0000ff",
    '&visited': {color: "#0000ff"},
    '&active': {color: "#0000ff"},
  }
});

const DocumentsKL = (props) => {
  const {classes} = props;
  return (
    <div style={{padding: 5}}>
      <Typography variant="button" paragraph>
        Документы по страхованию «Капитал Лайф Страхование Жизни»
      </Typography>
      <Divider/>
      <Typography>
        Подробную информацию о страховании от несчастного случая и COVID-19 вы можете узнать <a className={classes.link}
                                                                                                href="/docs/A+COVID-19.pdf"
                                                                                                target="_blank">здесь</a>
      </Typography>
      <Typography>
        <a className={classes.link} href="/docs/regulationsKL.pdf" target="_blank">
          Программа добровольного индивидуального страхования учащихся общеобразовательных организаций №1
        </a>
      </Typography>
      <Typography>
        <a className={classes.link} href="/docs/amountKL.pdf" target="_blank">Таблица размеров страховых выплат №2</a>
      </Typography>
    </div>
  );
};

export default withStyles(styles)(DocumentsKL);
