// reducer для глобальных ошибок
const initialState = {
  error: "",
  errorStatus: null
};

export default function errors(state = initialState, action) {
  if (action.type.match(/_ERROR$/)) {
    return { ...state, error: action.payload.error, errorStatus: action.payload.errorStatus };
  }
  if (action.type.match(/_SUCCESS|_REQUEST/)) {
    return { ...state, error: "", errorStatus: null };
  }
  return state;
}
