import axios from "axios";
import { API, handleApiError } from "api";
import { createAction } from "redux-actions";

export const fetchMonthlyAttendanceRequest = createAction("FETCH_MONTHLY_ATTENDANCE_REQUEST");
export const fetchMonthlyAttendanceSuccess = createAction("FETCH_MONTHLY_ATTENDANCE_SUCCESS");
export const fetchMonthlyAttendanceError = createAction("FETCH_MONTHLY_ATTENDANCE_ERROR");

export const fetchDailyAttendanceRequest = createAction("FETCH_DAILY_ATTENDANCE_REQUEST");
export const fetchDailyAttendanceSuccess = createAction("FETCH_DAILY_ATTENDANCE_SUCCESS");
export const fetchDailyAttendanceError = createAction("FETCH_DAILY_ATTENDANCE_ERROR");

export const fetchMonthlyAttendance = (pupilId, dateStart, dateEnd) => async dispatch => {
  try {
    dispatch(fetchMonthlyAttendanceRequest());
    const res = await axios.get(
      `${API}/attendance/calendar-stat/${pupilId}?dateStart=${dateStart}&dateEnd=${dateEnd}`
    );
    dispatch(fetchMonthlyAttendanceSuccess(res.data));
  } catch (error) {
    dispatch(handleApiError(error, fetchMonthlyAttendanceError()));
  }
};

export const fetchDailyAttendance = (pupilId, dateStart, dateEnd) => async dispatch => {
  try {
    dispatch(fetchDailyAttendanceRequest());
    const res = await axios.get(
      `${API}/attendance/daily-stat/${pupilId}?dateStart=${dateStart}&dateEnd=${dateEnd}`
    );
    dispatch(fetchDailyAttendanceSuccess(res.data));
  } catch (error) {
    dispatch(handleApiError(error, fetchDailyAttendanceError()));
  }
};
