import { handleActions } from "redux-actions";
import {
  fetchTransportCardOperationsRequest,
  fetchTransportCardOperationsSuccess,
  fetchTransportCardOperationsError,
  fetchTransportCardReplenishmentUrlSuccess,
  fetchTransportCardPurchaseUrlSuccess,
  transportCardPayUrlClear
} from "actions/transportCardActions";

const initialState = {
  operations: null,
  payUrl: null
};

const transportCard = handleActions(
  {
    [fetchTransportCardOperationsRequest]: state => ({ ...state, operations: null }),
    [fetchTransportCardOperationsSuccess]: (state, action) => ({
      ...state,
      operations: action.payload.operations,
      isLoading: false
    }),
    [fetchTransportCardOperationsError]: state => ({ ...state, operations: null }),
    [fetchTransportCardReplenishmentUrlSuccess]: (state, action) => ({
      ...state,
      payUrl: action.payload.payUrl
    }),
    [fetchTransportCardPurchaseUrlSuccess]: (state, action) => ({
      ...state,
      payUrl: action.payload.payUrl
    }),
    [transportCardPayUrlClear]: state => ({
      ...state,
      payUrl: null
    })
  },
  initialState
);

export default transportCard;
