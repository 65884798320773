import React, {Component} from "react";
import PropTypes from "prop-types";
import {Formik} from "formik";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import Button from "@material-ui/core/Button";

import StepButton from "@material-ui/core/StepButton";
import StepContent from "@material-ui/core/StepContent";
import ErrorMessage from "components/messages/ErrorMessage";
import InfoMessage from "components/messages/InfoMessage";
import LoadingMessage from "components/messages/LoadingMessage";

import {connect} from "react-redux";
import {withStyles} from "@material-ui/core/styles";

import {clearRegform, changeStep, checkPhone, getRegform, sendRegform} from "actions/registrationActions";
import Step1 from "./Step1";
import Step2 from "./Step2";
import Step3 from "./Step3";
import Step4 from "./Step4";
import Step5 from "./Step5";
import Step6 from "./Step6";
import RegHeader from "../header/RegHeader";
import FooterElements from "../FooterElements";

const mapStateToProps = state => ({
  registration: state.registration,
  loading: state.loading,
  link: state.registration.link
});

const mapDispatchToProps = {
  clearRegform,
  changeStep,
  checkPhone,
  getRegform,
  sendRegform
};

const styles = theme => ({
  root: {
    maxWidth: 1300,
    height: "auto",
    position: "relative",
    display: "flex",
    flexDirection: "column",
    margin: "0 auto",
    background: "white",
    borderRight: "1px solid rgba(0, 0, 0, 0.12)",
    borderLeft: "1px solid rgba(0, 0, 0, 0.12)"
  },
  container: {
    paddingTop: 80,
    display: "flex",
    flexDirection: "column",
    // [theme.breakpoints.down("sm")]: {
    //   paddingTop: 80
    // }
  },
  containerChild: {
    paddingTop: 0,
    display: "flex",
    flexDirection: "column",
    // [theme.breakpoints.down("sm")]: {
    //   paddingTop: 80
    // }
  },
  backButton: {
    maxWidth: 300,
    marginBottom: 20
  },
  stepper: {
    width: "calc(100% - 270px)",
    [theme.breakpoints.down("sm")]: {
      padding: "10px 5px",
      width: "calc(100% - 10px)"
    }
  },
  footer: {
    minHeight: 50,
    display: "fixed",
    bottom: 0,
    zIndex: theme.zIndex.drawer + 1,
    flexShrink: 0
  }
});

class Registration extends Component {
  componentDidMount() {
    this.props.clearRegform();
    if (!!this.props.parentId) {
      this.props.getRegform(this.props.parentId)
    } else {
      const {parentDilerIds} = this.props;
      const parentDilerId = (!!parentDilerIds) ? (parentDilerIds[0] || parentDilerIds) : parentDilerIds;
      this.props.changeThemeFaviconAndTitle(parentDilerId, 'Заполнение регистрационной формы')
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.parentId !== this.props.parentId) {
      this.props.getRegform(this.props.parentId)
    }
  }

  goToLogin = () => {
    if (!!this.props.parentId && this.props.onToggle) {
      this.props.onToggle()
    } else {
      window.location.href = "/";
    }
  };

  handleEnter = function (event) {
    if (event.keyCode === 13) {
      // event.preventDefault();
      const form = event.target.form;
      let index = Array.prototype.indexOf.call(form, event.target);
      let next, preNext;
      do {
        next = form.elements[++index];
        if (!preNext && next && (['BUTTON'].indexOf(next.tagName) >= 0 || next.type !== 'hidden'))
          preNext = next;
      } while (next && (['INPUT', 'SELECT'].indexOf(next.tagName) < 0 || next.type === 'hidden'))

      if (next) {
        next.focus()
      } else if (preNext && (['INPUT', 'BUTTON'].indexOf(preNext.tagName) >= 0 || preNext.type !== 'hidden')) {
        preNext.focus();
      }
    }
  }

  render() {
    const {classes, registration, changeStep, checkPhone, parentId, parentDilerIds, dilerId, regionId} = this.props;
    const parentDilerId = (!!parentDilerIds) ? (parentDilerIds[0] || parentDilerIds) : parentDilerIds;

    const {step, regformData, isNewPhone, isNewPhoneChecked} = registration
    const initParent = {
      parentId: (parentId || null),
      phone: "",
      email: "",
      smsCode: "",
      fam: "",
      im: "",
      otch: "",
      birthDay: "",
      birthPlace: "",
      parentDocTypeId: 1,
      parentDocSeries: "",
      parentDocNumber: "",
      parentDocDate: "",
      parentDocIssuer: "",
      region: null,
      area: null,
      city: null,
      locality: null,
      structure: null,
      street: null,
      house: null,
      apartment: "",
      acceptLegal: true,
      grantData: true,
    };
    const initPupil = {
      fam: "",
      im: "",
      otch: "",
      birthDay: "",
      degreeOfKinship: "",
      otherDoc: "",
      docSeries: "",
      docNumber: "",
      docDate: "",
      docIssuer: "",
      cardNumber: null,
      schoolId: null,
      classId: null,
      testPeriodOff: false
    };
    if (!!regformData) {
      regformData['parentId'] = parentId
      regformData['apartment'] = ''
      regformData['region'] = regformData['region'] || null
      regformData['area'] = regformData['area'] || null
      regformData['city'] = regformData['city'] || null
      regformData['locality'] = regformData['locality'] || null
      regformData['street'] = regformData['street'] || null
      regformData['house'] = regformData['house'] || null
      if (regformData.children.length < 1){
        regformData.children.push(initPupil);
      }
    }

    return (
      <div className={classes.root}>
        <InfoMessage/>
        <ErrorMessage/>
        <LoadingMessage/>
        {!parentId && (<RegHeader
          parentDilerId={parentDilerId || 6}
          title={'Заполнение данных регистрационной формы'}
          goToLogin={this.goToLogin}
        />)}
        <div className={(!parentId) ? classes.container : classes.containerChild}>
          <Formik
            enableReinitialize={!!parentId}
            initialValues={(!!regformData) ? regformData : {
              ...initParent,
              children: [initPupil]
            }}
            onSubmit={(values, {setSubmitting}) => {
              this.props.sendRegform(values);
            }}
          >
            {val => {
              const {values, handleChange, setFieldValue, handleSubmit} = val;
              return (
                <form onSubmit={handleSubmit}>
                  <Stepper className={classes.stepper} activeStep={step} orientation="vertical">
                    <Step>
                      <StepButton onClick={() => changeStep(0)}>
                        Описание регистрационной формы
                      </StepButton>
                      <StepContent>
                        <Step1 {...{changeStep, parentDilerId}} handleEnter={this.handleEnter}/>
                      </StepContent>
                    </Step>
                    <Step>
                      <StepButton onClick={() => changeStep(1)}>
                        Данные законного представителя
                      </StepButton>
                      <StepContent>
                        <Step2 {...{changeStep, values, handleChange, setFieldValue, isNewPhone, isNewPhoneChecked}}
                               handleEnter={this.handleEnter} checkPhone={checkPhone}/>
                      </StepContent>
                    </Step>
                    <Step>
                      <StepButton onClick={() => changeStep(2)}>
                        Адрес законного представителя
                      </StepButton>
                      <StepContent>
                        <Step3 {...{changeStep, values, setFieldValue, handleChange, parentDilerIds, regionId}}
                               handleEnter={this.handleEnter}/>
                      </StepContent>
                    </Step>
                    <Step>
                      <StepButton onClick={() => changeStep(3)}>Данные ученика</StepButton>
                      <StepContent>
                        <Step4 {...{changeStep, values, setFieldValue, initPupil, parentDilerIds, dilerId}}
                               handleEnter={this.handleEnter}/>
                      </StepContent>
                    </Step>
                    <Step>
                      <StepButton onClick={() => changeStep(4)}>Согласие с условиями и подтверждение
                        телефона</StepButton>
                      <StepContent>
                        <Step5 {...{changeStep, values, handleChange, setFieldValue, parentDilerId}} handleEnter={this.handleEnter}/>
                      </StepContent>
                    </Step>
                    <Step>
                      <StepButton onClick={() => changeStep(5)}>
                        Сохранить регистрационную форму
                      </StepButton>
                      <StepContent>
                        <Step6/>
                      </StepContent>
                    </Step>
                  </Stepper>
                </form>
              );
            }}
          </Formik>
          {!!parentId && (<Button
            className={classes.backButton}
            variant="outlined"
            color="primary"
            size="large"
            onClick={this.goToLogin}
          >
            {(!!parentId && this.props.onToggle) ? "Закрыть" : "Вход в Личный Кабинет"}
          </Button>)}
        </div>
        {!parentId && (<footer className={classes.footer}>
          <FooterElements dilerId={parentDilerId || 6}/>
        </footer>)}
      </div>
    );
  }
}

Registration.propTypes = {
  classes: PropTypes.object,
  parentId: PropTypes.number,
  regionId: PropTypes.number,
  dilerId: PropTypes.number,
  parentDilerIds: PropTypes.oneOfType([PropTypes.oneOf([null]), PropTypes.number, PropTypes.array]).isRequired,
  registration: PropTypes.shape({
    step: PropTypes.number.isRequired,
    errors: PropTypes.object
  }),
  changeStep: PropTypes.func.isRequired,
  sendRegform: PropTypes.func.isRequired,
  onToggle: PropTypes.func,
  changeThemeFaviconAndTitle: PropTypes.func,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(Registration));
