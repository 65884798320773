import React, {Component} from "react";
import {withRouter} from "react-router-dom";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import SwipeableViews from "react-swipeable-views";
import LoadingMessage from "components/messages/LoadingMessage";
import AppBarWithTabs from "components/AppBarWithTabs";

import Pay from "./pays/Pay";
import Transfer from "./transfers/Transfer";
import OpenUrl from "./pays/OpenUrl";
import Invoices from "./invoices/Invoices";

import {withStyles} from "@material-ui/core/styles";

import * as paysActions from "actions/paysActions";

const mapStateToProps = state => {
  return {
    user: state.user,
    pays: state.pays
  };
};

const mapDispatchToProps = dispatch => {
  return {
    paysActions: bindActionCreators(paysActions, dispatch)
  };
};

const styles = theme => ({
  container: {
    height: "100%",
    display: "flex",
    flexDirection: "column"
  },
  swipeViews: {
    height: "100%",
    [theme.breakpoints.down("xs")]: {
      height: "100%"
    }
  }
});

class Pays extends Component {
  constructor(props) {
    super(props);

    this.selectedSchool = this.props.user.selectedSchool;
    this.profile = this.props.user.schools[this.selectedSchool];
    this.defaultPayType = (this.profile.balances.hasOwnProperty("1"))
      ? this.profile.balances[1]
      : this.profile.balances[this.profile.balances.ids[0]];

    this.state = {
      payStep: 0,
      payType: this.defaultPayType,
      transferStep: 0,
      amount: "",
      promoCode: "",
      autoPayment: -1,
      transferAmount: "",
      pupil: undefined,
      tabIndex: 0,
      year: new Date().getFullYear() + "",
      month: "",
      paperInvoice: {
        // invoiceType: "service",
        invoiceType: "card",
        serviceId: 1,
        pupil: null,
        amount: ""
      }
    };

    this.pays = {
      selectPayType: payType => {
        let autoPayment = (payType.id !== this.state.autoPayment) ? -1 : this.state.autoPayment;
        this.setState({payType: payType , autoPayment: autoPayment});
      },
      selectPupilPay: pupil => {
        this.setState({pupil: pupil});
      },
      changeStep: (direction, newStep) => {
        // меняем шаг, либо по направлению direction, если указано,
        // либо на конкретный указанный шаг, если в direction не указано направление
        if (direction === "forward") {
          //если введен промокод, то переход к выбору детей
          if (this.state.payType.id === 1 && this.state.promoCode && this.state.payStep === 0) {
            this.setState({
              payStep: 1
            });
          } else
          // если не питание переходим к вводу суммы
          if (this.state.payType.id !== 0 && this.state.payStep === 0) {
            this.setState({
              payStep: this.state.isFoodActive ? 2 : 1
            });
          } else {
            this.setState({
              payStep: this.state.payStep + 1
            });
          }
        } else if (direction === "backward") {
          //если введен промокод то переход к выбору детей
          if (this.state.payType.id === 1 && this.state.promoCode && this.state.payStep === 2) {
            this.setState({
              payStep: 1
            });
          } else
          // если не питание и на вводе суммы, переходим к выбору услуг
          if (this.state.payType.id !== 0 && this.state.payStep === 2) {
            this.setState({
              payStep: 0,
              amount: ""
            });
          } else if (this.state.payType.id === 0 && this.state.payStep === 2) {
            this.setState({
              payStep: 1,
              amount: ""
            });
          } else {
            this.setState({
              payStep: this.state.payStep - 1
            });
          }
        } else {
          if (
            this.state.payType &&
            this.state.payType.id !== 0 &&
            newStep === 1
          ) {
            return;
          } else {
            this.setState({payStep: newStep, amount: "", pupil: {}});
          }
        }
      },
      onEnterPress: () => event => {
        if (
          event.keyCode === 13 &&
          !this.errorOnValidateAmount(this.state.amount)
        ) {
          this.setState({payStep: 3});
        }
      },
      setAmount: newAmount => {
        if (!this.state.amount && !/\d|\.|,/.test(newAmount)) {
          return;
        } else if (
          this.state.amount &&
          newAmount &&
          /[^\d|.|,]/.test(newAmount)
        ) {
          return;
        }
        const amount = newAmount ? newAmount.replace(/,/, ".") : "";
        this.setState({amount: amount});
      },
      setPromoCode: newPromoCode => {
        if (this.state.promoCode !== newPromoCode) {
          if (typeof newPromoCode !== "string" || newPromoCode.length < 1) {
            this.setState({promoCode: ""})
          } else {
            this.setState({promoCode: newPromoCode})
          }
        }
      },
      setAutoPayment: newAutoPayment => {
        if (this.state.autoPayment !== newAutoPayment) {
          if (typeof newAutoPayment !== "number") {
            this.setState({autoPayment: -1})
          } else {
            this.setState({autoPayment: newAutoPayment})
          }
        }
      },
      submitPay: () => {
        const selectedSchool = this.props.user.selectedSchool;
        const parentId = this.props.user.schools[selectedSchool].parent.id;
        const profile = this.props.user.schools[selectedSchool];
        const defaultPayType = (profile.balances.hasOwnProperty("1"))
          ? profile.balances[1]
          : profile.balances[profile.balances.ids[0]];
        this.props.paysActions.getPayUrl(
          parentId,
          this.state.payType.id,
          this.state.amount * 100,
          this.state.pupil ? this.state.pupil.id : "",
          this.state.promoCode,
          this.state.autoPayment
        );
        // после нажатия кнопки подтверждения оплаты сбрасываем на 1й шаг
        this.setState({
          payStep: 0,
          payType: {
            id: defaultPayType.id,
            name: defaultPayType.name,
            balance: defaultPayType.balance
          },
          amount: "",
          promoCode: "",
          autoPayment: -1,
          pupil: undefined
        });
        this.props.paysActions.clearPromoAmount();
      },
      cancelPay: () => {
        const selectedSchool = this.props.user.selectedSchool;
        this.props.paysActions.clearPayUrl(selectedSchool);
      },
      lastInvoicePay: amount => {
        const selectedSchool = this.props.user.selectedSchool;
        const profile = this.props.user.schools[selectedSchool];
        const defaultPayType = (profile.balances.hasOwnProperty("1"))
          ? profile.balances[1]
          : profile.balances[profile.balances.ids[0]];
        this.setState({
          payStep: 2,
          payType: {
            id: defaultPayType.id,
            name: defaultPayType.name,
            balance: defaultPayType.balance
          },
          amount: amount
        });
      }
    };

    this.invoices = {
      getInvoices: year => {
        const selectedSchool = this.props.user.selectedSchool;
        const parentId = this.props.user.schools[selectedSchool].parent.id;
        this.props.paysActions.getInvoices(parentId, year);
      },
      getInvoiceDetails: month => {
        const selectedSchool = this.props.user.selectedSchool;
        const parentId = this.props.user.schools[selectedSchool].parent.id;
        this.props.paysActions.getInvoiceDetails(parentId, month);
      }
    };

    this.transfers = {
      selectPayTypeFrom: payTypeFrom => {
        this.setState({payTypeFrom: payTypeFrom});
      },
      selectPayTypeTo: payTypeTo => {
        this.setState({payTypeTo: payTypeTo});
      },
      changeStep: (direction, newStep) => {
        if (direction === "forward") {
          this.setState({
            transferStep: this.state.transferStep + 1
          });
        } else if (direction === "backward") {
          this.setState({
            transferStep: this.state.transferStep - 1
          });
        } else {
          this.setState({transferStep: newStep});
        }
      },
      onEnterPress: () => event => {
        if (
          event.keyCode === 13 &&
          !this.errorOnValidateAmount(this.state.transferAmount)
        ) {
          this.setState({transferStep: 2});
        }
      },
      setAmount: newAmount => {
        if (!this.state.transferAmount && !/\d|\.|,/.test(newAmount)) {
          return;
        } else if (
          this.state.transferAmount &&
          newAmount &&
          /[^\d|.|,]/.test(newAmount)
        ) {
          return;
        }
        const amount = newAmount ? newAmount.replace(/,/, ".") : "";
        this.setState({transferAmount: amount});
      },
      submitTransfer: () => {
        const selectedSchool = this.props.user.selectedSchool;
        const parentId = this.props.user.schools[selectedSchool].parent.id;
        this.props.paysActions.transferBalance(
          this.state.payTypeFrom.id === 0
            ? this.state.payTypeFrom.pupil.id
            : parentId,
          this.state.payTypeFrom.id,
          this.state.payTypeTo.id === 0
            ? this.state.payTypeTo.pupil.id
            : parentId,
          this.state.payTypeTo.id,
          Number(this.state.transferAmount),
          selectedSchool
        );
        // после нажатия кнопки подтверждения оплаты сбрасываем на 1й шаг
        this.setState({
          transferStep: 0,
          payTypeFrom: undefined,
          payTypeTo: undefined,
          transferAmount: ""
        });
      }
    };

    this.paperInvoiceMethods = {
      changePaperInvoiceType: type => event => {
        this.setState({
          paperInvoice: {...this.state.paperInvoice, invoiceType: type}
        });
      },
      selectServiceId: event => {
        const selectedSchool = this.props.user.selectedSchool;
        const balances = this.props.user.schools[selectedSchool].balances;
        let paperAmount
        if (balances[1].balance < 0 && event.target.value === 1) {
          paperAmount = Math.abs(Number(balances[1].balance))
        } else {
          paperAmount = ""
        }
        this.setState({
          paperInvoice: {
            ...this.state.paperInvoice,
            serviceId: +event.target.value,
            amount: paperAmount,
          }
        });
      },
      selectPupil: event => {
        this.setState({
          paperInvoice: {
            ...this.state.paperInvoice,
            pupil: event.target.value
          }
        });
      },
      setAmount: event => {
        const newAmount = event.target.value;
        if (!this.state.paperInvoice.amount && !/\d|\.|,/.test(newAmount)) {
          return;
        } else if (
          this.state.paperInvoice.amount &&
          newAmount &&
          /[^\d|.|,]/.test(newAmount)
        ) {
          return;
        }
        const amount = newAmount ? newAmount.replace(/,/, ".") : "";
        this.setState({
          paperInvoice: {...this.state.paperInvoice, amount: amount}
        });
      },
      downloadPaperInvoice: () => {
        const {
          invoiceType,
          serviceId,
          pupil,
          amount
        } = this.state.paperInvoice;
        const selectedSchool = this.props.user.selectedSchool;
        const parentId = this.props.user.schools[selectedSchool].parent.id;
        if (invoiceType === "service") {
          this.props.paysActions.getServicePaperInvoice(
            parentId,
            serviceId,
            +amount * 100
          );
          this.setState({paperInvoice: {...this.state.paperInvoice, amount: ""}});
        } else if (invoiceType === "card") {
          this.props.paysActions.getCardPaperInvoice(pupil, +amount * 100);
        }
      }
    };
  }

  componentDidMount() {
    const selectedSchool = this.props.user.selectedSchool;
    const profile = this.props.user.schools[selectedSchool];
    const parentId = this.props.user.schools[selectedSchool].parent.id;
    const defaultPayType = (profile.balances.hasOwnProperty("1"))
      ? profile.balances[1]
      : profile.balances[profile.balances.ids[0]];
    const defaultPupil = this.props.user.selectedPupil;
    // наличие питания в школе
    const isFoodActive =
      profile.menu.filter(item => item.id === 4).length !== 0 ? true : false;
    this.setState({
      payType: defaultPayType,
      isFoodActive: isFoodActive,
      paperInvoice: {
        ...this.state.paperInvoice,
        pupil: defaultPupil,
      }
    });
    this.props.paysActions.getInvoicesAndCommission(parentId);
  }

  componentDidUpdate(prevProps, prevState) {
    // после изменетия промокода
    if (this.state.payStep !== prevState.payStep) {
      if (this.props.pays.promoAmount && (this.state.promoCode.length < 1 || this.state.payType.id !== 1)) {
        this.setState({promoCode: ""})
        this.props.paysActions.clearPromoAmount();
      } else if (this.state.promoCode.length > 0 && [0, 1].indexOf(this.state.payStep) >= 0) {
        const selectedSchool = this.props.user.selectedSchool;
        const parentId = this.props.user.schools[selectedSchool].parent.id;
        this.props.paysActions.getPromoToPay(parentId, this.state.promoCode)
      }
    }
    if ((prevProps.pays.promoAmount !== this.props.pays.promoAmount ) &&
      [0, 1, 2].indexOf(this.state.payStep) >= 0) {
      if (isNaN(Number(this.props.pays.promoAmount))) {
        this.setState({amount: ""})
        this.props.paysActions.clearPromoAmount();
      } else {
        this.setState({amount: this.props.pays.promoAmount.toString()});
      }
    }
    // после переключения школы сбрасываем на начальное состояние
    if (prevProps.user.selectedSchool !== this.props.user.selectedSchool) {
      const selectedSchool = this.props.user.selectedSchool;
      const profile = this.props.user.schools[selectedSchool];
      const parentId = this.props.user.schools[selectedSchool].parent.id;
      const defaultPayType = (profile.balances.hasOwnProperty("1"))
        ? profile.balances[1]
        : profile.balances[profile.balances.ids[0]];
      const isFoodActive =
        profile.menu.filter(item => item.id === 4).length !== 0 ? true : false;
      this.setState({
        payStep: 0,
        payType: defaultPayType,
        isFoodActive: isFoodActive,
        transferStep: 0,
        amount: "",
        transferAmount: "",
        pupil: undefined,
        tabIndex: 0,
        year: new Date().getFullYear() + "",
        month: ""
      });
      this.props.paysActions.getInvoicesAndCommission(parentId);
    }
    if (prevProps.location.state && this.props.location.state.payType === 3) {
      const {paySum, payType} = this.props.location.state;
      this.props.location.state = null;
      this.setState({
        payStep: 3,
        payType: {id: payType, name: "Страхование"},
        amount: paySum
      });
    }
    if (prevProps.pays.lastInvoice !== this.props.pays.lastInvoice) {
      const selectedSchool = this.props.user.selectedSchool;
      const balances = this.props.user.schools[selectedSchool].balances;
      let paperAmount = balances[1].balance < 0 ? Math.abs(Number(balances[1].balance)) : ""
      this.setState({
        paperInvoice: {
          ...this.state.paperInvoice,
          amount: paperAmount,
        }
      });
    }
  }

  errorOnValidateAmount = amount => {
    if (!amount) {
      return true;
    } else if (amount && /^\d{1,4}((\.|,)\d{1,2})?$/.test(amount)) {
      return false;
    } else {
      return true;
    }
  };

  tabClick = (event, value) => {
    this.setState({tabIndex: value});
  };

  tabSwipe = index => {
    this.setState({tabIndex: index});
  };

  render() {
    const {classes} = this.props;
    const {
      commissions,
      payUrl,
      promoPupils,
      invoices,
      lastInvoice,
      lastOperations,
      invoicePeriods,
      invoiceDetails
    } = this.props.pays;
    const {
      payStep,
      transferStep,
      payType,
      payTypeFrom,
      payTypeTo,
      pupil,
      amount,
      promoCode,
      autoPayment,
      transferAmount,
      isFoodActive,
      tabIndex,
      paperInvoice
    } = this.state;

    const selectedSchool = this.props.user.selectedSchool;
    const profile = this.props.user.schools[selectedSchool];
    const defaultPayType = (profile.balances.hasOwnProperty("1"))
      ? profile.balances[1]
      : profile.balances[profile.balances.ids[0]];

    if (commissions === undefined || invoicePeriods === undefined || profile === undefined) {
      return <LoadingMessage/>;
    // } else if (payUrl) {
    //   return <OpenUrl cancelPay={this.pays.cancelPay} payUrl={payUrl}/>;
    } else {
      if (payUrl) {
        window.open(payUrl, '_blank', 'noopener,noreferrer');
        this.pays.cancelPay();
      }
      return (
        <>
          <LoadingMessage/>
          <div className={classes.container}>
            <AppBarWithTabs
              tabs={["Оплата", "Перевод", "Выставленные счета"]}
              tabIndex={tabIndex}
              tabClick={this.tabClick}
            />
            <SwipeableViews
              className={classes.swipeViews}
              index={tabIndex}
              onChangeIndex={this.tabSwipe}
              animateHeight
              threshold={10}
              springConfig={{
                easeFunction: "ease-in",
                delay: "0s",
                duration: "0.3s"
              }}
            >
              <Pay
                {...{
                  payType,
                  pupil,
                  profile,
                  commissions,
                  isFoodActive,
                  amount,
                  promoCode,
                  autoPayment,
                  promoPupils,
                  lastInvoice,
                  lastOperations,
                  defaultPayType,
                  step: payStep,
                  pupils: profile.pupils,
                  pays: this.pays,
                  errorOnValidateAmount: this.errorOnValidateAmount,
                  paperInvoice,
                  paperInvoiceMethods: this.paperInvoiceMethods
                }}
              />

              <Transfer
                {...{
                  payTypeFrom,
                  payTypeTo,
                  profile,
                  isFoodActive,
                  transferAmount
                }}
                amount={transferAmount}
                step={transferStep}
                transfers={this.transfers}
                pupils={profile.pupils}
                errorOnValidateAmount={this.errorOnValidateAmount}
              />

              <Invoices
                {...{invoicePeriods, invoices, invoiceDetails}}
                getInvoices={this.invoices.getInvoices}
                getInvoiceDetails={this.invoices.getInvoiceDetails}
              />
            </SwipeableViews>
          </div>
        </>
      );
    }
  }
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withStyles(styles)(Pays))
);
