import React, {Component} from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {withStyles} from "@material-ui/core/styles";
import AppBarWithTabs from "components/AppBarWithTabs";


import SwipeableViews from "react-swipeable-views";
import {Switch, Route} from "react-router-dom";

import Products from "./products";
import Orders from "./orders";
import ShopOrderPaymentDlg from "./ShopOrderPaymentDlg";

import {
  clearShopPayUrl,
} from "actions/shopActions";
import TrackDeviceOrder from "./TrackDeviceOrder";

const mapStateToProps = state => ({
  user: state.user,
  shop: state.shop
});

const mapDispatchToProps = {
  clearShopPayUrl,
};

const styles = theme => ({
  container: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    height: "100%"
  },
  swipeViews: {
    height: "100%",
    position: "relative"
  },
  dataContainer: {
    display: "flex",
    width: "100%",
    flexDirection: "column",
    justifyContent: "center",
    [theme.breakpoints.down("xs")]: {
      alignItems: "center"
    }
  },
  data: {
    display: "flex",
    flexDirection: "row",
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column"
    }
  }
});

class Profile extends Component {
  state = {
    tabIndex: 0,
  };

  componentDidMount() {
  }

  componentDidUpdate(prevProps, prevState) {
    // после переключения школы принудительно переходим на вкладку родителя
    if (prevProps.user.schoolIndex !== this.props.user.schoolIndex) {
      this.setState({tabIndex: 0});
    }
  }

  tabClick = (event, value) => {
    this.setState({tabIndex: value});
  };

  tabSwipe = index => {
    this.setState({tabIndex: index});
  };

  handleOrderPayToggle = () => {
    this.props.clearShopPayUrl();
  }

  render() {
    const {schools, selectedSchool} = this.props.user;
    const {payUrl} = this.props.shop;
    // const {    } = this.props;
    const {
      tabIndex,
    } = this.state;
    const {classes} = this.props;
    const profile = schools[selectedSchool];
    // const parentDilerId = profile.parentDilerId;

    // формируем табы
    let tabs = ["Товары", "Заказы"];

    return (
      <Switch>
        <Route
          exact
          path='/shop'
          render={() => (
            <>
              <div className={classes.container}>
                <AppBarWithTabs tabs={tabs} tabIndex={tabIndex} tabClick={this.tabClick}/>
                <SwipeableViews
                  className={classes.swipeViews}
                  index={tabIndex}
                  onChangeIndex={this.tabSwipe}
                  animateHeight
                  threshold={10}
                  springConfig={{
                    easeFunction: "ease-in",
                    delay: "0s",
                    duration: "0.3s"
                  }}
                >
                  <Products {...{profile}}
                            active={(tabIndex === 0)}
                  />
                  <Orders {...{profile}}
                          active={(tabIndex === 1)}/>
                </SwipeableViews>
                <ShopOrderPaymentDlg
                  shopPayUrl={payUrl}
                  onToggle={this.handleOrderPayToggle}
                />
              </div>
            </>
          )}
          key='shop-inner'
        />
        <Route
          exact
          path='/shop/confirm-order'
          component={TrackDeviceOrder}
          key='confirm-order-inner'
        />
      </Switch>
    );
  }
}

Profile.propTypes = {
  classes: PropTypes.object,
  user: PropTypes.object
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(Profile));
