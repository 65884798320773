import React from "react";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import InputAdornment from "@material-ui/core/InputAdornment";
import TextField from "@material-ui/core/TextField";

import withStyles from "@material-ui/core/styles/withStyles";
import PropTypes from "prop-types";

const styles = () => ({
  listInput: {
    flexDirection: "column",
    alignItems: "flex-start"
  },
  fullWidth: {
    width: "100%"
  },
  row: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center"
  }
});

const SosPhonesSettings = ({
  classes,
  settings,
  settingsDescription,
  handleSosPhonesInputChange,
  errorOnPhoneValidate
}) => (
  <ListItem disableGutters className={classes.listInput}>
    <ListItemText primary={settingsDescription.sosPhones.title} />
    {settings.sosPhones.map((item, index) => (
      <div key={index} className={classes.row}>
        <span>{index + 1}. </span>
        <TextField
          className={classes.fullWidth}
          value={settings.sosPhones[index]}
          onChange={handleSosPhonesInputChange(index)}
          margin="none"
          type="number"
          error={errorOnPhoneValidate(settings.sosPhones[index])}
          InputProps={{
            startAdornment: <InputAdornment position="start">+7</InputAdornment>
          }}
        />
      </div>
    ))}
  </ListItem>
);

SosPhonesSettings.propTypes = {
  settings: PropTypes.object,
  settingsDescription: PropTypes.object,
  handleSosPhonesInputChange: PropTypes.func.isRequired,
  classes: PropTypes.object,
  errorOnPhoneValidate: PropTypes.func.isRequired
};

export default withStyles(styles)(SosPhonesSettings);
