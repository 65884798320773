import React from "react";
import Button from "@material-ui/core/Button";

import { withStyles } from "@material-ui/core/styles";

const styles = theme => ({
  button: {
    marginBottom: 5
  }
});

const PaperInvoiceButton = props => {
  const {
    classes,
    paperInvoice,
    paperInvoiceMethods,
    errorOnValidateAmount
  } = props;
  return (
    <div className={classes.button}>
      <Button
        variant="contained"
        color="primary"
        disabled={
          paperInvoice.invoiceType === "service" &&
          errorOnValidateAmount(paperInvoice.amount)
        }
        onClick={paperInvoiceMethods.downloadPaperInvoice}
      >
        Сформировать
      </Button>
    </div>
  );
};

export default withStyles(styles)(PaperInvoiceButton);
