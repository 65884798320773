const messageStyles = theme => ({
  content: {
    display: "flex",
    alignItems: "center",
    flexWrap: "nowrap",
    fontSize: 16,
    padding: "6px 6px"
  },
  action: {
    paddingLeft: 0
  }
});

export default messageStyles;
