import axios from "axios";
import { createAction } from "redux-actions";
import moment from "moment";
import { API, handleApiError } from "api";
import { showInfoMessage } from "./infoMessageActions";

export const fetchGpsStatusRequest = createAction("GPS_STATUS_REQUEST");
export const fetchGpsStatusSuccess = createAction("GPS_STATUS_SUCCESS");
export const fetchGpsStatusError = createAction("GPS_STATUS_ERROR");

export const fetchGpsDeliveryTypesRequest = createAction("GPS_DELIVERY_TYPES_REQUEST");
export const fetchGpsDeliveryTypesSuccess = createAction("GPS_DELIVERY_TYPES_SUCCESS");
export const fetchGpsDeliveryTypesError = createAction("GPS_DELIVERY_TYPES_ERROR");

export const gpsChangeDeliveryTypeRequest = createAction("GPS_CHANGE_DELIVERY_TYPE_REQUEST");
export const gpsChangeDeliveryTypeSuccess = createAction("GPS_CHANGE_DELIVERY_TYPE_SUCCESS");
export const gpsChangeDeliveryTypeError = createAction("GPS_CHANGE_DELIVERY_TYPE_ERROR");

export const gpsChangeStatusRequest = createAction("GPS_CHANGE_STATUS_REQUEST");
export const gpsChangeStatusSuccess = createAction("GPS_CHANGE_STATUS_SUCCESS");
export const gpsChangeStatusError = createAction("GPS_CHANGE_STATUS_ERROR");

export const fetchGpsDevicesRequest = createAction("GPS_DEVICES_REQUEST");
export const fetchGpsDevicesSuccess = createAction("GPS_DEVICES_SUCCESS");
export const fetchGpsDevicesError = createAction("GPS_DEVICES_ERROR");

export const gpsDevicesAddRequest = createAction("GPS_DEVICES_ADD_REQUEST");
export const gpsDevicesAddSuccess = createAction("GPS_DEVICES_ADD_SUCCESS");
export const gpsDevicesAddError = createAction("GPS_DEVICES_ADD_ERROR");

export const gpsDevicesDeleteRequest = createAction("GPS_DEVICES_DELETE_REQUEST");
export const gpsDevicesDeleteSuccess = createAction("GPS_DEVICES_DELETE_SUCCESS");
export const gpsDevicesDeleteError = createAction("GPS_DEVICES_DELETE_ERROR");

export const gpsDevicesChangeStatusRequest = createAction("GPS_DEVICES_CHANGE_STATUS_REQUEST");
export const gpsDevicesChangeStatusSuccess = createAction("GPS_DEVICES_CHANGE_STATUS_SUCCESS");
export const gpsDevicesChangeStatusError = createAction("GPS_DEVICES_CHANGE_STATUS_ERROR");

export const gpsDevicesChangeNameRequest = createAction("GPS_DEVICES_CHANGE_NAME_REQUEST");
export const gpsDevicesChangeNameSuccess = createAction("GPS_DEVICES_CHANGE_NAME_SUCCESS");
export const gpsDevicesChangeNameError = createAction("GPS_DEVICES_CHANGE_NAME_ERROR");

export const fetchGpsZonesRequest = createAction("GPS_ZONES_REQUEST");
export const fetchGpsZonesSuccess = createAction("GPS_ZONES_SUCCESS");
export const fetchGpsZonesError = createAction("GPS_ZONES_ERROR");

export const gpsZonesAddRequest = createAction("GPS_ZONES_ADD_REQUEST");
export const gpsZonesAddSuccess = createAction("GPS_ZONES_ADD_SUCCESS");
export const gpsZonesAddError = createAction("GPS_ZONES_ADD_ERROR");

export const gpsZonesDeleteRequest = createAction("GPS_ZONES_DELETE_REQUEST");
export const gpsZonesDeleteSuccess = createAction("GPS_ZONES_DELETE_SUCCESS");
export const gpsZonesDeleteError = createAction("GPS_ZONES_DELETE_ERROR");

export const gpsZonesChangeStatusRequest = createAction("GPS_ZONES_CHANGE_STATUS_REQUEST");
export const gpsZonesChangeStatusSuccess = createAction("GPS_ZONES_CHANGE_STATUS_SUCCESS");
export const gpsZonesChangeStatusError = createAction("GPS_ZONES_CHANGE_STATUS_ERROR");

export const gpsZonesChangeNameRequest = createAction("GPS_ZONES_CHANGE_NAME_REQUEST");
export const gpsZonesChangeNameSuccess = createAction("GPS_ZONES_CHANGE_NAME_SUCCESS");
export const gpsZonesChangeNameError = createAction("GPS_ZONES_CHANGE_NAME_ERROR");

export const fetchGpsLastCoordinatesRequest = createAction("GPS_LAST_COORDINATES_REQUEST");
export const fetchGpsLastCoordinatesSuccess = createAction("GPS_LAST_COORDINATES_SUCCESS");
export const fetchGpsLastCoordinatesError = createAction("GPS_LAST_COORDINATES_ERROR");
export const fetchGpsLastCoordinatesSilentRequest = createAction(
  "GPS_LAST_COORDINATES_REQUEST_LOCAL"
);
export const fetchGpsLastCoordinatesSilentSuccess = createAction(
  "GPS_LAST_COORDINATES_SUCCESS_LOCAL"
);
export const fetchGpsLastCoordinatesSilentError = createAction("GPS_LAST_COORDINATES_ERROR_LOCAL");

export const fetchGpsCoordinatesHistoryRequest = createAction("GPS_COORDINATES_HISTORY_REQUEST");
export const fetchGpsCoordinatesHistorySuccess = createAction("GPS_COORDINATES_HISTORY_SUCCESS");
export const fetchGpsCoordinatesHistoryError = createAction("GPS_COORDINATES_HISTORY_ERROR");
export const gpsCoordinatesHistoryClear = createAction("GPS_COORDINATES_HISTORY_CLEAR");

export const gpsRegisterRequest = createAction("GPS_REGISTER_REQUEST");
export const gpsRegisterSuccess = createAction("GPS_REGISTER_SUCCESS");
export const gpsRegisterError = createAction("GPS_REGISTER_ERROR");

export const fetchGpsWatchSettingsRequest = createAction("GPS_WATCH_SETTINGS_REQUEST");
export const fetchGpsWatchSettingsSuccess = createAction("GPS_WATCH_SETTINGS_SUCCESS");
export const fetchGpsWatchSettingsError = createAction("GPS_WATCH_SETTINGS_ERROR");

export const gpsWatchSettingsAddRequest = createAction("GPS_WATCH_SETTINGS_ADD_REQUEST");
export const gpsWatchSettingsAddSuccess = createAction("GPS_WATCH_SETTINGS_ADD_SUCCESS");
export const gpsWatchSettingsAddError = createAction("GPS_WATCH_SETTINGS_ADD_ERROR");

export const gpsWatchCheckConnectRequest = createAction("GPS_WATCH_CHECK_CONNECT_REQUEST");
export const gpsWatchCheckConnectSuccess = createAction("GPS_WATCH_CHECK_CONNECT_SUCCESS");
export const gpsWatchCheckConnectError = createAction("GPS_WATCH_CHECK_CONNECT_ERROR");

export const gpsWatchCallbackRequest = createAction("GPS_WATCH_CALLBACK_REQUEST");
export const gpsWatchCallbackSuccess = createAction("GPS_WATCH_CALLBACK_SUCCESS");
export const gpsWatchCallbackError = createAction("GPS_WATCH_CALLBACK_ERROR");

export const gpsWatchRebootRequest = createAction("GPS_WATCH_REBOOT_REQUEST");
export const gpsWatchRebootSuccess = createAction("GPS_WATCH_REBOOT_SUCCESS");
export const gpsWatchRebootError = createAction("GPS_WATCH_REBOOT_ERROR");

export const gpsWatchPowerOffRequest = createAction("GPS_WATCH_POWER_OFF_REQUEST");
export const gpsWatchPowerOffSuccess = createAction("GPS_WATCH_POWER_OFF_SUCCESS");
export const gpsWatchPowerOffError = createAction("GPS_WATCH_POWER_OFF_ERROR");

export const gpsWatchFindRequest = createAction("GPS_WATCH_FIND_REQUEST");
export const gpsWatchFindSuccess = createAction("GPS_WATCH_FIND_SUCCESS");
export const gpsWatchFindError = createAction("GPS_WATCH_FIND_ERROR");

export const gpsWatchBluetoothRequest = createAction("GPS_WATCH_BLUETOOTH_REQUEST");
export const gpsWatchBluetoothSuccess = createAction("GPS_WATCH_BLUETOOTH_SUCCESS");
export const gpsWatchBluetoothError = createAction("GPS_WATCH_BLUETOOTH_ERROR");

export const getGpsStatus = parentId => async dispatch => {
  try {
    dispatch(fetchGpsStatusRequest());
    const res = await axios.get(`${API}/gps/check/${parentId}`);
    dispatch(fetchGpsStatusSuccess({ status: res.data }));
  } catch (error) {
    if (error.response && error.response.status === 404) {
      dispatch(fetchGpsStatusSuccess({ status: { active: -1, balance: 0 } }));
    } else {
      dispatch(handleApiError(error, fetchGpsStatusError()));
    }
  }
};

export const changeGpsStatus = (parentId, status) => async dispatch => {
  try {
    dispatch(gpsChangeStatusRequest());
    await axios.post(`${API}/gps/activate/${parentId}`, {
      state: status
    });
    dispatch(gpsChangeStatusSuccess({ status }));
  } catch (error) {
    dispatch(handleApiError(error, gpsChangeStatusError()));
  }
};

export const getGpsDeliveryTypes = parentId => async dispatch => {
  try {
    dispatch(fetchGpsDeliveryTypesRequest());
    const res = await axios.get(`${API}/gps/delivery-types/${parentId}`);
    dispatch(fetchGpsDeliveryTypesSuccess({ deliveryTypes: res.data }));
  } catch (error) {
    dispatch(handleApiError(error, fetchGpsDeliveryTypesError()));
  }
};

export const changeGpsDeliveryType = (parentId, deliveryTypeId, state) => async dispatch => {
  try {
    dispatch(gpsChangeDeliveryTypeRequest());
    await axios.post(`${API}/gps/delivery-types/${parentId}`, {
      deliveryTypeId,
      state
    });
    dispatch(gpsChangeDeliveryTypeSuccess());
    dispatch(showInfoMessage("Способ уведомления успешно изменен"));
    dispatch(getGpsDeliveryTypes(parentId));
  } catch (error) {
    dispatch(handleApiError(error, gpsChangeDeliveryTypeError()));
  }
};

export const getGpsDevices = parentId => async dispatch => {
  try {
    dispatch(fetchGpsDevicesRequest());
    const res = await axios.get(`${API}/gps/devices/${parentId}`);
    dispatch(fetchGpsDevicesSuccess({ devices: res.data }));
  } catch (error) {
    dispatch(handleApiError(error, fetchGpsDevicesError()));
  }
};

export const getGpsZones = parentId => async dispatch => {
  try {
    dispatch(fetchGpsZonesRequest());
    const res = await axios.get(`${API}/gps/zones/${parentId}`);
    dispatch(fetchGpsZonesSuccess({ zones: res.data }));
  } catch (error) {
    dispatch(handleApiError(error, fetchGpsZonesError()));
  }
};

export const getStatusDevicesAndZones = parentId => async dispatch => {
  Promise.all([
    dispatch(getGpsStatus(parentId)),
    dispatch(getGpsDeliveryTypes(parentId)),
    dispatch(getGpsDevices(parentId)),
    dispatch(getGpsZones(parentId))
  ]);
};

export const addGpsDevice = (parentId, device, isWatch) => async dispatch => {
  try {
    dispatch(gpsDevicesAddRequest());
    const apiUrl = !isWatch
      ? `${API}/gps/devices/${parentId}`
      : `${API}/gps/devices/watch/${parentId}`;
    await axios.post(apiUrl, {
      name: device.name,
      imei: device.code
    });
    dispatch(gpsDevicesAddSuccess());
    dispatch(showInfoMessage("Устройство успешно добавлено"));
    dispatch(getGpsDevices(parentId));
  } catch (error) {
    dispatch(handleApiError(error, gpsDevicesAddError()));
  }
};

export const deleteGpsDevice = (parentId, deviceId) => async dispatch => {
  try {
    dispatch(gpsDevicesDeleteRequest());
    await axios.delete(`${API}/gps/devices/${parentId}/${deviceId}`);
    dispatch(gpsDevicesDeleteSuccess({ deviceId }));
    dispatch(showInfoMessage("Устройство успешно удалено"));
  } catch (error) {
    dispatch(handleApiError(error, gpsDevicesDeleteError()));
  }
};

export const changeGpsDeviceStatus = (parentId, deviceId, status) => async dispatch => {
  try {
    dispatch(gpsDevicesChangeStatusRequest());
    await axios.patch(`${API}/gps/devices/${parentId}`, {
      deviceId,
      state: status
    });
    dispatch(
      gpsDevicesChangeStatusSuccess({
        deviceId,
        status
      })
    );
  } catch (error) {
    dispatch(handleApiError(error, gpsDevicesChangeStatusError()));
  }
};

export const changeGpsDeviceName = (parentId, deviceId, name) => async dispatch => {
  try {
    dispatch(gpsDevicesChangeNameRequest());
    await axios.patch(`${API}/gps/devices/${parentId}`, {
      deviceId,
      name
    });
    dispatch(
      gpsDevicesChangeNameSuccess({
        deviceId,
        name
      })
    );
    dispatch(showInfoMessage("Имя устройства успешно изменено"));
  } catch (error) {
    dispatch(handleApiError(error, gpsDevicesChangeNameError()));
  }
};

export const addGpsZone = (parentId, zone) => async dispatch => {
  try {
    dispatch(gpsZonesAddRequest());
    const zoneCoordinates = zone.zonePoints.map(point => ({
      lat: point[0],
      lng: point[1]
    }));
    await axios.post(`${API}/gps/zones/${parentId}`, {
      zoneName: zone.zoneName,
      zonePoints: zoneCoordinates
    });
    dispatch(gpsZonesAddSuccess());
    dispatch(showInfoMessage("GPS-зона успешно добавлена"));
    dispatch(getGpsZones(parentId));
  } catch (error) {
    dispatch(handleApiError(error, gpsZonesAddError()));
  }
};

export const deleteGpsZone = (parentId, zoneId) => async dispatch => {
  try {
    dispatch(gpsZonesDeleteRequest());
    await axios.delete(`${API}/gps/zones/${parentId}/${zoneId}`);
    dispatch(gpsZonesDeleteSuccess({ zoneId }));
    dispatch(showInfoMessage("GPS-зона успешно удалена"));
  } catch (error) {
    dispatch(handleApiError(error, gpsZonesDeleteError()));
  }
};

export const changeGpsZoneStatus = (parentId, zoneId, status) => async dispatch => {
  try {
    dispatch(gpsZonesChangeStatusRequest());
    await axios.patch(`${API}/gps/zones/${parentId}`, {
      zoneId,
      state: status
    });
    dispatch(
      gpsZonesChangeStatusSuccess({
        zoneId,
        status
      })
    );
  } catch (error) {
    dispatch(handleApiError(error, gpsZonesChangeStatusError()));
  }
};

export const changeGpsZoneName = (parentId, zoneId, name, points) => async dispatch => {
  try {
    dispatch(gpsZonesChangeNameRequest());
    await axios.patch(`${API}/gps/zones/${parentId}`, {
      zoneId,
      zoneName: name,
      zonePoints: points
    });
    dispatch(gpsZonesChangeNameSuccess({ zoneId, name }));
    dispatch(showInfoMessage("Имя gps-зоны успешно изменено"));
  } catch (error) {
    dispatch(handleApiError(error, gpsZonesChangeNameError()));
  }
};

export const getLastCoordinates = (parentId, deviceId) => async dispatch => {
  try {
    if (!deviceId) {
      dispatch(
        fetchGpsLastCoordinatesError({
          error: "Не выбрано устройство для отображения последнего местоположения",
          errorStatus: 400
        })
      );
      return;
    }

    dispatch(fetchGpsLastCoordinatesRequest());
    const res = await axios.get(`${API}/gps/coordinates/last/${parentId}?deviceId=${deviceId}`);
    if (res.status === 204) {
      dispatch(
        fetchGpsLastCoordinatesError({
          error: "Координаты у выбранного устройства отсутствуют",
          errorStatus: 204
        })
      );
      return;
    }
    dispatch(
      fetchGpsLastCoordinatesSuccess({
        lastCoordinates: res.data
      })
    );
  } catch (error) {
    dispatch(handleApiError(error, fetchGpsLastCoordinatesError()));
  }
};

export const getLastCoordinatesAutoUpdated = (parentId, deviceId) => async dispatch => {
  try {
    if (!deviceId) {
      dispatch(
        fetchGpsLastCoordinatesSilentError({
          error: "Не выбрано устройство для отображения последнего местоположения",
          errorStatus: 400
        })
      );
      return;
    }

    dispatch(fetchGpsLastCoordinatesSilentRequest());
    const res = await axios.get(`${API}/gps/coordinates/last/${parentId}?deviceId=${deviceId}`);
    if (res.status === 204) {
      dispatch(
        fetchGpsLastCoordinatesSilentError({
          error: "Координаты у выбранного устройства отсутствуют",
          errorStatus: 204
        })
      );
      return;
    }
    dispatch(
      fetchGpsLastCoordinatesSilentSuccess({
        lastCoordinates: res.data
      })
    );
  } catch (error) {
    dispatch(handleApiError(error, fetchGpsLastCoordinatesSilentError()));
  }
};

export const getCoordinatesHistory = (parentId, deviceId, dateStart, dateEnd) => async dispatch => {
  try {
    dispatch(fetchGpsCoordinatesHistoryRequest());
    if (moment(dateEnd).diff(moment(dateStart), "days") > 1) {
      dispatch(
        fetchGpsCoordinatesHistoryError({
          error: "Период не должен быть больше суток",
          errorStatus: 400
        })
      );
      return;
    }
    if (moment(dateStart).isAfter(moment(dateEnd))) {
      dispatch(
        fetchGpsCoordinatesHistoryError({
          error: "Начало периода не должно быть больше окончания",
          errorStatus: 400
        })
      );
      return;
    }
    const res = await axios.get(
      `${API}/gps/coordinates/${parentId}?deviceId=${deviceId}&dateStart=${dateStart}&dateEnd=${dateEnd}`
    );
    if (res.data.length === 0) {
      dispatch(
        fetchGpsCoordinatesHistoryError({
          error: "Координаты за выбранный период по устройству отсутствуют",
          errorStatus: 204
        })
      );
      return;
    }
    dispatch(
      fetchGpsCoordinatesHistorySuccess({
        history: res.data
      })
    );
  } catch (error) {
    dispatch(handleApiError(error, fetchGpsCoordinatesHistoryError()));
  }
};

export const clearCoordinatesHistory = () => dispatch => {
  dispatch(gpsCoordinatesHistoryClear());
};

export const registerGps = parentId => async dispatch => {
  try {
    dispatch(gpsRegisterRequest());
    await axios.post(`${API}/gps/register/${parentId}`);
    dispatch(gpsRegisterSuccess());
    return Promise.resolve();
  } catch (error) {
    return dispatch(handleApiError(error, gpsRegisterError()));
  }
};

export const addFirstDevice = (parentId, device, isWatch) => dispatch => {
  dispatch(registerGps(parentId)).then(() => {
    dispatch(addGpsDevice(parentId, device, isWatch));
  });
};

export const getGpsWatchSettings = (parentId, deviceId) => async dispatch => {
  try {
    dispatch(fetchGpsWatchSettingsRequest());
    const res = await axios.get(`${API}/gps/devices/settings/${parentId}/${deviceId}`);
    dispatch(
      fetchGpsWatchSettingsSuccess({
        watchSettings: res.data
      })
    );
  } catch (error) {
    dispatch(handleApiError(error, fetchGpsWatchSettingsError()));
  }
};

export const changeGpsWatchSettings = (parentId, deviceId, watchSettings) => async dispatch => {
  try {
    dispatch(gpsWatchSettingsAddRequest());
    await axios.post(`${API}/gps/devices/settings/${parentId}/${deviceId}`, watchSettings);
    dispatch(gpsWatchSettingsAddSuccess({ watchSettings }));
    dispatch(showInfoMessage("Настройки часов успешно изменены"));
    dispatch(getGpsWatchSettings(parentId, deviceId));
  } catch (error) {
    dispatch(handleApiError(error, gpsWatchSettingsAddError()));
  }
};

export const checkWatchConnection = (parentId, deviceId) => async dispatch => {
  try {
    dispatch(gpsWatchCheckConnectRequest());
    const res = await axios.get(`${API}/gps/devices/check-connected/${parentId}/${deviceId}`);
    dispatch(gpsWatchCheckConnectSuccess());
    dispatch(showInfoMessage(`Связь установлена, уровень заряда часов: ${res.data.batLvl}%`));
  } catch (error) {
    dispatch(handleApiError(error, gpsWatchCheckConnectError()));
  }
};

export const makeWatchCallback = (parentId, deviceId, phone) => async dispatch => {
  try {
    dispatch(gpsWatchCallbackRequest());
    await axios.post(`${API}/gps/devices/monitor/${parentId}`, {
      deviceId,
      phone
    });
    dispatch(gpsWatchCallbackSuccess());
    dispatch(showInfoMessage("Команда успешно отправлена на устройство"));
  } catch (error) {
    dispatch(handleApiError(error, gpsWatchCallbackError()));
  }
};

export const gpsWatchReboot = (parentId, deviceId) => async dispatch => {
  try {
    dispatch(gpsWatchRebootRequest());
    await axios.post(`${API}/gps/devices/watch/reboot/${parentId}`, {
      deviceId
    });
    dispatch(gpsWatchRebootSuccess());
    dispatch(showInfoMessage("Команда успешно отправлена на устройство"));
  } catch (error) {
    dispatch(handleApiError(error, gpsWatchRebootError()));
  }
};

export const gpsWatchPowerOff = (parentId, deviceId) => async dispatch => {
  try {
    dispatch(gpsWatchPowerOffRequest());
    await axios.post(`${API}/gps/devices/watch/power-off/${parentId}`, {
      deviceId
    });
    dispatch(gpsWatchPowerOff());
    dispatch(showInfoMessage("Команда успешно отправлена на устройство"));
  } catch (error) {
    dispatch(handleApiError(error, gpsWatchPowerOffError()));
  }
};

export const gpsWatchFind = (parentId, deviceId) => async dispatch => {
  try {
    dispatch(gpsWatchFindRequest());
    await axios.post(`${API}/gps/devices/watch/find/${parentId}`, {
      deviceId
    });
    dispatch(gpsWatchFindSuccess());
    dispatch(showInfoMessage("Команда успешно отправлена на устройство"));
  } catch (error) {
    dispatch(handleApiError(error, gpsWatchFindError()));
  }
};

export const changeGpsWatchBluetooth = (parentId, deviceId, state) => async dispatch => {
  try {
    dispatch(gpsWatchBluetoothRequest());
    await axios.post(`${API}/gps/devices/watch/bluetooth-control/${parentId}`, {
      deviceId,
      state
    });
    dispatch(gpsWatchBluetoothSuccess());
    dispatch(showInfoMessage("Команда успешно отправлена на устройство"));
  } catch (error) {
    dispatch(handleApiError(error, gpsWatchBluetoothError()));
  }
};
