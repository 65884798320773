import React from "react";

import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";

import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";

const styles = () => ({
  listInput: {
    flexDirection: "column",
    alignItems: "flex-start"
  },
  fullWidth: {
    width: "100%"
  }
});

const watchLanguages = [
  {
    code: 9,
    name: "Русский"
  },
  {
    code: 0,
    name: "Английский"
  }
];

const LanguageSettings = ({
  settings, settingsDescription, handleSelectChange, classes
}) => (
  <ListItem disableGutters className={classes.listInput}>
    <ListItemText primary={settingsDescription.langNum.title} />
    <FormControl variant="outlined" className={classes.fullWidth}>
      <Select
        name="langNum"
        value={Number(settings.langNum)}
        onChange={event => handleSelectChange(event.target.name, event.target.value)}
      >
        {watchLanguages.map(item => (
          <MenuItem key={item.code} value={item.code}>
            {item.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  </ListItem>
);

LanguageSettings.propTypes = {
  settings: PropTypes.object,
  settingsDescription: PropTypes.object,
  handleSelectChange: PropTypes.func.isRequired,
  classes: PropTypes.object
};

export default withStyles(styles)(LanguageSettings);
