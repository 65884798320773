import React, { Component } from "react";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import LoadingMessage from "components/messages/LoadingMessage";

import ChangeEmail from "components/ChangeEmail";
import * as tariffsActions from "actions/tariffsActions";
import * as userActions from "actions/userActions";
import CurrentTariffs from "./CurrentTariffs";
import SetTariff from "./SetTariff";
import TariffHistory from "./TariffHistory";
import ReferralPay from "./ReferralPay";

const mapStateToProps = state => ({
  user: state.user,
  tariffs: state.tariffs,
  errors: state.errors
});

const mapDispatchToProps = dispatch => ({
  tariffsActions: bindActionCreators(tariffsActions, dispatch),
  userActions: bindActionCreators(userActions, dispatch)
});

class Tariffs extends Component {
  state = {
    pupil: undefined,
    step: 0,
    selectedTariff: undefined,
    selectedDeliveries: [],
    selectedModules: [],
    referralCode: localStorage.getItem("referral"),
    showModules: false,
    infoWindowIsOpen: false,
    infoText: "",
    changeEmailIsOpen: false,
    expanded: null,
    history: undefined
  };

  componentDidUpdate(prevProps) {
    // после переключения школы принудительно переходим на вкладку родителя
    if (prevProps.user.selectedSchool !== this.props.user.selectedSchool) {
      this.setState({
        step: 0,
        pupil: undefined,
        selectedTariff: undefined,
        selectedDeliveries: [],
        selectedModules: [],
        history: undefined
      });
    }
  }

  static getDerivedStateFromProps(nextProps) {
    if (nextProps.errors.error) {
      return {
        step: 0,
        pupil: undefined,
        selectedTariff: undefined,
        selectedDeliveries: [],
        selectedModules: [],
        history: undefined
      };
    }
    return null;
  }

  changeTariffClick = pupilId => () => {
    const { selectedSchool } = this.props.user;
    const { pupils } = this.props.user.schools[selectedSchool];
    this.setState({ pupil: pupils[pupilId] });
    this.props.tariffsActions.getCurrentAndAvailableTariffs(pupilId);
  };

  tariffHistoryClick = pupilId => () => {
    const { selectedSchool } = this.props.user;
    const { pupils } = this.props.user.schools[selectedSchool];
    this.setState({ history: pupils[pupilId] });
    this.props.tariffsActions.getTariffHistory(pupilId);
  };

  selectTariff = value => () => {
    this.setState(
      {
        selectedTariff: value,
        selectedDeliveries: []
      },
      () =>
        this.props.tariffsActions.getTariffPrice(
          this.state.pupil.id,
          value,
          this.state.selectedDeliveries.join(),
          this.state.selectedModules.join(),
          this.state.referralCode
        )
    );
  };

  selectDelivery = delivery => () => {
    if (delivery.name === "email") {
      const deliveryEmail = this.props.tariffs.available.deliveryTypes.filter(
        item => item.name === "email"
      )[0];
      if (!deliveryEmail.isValid) {
        this.setState({ changeEmailIsOpen: true });
        return;
      }
    }
    const deliveries = this.state.selectedDeliveries;
    // выбираем вход-выход как модуль по-умолчанию, если модули доступны
    const defaultModules = this.props.tariffs.available.packetModules
      ? [this.props.tariffs.available.packetModules[0].id]
      : [];
    // если способ доставки не найден в ранее выбранных, то добавляем в массив
    if (deliveries.filter(item => item.name === delivery.name).length === 0) {
      deliveries.push(delivery);
      // если хотя бы один из способов доставки предполагает показ модулей - показываем модули
      if (deliveries.filter(item => item.showPacketModules).length !== 0) {
        this.setState({
          selectedDeliveries: deliveries,
          showModules: true,
          selectedModules: defaultModules
        });
      } else {
        this.setState({ selectedDeliveries: deliveries, showModules: false, selectedModules: [] });
      }
    } else {
      // если кликнули на уже выбранном способе доставке - удаляем ее из массива
      // а также проверяем есть ли хотя бы один из оставшихся способов, предполагающий показ модулей
      deliveries.splice(deliveries.findIndex(item => item.name === delivery.name), 1);
      if (deliveries.filter(item => item.showPacketModules).length !== 0) {
        this.setState({
          selectedDeliveries: deliveries,
          showModules: true,
          selectedModules: defaultModules
        });
      } else {
        this.setState({ selectedDeliveries: deliveries, showModules: false, selectedModules: [] });
      }
    }
    this.props.tariffsActions.getTariffPrice(
      this.state.pupil.id,
      this.state.selectedTariff,
      this.state.selectedDeliveries.map(item => item.name).join(),
      this.state.selectedModules.length > 0
        ? this.state.selectedModules.join()
        : this.props.tariffs.available.packetModules[0].id,
      this.state.referralCode
    );
  };

  selectModule = id => () => {
    const modules = this.state.selectedModules;
    if (modules.indexOf(id) === -1) {
      modules.push(id);
      this.setState({ selectedModules: modules });
    } else if (modules.length === 1) {
      return;
    } else {
      modules.splice(modules.indexOf(id), 1);
      this.setState({ selectedModules: modules });
    }
    this.props.tariffsActions.getTariffPrice(
      this.state.pupil.id,
      this.state.selectedTariff,
      this.state.selectedDeliveries.map(item => item.name).join(),
      this.state.selectedModules.join(),
      this.state.referralCode
    );
  };

  cancelStepsClick = () => {
    this.setState({
      pupil: undefined,
      step: 0,
      selectedTariff: undefined,
      selectedDelivery: [],
      selectedModules: [],
      history: undefined,
      showModules: false
    });
  };

  goToStep = stepIndex => () => {
    if (this.state.selectedTariff !== 2440 && stepIndex !== 1) {
      this.setState({ step: stepIndex });
    }
  };

  nextStep = () => {
    if (
      this.state.selectedTariff === 2440 || // если нулевой переходим на последний шаг
      (this.state.step === 1 && // если находимся в выборе способа уведомлений
        !this.state.showModules) // и модули не нужно показывать
    ) {
      this.setState({ step: 3 });
    } else {
      this.setState({
        step: this.state.step + 1
      });
    }
  };

  prevStep = () => {
    if (this.state.selectedTariff === 2440) {
      this.setState({ step: 0 });
    } else if (
      this.state.step === 3 && // если на финальном шаге
      !this.state.showModules // и модули не нужно показывать
    ) {
      this.setState({ step: 1 });
    } else {
      this.setState({ step: this.state.step - 1 });
    }
  };

  getStepLabel = currentStep => {
    switch (currentStep) {
      case 0:
        return "Выберите тариф";
      case 1:
        return this.state.selectedTariff === 2440
          ? "Выбор видов уведомлений не требуется"
          : "Выберите виды уведомлений, которые вы хотите получать";
      case 2:
        return this.state.selectedTariff === 2440 ||
          this.state.selectedDeliveries.length === 0 ||
          !this.state.showModules
          ? "Выбор модулей не требуется"
          : "Выберите модули, на которые будет действовать тариф";
      case 3:
        return "Завершение настройки тарифа";
      default:
        return "Ошибка при загрузке заголовка";
    }
  };

  setTariff = () => () => {
    const {pupil, selectedTariff, selectedDeliveries, selectedModules, referralCode} = this.state

    const { selectedTariffProps: {referralMessage, amountCent}} = this.props.tariffs;

    const { schools, selectedSchool } = this.props.user;
    const profile = schools[selectedSchool];

    const needReferralCode = profile.menu.filter(item => item.id === 10).length !== 0;
    if (referralMessage !== 'OK' || amountCent === 0) {
      this.props.tariffsActions.changeTariff(
        pupil.id,
        selectedTariff,
        selectedDeliveries.map(item => item.name),
        selectedModules,
        (needReferralCode) ? referralCode : undefined
      );
    } else {
      this.props.tariffsActions.getPayUrlReferral(
        pupil.id,
        profile.parent.id,
        selectedTariff,
        selectedDeliveries.map(item => item.name),
        selectedModules,
        (needReferralCode) ? referralCode : undefined,
        amountCent
      );
    }
    this.setState({
      pupil: undefined,
      step: 0,
      selectedTariff: undefined,
      selectedDeliveries: [],
      selectedModules: [],
      history: undefined,
      showModules: false,
      referralCode: localStorage.getItem("referral") ? referralCode : undefined
    });
  };

  infoWindowOpen = infoText => {
    this.setState({
      infoWindowIsOpen: true,
      infoText
    });
  };

  infoWindowClose = () => {
    this.setState({
      infoWindowIsOpen: false
    });
  };

  changeEmailToggle = () => {
    this.setState({ changeEmailIsOpen: !this.state.changeEmailIsOpen });
  };

  changeExpand = panel => (event, expanded) => {
    this.setState({
      expanded: expanded ? panel : false
    });
  };

  changeReferral = code => {
    this.setState({
      referralCode: code
    });

    if (this.state.selectedTariff) {
      this.props.tariffsActions.getTariffPrice(
        this.state.pupil.id,
        this.state.selectedTariff,
        this.state.selectedDeliveries.map(item => item.name).join(),
        this.state.selectedModules.join(),
        code
      );
    }
  };

  render() {
    const {
      pupil,
      step,
      selectedTariff,
      selectedDeliveries,
      selectedModules,
      infoWindowIsOpen,
      infoText,
      changeEmailIsOpen,
      expanded,
      history,
      referralCode
    } = this.state;
    const { schools, selectedSchool } = this.props.user;
    const { available, selectedTariffProps, isLocalLoading, tariffHistory,
      referralPayUrl,
    } = this.props.tariffs;
    const { changeEmail } = this.props.userActions;
    const profile = schools[selectedSchool];

    if (!schools) {
      return <LoadingMessage />;
    }
    if (referralPayUrl) {
      return (
        <ReferralPay
          {...{referralPayUrl}}
          clearReferralPayUrl={this.props.tariffsActions.clearReferralPayUrl}
        />
      )
    }
    if (!pupil && !history) {
      return (
        <CurrentTariffs
          pupils={profile.pupils}
          changeTariffClick={this.changeTariffClick}
          tariffHistoryClick={this.tariffHistoryClick}
          changeExpand={this.changeExpand}
          expanded={expanded}
        />
      );
    }
    if (pupil) {
      return (
        <>
          <LoadingMessage />
          <SetTariff
            {...{
              step,
              pupil,
              available,
              selectedTariff,
              selectedDeliveries,
              selectedModules,
              selectedTariffProps,
              isLocalLoading,
              infoWindowIsOpen,
              infoText,
              referralCode,
              profile
            }}
            cancelStepsClick={this.cancelStepsClick}
            nextStep={this.nextStep}
            prevStep={this.prevStep}
            selectTariff={this.selectTariff}
            selectDelivery={this.selectDelivery}
            selectModule={this.selectModule}
            getStepLabel={this.getStepLabel}
            setTariff={this.setTariff}
            goToStep={this.goToStep}
            infoWindowOpen={this.infoWindowOpen}
            infoWindowClose={this.infoWindowClose}
            changeEmailToggle={this.changeEmailToggle}
            changeReferral={this.changeReferral}
          />
          <ChangeEmail
            {...{ changeEmailIsOpen }}
            changeEmailSubmit={changeEmail}
            parentId={profile.parent.id}
            changeEmailToggle={this.changeEmailToggle}
            infoText="Для подключения отправки уведомлений на email необходимо подтверждение email адреса.
              Для этого введите ваш адрес email, а после получения письма с подтверждением пройдите по ссылке в письме.
              Если вы подтвердили адрес, но в тарифах отображается, что адрес не подтвержден - обновите страницу."
          />
        </>
      );
    }
    if (history) {
      return (
        <TariffHistory {...{ tariffHistory, history }} cancelStepsClick={this.cancelStepsClick} />
      );
    }
    return null;
  }
}

Tariffs.propTypes = {
  user: PropTypes.object,
  errors: PropTypes.shape({
    error: PropTypes.string
  }),
  tariffsActions: PropTypes.shape({
    getCurrentAndAvailableTariffs: PropTypes.func.isRequired,
    getTariffHistory: PropTypes.func.isRequired,
    getTariffPrice: PropTypes.func.isRequired,
    changeTariff: PropTypes.func.isRequired,
    getPayUrlReferral: PropTypes.func.isRequired,
    clearReferralPayUrl: PropTypes.func.isRequired
  }),
  userActions: PropTypes.shape({
    changeEmail: PropTypes.func.isRequired
  }),
  tariffs: PropTypes.shape({
    available: PropTypes.shape({
      deliveryTypes: PropTypes.array,
      packetModules: PropTypes.array
    }),
    selectedTariffProps: PropTypes.object,
    isLocalLoading: PropTypes.bool,
    tariffHistory: PropTypes.array
  })
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Tariffs);
