import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';

import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%'
  },
  appBar: {
    height: 48,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  button: {
    width: 250
  }
});

const OpenUrl = props => {
  const { classes, payUrl, cancelPay } = props;
  return (
    <div className={classes.container}>
      <AppBar
        className={classes.appBar}
        color="default"
        position="static"
        elevation={1}
      >
        <Button
          className={classes.button}
          variant="outlined"
          size="small"
          onClick={e => cancelPay()}
        >
          Закрыть окно оплаты
        </Button>
      </AppBar>
      <iframe title="Оплата услуг УЭШКа" src={payUrl} height="100%" />
    </div>
  );
};

export default withStyles(styles)(OpenUrl);
