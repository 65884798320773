import React from "react";
import axios from "axios";
import { API } from "api";

import Typography from "@material-ui/core/Typography";

import { connect } from "react-redux";

const mapStateToProps = state => {
  return {
    user: state.user
  };
};

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error, info) {
    this.setState({ hasError: true });
    const selectedSchool = this.props.user.selectedSchool;
    axios.post(`${API}/exceptions`, {
      message: error.toString(),
      login: this.props.user.schools[selectedSchool].parent.phone,
      parentId: this.props.user.schools[selectedSchool].parent.id,
      component: this.props.component
    });
  }

  render() {
    if (this.state.hasError) {
      return (
        <Typography variant="h6" align="center" style={{ display: "flex", alignItems: "center" }}>
          Что-то пошло не так :( Попробуйте перезагрузить страницу или сообщите в техническую
          поддержку, если ошибка повторяется.
        </Typography>
      );
    }

    return this.props.children;
  }
}

export default connect(mapStateToProps)(ErrorBoundary);
