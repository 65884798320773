import React from "react";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Typography from "@material-ui/core/Typography";

const tariffDescription = [
  {
    id: "info",
    title: "Информационный",
    text: `При использовании тарифа «Информационный» вы получаете расширенный
  доступ в Личный Кабинет к закрытым для абонентов тарифа «Нулевой»
  разделам. Также вы можете подключить уведомления на электронную
  почту.`
  },
  {
    id: "smart",
    title: "Информационный СМАРТ",
    text: `С подключенной опцией СМАРТ вы можете получать уведомления через
  Мобильное приложение «УЭШКА» и/или социальную сеть ВКонтакте и
  месенджер Viber. Использование Абонентом Приложения «УЭШКА»
  позволяет получать оперативные оповещения по выбранным Абонентам
  событиям на мобильный телефон в виде PUSH сообщений.`
  },
  {
    id: "sms",
    title: "Информационный SMS",
    text: `С подключенной опцией SMS вы будете получать смс сообщения о
  событиях на ваш телефон.`
  },
  {
    id: "free",
    title: "Нулевой",
    text: `При использовании тарифа «Нулевой» абонентская плата не начисляется,
  но учащийся при этом может пользоваться основными функциями системы,
  а его родители - получать адресную информацию из Личного Кабинета на
  вэб-сайте системы.
  <br />
  Возможности учащегося:
  <br />- Вход и выход по карте;
  <br />- Безналичная оплата питания (в школах, где есть модуль
  питания) и беспроцентное пополнение лицевого счета через терминал
  УЭШКА, установленный в школе.
  <br />
  Возможности абонента (родителей):
  <br />- Доступ к основной учетной информации через Личный Кабинет на
  сайте ueshka.ru;
  <br />- Персональные данные, настройки, новости;
  <br />- Информация о действующем тарифе и возможность изменить
  тариф;
  <br />- Информация о начислениях и платежах, возможность сделать
  выписку за период;
  <br />- Сервисы оплаты.`
  }
];

const TariffDescription = props => {
  const { changeExpand, expanded } = props;
  return (
    <>
      <br />
      <Typography paragraph variant="h6" align="center">
        Описание тарифов
      </Typography>
      {tariffDescription.map(desription => (
        <ExpansionPanel
          key={desription.id}
          expanded={expanded === desription.id}
          onChange={changeExpand(desription.id)}
        >
          <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="subtitle1">{desription.title}</Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <Typography align="justify">
              <span dangerouslySetInnerHTML={{ __html: desription.text }} />
            </Typography>
          </ExpansionPanelDetails>
        </ExpansionPanel>
      ))}
    </>
  );
};

export default TariffDescription;
