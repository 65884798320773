import React from "react";
import PropTypes from "prop-types";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import Typography from "@material-ui/core/Typography";

import InputField from "../../../InputField";

import { withStyles } from "@material-ui/core/styles";

const styles = theme => ({
  formControl: {
    width: "100%",
    maxWidth: 620,
    minWidth: 150,
    margin: "8px 10px 4px 0"
  },
  input: {
    padding: "10px 15px"
  }
});

const PupilDocs = props => {
  const {
    pupilIndex,
    pupil,
    handleInputChange,
    handleDateChange,
    handleSelectChange,
    classes,
    docTypes,
    relationDegree,
    errors,
    onKeyUp
  } = props;

  return (
    <>
      <Typography variant="button">Документ обучающегося</Typography>
      <>
        <FormControl variant="outlined" className={classes.formControl}>
          <InputLabel>Степень родства представителя обучающегося *</InputLabel>
          <Select
            value={pupil.degreeOfKinship || -1}
            onChange={handleSelectChange("degreeOfKinship", pupilIndex)}
            onKeyUp={onKeyUp}
            input={<OutlinedInput labelWidth={415} name="degreeOfKinship" id="degreeOfKinship" />}
            inputProps={{
              classes: { select: classes.input }
            }}
          >
            {relationDegree.map(type => (
              <MenuItem key={type.value} value={type.value}>
                {type.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <br />
        {pupil.degreeOfKinship === "Опекун" && (
          <>
            <InputField
              id="otherDoc"
              label="Иной документ"
              fullWidth
              required
              error={errors["children_" + pupilIndex + "_otherDoc"]}
              value={pupil.otherDoc}
              onChange={handleInputChange("otherDoc", pupilIndex)}
              onKeyUp={onKeyUp}
            />
            <br />
          </>
        )}

        <FormControl variant="outlined" className={classes.formControl}>
          <InputLabel>Тип документа *</InputLabel>
          <Select
            value={pupil.docTypeId || 11}
            onChange={handleSelectChange("docTypeId", pupilIndex)}
            onKeyUp={onKeyUp}
            input={<OutlinedInput labelWidth={135} name="docType" id="docType" />}
            inputProps={{
              classes: { select: classes.input }
            }}
          >
            {docTypes.map(type => (
              <MenuItem key={type.value} value={type.value}>
                {type.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <br />

        <InputField
          id="docSeries"
          label="Серия"
          required
          error={errors["children_" + pupilIndex + "_docSeries"]}
          value={pupil.docSeries}
          onChange={handleInputChange("docSeries", pupilIndex)}
          onKeyUp={onKeyUp}
        />

        <InputField
          id="docNumber"
          label="Номер"
          required
          error={errors["children_" + pupilIndex + "_docNumber"]}
          value={pupil.docNumber}
          onChange={handleInputChange("docNumber", pupilIndex)}
          onKeyUp={onKeyUp}
        />

        <InputField
          id="docDate"
          label="Дата выдачи *"
          datePicker
          error={errors["children_" + pupilIndex + "_docDate"]}
          value={pupil.docDate}
          onChange={handleDateChange("docDate", pupilIndex)}
          onKeyUp={onKeyUp}
        />

        <InputField
          id="docIssuer"
          label="Кем выдан"
          fullWidth
          required
          error={errors["children_" + pupilIndex + "_docIssuer"]}
          value={pupil.docIssuer}
          onChange={handleInputChange("docIssuer", pupilIndex)}
          onKeyUp={onKeyUp}
        />
      </>
      <br />
      <br />
    </>
  );
};

PupilDocs.propTypes = {
  classes: PropTypes.object,
  docTypes: PropTypes.array,
  relationDegree: PropTypes.array,
  pupilIndex: PropTypes.number.isRequired,
  pupil: PropTypes.object,
  handleInputChange: PropTypes.func.isRequired,
  handleDateChange: PropTypes.func.isRequired,
  handleSelectChange: PropTypes.func.isRequired,
  errors: PropTypes.object
};

export default withStyles(styles)(PupilDocs);
