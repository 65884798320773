import React from "react";
import PropTypes from "prop-types";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import Typography from "@material-ui/core/Typography";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

import {withStyles} from "@material-ui/core/styles";

const styles = theme => ({
  formControlFull: {
    width: "100%",
    maxWidth: 620,
    minWidth: 150,
    margin: "8px 10px 4px 0"
  },
  formControl: {
    width: 200,
    minWidth: 150,
    margin: "8px 10px 4px 0"
  },
  input: {
    padding: "10px 15px"
  }
});

const PupilSchoolInfo = props => {
  const {
    pupilIndex,
    pupil,
    handleInputChange,
    handleSelectChange,
    classes,
    schools,
    schoolClasses,
    onKeyUp,
    parentDilerId
  } = props;

  const selectedSchool = schools.find(school => school.value === pupil.schoolId);

  return (
    <>
      <>
        <FormControl variant="outlined" className={classes.formControlFull}>
          <InputLabel>Школа *</InputLabel>
          <Select
            disabled={!!pupil.id}
            value={pupil.schoolId !== null ? pupil.schoolId : -1}
            onChange={handleSelectChange("schoolId", pupilIndex)}
            onKeyUp={onKeyUp}
            input={<OutlinedInput labelWidth={70} name="schoolId" id="schoolId"/>}
            inputProps={{
              classes: {select: classes.input}
            }}
          >
            {schools.map(school => (
              <MenuItem key={school.value} value={school.value}>
                {school.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <br/>
        {selectedSchool && (
          <>
            <Typography>Адрес школы: {selectedSchool.address}</Typography>
            <br/>
          </>
        )}
        {typeof pupil.schoolId === "number" && schoolClasses && schoolClasses[pupil.schoolId] && (
          <>
            <FormControl variant="outlined" className={classes.formControl}>
              <InputLabel>Класс *</InputLabel>
              <Select
                disabled={!!pupil.id}
                value={pupil.classId || -1}
                onChange={handleSelectChange("classId", pupilIndex)}
                onKeyUp={onKeyUp}
                input={<OutlinedInput labelWidth={65} name="classId" id="classId"/>}
                inputProps={{
                  classes: {select: classes.input}
                }}
              >
                {schoolClasses[pupil.schoolId].map(schoolClass => (
                  <MenuItem key={schoolClass.value} value={schoolClass.value}>
                    {schoolClass.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <br/>
          </>
        )}
        <br/>
        {(parentDilerId !== 7) && (
          <FormControlLabel
            control={
              <Checkbox
                id="testPeriodOff"
                checked={!!pupil.testPeriodOff}
                disabled={!!pupil.id && pupil.tarifId && pupil.tarifId >= 0}
                onChange={handleInputChange("testPeriodOff", pupilIndex)}
                onKeyUp={onKeyUp}
                value="testPeriodOff"
                color="primary"
              />
            }
            label="Отказ от бесплатного тестового периода"
          />)}
      </>
    </>
  );
};

PupilSchoolInfo.propTypes = {
  classes: PropTypes.object,
  docTypes: PropTypes.array,
  pupilIndex: PropTypes.number.isRequired,
  pupil: PropTypes.object,
  handleInputChange: PropTypes.func.isRequired,
  handleDateChange: PropTypes.func.isRequired,
  handleSelectChange: PropTypes.func.isRequired,
  schools: PropTypes.array,
  schoolClasses: PropTypes.object,
  errors: PropTypes.object,
  parentDilerId: PropTypes.number
};

export default withStyles(styles)(PupilSchoolInfo);
