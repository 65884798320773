import React, {Component} from "react";
import {connect} from "react-redux";
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";


import RegHeader from "../header/RegHeader";
import InfoMessage from "../messages/InfoMessage";
import ErrorMessage from "../messages/ErrorMessage";
import LoadingMessage from "../messages/LoadingMessage";

import {getKhalvaRegionsSelector} from "selectors/khalva";
import {khalvaGetRegions, khalvaSetBankCardOrder, khalvaSetErrors} from "actions/khalvaActions";
import KhalvaFooter from "./KhalvaFooter";
import KhalvaPaper from "./KhavlaPaper";

const styles = theme => ({
  root: {
    // maxWidth: 1300,
    height: "auto",
    position: "relative",
    display: "flex",
    flexDirection: "column",
    margin: "0 auto",
    background: "white",
    borderRight: "1px solid rgba(0, 0, 0, 0.12)",
    borderLeft: "1px solid rgba(0, 0, 0, 0.12)"
  },
  container: {
    paddingTop: 120,
    display: "flex",
    flexDirection: "column",
    height: "100%",
    backgroundImage: "url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAC8AAAOJAQMAAAC3ROmBAAAABlBMVEX///8AAABVwtN+AAAAAnRSTlMNAMM9s3UAAAAnSURBVFjD7cUxAQAACAOgNbB/G5OpNTzgIdtJjSRJkiRJkiRJ0pcORsTjtOyfSO0AAAAASUVORK5CYII=), linear-gradient(-48deg, #fb795b 0%, #ef4c58 100%)",
    [theme.breakpoints.down("sm")]: {
      paddingTop: 80
    },
    overflowY: "auto"
  },
})

class KhalvaForm extends Component {
  state = {
    dilerId: 6
  }

  componentDidMount() {
    // this.props.changeTheme(this.props.parentDilerId);
    this.setState({dilerId: this.props.parentDilerId})
    this.props.khalvaGetRegions();
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      (prevProps.parentDilerId !== this.props.parentDilerId) &&
      (this.state.dilerId !== this.props.parentDilerId)
    ) {
      this.props.changeTheme(this.props.parentDilerId);
      this.setState({dilerId: this.props.parentDilerId})
    }
  }

  goToLogin = () => {
    if (!!this.props.parentId && this.props.onToggle) {
      this.props.onToggle()
    } else {
      window.location.href = "/";
    }
  };

  handleSendOrder = values => {
    const {parentDilerId} = this.props;
    const sendData = {
      declarerLastName: values.lastName,
      declarerFirstName: values.firstName,
      declarerPatronymic: values.patronymic,
      declarerBirthDate: values.birthDate,
      regionId: (values.region) ? values.region.value : 0,
      declarerPhone: values.phone,
      consent: values.consent
    }
    this.props.khalvaSetBankCardOrder({
      dilerId: parentDilerId,
      sendData
    })
  }

  handleEnter = function (event) {
    if (event.keyCode === 13) {
      // event.preventDefault();
      const form = event.target.form;
      let index = Array.prototype.indexOf.call(form, event.target);
      let next, preNext;
      do {
        next = form.elements[++index];
        if (!preNext && next && (['BUTTON'].indexOf(next.tagName) >= 0 || next.type !== 'hidden'))
          preNext = next;
      } while (next && (['INPUT', 'SELECT'].indexOf(next.tagName) < 0 || next.type === 'hidden'))

      if (next) {
        next.focus()
      } else if (preNext && (['INPUT', 'BUTTON'].indexOf(preNext.tagName) >= 0 || preNext.type !== 'hidden')) {
        preNext.focus();
      }
    }
  }


  render() {
    const {classes, parentDilerId, regions, khalva} = this.props;
    const {errors, success} = khalva;

    const initValues = {
      dilerId: parentDilerId,
      lastName: "",
      firstName: "",
      patronymic: "",
      birthDate: "",
      phone: "",
      region: null,
      consent: true,
    }

    return (
      <>
        <InfoMessage/>
        <ErrorMessage/>
        <div className={classes.root}>
          <LoadingMessage/>
          <RegHeader
            parentDilerId={parentDilerId}
            title="Заявка на получение Карты рассрочки «Халва»"
            goToLogin={this.goToLogin}
            showLogin={false}
            isGray
          />
          <div className={classes.container}>
            <KhalvaPaper
              regions={regions}
              initValues={initValues}
              errors={errors}
              success={success}
              handleSendOrder={this.handleSendOrder}
            />
          </div>
          <KhalvaFooter/>
        </div>
      </>
    )
  }
}

KhalvaForm.propTypes = {
  khalvaGetRegions: PropTypes.func.isRequired,
  khalvaSetBankCardOrder: PropTypes.func.isRequired,

  parentDilerId: PropTypes.number.isRequired,
}

const mapStateToProps = state => ({
  loading: state.loading,
  khalva: state.khalva,
  regions: getKhalvaRegionsSelector(state),
});

const mapDispatchToProps = {
  khalvaGetRegions,
  khalvaSetBankCardOrder,
  khalvaSetErrors
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(KhalvaForm))