import React, {Component} from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {withStyles} from "@material-ui/core/styles";
import LoadingMessage from "components/messages/LoadingMessage";
import AppBarWithTabs from "components/AppBarWithTabs";

import SwipeableViews from "react-swipeable-views";
import ReactPixel from 'react-facebook-pixel';

import AccountPhoto from "./AccountPhoto";
import PupilData from "./PupilData";
import ParentData from "./ParentData";
import RefreshProfile from "./RefreshProfile";
import TransportCardOperationsModal from "./TransportCardOperations";
import LoseCard from "./LoseCard";

import {
  changePassword,
  newPhoneSendCode,
  newPhoneCheckCode,
  resetCheckCode,
  changeEmail,
  getProfile,
  getAvatar,
  unlinkCard,
  parentAutoPaymentOff,
  clearAdvertising,
  addFoodDataOrder
} from "actions/userActions";
import Advertising from "./Advertising";
import EditRegFormDlg from "./EditRegFormDlg";

const mapStateToProps = state => ({
  user: state.user
});

const mapDispatchToProps = {
  changePassword,
  newPhoneSendCode,
  newPhoneCheckCode,
  resetCheckCode,
  changeEmail,
  getProfile,
  getAvatar,
  unlinkCard,
  parentAutoPaymentOff,
  clearAdvertising,
  addFoodDataOrder
};

const styles = theme => ({
  container: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    height: "100%"
  },
  swipeViews: {
    height: "100%",
    position: "relative"
  },
  dataContainer: {
    display: "flex",
    width: "100%",
    flexDirection: "column",
    justifyContent: "center",
    [theme.breakpoints.down("xs")]: {
      alignItems: "center"
    }
  },
  data: {
    display: "flex",
    flexDirection: "row",
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column"
    }
  }
});

class Profile extends Component {
  state = {
    tabIndex: 0,
    changePasswordIsOpen: false,
    changePhoneIsOpen: false,
    changeEmailIsOpen: false,
    loseCardIsOpen: null,
    autoPaymentIsOpen: null,
    editRegFormIsOpen: null,
    autoPaymentServiceId: null,
    transportCardOperationsIsOpen: null
  };

  componentDidMount() {
    ReactPixel.init('579733546212185')
    ReactPixel.pageView()
  }

  componentDidUpdate(prevProps, prevState) {
    // после переключения школы принудительно переходим на вкладку родителя
    if (prevProps.user.schoolIndex !== this.props.user.schoolIndex) {
      this.setState({tabIndex: 0});
    }

    const selectedSchool = this.props.user.selectedSchool;
    const profile = this.props.user.schools[selectedSchool];
    const avatars = profile.avatars;

    if (!avatars.hasOwnProperty(profile.parent.id)) {
      this.props.getAvatar(selectedSchool, profile.parent.id);
      for (let pupilId of profile.pupils.ids) {
        if (!avatars.hasOwnProperty(pupilId))
          this.props.getAvatar(selectedSchool, pupilId);
      }
    }
    for (let pupilId of profile.pupils.ids) {
      if (!avatars.hasOwnProperty(pupilId))
        this.props.getAvatar(selectedSchool, pupilId);
    }
  }

  tabClick = (event, value) => {
    this.setState({tabIndex: value});
  };

  tabSwipe = index => {
    this.setState({tabIndex: index});
  };

  changePasswordToggle = () => {
    this.setState({changePasswordIsOpen: !this.state.changePasswordIsOpen});
  };

  changePhoneToggle = () => {
    this.setState({changePhoneIsOpen: !this.state.changePhoneIsOpen});
  };

  changeEmailToggle = () => {
    this.setState({changeEmailIsOpen: !this.state.changeEmailIsOpen});
  };

  transportCardOperationsToggle = id => {
    this.setState({
      transportCardOperationsIsOpen: this.state.transportCardOperationsIsOpen === null ? id : null
    });
  };

  loseCardToggle = id => {
    this.setState({loseCardIsOpen: this.state.loseCardIsOpen === null ? id : null});
  };

  autoPaymentToggle = (parentId, serviceId) => {
    const autoPaymentIsOpen = this.state.autoPaymentIsOpen === null ? parentId : null
    const autoPaymentServiceId = (autoPaymentIsOpen >= 0) ? serviceId : null
    this.setState({autoPaymentIsOpen: autoPaymentIsOpen, autoPaymentServiceId: autoPaymentServiceId});
  };

  editRegFormToggle = id => {
    this.setState({editRegFormIsOpen: this.state.editRegFormIsOpen === null ? id : null});
  };

  render() {
    const {schools, selectedSchool, profileDate, isCodeSend, advertImg, advertLinks} = this.props.user;
    const {
      changePassword,
      newPhoneSendCode,
      newPhoneCheckCode,
      resetCheckCode,
      changeEmail,
      getProfile,
      unlinkCard,
      parentAutoPaymentOff,
      clearAdvertising,
      addFoodDataOrder
    } = this.props;
    const {
      tabIndex,
      changePasswordIsOpen,
      changePhoneIsOpen,
      changeEmailIsOpen,
      transportCardOperationsIsOpen,
      loseCardIsOpen,
      editRegFormIsOpen,
      autoPaymentServiceId,
      autoPaymentIsOpen
    } = this.state;
    const {classes} = this.props;
    const profile = schools[selectedSchool];
    const parentDilerId = profile.parentDilerId;
    const parentAvatar = profile.avatars[profile.parent.id];

    // формируем табы
    let tabs = ["Профиль родителя"];
    // добавляем учеников во вкладки
    profile.pupils.ids.map(pupil => {
      const pupilProfile = profile.pupils[pupil];
      return tabs.push(`${pupilProfile.im} ${pupilProfile.className}`);
    });

    return (
      <>
        <LoadingMessage/>
        <div className={classes.container}>
          <Advertising {...{advertImg, advertLinks, clearAdvertising}} />
          <AppBarWithTabs tabs={tabs} tabIndex={tabIndex} tabClick={this.tabClick}/>
          <SwipeableViews
            className={classes.swipeViews}
            index={tabIndex}
            onChangeIndex={this.tabSwipe}
            animateHeight
            threshold={10}
            springConfig={{
              easeFunction: "ease-in",
              delay: "0s",
              duration: "0.3s"
            }}
          >
            <div className={classes.dataContainer}>
              <RefreshProfile {...{getProfile, profileDate, selectedSchool}} />
              <div className={classes.data}>
                <AccountPhoto
                  url={parentAvatar || ''}
                  fio={`${profile.parent.fam} ${profile.parent.im} ${profile.parent.otch}`}
                />
                {profile && (
                  <ParentData
                    {...{
                      profile,
                      selectedSchool,
                      changePasswordIsOpen,
                      changePhoneIsOpen,
                      newPhoneSendCode,
                      newPhoneCheckCode,
                      isCodeSend,
                      resetCheckCode,
                      changeEmailIsOpen,
                      autoPaymentServiceId,
                      autoPaymentIsOpen
                    }}
                    changePasswordToggle={this.changePasswordToggle}
                    changePasswordSubmit={changePassword}
                    changePhoneToggle={this.changePhoneToggle}
                    changeEmailToggle={this.changeEmailToggle}
                    changeEmailSubmit={changeEmail}
                    loseCardToggle={this.loseCardToggle}
                    autoPaymentToggle={this.autoPaymentToggle}
                    parentAutoPaymentOff={parentAutoPaymentOff}
                    transportCardOperationsToggle={this.transportCardOperationsToggle}
                    editRegFormToggle={this.editRegFormToggle}
                  />
                )}
                {(profile && transportCardOperationsIsOpen) && (
                  <TransportCardOperationsModal
                    pupilId={profile.parent.id}
                    transportCardOperationsIsOpen={transportCardOperationsIsOpen}
                    transportCardOperationsToggle={this.transportCardOperationsToggle}
                  />
                )}
                {(profile && loseCardIsOpen === profile.parent.id) && (
                  <LoseCard
                    dilerId={parentDilerId}
                    accountId={profile.parent.id}
                    cardNum={profile.parent.cardNum}
                    loseCardIsOpen={loseCardIsOpen}
                    loseCardToggle={this.loseCardToggle}
                    unlinkCard={unlinkCard}
                  />
                )}
                {(profile && editRegFormIsOpen === profile.parent.id) && (
                  <EditRegFormDlg
                    isOpened={editRegFormIsOpen}
                    onToggle={this.editRegFormToggle}
                  />
                )}
              </div>
            </div>
            {// добавляем данные для каждой вкладки ученика
              profile &&
              profile.pupils.ids.map(pupilId => {
                const pupilProfile = profile.pupils[pupilId];
                return (
                  <div key={pupilProfile.id} className={classes.dataContainer}>
                    <RefreshProfile {...{getProfile, profileDate, selectedSchool}} />
                    <div className={classes.data}>
                      <AccountPhoto
                        url={profile.avatars[pupilProfile.id]}
                        fio={`${pupilProfile.fam} ${pupilProfile.im} ${pupilProfile.otch}`}
                      />
                      <PupilData
                        accountData={pupilProfile}
                        transportCardOperationsToggle={this.transportCardOperationsToggle}
                        loseCardToggle={this.loseCardToggle}
                        addFoodDataOrder={addFoodDataOrder}
                      />
                      {transportCardOperationsIsOpen && (
                        <TransportCardOperationsModal
                          pupilId={pupilId}
                          transportCardOperationsIsOpen={transportCardOperationsIsOpen}
                          transportCardOperationsToggle={this.transportCardOperationsToggle}
                        />
                      )}
                      {(loseCardIsOpen === pupilId) && (
                        <LoseCard
                          dilerId={parentDilerId}
                          accountId={pupilId}
                          cardNum={pupilProfile.cardNum}
                          loseCardIsOpen={loseCardIsOpen}
                          loseCardToggle={this.loseCardToggle}
                          unlinkCard={unlinkCard}
                        />
                      )}
                    </div>
                  </div>
                );
              })}
          </SwipeableViews>
        </div>
      </>
    );
  }
}

Profile.propTypes = {
  changePassword: PropTypes.func.isRequired,
  newPhoneSendCode: PropTypes.func.isRequired,
  newPhoneCheckCode: PropTypes.func.isRequired,
  resetCheckCode: PropTypes.func.isRequired,
  changeEmail: PropTypes.func.isRequired,
  getProfile: PropTypes.func.isRequired,
  classes: PropTypes.object,
  user: PropTypes.object
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(Profile));
