import { createSelector } from "reselect";

const getDocTypes = state => state.registration.docTypes;

export const getDocTypesSelector = createSelector(
  [getDocTypes],
  docTypes => {
    if (!docTypes) return [];
    return docTypes
      .filter(type => type.id === 1)
      .map(type => ({
        value: type.id,
        label: type.docName
      }));
  }
);

export const getPupilDocTypesSelector = createSelector(
  [getDocTypes],
  docTypes => {
    if (!docTypes) return [];
    return docTypes
      .filter(type => type.id === 1 || type.id === 11)
      .map(type => ({
        value: type.id,
        label: type.docName
      }));
  }
);

const getRelationDegree = state => state.registration.relationDegree;

export const getRelationDegreeSelector = createSelector(
  [getRelationDegree],
  relationDegree => {
    if (!relationDegree) return [];
    return relationDegree.map(item => ({
      value: item,
      label: item
    }));
  }
);

const getSchools = state => state.registration.schools;

export const getSchoolsSelector = createSelector(
  [getSchools],
  schools => {
    if (!schools) return [];
    return schools.map(school => ({
      value: school.id,
      label: school.schoolName,
      address: school.schoolAddress
    }));
  }
);
