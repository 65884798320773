import React from "react";
import {withStyles} from "@material-ui/core/styles";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";

import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";

import ChangePassword from "./ChangePassword";
import ChangePhone from "./ChangePhone";
import ChangeEmail from "components/ChangeEmail";
import AutoPaymentOff from "./AutoPaymentOff";

import moment from "moment";
import SvgIcon from "@material-ui/core/SvgIcon";

const styles = theme => ({
  root: {
    flexGrow: 1,
    maxWidth: 600,
    minWidth: 470,
    [theme.breakpoints.down("xs")]: {
      minWidth: 100,
      maxWidth: "100%",
      width: "100%"
    }
  },
  balance: {
    backgroundColor: theme.palette.primary.main,
    fontSize: 20,
    paddingLeft: "12px !important"
  },
  leftCell: {
    maxWidth: 120,
    paddingLeft: 12
  },
  rightCell: {
    padding: "5px !important"
  },
  leftBalanceCell: {
    width: 390,
    paddingLeft: 12
  },
  indentButton: {
    marginLeft: 5
  },
  autoPaymentButton: {
    maxWidth: 16,
    height: 16
  }
});

const formatTransportCard = str => {
  // разбиваем номер пробелами по 5 символов с конца
  str = (typeof str === 'string') ? str : "отсутствует";
  return str
    .split("")
    .reverse()
    .join("")
    .match(/(.{5})|(.{4})|(.{3})|(.{2})|(.{1})/g)
    .join(" ")
    .split("")
    .reverse()
    .join("")
};

const ParentData = props => {
  const typographyStyle = "subtitle1";
  const {
    classes,
    profile,
    isCodeSend,
    resetCheckCode,
    changePhoneIsOpen,
    changePhoneToggle,
    newPhoneSendCode,
    newPhoneCheckCode,
    changePasswordIsOpen,
    changePasswordToggle,
    changePasswordSubmit,
    changeEmailIsOpen,
    changeEmailToggle,
    changeEmailSubmit,
    loseCardToggle,
    autoPaymentIsOpen,
    autoPaymentToggle,
    autoPaymentServiceId,
    parentAutoPaymentOff,
    transportCardOperationsToggle
  } = props;
  const {balances, schoolName, autoPaymentAvailable, parentDilerId} = profile;
  const {
    parentId = profile.parent.id,
    email,
    needToPay,
    parentCode,
    cardId,
    cardNum,
    foreignCardNum,
    hasTransportCard,
    payLockedDate,
    phone,
    foodBalance,
    foodModuleActive
  } = profile.parent;

  const formatedTransportCard = formatTransportCard(foreignCardNum);

  return (
    <div className={classes.root}>
      <Table padding="none">
        <TableBody>
          <TableRow>
            <TableCell className={classes.leftCell}>
              <Typography variant={typographyStyle}>Школа</Typography>
            </TableCell>
            <TableCell numeric className={classes.rightCell}>
              <Typography variant={typographyStyle}>{schoolName}</Typography>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={classes.leftCell}>
              <Typography variant={typographyStyle}>Лицевой счет</Typography>
            </TableCell>
            <TableCell numeric className={classes.rightCell}>
              <Typography variant={typographyStyle}>{parentCode}</Typography>
            </TableCell>
          </TableRow>
          {(cardId !== null && cardId >= 0) && (
            <TableRow>
              <TableCell className={classes.leftCell}>
                <Typography variant={typographyStyle}>Пропуск УЭШКа</Typography>
              </TableCell>
              <TableCell numeric className={classes.rightCell}>
                <Typography variant={typographyStyle}>{cardNum}<br/>
                  {(Number(cardNum) > 0) && (<Button
                    className={classes.indentButton}
                    variant="outlined"
                    size="small"
                    onClick={() => loseCardToggle(parentId)}
                  >
                    Заблокировать по утере
                  </Button>)}
                </Typography>
              </TableCell>
            </TableRow>
          )}
          {(cardId !== null && cardId >= 0 && hasTransportCard) && (
            <TableRow>
              <TableCell className={classes.leftCell}>
                <Typography variant={typographyStyle}>Транспортная карта</Typography>
              </TableCell>
              <TableCell numeric className={classes.rightCell}>
                <Typography variant={typographyStyle}>
                  {formatedTransportCard}
                  <div className={classes.buttonWrapper}>
                    <Button
                      className={classes.indentButton}
                      variant="outlined"
                      size="small"
                      onClick={() => transportCardOperationsToggle(parentId)}
                    >
                      Операции
                    </Button>
                  </div>
                </Typography>
              </TableCell>
            </TableRow>
          )}
          <TableRow>
            <TableCell className={classes.leftCell}>
              <Typography variant={typographyStyle}>Телефон</Typography>
            </TableCell>
            <TableCell numeric className={classes.rightCell}>
              <Typography variant={typographyStyle} color="primary">
                {phone}
                <Button
                  className={classes.indentButton}
                  variant="outlined"
                  size="small"
                  onClick={() => props.changePhoneToggle()}
                >
                  Изменить
                </Button>
              </Typography>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={classes.leftCell}>
              <Typography variant={typographyStyle}>Email</Typography>
            </TableCell>
            <TableCell numeric className={classes.rightCell}>
              <Typography variant={typographyStyle} color="primary">
                {email}
                <Button
                  className={classes.indentButton}
                  variant="outlined"
                  size="small"
                  onClick={() => props.changeEmailToggle()}
                >
                  {!email ? "Добавить" : "Изменить"}
                </Button>
              </Typography>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={classes.leftCell}>
              <Typography variant={typographyStyle}>Задолженность</Typography>
            </TableCell>
            <TableCell numeric className={classes.rightCell}>
              <Typography variant={typographyStyle}>
                {needToPay && needToPay > 0 ? needToPay + " р." : "Отсутствует"}
              </Typography>
            </TableCell>
          </TableRow>
          {payLockedDate ? (
            <TableRow>
              <TableCell className={classes.leftCell}>
                <Typography variant={typographyStyle}>Дата блокировки</Typography>
              </TableCell>
              <TableCell numeric className={classes.rightCell}>
                <Typography variant={typographyStyle}>
                  {moment(payLockedDate, "YYYY-MM-DD").format("DD.MM.YYYY")}г.
                </Typography>
              </TableCell>
            </TableRow>
          ) : null}
          <TableRow>
            <TableCell colSpan={2} numeric className={classes.rightCell}>
              <Button variant="outlined" size="small" onClick={() => props.changePasswordToggle()}>
                Сменить пароль
              </Button>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell colSpan={2} numeric className={classes.rightCell}>
              {(parentDilerId !== 7) &&(<Button variant="outlined" size="small" onClick={() => props.editRegFormToggle(parentId)}>
                Изменить данные регистрационной формы
              </Button>)}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell colSpan={2} className={classes.balance}>
              Балансы
            </TableCell>
          </TableRow>
          {balances &&
          balances.ids.map(balanceId => {
            // оставляем только отличные от 0
            if (balanceId === 1 || Number(balances[balanceId].balance) !== 0 || balances[balanceId].payDate.length > 0) {
              return (
                <TableRow key={balanceId}>
                  <TableCell className={classes.leftBalanceCell}>
                    <Typography variant={typographyStyle}
                                color={(balances[balanceId].balance < 0) ? "error" : "default"}>{balances[balanceId].name}</Typography>
                    {
                      (autoPaymentAvailable && balances[balanceId].payDate.length > 0)
                      && (
                        <Table padding="none">
                          <TableBody>
                            <TableRow>
                              <TableCell>
                                <Typography
                                  variant="caption">Автоплатеж: {balances[balanceId].payDate} ({balances[balanceId].autopayCard})</Typography>
                              </TableCell>
                              <TableCell>
                                <Button className={classes.autoPaymentButton}
                                        onClick={() => autoPaymentToggle(parentId, balanceId)}
                                        size="small"
                                >
                                  <SvgIcon viewBox="0 0 120 120">
                                    <path fill="#f78f8f"
                                          d="M40 49.007L15.714 73.293 6.707 64.286 30.993 40 6.707 15.714 15.714 6.707 40 30.993 64.286 6.707 73.293 15.714 49.007 40 73.293 64.286 64.286 73.293z"/>
                                    <path fill="#c74343"
                                          d="M15.714,7.414l23.578,23.578L40,31.7l0.707-0.707L64.286,7.414l8.3,8.3L49.007,39.293L48.3,40 l0.707,0.707l23.578,23.579l-8.3,8.3L40.707,49.007L40,48.3l-0.707,0.707L15.714,72.586l-8.3-8.3l23.579-23.579L31.7,40 l-0.707-0.707L7.414,15.714L15.714,7.414 M64.286,6L40,30.286L15.714,6L6,15.714L30.286,40L6,64.286L15.714,74L40,49.714L64.286,74 L74,64.286L49.714,40L74,15.714L64.286,6L64.286,6z"/>
                                  </SvgIcon>
                                </Button>
                              </TableCell>
                            </TableRow>
                          </TableBody>
                        </Table>
                      )
                    }
                  </TableCell>
                  <TableCell numeric className={classes.rightCell}>
                    <Typography variant={typographyStyle}>
                      {balances[balanceId].balance} р.
                    </Typography>
                  </TableCell>
                </TableRow>
              );
            } else return null;
          })}
          {(foodModuleActive || Number(foodBalance) !== 0) && (
            <TableRow key={0}>
              <TableCell padding="checkbox">
                <Typography variant={typographyStyle}>Питание</Typography>
              </TableCell>
              <TableCell numeric className={classes.rightCell}>
                <Typography variant={typographyStyle} color={(Number(foodBalance) < 0) ? "error" : "default"}>
                  {foodBalance} р.
                </Typography>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
      <ChangePassword {...{changePasswordIsOpen, changePasswordToggle, changePasswordSubmit}} />
      <ChangePhone
        {...{
          parentId,
          changePhoneIsOpen,
          changePhoneToggle,
          newPhoneSendCode,
          newPhoneCheckCode,
          isCodeSend,
          resetCheckCode
        }}
      />
      <ChangeEmail {...{parentId, changeEmailIsOpen, changeEmailToggle, changeEmailSubmit}} />
      <AutoPaymentOff {...{
        parentId,
        balances,
        autoPaymentServiceId,
        autoPaymentIsOpen,
        autoPaymentToggle,
        parentAutoPaymentOff
      }} />
    </div>
  );
};

export default withStyles(styles)(ParentData);
