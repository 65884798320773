import React from "react";
import PropTypes from "prop-types";
import Typography from "@material-ui/core/Typography";

import InputField from "../../InputField";

const ParentFio = props => {
  const { values, handleChange, errors, onKeyUp } = props;
  return (
    <>
      <Typography variant="button">ФИО законного представителя</Typography>
      <div>
        <InputField
          id="fam"
          label="Фамилия"
          required
          error={errors.fam}
          onChange={handleChange}
          value={values.fam}
          onKeyUp={onKeyUp}
        />
        <InputField
          id="im"
          label="Имя"
          required
          error={errors.im}
          onChange={handleChange}
          value={values.im}
          onKeyUp={onKeyUp}
        />

        <InputField
          id="otch"
          label="Отчество"
          error={errors.otch}
          onChange={handleChange}
          value={values.otch}
          onKeyUp={onKeyUp}
        />
      </div>
      <br />
    </>
  );
};

ParentFio.propTypes = {
  values: PropTypes.shape({
    fam: PropTypes.string,
    im: PropTypes.string,
    otch: PropTypes.string
  }),
  handleChange: PropTypes.func.isRequired,
  errors: PropTypes.object
};

export default ParentFio;
