import axios from "axios";
import { createAction } from "redux-actions";
import { API, handleApiError } from "api";

export const helpRequest = createAction("HELP_REQUEST");
export const helpSuccess = createAction("HELP_SUCCESS");
export const helpError = createAction("HELP_ERROR");

export const getHelpData = parentId => async dispatch => {
  try {
    dispatch(helpRequest());
    const res = await axios.get(`${API}/help/faq/${parentId}`);
    dispatch(
      helpSuccess({
        helpData: res.data
      })
    );
  } catch (error) {
    dispatch(handleApiError(error, helpError()));
  }
};
