import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import Hidden from "@material-ui/core/Hidden";

import Answers from "./Answers";
import FooterElements from "components/FooterElements";

import LoadingMessage from "components/messages/LoadingMessage";

import * as helpActions from "actions/helpActions";

const mapStateToProps = state => {
  return {
    user: state.user,
    help: state.help
  };
};

const mapDispatchToProps = dispatch => {
  return {
    helpActions: bindActionCreators(helpActions, dispatch)
  };
};

class Help extends Component {
  state = {
    expanded: null
  };

  componentDidMount() {
    const selectedSchool = this.props.user.selectedSchool;
    const profile = this.props.user.schools[selectedSchool];
    const parentId = profile.parent.id;
    this.props.helpActions.getHelpData(parentId);
  }

  changeExpand = panel => (event, expanded) => {
    this.setState({
      expanded: expanded ? panel : false
    });
  };
  //TODO: передавать разные ответы в завсисимости от дилера
  render() {
    const { expanded } = this.state;
    const { answers, description } = this.props.help;
    if (!answers) {
      return <LoadingMessage />;
    } else {
      return (
        <div>
          <Answers {...{ answers, description, expanded }} changeExpand={this.changeExpand} />
          <Hidden smUp>
            <FooterElements />
          </Hidden>
        </div>
      );
    }
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Help);
