import axios from "axios";
import {createAction} from "redux-actions";
import {API, handleApiError} from "api";
import {showInfoMessage} from "actions/infoMessageActions";

export const khalvaRegionsRequest = createAction('KHALVA_REGIONS_REQUEST');
export const khalvaRegionsSuccess = createAction('KHALVA_REGIONS_SUCCESS');
export const khalvaRegionsError = createAction('KHALVA_REGIONS_ERROR');

export const khalvaSetBankCardOrderRequest = createAction('KHALVA_SET_BANKCARD_ORDER_REQUEST');
export const khalvaSetBankCardOrderSuccess = createAction('KHALVA_SET_BANKCARD_ORDER_SUCCESS');
export const khalvaSetBankCardOrderError = createAction('KHALVA_SET_BANKCARD_ORDER_ERROR');

export const khalvaSetErrors = errors => dispatch => {
  dispatch(khalvaSetBankCardOrderError({
    errors: errors,
    error: "Проверьте правильность введенных данных",
  }))
}

export const khalvaGetRegions = () => async dispatch => {
  try {
    dispatch(khalvaRegionsRequest());
    const res = await axios.get(`${API}/khalva/regions`);
    dispatch(khalvaRegionsSuccess({
      regions: res.data
    }))
  } catch (error) {
    dispatch(handleApiError(error, khalvaRegionsError()));
  }
}

export const khalvaSetBankCardOrder = orderData => async dispatch => {
  try {
    dispatch(khalvaSetBankCardOrderRequest());
    const {dilerId, sendData} = orderData;

    const res = await axios.post(`${API}/khalva/${dilerId}/order`, {
      ...sendData
    })
    dispatch(khalvaSetBankCardOrderSuccess());
    dispatch(showInfoMessage(res.data.message));
  } catch (error) {
    if (error.response && error.response.status === 400) {
      dispatch(khalvaSetBankCardOrderError({
        errors: error.response.data.message,
        error: "Проверьте правильность введенных данных",
      }))
    } else {
      dispatch(handleApiError(error, khalvaSetBankCardOrderError()));
    }
  }
}