// reducer для обработки статуса загрузки
const initialState = {
  isLoading: false,
  message: ""
};

export default function loading(state = initialState, action) {
  // загрузка при запросе данных из апи
  if (action.type.match(/_REQUEST$/)) {
    return {
      ...state,
      isLoading: true,
      message: action.payload && action.payload.message ? action.payload.message : "Загрузка"
    };
  }
  if (action.type.match(/_ERROR|_SUCCESS/)) {
    // если ошибка или данные загружены прекращаем загрузку
    return { ...state, isLoading: false };
  }
  return state;
}
