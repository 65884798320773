import React, { Component } from "react";

import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import InputAdornment from "@material-ui/core/InputAdornment";
import PhonelinkSetup from "@material-ui/icons/PhonelinkSetup";

import withStyles from "@material-ui/core/styles/withStyles";

const styles = theme => ({
  input: {
    marginTop: 10,
    fontSize: 25
  },
  positionStart: {
    fontSize: 20
  },
  modal: {
    [theme.breakpoints.down("xs")]: {
      alignItems: "flex-start"
    }
  },
  paper: {
    margin: 10
  }
});

class ChangePhone extends Component {
  state = { phone: "", code: "", view: 1 };

  errorOnValidate = phone => {
    if (!phone) {
      return false;
    } else if (phone && /^\d{10}$/.test(phone)) {
      return false;
    } else {
      return true;
    }
  };

  sendPhoneClick = () => {
    this.props.newPhoneSendCode(this.props.parentId, this.state.phone);
    this.setState({ phone: "", view: 2 });
  };

  sendCodeClick = () => {
    this.props.newPhoneCheckCode(this.props.parentId, this.state.code);
    this.setState({ code: "", view: 1 });
    this.props.changePhoneToggle();
    this.props.resetCheckCode();
  };

  inputOnChange = name => event => {
    this.setState({
      [name]: event.target.value
    });
  };

  onClose = () => {
    this.props.changePhoneToggle();
    this.props.resetCheckCode();
    this.setState({ phone: "", code: "", view: 1 });
  };

  render() {
    const { classes, isCodeSend } = this.props;
    if (!isCodeSend) {
      return (
        <Dialog
          open={this.props.changePhoneIsOpen}
          onClose={this.onClose}
          classes={{ root: classes.modal, paper: classes.paper }}
        >
          <DialogTitle>Изменение номера телефона</DialogTitle>
          <DialogContent>
            <DialogContentText>Введите новый номер телефона. 10 цифр</DialogContentText>
            <FormControl required fullWidth>
              <InputLabel>Номер телефона</InputLabel>
              <Input
                autoFocus
                margin="dense"
                name="phone"
                type="number"
                error={this.errorOnValidate(this.state.phone)}
                className={classes.input}
                startAdornment={
                  <InputAdornment position="start">
                    <span className={classes.positionStart}>+7</span>
                  </InputAdornment>
                }
                endAdornment={
                  <InputAdornment position="end">
                    <PhonelinkSetup color="primary" />
                  </InputAdornment>
                }
                value={this.state.phone}
                onChange={this.inputOnChange("phone")}
              />
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.onClose} color="primary">
              Отмена
            </Button>
            <Button
              onClick={this.sendPhoneClick}
              color="primary"
              disabled={this.errorOnValidate(this.state.phone) || !this.state.phone ? true : false}
            >
              Подтвердить
            </Button>
          </DialogActions>
        </Dialog>
      );
    } else if (isCodeSend) {
      return (
        <Dialog
          open={this.props.changePhoneIsOpen}
          onClose={this.onClose}
          className={classes.modal}
        >
          <DialogTitle>Проверка кода</DialogTitle>
          <DialogContent>
            <DialogContentText>
              На указанный номер был отправлен код подтверждения.
              <br />
              Введите его в поле ниже.
            </DialogContentText>
            <FormControl required fullWidth>
              <InputLabel>Проверочный код</InputLabel>
              <Input
                className={classes.input}
                autoFocus
                margin="dense"
                name="code"
                type="number"
                label="Проверочный код"
                value={this.state.code}
                onChange={this.inputOnChange("code")}
              />
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.onClose} color="primary">
              Отмена
            </Button>
            <Button onClick={this.sendCodeClick} color="primary">
              Подтвердить
            </Button>
          </DialogActions>
        </Dialog>
      );
    }
  }
}

export default withStyles(styles)(ChangePhone);
