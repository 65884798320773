import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import Button from "@material-ui/core/Button";
import { fetchArchive } from "actions/registrationActions";

import { withStyles } from "@material-ui/core/styles";
const styles = theme => ({
  buttons: {
    position: "relative",
    display: "flex",
    justifyContent: "flex-start",
    marginTop: 15
  },
  nextButton: {
    flexGrow: 1
  }
});

class Step6 extends Component {
  render() {
    const { classes, fetchArchive, link } = this.props;
    return (
      <>
        {link && (
          <div className={classes.buttons}>
            <Button
              // className={classes.nextButton}
              variant="outlined"
              color="secondary"
              size="large"
              onClick={() => fetchArchive(link)}
            >
              Скачать архив
            </Button>
          </div>
        )}
      </>
    );
  }
}

Step6.propTypes = {
  classes: PropTypes.object,
  fetchArchive: PropTypes.func.isRequired,
  link: PropTypes.string
};

const mapStateToProps = ({ registration }) => ({
  link: registration.link
});

const mapDispatchToProps = {
  fetchArchive
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(Step6));
