import React from "react";
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";

import withStyles from "@material-ui/core/styles/withStyles";

const styles = theme => ({
  textField: {
    margin: "5px 0"
  },
  label: {
    color: "white"
  },
  input: {
    backgroundColor: "white",
    color: "black",
    paddingLeft: 5,
    marginTop: 5
  },
  radioWrapper: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center"
  },
  radioLabel: {
    color: "white"
  },
  radioRoot: {
    marginLeft: 0
  },
  radio: {
    color: theme.palette.primary.main,
    padding: "2px 15px"
  }
});

const AddDeviceForm = ({
  classes,
  deviceName,
  deviceCode,
  changeInputValue,
  clearAddDeviceForm,
  submitAddDeviceForm,
  changeDeviceType,
  isWatch
}) => (
    <div className={classes.addDevice}>
      <TextField
        className={classes.textField}
        name="deviceName"
        label="Наименование устройства"
        value={deviceName || ""}
        onChange={changeInputValue}
        fullWidth
        InputLabelProps={{
          classes: { root: classes.label },
          shrink: true
        }}
        InputProps={{ classes: { input: classes.input } }}
      />
      <RadioGroup
        className={classes.radioWrapper}
        value={isWatch.toString()}
        onChange={event => changeDeviceType(event.target.value === "true")}
      >
        <FormControlLabel
          value="false"
          classes={{ root: classes.radioRoot, label: classes.radioLabel }}
          control={<Radio className={classes.radio} />}
          label="Смартфон"
          labelPlacement="start"
        />
        <FormControlLabel
          value="true"
          classes={{ label: classes.label }}
          control={<Radio className={classes.radio} />}
          label="Часы"
          labelPlacement="start"
        />
      </RadioGroup>
      <TextField
        className={classes.textField}
        name="deviceCode"
        label="Код устройства"
        value={deviceCode || ""}
        onChange={changeInputValue}
        fullWidth
        InputLabelProps={{
          classes: { root: classes.label },
          shrink: true
        }}
        InputProps={{ classes: { input: classes.input }, type: "number" }}
      />
      <Button color="primary" onClick={clearAddDeviceForm}>
        Отмена
      </Button>
      <Button color="primary" onClick={submitAddDeviceForm}>
        Добавить
      </Button>
    </div>
);

export default withStyles(styles)(AddDeviceForm);

AddDeviceForm.propTypes = {
  classes: PropTypes.object,
  deviceName: PropTypes.string.isRequired,
  deviceCode: PropTypes.string.isRequired,
  submitAddDeviceForm: PropTypes.func.isRequired,
  changeInputValue: PropTypes.func.isRequired,
  clearAddDeviceForm: PropTypes.func.isRequired,
  changeDeviceType: PropTypes.func.isRequired,
  isWatch: PropTypes.bool.isRequired
};
