import { handleActions, combineActions } from "redux-actions";

import {
  fiasRegionsSuccess,
  fiasAreasRequest,
  fiasAreasError,
  fiasAreasSuccess,
  fiasCitiesRequest,
  fiasCitiesError,
  fiasCitiesSuccess,
  fiasLocalitiesRequest,
  fiasLocalitiesError,
  fiasLocalitiesSuccess,
  fiasStructuresRequest,
  fiasStructuresError,
  fiasStructuresSuccess,
  fiasStreetsRequest,
  fiasStreetsSuccess,
  fiasStreetsError,
  fiasHousesRequest,
  fiasHousesSuccess,
  fiasHousesError,
  clearFiasDataStart
} from "actions/fiasActions";

const initialState = {
  regions: null,
  areas: null,
  cities: null,
  localities: null,
  structures: null,
  streets: null
};

const fias = handleActions(
  {
    [fiasRegionsSuccess]: (state, action) => ({
      ...state,
      regions: action.payload.regions
    }),
    [combineActions(fiasAreasRequest, fiasAreasError)]: state => ({
      ...state,
      areas: null
    }),
    [fiasAreasSuccess]: (state, action) => ({
      ...state,
      areas: action.payload.areas
    }),
    [combineActions(fiasCitiesRequest, fiasCitiesError)]: state => ({
      ...state,
      cities: null
    }),
    [fiasCitiesSuccess]: (state, action) => ({
      ...state,
      cities: action.payload.cities
    }),
    [combineActions(fiasLocalitiesRequest, fiasLocalitiesError)]: state => ({
      ...state,
      localities: null
    }),
    [fiasLocalitiesSuccess]: (state, action) => ({
      ...state,
      localities: action.payload.localities
    }),
    [combineActions(fiasStructuresRequest, fiasStructuresError)]: state => ({
      ...state,
      structures: null
    }),
    [fiasStructuresSuccess]: (state, action) => ({
      ...state,
      structures: action.payload.structures
    }),
    [combineActions(fiasStreetsRequest, fiasStreetsError)]: state => ({
      ...state,
      streets: null
    }),
    [fiasStreetsSuccess]: (state, action) => ({
      ...state,
      streets: action.payload.streets
    }),
    [combineActions(fiasHousesRequest, fiasHousesError)]: state => ({
      ...state,
      houses: null
    }),
    [fiasHousesSuccess]: (state, action) => ({
      ...state,
      houses: action.payload.houses
    }),

    [clearFiasDataStart]: (state, action) => ({
      ...state,
      [action.payload]: null
    })
  },
  initialState
);

export default fias;
