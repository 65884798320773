import React from "react";
import PaperInvoiceCard from "../paperInvoice/PaperInvoiceCard";
import BankPay from "./BankPay";

const Pay = props => {
  const { paperInvoice, paperInvoiceMethods, ...bankPaysProps } = props;
  const {profile, errorOnValidateAmount} = props;
  return (
    <>
      <PaperInvoiceCard {...{ paperInvoice, paperInvoiceMethods, profile, errorOnValidateAmount }} />
      <BankPay {...bankPaysProps} />
    </>
  );
};

export default Pay;
