import React from "react";
import { Placemark } from "react-yandex-maps";
import moment from "moment";

import PropTypes from "prop-types";

const LastCoordinatesPlacemark = props => {
  const { selectedDevice, lastCoordinates } = props;

  if (selectedDevice && lastCoordinates) {
    const baloonText = `${moment(lastCoordinates.day, "YYYY-MM-DD").format("DD.MM.YYYY")} ${
      lastCoordinates.time
    }`;
    return (
      <Placemark
        geometry={[lastCoordinates.latitude, lastCoordinates.longitude]}
        properties={{
          balloonContentBody: baloonText
        }}
        options={{
          zIndex: 100
        }}
      />
    );
  } else return null;
};

export default LastCoordinatesPlacemark;

LastCoordinatesPlacemark.propTypes = {
  selectedDevice: PropTypes.number,
  lastCoordinates: PropTypes.shape({
    latitude: PropTypes.number.isRequired,
    longitude: PropTypes.number.isRequired,
    day: PropTypes.string.isRequired,
    time: PropTypes.string.isRequired
  })
};
