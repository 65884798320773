import { handleActions } from "redux-actions";

import {
  pupilListClear,
  pupilListSuccess,
  payPromoUrlSuccess,
  payPromoUrlClear,
  changePromoStepSuccess
} from "actions/promo201912Actions";

const initialState = {
  step: 0,
  payUrl: undefined,
  pupils: []
};

const promo = handleActions(
  {
    [pupilListSuccess]: (state, action) => ({
      ...state,
      pupils: action.payload.pupils
    }),
    [pupilListClear]: (state) => ({
      ...state,
      pupils: []
    }),
    [payPromoUrlSuccess]: (state, action) => ({
      ...state,
      payUrl: action.payload.payUrl
    }),
    [payPromoUrlClear]: state => ({
      ...state,
      payUrl: undefined
    }),
    [changePromoStepSuccess]: (state, { payload: step }) => ({ ...state, step: step })
  },
  initialState
);

export default promo;
