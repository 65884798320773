import { handleActions } from "redux-actions";
import {
  fetchMonthlyAttendanceSuccess,
  fetchDailyAttendanceSuccess
} from "actions/attendanceActions";

const initialState = {};

const attendance = handleActions(
  {
    [fetchMonthlyAttendanceSuccess]: (state, { payload: month }) => ({
      ...state,
      month: month,
      isLocalLoading: false
    }),
    [fetchDailyAttendanceSuccess]: (state, { payload: day }) => ({
      ...state,
      day: day,
      isLocalLoading: false
    })
  },
  initialState
);

export default attendance;
