import React from "react";
import TextField from "@material-ui/core/TextField";
import {DatePicker} from "material-ui-pickers";
import MomentUtils from "@date-io/moment";
import {MuiPickersUtilsProvider} from "material-ui-pickers";

import moment from "moment";

import {withStyles} from "@material-ui/core/styles";

const styles = theme => ({
  textField: {
    width: 200,
    minWidth: 150,
    margin: "8px 10px 4px 0"
  },
  textFieldFullWidth: {
    width: "100%",
    maxWidth: 620,
    minWidth: 150,
    margin: "8px 0 4px 0"
  },
  input: {
    padding: "10px 15px"
  },
  inputFullWidth:{
    padding: "10px 15px",
    width: "100%",
    maxWidth: 620,
    minWidth: 150,
  },
  label: {
    transform: "translate(14px, 10px) scale(1)"
  }
});

const InputField = props => {
  const {classes} = props;
  const maxDate = (props.maxDate) ? props.maxDate : Date.now();

  if (props.datePicker) {
    const tenYearsBack = (new Date()).getFullYear() - 10;
    return (
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <DatePicker
          className={(props.fullWidth) ? classes.textFieldFullWidth : classes.textField}
          InputProps={{
            classes: {input: (props.fullWidth) ? classes.inputFullWidth :classes.input}
          }}
          InputLabelProps={{
            classes: {outlined: classes.label},
            shrink: true
          }}
          initialFocusedDate={moment(new Date(tenYearsBack, 0, 1))}
          keyboard
          clearable
          label={props.label}
          format="DD.MM.YYYY"
          mask={value =>
            value
              ? [/[0-3]/, /\d/, ".", /0|1/, /\d/, ".", /1|2/, /\d/, /\d/, /\d/]
              : []
          }
          disableOpenOnEnter
          autoOk
          disableFuture
          maxDate={maxDate}
          maxDateMessage="Дата не должна быть больше текущей"
          clearLabel="Очистить"
          cancelLabel="Отмена"
          todayLabel="Сегодня"
          variant="outlined"
          margin="none"
          error={!!props.error}
          helperText={typeof props.error === 'string' ? props.error : ""}
          value={props.value ? moment(props.value, "YYYY-MM-DD") : null}
          onChange={props.onChange}
          onKeyUp={e => {if (props.onKeyUp) props.onKeyUp(e)}}
        />
      </MuiPickersUtilsProvider>
    );
  }
  if (props.phone) {
    return (
        <TextField
          id={props.id}
          name={props.name}
          required={props.required}
          label={props.label}
          error={!!props.error}
          disabled={props.disabled}
          helperText={typeof props.error === 'string' ? props.error : ""}
          defaultValue={props.defaultValue}
          InputProps={{
            classes: {input: classes.input}
          }}
          InputLabelProps={{
            classes: {outlined: classes.label},
            shrink: true
          }}
          className={props.fullWidth ? classes.textFieldFullWidth : classes.textField}
          onChange={props.onChange}
          onKeyUp={e => {if (props.onKeyUp) props.onKeyUp(e); return e;}}
          value={props.value}
          margin="none"
          variant="outlined"
        />
    );
  } else {
    return (
      <TextField
        id={props.id}
        name={props.name}
        required={props.required}
        label={props.label}
        error={!!props.error}
        disabled={props.disabled}
        helperText={typeof props.error === 'string' ? props.error : ""}
        defaultValue={props.defaultValue}
        InputProps={{
          classes: {input: classes.input}
        }}
        InputLabelProps={{
          classes: {outlined: classes.label},
          shrink: true
        }}
        className={props.fullWidth ? classes.textFieldFullWidth : classes.textField}
        onChange={props.onChange}
        onKeyUp={e => {if (props.onKeyUp) props.onKeyUp(e); return e;}}
        value={props.value}
        margin="none"
        variant="outlined"
      />
    );
  }
};

export default withStyles(styles)(InputField);
