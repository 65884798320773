import React from "react";
import { Polyline } from "react-yandex-maps";

const HistoryCoordinatesLines = props => {
  const { history } = props;
  const linePoints = history.map(point => {
    return [point.latitude, point.longitude];
  });

  if (history && history.length > 0) {
    return (
      <Polyline
        geometry={linePoints}
        options={{
          balloonCloseButton: false,
          strokeColor: "#091cfd",
          strokeWidth: 4,
          strokeOpacity: 0.7
        }}
      />
    );
  } else return null;
};

export default HistoryCoordinatesLines;
