import React, {Component} from "react";
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";

import {withStyles} from "@material-ui/core/styles";
import {registrationRes} from "../resources";

const styles = theme => ({
  buttons: {
    position: "relative",
    display: "flex",
    justifyContent: "flex-start",
    marginTop: 15
  },
  nextButton: {
    flexGrow: 1
  },
  aLink: {
    color: "#00F",
    ":visited": {
      color: "#00F",
    }
  }
});

class Step1 extends Component {
  render() {
    const {classes, changeStep, parentDilerId} = this.props;
    const regData = registrationRes[parentDilerId] || registrationRes[registrationRes.default];
    const {Description, contractLink, videoLink} = regData

    return (
      <>
        <Description classes={classes} contractLink={contractLink} videoLink={videoLink}/>
        <div className={classes.buttons}>
          <Button
            className={classes.nextButton}
            variant="outlined"
            color="secondary"
            size="large"
            onClick={e => changeStep(1)}
          >
            Далее
          </Button>
        </div>
      </>
    );
  }
}

Step1.propTypes = {
  classes: PropTypes.object,
  changeStep: PropTypes.func.isRequired
};

export default withStyles(styles)(Step1);
