import React from "react";

import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepButton from "@material-ui/core/StepButton";
import StepContent from "@material-ui/core/StepContent";

import Step1 from "./step1/Step1";
import Step2 from "./step2/Step2";
import Step3 from "./step3/Step3";
import Step4 from "./step4/Step4";

import {withStyles} from "@material-ui/core/styles";

const styles = theme => ({
  container: {
    display: "flex",
    flexDirection: "column"
  },
  stepper: {
    width: "calc(100% - 270px)",
    [theme.breakpoints.down("sm")]: {
      padding: "10px 5px",
      width: "calc(100% - 10px)",
      overflow: "hidden"
    }
  }
});

const validateError = {
  insurerPostIndex: (localObject, serverObject) => {
    return localObject.insurerPostIndex !== undefined
      ? localObject.insurerPostIndex.search(/^\d{6}$/) === -1
      : serverObject.insurerPostIndex === null
        ? true
        : false;
  },
  insurerCityName: (localObject, serverObject) => {
    return localObject.insurerCityName !== undefined
      ? localObject.insurerCityName.search(/^[а-яА-Я]{2,}/) === -1
      : serverObject.insurerCityName === null
        ? true
        : false;
  },
  insurerPhone: (localObject, serverObject) => {
    return localObject.insurerPhone !== undefined
      ? localObject.insurerPhone.search(/^\d{10}$/) === -1
      : serverObject.insurerPhone === null
        ? true
        : false;
  },
  notEmpty: (name, localObject, serverObject) => {
    // if (name === 'accountGender') console.log(localObject, serverObject)
    if (name === 'insurerDocSeriesNumber') {
      return (validateError.notEmpty('insurerDocSeries', localObject, serverObject) ||
        validateError.notEmpty('insurerDocNumber', localObject, serverObject))
    }
    return localObject[name] !== undefined
      ? localObject[name].length === 0
      : serverObject[name] === null
        ? true
        : false;
  }
};

const NewInsurance = props => {
  const {
    classes,
    available,
    step,
    selectedPolicy,
    selectedInsurance,
    changeStep,
    changePolicy,
    changeInsurance,
    parentData,
    isLoading,
    changeParentData,
    changeParentDocDate,
    changeParentBirthDate,
    changePupilDocDate,
    parent,
    pupilData,
    pupil,
    pupils,
    changePupilData,
    selectPupil,
    selectedPupil,
    pupilDocsType,
    changePupilDocsType,
    changePupilBirthday,
    addInsuranceInvoice
  } = props;
  return (
    <div className={classes.container}>
      <Stepper className={classes.stepper} activeStep={step} orientation="vertical">
        <Step>
          <StepButton onClick={e => changeStep("none", 0)}>Выбор программы страхования</StepButton>
          <StepContent>
            <Step1
              {...{
                available,
                selectedPolicy,
                selectedInsurance,
                isLoading,
                changePolicy,
                changeInsurance,
                changeStep
              }}
            />
          </StepContent>
        </Step>
        <Step>
          <StepButton onClick={e => changeStep("none", 1)}>Данные страхователя</StepButton>
          <StepContent>
            <Step2
              {...{
                available,
                selectedPolicy,
                selectedInsurance,
                validateError,
                changeStep,
                parentData,
                isLoading,
                changeParentData,
                changeParentDocDate,
                changeParentBirthDate,
                parent
              }}
            />
          </StepContent>
        </Step>
        <Step>
          <StepButton onClick={e => changeStep("none", 2)}>Данные застрахованного лица</StepButton>
          <StepContent>
            <Step3
              {...{
                available,
                selectedPolicy,
                selectedInsurance,
                validateError,
                changeStep,
                changePupilData,
                pupilData,
                pupils,
                pupil,
                selectPupil,
                selectedPupil,
                isLoading,
                changePupilDocDate,
                pupilDocsType,
                changePupilDocsType,
                changePupilBirthday
              }}
            />
          </StepContent>
        </Step>
        <Step>
          <StepButton>Оформление полиса</StepButton>
          <StepContent>
            <Step4 {...{
              available,
              selectedPolicy,
              selectedInsurance,
              isLoading,
            }}
                   addInsuranceInvoice={addInsuranceInvoice} changeStep={changeStep}/>
          </StepContent>
        </Step>
      </Stepper>
    </div>
  );
};

export default withStyles(styles)(NewInsurance);
