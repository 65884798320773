import { handleActions } from "redux-actions";
import {
  foodCommissionSuccess,
  commissionsSuccess,
  promoAmountSuccess,
  payUrlSuccess,
  payUrlClear,
  promoAmountClear,
  invoicesSuccess,
  invoicePeriodsRequest,
  invoicePeriodsSuccess,
  setLastInvoice,
  setLastOperations,
  invoiceDetailsSuccess,
  paperInvoiceSuccess
} from "actions/paysActions";

const initialState = {};

const pays = handleActions(
  {
    [foodCommissionSuccess]: (state, action) => ({
      ...state,
      foodCommission: action.payload.foodCommission
    }),
    [commissionsSuccess]: (state, action) => ({
      ...state,
      commissions: action.payload.commissions
    }),
    [promoAmountSuccess]: (state, action) => ({
      ...state,
      promoAmount: action.payload.promoAmount,
      promoPupils: action.payload.pupils
    }),
    [promoAmountClear]: state => ({
      ...state,
      promoAmount: undefined,
      promoPupils: undefined
    }),
    [payUrlSuccess]: (state, action) => ({
      ...state,
      payUrl: action.payload.payUrl
    }),
    [payUrlClear]: state => ({
      ...state,
      payUrl: undefined
    }),
    [invoicesSuccess]: (state, action) => ({
      ...state,
      invoices: action.payload.invoices
    }),
    [invoicePeriodsRequest]: state => ({
      ...state,
      invoicePeriods: undefined,
      invoices: undefined,
      lastInvoice: undefined
    }),
    [invoicePeriodsSuccess]: (state, action) => ({
      ...state,
      invoicePeriods: action.payload.invoicePeriods
    }),
    [setLastInvoice]: (state, action) => ({
      ...state,
      lastInvoice: action.payload.lastInvoice
    }),
    [setLastOperations]: (state, action) => ({
      ...state,
      lastOperations: action.payload.lastOperations
    }),
    [invoiceDetailsSuccess]: (state, action) => ({
      ...state,
      invoiceDetails: action.payload.details
    }),
    [paperInvoiceSuccess]: (state, action) => ({
      ...state,
      paperInvoice: action.payload.paperInvoice
    })
  },
  initialState
);

export default pays;
