import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import LoadingMessage from "components/messages/LoadingMessage";
import AppBarWithTabs from "components/AppBarWithTabs";
import SwipeableViews from "react-swipeable-views";

import Description from "./Description";
import Completed from "./completed/Completed";
import NewInsurance from "./new/NewInsurance";

import withStyles from "@material-ui/core/styles/withStyles";

import * as insuranceActions from "actions/insuranceActions";

import moment from "moment";
import PolicyTypeSelect from "./new/step1/PolicyTypeSelect";

const styles = theme => ({
  container: {
    display: "flex",
    height: "100%",
    flexDirection: "column"
  },
  swipeViews: {
    height: "100%"
  }
});

class Insurance extends Component {
  state = {
    tabIndex: 0,
    step: 0,
    selectedPolicy: null,
    selectedInsurance: null,
    parent: {},
    pupil: {},
    selectedPupil: null,
    pupilDocsType: 1, // 1 - свидетельство, 2 - паспорт
    detailsIsOpen: false
  };

  tabs = {
    click: (event, value) => {
      this.setState({ tabIndex: value });
    },
    swipe: index => {
      this.setState({ tabIndex: index });
    }
  };

  newInsuranceClick = () => {
    this.setState({ tabIndex: 1 });
  };

  downloadPdf = insuranceId => {
    this.props.insuranceActions.getCompletedPdf(insuranceId);
  };

  changeStep = (direction, newStep) => {
    if (direction === "forward") {
      this.setState({ step: this.state.step + 1 });
    } else if (direction === "backward") {
      this.setState({ step: this.state.step - 1 });
    } else if (newStep !== undefined) {
      this.setState({ step: newStep });
    }
  };

  changePolicy = event => {
    const policies = this.props.insurance.available.policies
    const selectedPolicy = event.target.value;
    const selectedInsurance = (!!policies[selectedPolicy] && policies[selectedPolicy].insurances.ids.length === 1 )
      ? policies[selectedPolicy].insurances.ids[0]
      : null
    this.setState({
      selectedPolicy: event.target.value,
      selectedInsurance: selectedInsurance
    });
  };

  changeInsurance = id => {
    this.setState({ selectedInsurance: id });
  };

  changeParentData = name => event => {
    let parent = this.state.parent;
    if(name === 'insurerDocSeriesNumber'){
      const match = event.target.value.toString().match(/^(\d{2} *\d{2})(?:[\/ ]*)([a-zA-Z0-9]{6}$)?/si);
      console.log(match)
      if (match) {
        parent = { ...parent, ['insurerDocSeries']: match[1] || undefined, ['insurerDocNumber']: match[2] || undefined };
      } else {
        parent = { ...parent, ['insurerDocSeries']: undefined, ['insurerDocNumber']: undefined };
      }
    } else {
      parent = {...this.state.parent, [name]: event.target.value};
    }
    console.log(parent)
    this.setState({
      parent: parent
    });
  };

  changePupilData = name => event => {
    const pupil = {
      ...this.state.pupil,
      [name]: event.target.value
    };
    this.setState({
      pupil: pupil
    });
  };

  changeParentDocDate = newDate => {
    const parent = { ...this.state.parent, insurerDocDate: newDate };
    this.setState({
      parent: parent
    });
  };

  changeParentBirthDate = newDate => {
    const parent = { ...this.state.parent, insurerBirthDay: newDate };
    this.setState({
      parent: parent
    });
  };

  changePupilDocDate = newDate => {
    const pupil = { ...this.state.pupil, accountDocDate: newDate };
    this.setState({
      pupil: pupil
    });
  };

  changePupilBirthday = newDate => {
    const pupil = { ...this.state.pupil, accountBirthDate: newDate };
    this.setState({
      pupil: pupil
    });
  };

  selectPupil = event => {
    const pupilId = event.target.value;
    if (pupilId !== this.state.selectedPupil) {
      this.props.insuranceActions.getPupilData(pupilId);
      this.setState({ pupil: {}, selectedPupil: pupilId });
    }
  };

  addInsuranceInvoice = () => {
    const { parentData, pupilData } = this.props.insurance;
    const { parent, pupil, selectedPupil, pupilDocsType, selectedInsurance } = this.state;

    const getParentData = name => {
      if ( ["insurerDocDate", "insurerBirthDay"].indexOf(name) >= 0 ) {
        return parent && parent[name]
          ? moment(parent[name]).format("YYYY-MM-DD")
          : parentData[name];
      } else {
        return parent && parent[name] ? parent[name] + "" : parentData[name];
      }
    };

    const getPupilData = name => {
      if (name === "accountDocDate" || name === "accountBirthDate") {
        return pupil && pupil[name] ? moment(pupil[name]).format("YYYY-MM-DD") : pupilData[name];
      } else {
        return pupil && pupil[name] ? pupil[name] + "" : pupilData[name];
      }
    };

    this.props.insuranceActions.addInsuranceInvoice({
      insurerFam: getParentData("insurerFam"),
      insurerIm: getParentData("insurerIm"),
      insurerOtch: getParentData("insurerOtch"),
      insurerGender: getParentData("insurerGender"),
      insurerBirthDay: getParentData("insurerBirthDay"),
      insurerDocTypeId: 1,
      insurerDocSeries: getParentData("insurerDocSeries"),
      insurerDocNumber: getParentData("insurerDocNumber"),
      insurerDocIssuer: getParentData("insurerDocIssuer"),
      insurerDocDate: getParentData("insurerDocDate"),
      insurerPostIndex: getParentData("insurerPostIndex"),
      insurerCityName: getParentData("insurerCityName"),
      insurerLocalityName: getParentData("insurerLocalityName"),
      insurerStreetName: getParentData("insurerStreetName"),
      insurerHouseNum: getParentData("insurerHouseNum"),
      insurerBuildNum: getParentData("insurerBuildNum"),
      insurerApartmentNum: getParentData("insurerApartmentNum"),
      insurerPhone: getParentData("insurerPhone"),
      insurerEmail: getParentData("insurerEmail"),
      accountDocSeries: getPupilData("accountDocSeries"),
      accountDocNumber: getPupilData("accountDocNumber"),
      accountDocIssuer: getPupilData("accountDocIssuer"),
      accountDocDate: getPupilData("accountDocDate"),
      accountBirthDate: getPupilData("accountBirthDate"),
      accountGender: getPupilData("accountGender"),
      accountId: selectedPupil,
      accountDocType: pupilDocsType,
      insuranceId: selectedInsurance
    });
  };

  deleteInsuranceInvoice = insuranceId => {
    const schools = this.props.user.schools;
    const selectedSchool = this.props.user.selectedSchool;
    const parentId = schools[selectedSchool].parent.id;
    this.props.insuranceActions.deleteInsuranceInvoice(insuranceId, parentId);
  };

  changePupilDocsType = (event, docTypeId) => {
    if (docTypeId) {
      this.setState({ pupilDocsType: docTypeId });
    } else {
      this.setState({ pupilDocsType: event.target.value });
    }
  };

  detailsPopupOpen = insuranceId => {
    this.props.insuranceActions.getInsurance(insuranceId);
    this.setState({
      detailsIsOpen: true
    });
  };

  detailsPopupClose = () => {
    this.setState({
      detailsIsOpen: false
    });
  };

  componentDidMount = () => {
    this.props.insuranceActions.getAvailableInsurances();
    const available = this.props.insurance.available;
    if (!!available
      && (this.state.selectedPolicy === null || !available.policies[this.state.selectedPolicy])) {
      const policies = available.policies
      const selectedPolicy = policies.ids[0];
      const selectedInsurance = (!!policies[selectedPolicy] && policies[selectedPolicy].insurances.ids.length === 1 )
        ? policies[selectedPolicy].insurances.ids[0]
        : null

      this.setState({
        selectedPolicy: selectedPolicy,
        selectedInsurance: selectedInsurance
      })
    }
  };

  componentDidUpdate = (prevProps, prevState) => {
    const available = this.props.insurance.available
    if (!!available
    && (this.state.selectedPolicy === null || !available.policies[this.state.selectedPolicy])) {
      const policies = available.policies
      const selectedPolicy = policies.ids[0];
      const selectedInsurance = (!!policies[selectedPolicy] && policies[selectedPolicy].insurances.ids.length === 1 )
        ? policies[selectedPolicy].insurances.ids[0]
        : null

      this.setState({
        selectedPolicy: selectedPolicy,
        selectedInsurance: selectedInsurance
      })
    }

    if (prevState.tabIndex !== 2 && this.state.tabIndex === 2) {
      const schools = this.props.user.schools;
      const selectedSchool = this.props.user.selectedSchool;
      const parentId = schools[selectedSchool].parent.id;
      this.props.insuranceActions.getCompletedInsurances(parentId);
    } else if (prevState.tabIndex !== 1 && this.state.tabIndex === 1) {
      const schools = this.props.user.schools;
      const selectedSchool = this.props.user.selectedSchool;
      const parentId = schools[selectedSchool].parent.id;

      const policies = this.props.insurance.available.policies
      const selectedPolicy = this.state.selectedPolicy;
      const selectedInsurance = (!!policies[selectedPolicy] && policies[selectedPolicy].insurances.ids.length === 1 )
        ? policies[selectedPolicy].insurances.ids[0]
        : null
      this.setState({
        step: 0,
        selectedInsurance: selectedInsurance,
        pupil: {},
        pupilDocsType: 1, // 1 - свидетельство, 2 - паспорт
        detailsIsOpen: false
      });
      this.props.insuranceActions.getParentData(parentId);
    }
  };

  render() {
    const { classes } = this.props;
    const {isLoading} = this.props.loading;

    const {
      tabIndex,
      step,
      selectedPolicy,
      selectedInsurance,
      parent,
      pupil,
      selectedPupil,
      pupilDocsType,
      detailsIsOpen
    } = this.state;
    const { schools, selectedSchool } = this.props.user;
    const pupils = schools[selectedSchool].pupils;
    const { available, completed, selected, parentData, pupilData } = this.props.insurance;

    if (!schools || !available) {
      return <LoadingMessage />;
    } else {
      return (
        <div className={classes.container}>
          <AppBarWithTabs
            tabs={["Описание", "Новый полис", "Оформленные полисы"]}
            tabIndex={tabIndex}
            tabClick={this.tabs.click}
          />
          <SwipeableViews
            className={classes.swipeViews}
            index={tabIndex}
            onChangeIndex={this.tabs.swipe}
            animateHeight
            threshold={10}
            springConfig={{
              easeFunction: "ease-in",
              delay: "0s",
              duration: "0.3s"
            }}
          >
            <Description newInsuranceClick={this.newInsuranceClick} />
            <NewInsurance
              {...{
                available,
                step,
                selectedPolicy,
                selectedInsurance,
                parentData,
                parent,
                pupilData,
                pupil,
                pupils,
                selectedPupil,
                pupilDocsType,
                isLoading
              }}
              changeStep={this.changeStep}
              changePolicy={this.changePolicy}
              changeInsurance={this.changeInsurance}
              changeParentData={this.changeParentData}
              changeParentDocDate={this.changeParentDocDate}
              changeParentBirthDate={this.changeParentBirthDate}
              changePupilDocDate={this.changePupilDocDate}
              changePupilData={this.changePupilData}
              selectPupil={this.selectPupil}
              changePupilDocsType={this.changePupilDocsType}
              changePupilBirthday={this.changePupilBirthday}
              addInsuranceInvoice={this.addInsuranceInvoice}
            />
            <Completed
              {...{ completed, selected, detailsIsOpen, pupils }}
              downloadPdf={this.downloadPdf}
              deleteInsuranceInvoice={this.deleteInsuranceInvoice}
              detailsPopupOpen={this.detailsPopupOpen}
              detailsPopupClose={this.detailsPopupClose}
            />
          </SwipeableViews>
        </div>
      );
    }
  }
}

export default connect(
  state => ({
    loading: state.loading,
    user: state.user,
    insurance: state.insurance
  }),
  dispatch => ({
    insuranceActions: bindActionCreators(insuranceActions, dispatch)
  })
)(withStyles(styles)(Insurance));
