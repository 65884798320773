import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';

const styles = theme => ({
  toggle: {
    position: 'fixed',
    zIndex: 1300,
    top: 1,
    left: 0,
    [theme.breakpoints.up('sm')]: {
      top: 4
    },
    [theme.breakpoints.up('md')]: {
      display: 'none'
    }
  },
  toggleIcon: {
    [theme.breakpoints.up('sm')]: {
      top: 7,
      fontSize: 30
    }
  }
});

const MenuToggle = props => {
  const { classes } = props;
  return (
    <IconButton
      color="inherit"
      aria-label="Menu"
      className={classes.toggle}
      onClick={props.handleDrawerToggle}
    >
      <MenuIcon className={classes.toggleIcon} />
    </IconButton>
  );
};

export default withStyles(styles)(MenuToggle);
