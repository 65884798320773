import React from "react";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Radio from "@material-ui/core/Radio";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";

import { withStyles } from "@material-ui/core/styles";

const styles = theme => ({
  list: {
    padding: 0
  },
  payType: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center"
  },
  radio: {
    paddingRight: 0,
    paddingLeft: 0
  },
  buttons: {
    position: "relative",
    display: "flex",
    justifyContent: "flex-start",
    marginTop: 15
  },
  nextButton: {
    flexGrow: 1,
    margin: "0 5px"
  }
});
const Step2 = props => {
  const {
    classes,
    profile,
    payTypeFrom,
    payTypeTo,
    changeStep,
    selectPayTypeTo,
    pupils,
    isFoodActive
  } = props;
  const balances = profile.balances;
  return (
    <>
      <List>
        {((payTypeFrom.id === 0 && (profile.parentDilerId === 7 || profile.dilerId === 26)) ||
          payTypeFrom.id !== 0) &&
          profile.balances.ids.map(balanceId => {
            if (payTypeFrom.id !== balanceId) {
              return (
                <React.Fragment key={balanceId}>
                  <ListItem
                    className={classes.list}
                    button
                    onClick={e =>
                      selectPayTypeTo({ id: balanceId, name: balances[balanceId].name })
                    }
                  >
                    <Radio
                      className={classes.radio}
                      checked={payTypeTo !== undefined && payTypeTo.id === balanceId}
                    />
                    <ListItemText>
                      <div className={classes.payType}>
                        <Typography variant="subtitle1">{balances[balanceId].name}</Typography>
                        <Typography variant="body2">
                          баланс: {balances[balanceId].balance} руб.
                        </Typography>
                      </div>
                    </ListItemText>
                  </ListItem>
                  <Divider />
                </React.Fragment>
              );
            } else return null;
          })}
        {isFoodActive &&
          ((payTypeFrom.id !== 0 && (profile.parentDilerId === 7 || profile.dilerId === 26)) ||
            payTypeFrom.id === 0) &&
          pupils.ids.map(pupilId => {
            if (!payTypeFrom.pupil || pupilId !== payTypeFrom.pupil.id) {
              return (
                <React.Fragment key={pupilId}>
                  <ListItem
                    className={classes.list}
                    button
                    onClick={e =>
                      selectPayTypeTo({
                        id: 0,
                        name: "Питание",
                        pupil: {
                          id: pupilId,
                          name: `${pupils[pupilId].im} ${pupils[pupilId].className}`
                        }
                      })
                    }
                  >
                    <Radio
                      className={classes.radio}
                      checked={
                        payTypeTo !== undefined &&
                        payTypeTo.id === 0 &&
                        payTypeTo.pupil.id === pupilId
                      }
                    />
                    <ListItemText>
                      <div className={classes.payType}>
                        <Typography variant="subtitle1">
                          Питание, {pupils[pupilId].im} {pupils[pupilId].className}
                        </Typography>
                        <Typography variant="body2">
                          баланс: {pupils[pupilId].foodBalance} руб.
                        </Typography>
                      </div>
                    </ListItemText>
                  </ListItem>
                  <Divider />
                </React.Fragment>
              );
            } else return null;
          })}
        {(profile.parent.foodModuleActive || profile.parent.foodBalance < 0)  &&
        ((payTypeFrom.id !== 0 && (profile.parentDilerId === 7 || profile.dilerId === 26)) ||
          payTypeFrom.id === 0) &&
        ((!payTypeFrom.pupil || profile.parent.id !== payTypeFrom.pupil.id) && (
          <React.Fragment key={profile.parent.id}>
            <ListItem
              className={classes.list}
              button
              onClick={e =>
                selectPayTypeTo({
                  id: 0,
                  name: "Питание",
                  pupil: {
                    id: profile.parent.id,
                    name: `${profile.parent.fam.slice(0,1)}. ${profile.parent.im}`
                  }
                })
              }
            >
              <Radio
                className={classes.radio}
                checked={
                  payTypeTo !== undefined &&
                  payTypeTo.id === 0 &&
                  payTypeTo.pupil.id === profile.parent.id
                }
              />
              <ListItemText>
                <div className={classes.payType}>
                  <Typography variant="subtitle1">
                    Питание, {profile.parent.fam.slice(0,1) +". " + profile.parent.im}
                  </Typography>
                  <Typography variant="body2">
                    баланс: {profile.parent.foodBalance} руб.
                  </Typography>
                </div>
              </ListItemText>
            </ListItem>
            <Divider />
          </React.Fragment>
        ))}
      </List>
      <div className={classes.buttons}>
        <Button variant="outlined" size="small" onClick={e => changeStep("backward")}>
          Назад
        </Button>
        <Button
          className={classes.nextButton}
          variant="outlined"
          color="secondary"
          size="large"
          onClick={e => changeStep("forward")}
        >
          Далее
        </Button>
      </div>
    </>
  );
};

export default withStyles(styles)(Step2);
