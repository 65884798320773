import { createSelector } from "reselect";

const getOperations = state => state.transportCard.operations;

export const getTransportCardServices = createSelector(
  [getOperations],
  operations => {
    if (!operations) return null;
    let services = {};
    services.ids = operations.purchase.services.map(service => {
      services[service.serviceId] = {
        actionRange: service.actionRange,
        cost: service.cost,
        description: service.description
      };
      return service.serviceId;
    });

    return services;
  }
);
