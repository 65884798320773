import React, {Component} from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";

import withStyles from "@material-ui/core/styles/withStyles";
import {
  Divider, ExpansionPanel,
  Button,
  Typography
} from "@material-ui/core";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Paper from "@material-ui/core/Paper";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";

import {Formik} from "formik";

import TrackDeviceOrderItems from "./TrackDeviceOrderItems";
import FiasForm from "../../../FiasForm";

import {
  clearShopOrderDetails,
  getShopTrackDevicesDeliveryPrice,
  setShopTrackDevicesAddOrder,
  clearShopTrackDevicesOrder,
} from 'actions/shopActions';
import {Redirect} from "react-router-dom";

const mapStateToProps = state => ({
  user: state.user,
  shop: state.shop,
});

const mapDispatchToProps = {
  clearShopOrderDetails,
  getShopTrackDevicesDeliveryPrice,
  setShopTrackDevicesAddOrder,
  clearShopTrackDevicesOrder,
};

const styles = theme => ({
  dialog: {
    zIndex: 0
  },
  dialogPaper: {overflow: 'visible'},
  closeButton: {
    position: 'absolute',
    right: theme.spacing.unit,
    top: theme.spacing.unit,
  },
  root: {
    paddingTop: theme.spacing.unit * 2,
    paddingBottom: theme.spacing.unit * 2,
    marginTop: theme.spacing.unit * 3,
    marginLeft: "auto",
    marginRight: "auto",
    width: "80%",
    [theme.breakpoints.down('sm')]: {
      width: "100%"
    }
  },
  expHeading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
    flexBasis: 85,
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '70%',
    flexShrink: 1,
  },
  deliveryPrice: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: 80,
    flexShrink: 0,
  },
  expDetails: {
    display: "block"
    // width: "100%"
  },
  button: {
    margin: theme.spacing.unit / 2,
    float: "right"
  }
})

class TrackDeviceOrder extends Component {
  _isMounted = false;

  state = {
    goToShop: undefined
  }

  componentDidMount() {
    this._isMounted = true;
    const selectedSchool = this.props.user.selectedSchool;
    const profile = this.props.user.schools[selectedSchool];
    const parentId = profile.parent.id

    this.props.getShopTrackDevicesDeliveryPrice(parentId);

    this.props.clearShopOrderDetails();

    // if (!!trackDevicesTypeId) this.props.getShopTrackDeviceModels(parentId, trackDevicesTypeId);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.orderId !== this.props.orderId) {
      this.props.clearShopOrderDetails();
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  handleSubmit = (values) => {

    const orderData = {
      fiasAddressObjectId: (!!values.structure) ? values.structure.value
        : (!!values.locality) ? values.locality.value
          : (!!values.city) ? values.city.value
            : (!!values.area) ? values.area.value
              : (!!values.region) ? values.region.value : undefined,
      fiasStreetName: (!!values.street && !!values.street.formalName) ? values.street.formalName : undefined,
      fiasStreetType: (!!values.street && !!values.street.streetType) ? values.street.streetType : undefined,
      fiasHouse: (!!values.house && !!values.house.housenum) ? values.house.housenum : undefined,
      fiasBuild: (!!values.house && !!values.house.buildnum) ? values.house.buildnum : undefined,
      fiasStruc: (!!values.house && !!values.house.strucnum) ? values.house.strucnum : undefined,
      fiasAppartment: (!!values.apartment) ? values.apartment : undefined,
      fiasPostIndex: (values.house && values.house.postalCode) ? values.house.postalCode
        : (values.street && values.street.postalCode) ? values.street.postalCode
          : undefined,
      items: (!!values.items && !!values.items.length) ? values.items.map(item => ({
          setId: item.id,
          priceId: item.priceId
        }))
        : undefined
    }
    this.props.setShopTrackDevicesAddOrder(values.parentId, orderData);
    this.handleClose();
  }

  handleClose = (state) => {
    this.props.clearShopTrackDevicesOrder();
    if (this._isMounted) {
      this.setState({goToShop: state})
    }
  }

  isValidated = (values) => {
    return (
      !!values.house
      && (!!values.items && !!values.items.length)
    )
  }

  render() {
    const {classes,} = this.props;
    const {
      trackDevicesOrderItems: orderItems,
      trackDevicesDeliveryPriceCent,
      shopOrderDetails,
      errors
    } = this.props.shop;

    const selectedSchool = this.props.user.selectedSchool;
    const profile = this.props.user.schools[selectedSchool];
    const parentId = profile.parent.id
    const regionId = profile.regionId;
    const parentDilerId = profile.parentDilerId;

    let orderPriceCent = trackDevicesDeliveryPriceCent || 0;
    orderItems.map(item => {
      orderPriceCent += item['priceCent'] || 0;
    })

    const initOrder = {
      parentId: parentId,
      items: orderItems || [],
      region: null,
      area: null,
      city: null,
      locality: null,
      structure: null,
      street: null,
      house: null,
      apartment: "",
    }

    if (!orderItems || !orderItems.length || !!shopOrderDetails || this.state.goToShop) {
      return (
        <Redirect to="/shop"/>
      )
    }
    return (
      <Paper className={classes.root} elevation={1}><Formik
        onSubmit={(values, { resetForm}) => {
          this.handleSubmit(values);
          if (this._isMounted) {
            resetForm(initOrder);
          }
        }}

        initialValues={{...initOrder}}>
        {val => {
          const {values, handleChange, setFieldValue, handleSubmit} = val;

          const postalCode = (values.house && values.house.postalCode) ? values.house.postalCode
            : (values.street && values.street.postalCode) ? values.street.postalCode
              : '';
          const deliveryAddress = (values.house) ? `${postalCode}, ` +
            ((values.apartment) ? `${values.apartment} кв,` : '') +
            `${values.house.label}, ` +
            ((values.street) ? `${values.street.label}, ` : '') +
            ((values.stucture) ? `${values.stucture.label}, ` : '') +
            ((values.locality) ? `${values.locality.label}, ` : '') +
            ((values.city) ? `${values.city.label}, ` : '') +
            ((values.area) ? `${values.area.label}, ` : '') +
            `${values.region.label}`
            : null;
          return (
            <form onSubmit={handleSubmit}>
              <TrackDeviceOrderItems
                {...{values, handleChange, setFieldValue, handleSubmit}}
              />
              <br/>
              <Divider variant="middle"/>
              <ExpansionPanel>
                <ExpansionPanelSummary expandIcon={<ExpandMoreIcon/>} style={{display: "flex"}}>
                  <Typography className={classes.expHeading}>Доставка</Typography>
                  <Typography className={classes.secondaryHeading}
                              color={(!deliveryAddress || !postalCode) ? "error" : "textSecondary"}
                  >
                    {(!!deliveryAddress) ? deliveryAddress : 'не заполнен'}
                  </Typography>
                  <Typography className={classes.deliveryPrice}>
                    {(!!deliveryAddress && !!trackDevicesDeliveryPriceCent) ? `${(trackDevicesDeliveryPriceCent / 100).toFixed(2)} р.` : ''}
                  </Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails className={classes.expDetails}>
                  <FiasForm
                    {...{values, handleChange, setFieldValue, handleSubmit, errors, regionId, parentDilerId}}
                  />
                </ExpansionPanelDetails>
              </ExpansionPanel>
              <br/>
              <Divider variant="middle"/>
              <div>
                <Typography>Сумма по заказу: {((orderPriceCent) / 100).toFixed(2) || '-'} р.</Typography>
                <Typography variant="caption">Заказ будет принят к исполнению, только после оплаты. Срок исполнения
                  заказа от 3 недель со дня оплаты</Typography>
                <div style={{display: "inline-flex", width: '100%', flex: 1, textAlign: "right"}}>
                  <Button
                    className={classes.button}
                    variant="contained"
                    color="primary"
                    type="submit"
                    disabled={!this.isValidated(values)}
                  >Заказать</Button>
                  <Button
                    className={classes.button}
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      this.handleClose(1)
                    }}
                  >Отменить</Button>
                </div>
              </div>
            </form>
          )
        }}
      </Formik></Paper>
    )
  }
}

TrackDeviceOrder.propTypes = {
  orderId: PropTypes.number,
  clearShopTrackDevicesOrder: PropTypes.func.isRequired,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(TrackDeviceOrder));
