import React, {Component} from 'react';
import {connect} from 'react-redux';
import PropTypes from "prop-types";
import moment from "moment";

import {
  Button,
  Table, TableBody, TableCell, TableHead, TableRow, Tooltip,
  Typography,
  withStyles
} from "@material-ui/core";

import PaymentIcon from '@material-ui/icons/Payment';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';

import LoadingMessage from "components/messages/LoadingMessage";

import {
  getShopOrders,
  clearShopOrderDetails,
  getShopPayUrl,
  setShopAltCardOrderDetails,
  setShopAltCardDelOrder,
  getShopTrackDevicesOrderDetails,
  setShopTrackDevicesOrderDel,
} from "actions/shopActions";
import OrderDetailsDlg from "../OrderDetailsDlg";

const styles = theme => ({
  root: {
    margin: theme.spacing.unit,
    width: "auto"
  },
  headerText: {},
  buttonProgress: {
    color: "white",
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12
  },
  clickCell: {
    cursor: "pointer"
  },
  newOrder: {
    color: "orange"
  },
  successOrder: {
    color: "green"
  },
  canceledOrder: {
    color: "red"
  },
});

const initState = {}

class Orders extends Component {
  constructor(props) {
    super(props)

    this.state = initState;
  }

  componentDidMount() {
    const selectedSchool = this.props.user.selectedSchool;
    const profile = this.props.user.schools[selectedSchool];
    const parentId = profile.parent.id;

    this.setState(initState);

    if (this.props.active) {
      this.props.getShopOrders(parentId);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    // после переключения школы сбрасываем на начальное состояние
    if (
      (this.props.active && prevProps.active !== this.props.active)) {
      const selectedSchool = this.props.user.selectedSchool;
      const profile = this.props.user.schools[selectedSchool];
      const parentId = profile.parent.id;

      this.setState(initState);

      this.props.getShopOrders(parentId);
    }
  }

  handleOrderDetails = order => {
    if (order['categoryId'] === 1) {
      this.props.setShopAltCardOrderDetails(order['pupilId'], order['id']);
    } else if (order['categoryId'] === 2) {
      this.props.getShopTrackDevicesOrderDetails(order['accountId'], order['id']);
    }
  }

  handlePayment = order => {
    this.props.getShopPayUrl(order['requestPayPath'], order['accountId'], order['id']);
  }

  handleOrderDetailsDlgToggle = () => {
    const {
      shopOrderDetails,
    } = this.props.shop;
    if (shopOrderDetails)
      this.props.clearShopOrderDetails();
  };


  handleDelete = order => {
    const selectedSchool = this.props.user.selectedSchool;
    const profile = this.props.user.schools[selectedSchool];
    const parentId = profile.parent.id;

    if (order['categoryId'] === 1) {
      this.props.setShopAltCardDelOrder(parentId, order['pupilId'] || order['parentId'], order['id']);
    } else if (order['categoryId'] === 2) {
      this.props.setShopTrackDevicesOrderDel(order['accountId'], order['id']);
    }
  }

  render() {
    const {classes} = this.props;
    const {orders, shopOrderDetails} = this.props.shop;
    const {isLoading} = this.props.loading;

    if (isLoading || !orders) {
      return (<LoadingMessage/>)
    } else if (orders.length === 0) {
      return (
        <div className={classes.root}>
          <Typography variant="h6">
            Вы еще не сделали ни одного заказа у нас (:
          </Typography>
        </div>
      )
    } else
      return (
        <div className={classes.root}>
          <OrderDetailsDlg
            shopOrderDetails={shopOrderDetails}
            onPayment={this.handlePayment}
            onToggle={this.handleOrderDetailsDlgToggle}
          />
          <Typography variant="body1">
            Ваши заказы:
          </Typography>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell component="th" scope="row" width={10}>№</TableCell>
                <TableCell component="th" scope="row">Дата</TableCell>
                <TableCell component="th" scope="row">Статус</TableCell>
                <TableCell component="th" scope="row">ФИО</TableCell>
                <TableCell component="th" scope="row">Описание</TableCell>
                <TableCell component="th" scope="row">Сумма</TableCell>
                <TableCell component="th" scope="row">&nbsp;</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {orders.map(order => (
                <TableRow key={order.id}>
                  <TableCell component="th" scope="row">{order.id}</TableCell>
                  <TableCell component="th" scope="row">{moment(order.orderDate).format('lll')}</TableCell>
                  <TableCell component="th" scope="row"
                             className={(order.orderStatusId < 1) ? classes.newOrder
                               : (((order.categoryId === 1 && [5, 6].indexOf(order.orderStatusId) >= 0)) ||
                                 (order.categoryId === 2 && [4, 5].indexOf(order.orderStatusId) >= 0)) ? classes.canceledOrder
                                 : (((order.categoryId === 1 && [4].indexOf(order.orderStatusId) >= 0)) ||
                                   (order.categoryId === 2 && [3].indexOf(order.orderStatusId) >= 0)) ? classes.successColor
                                   : null}
                  >{order.orderStatus}</TableCell>
                  <TableCell component="th" scope="row">{order.fio}</TableCell>
                  <TableCell component="th" scope="row" className={classes.clickCell} onClick={() => {
                    this.handleOrderDetails(order);
                  }}>{order.fullSetName}</TableCell>
                  <TableCell component="th" scope="row">{(order.priceCent / 100).toFixed(2)}</TableCell>
                  <TableCell component="th" scope="row">{(order.orderStatusId < 1)
                    ? (<Tooltip title="Оплатить"><Button size="small" color="primary" onClick={() => {
                      this.handlePayment(order);
                    }}><PaymentIcon/></Button></Tooltip>)
                    : null}
                    {(order.orderStatusId < 2) ? (
                      <Tooltip title="Удалить"><Button size="small" color="primary" onClick={() => {
                        this.handleDelete(order);
                      }}><DeleteForeverIcon/></Button></Tooltip>) : null}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>
      )
  }

}

Orders.propTypes = {
  active: PropTypes.bool.isRequired,
}

const mapStateToProps = state => ({
  user: state.user,
  loading: state.loading,
  shop: state.shop,
});

const mapDispatchToProps = {
  getShopOrders,
  getShopPayUrl,
  clearShopOrderDetails,
  setShopAltCardOrderDetails,
  setShopAltCardDelOrder,
  getShopTrackDevicesOrderDetails,
  setShopTrackDevicesOrderDel,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withStyles(styles)(Orders));