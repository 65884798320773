import React from "react";
import PropTypes from "prop-types";
import SwipeableViews from "react-swipeable-views";
import Hidden from "@material-ui/core/Hidden";
import withStyles from "@material-ui/core/styles/withStyles";
import AppBarWithTabs from "../../AppBarWithTabs.jsx";
import MealsCalendar from "./MealsCalendar.jsx";
import MealsDetails from "./MealsDetails.jsx";
import DayLimit from "./DayLimit.jsx";
import MealOrders from "./MealOrders";

const styles = theme => ({
  container: {
    height: "100%",
    display: "flex",
    flexDirection: "row",
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
    },
  },
  swipeViews: {
    height: "100%",
  },
});

const MealsView = props => {
  const {
    classes,
    monthMeals,
    dayMeals,
    mealTypes,
    tabIndex,
    tabClick,
    tabSwipe,
    setMonth,
    setDate,
    selectedMonth,
    selectedDate,
    selectedMeal,
    isLoading,
    dayLimit,
    isParentPreOrder,
    isMealsDetailPossible,
    changeDayLimitValue,
    submitDayLimit,
    selectedPupil,
    addFoodDataOrder,
    infoWindowIsOpen,
    infoText
  } = props;

  const mealOrders = <MealOrders
    {...{mealTypes,
      selectedMeal,
      infoWindowIsOpen,
      infoText}}

    tabMealTypesIndex={props.tabMealTypesIndex}
    tabMealTypesClick={props.tabMealTypesClick}
    tabMealTypesSwipe={props.tabMealTypesSwipe}
    handleSelectedMealChange={props.handleSelectedMealChange}
    infoWindowOpen={props.infoWindowOpen}
    infoWindowClose={props.infoWindowClose}
    submitBindMealAccount={props.submitBindMealAccount}
    submitUnbindMealAccount={props.submitUnbindMealAccount}
  />

  return (
    <>
      <Hidden smUp>
        <div className={classes.container}>
          <AppBarWithTabs
            tabs={["Календарь", "Детализация", "Суточный лимит", "Комплексное питание"]}
            {...{ tabIndex, tabClick }}
          />
          <SwipeableViews
            className={classes.swipeViews}
            index={tabIndex}
            onChangeIndex={tabSwipe}
            animateHeight
            threshold={10}
            springConfig={{
              easeFunction: "ease-in",
              delay: "0s",
              duration: "0.3s",
            }}
          >
            <MealsCalendar
              {...{
                monthMeals,
                setMonth,
                setDate,
                selectedMonth,
                selectedDate,
              }}
            />

            <MealsDetails {...{ dayMeals, selectedDate, isMealsDetailPossible, selectedPupil, addFoodDataOrder }} />
            <DayLimit {...{ dayLimit, changeDayLimitValue, submitDayLimit }} />
            {isParentPreOrder && mealOrders}
          </SwipeableViews>
        </div>
      </Hidden>
      <Hidden xsDown>
        <DayLimit {...{ dayLimit, changeDayLimitValue, submitDayLimit }} />
        {isParentPreOrder && mealOrders}
        <div className={classes.container}>
          <MealsCalendar
            {...{
              monthMeals,
              setMonth,
              setDate,
              selectedMonth,
              selectedDate,
            }}
          />
          <MealsDetails {...{ dayMeals, selectedDate, isLoading, isMealsDetailPossible, selectedPupil, addFoodDataOrder  }} />
        </div>
      </Hidden>
    </>
  );
};

MealsView.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string),
  dayMeals: PropTypes.arrayOf(
    PropTypes.shape({
      createTime: PropTypes.string,
      onDate: PropTypes.string,
      operationName: PropTypes.string,
      price: PropTypes.string,
    }),
  ),
  monthMeals: PropTypes.arrayOf(
    PropTypes.shape({
      buys: null,
      calendarDate: PropTypes.string,
      inetShow: PropTypes.number,
      pays: PropTypes.string,
      transfers: PropTypes.string,
    }),
  ),
  mealTypes: PropTypes.object,
  tabIndex: PropTypes.number.isRequired,
  tabClick: PropTypes.func.isRequired,
  tabSwipe: PropTypes.func.isRequired,
  tabMealTypesIndex: PropTypes.number.isRequired,
  tabMealTypesClick: PropTypes.func.isRequired,
  tabMealTypesSwipe: PropTypes.func.isRequired,
  handleSelectedMealChange: PropTypes.func.isRequired,
  setMonth: PropTypes.func.isRequired,
  setDate: PropTypes.func.isRequired,
  selectedMonth: PropTypes.object.isRequired,
  selectedDate: PropTypes.object.isRequired,
  selectedMeal:PropTypes.object.isRequired,
  isLoading: PropTypes.bool,
  dayLimit: PropTypes.string,
  changeDayLimitValue: PropTypes.func.isRequired,
  submitDayLimit: PropTypes.func.isRequired,
  selectedPupil: PropTypes.number,
  addFoodDataOrder: PropTypes.func.isRequired,
  infoWindowIsOpen: PropTypes.bool.isRequired,
  infoText: PropTypes.string,
  infoWindowOpen: PropTypes.func.isRequired,
  infoWindowClose: PropTypes.func.isRequired,
  submitBindMealAccount: PropTypes.func.isRequired,
  submitUnbindMealAccount: PropTypes.func.isRequired,
};

export default withStyles(styles)(MealsView);
