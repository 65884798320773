import axios from "axios";
import { API, handleApiError } from "api";
import { createAction } from "redux-actions";

export const fetchArchiveRequest = createAction("FETCH_ARCHIVE_REQUEST");
export const fetchArchiveSuccess = createAction("FETCH_ARCHIVE_SUCCESS");
export const fetchArchiveError = createAction("FETCH_ARCHIVE_ERROR");

export const getArchiveMessages = (
  parentId,
  dateStart,
  dateEnd,
  pupils,
  eventType,
  indexStart,
  indexStop
) => async dispatch => {
  try {
    dispatch(fetchArchiveRequest());
    const res = await axios.get(
      `${API}/archive/event-messages/${parentId}?dateStart=${dateStart}&dateEnd=${dateEnd}
      ${pupils ? `&pupils=${pupils}` : ""}
      ${eventType ? `&eventType=${eventType}` : ""}
      ${indexStart ? `&indexStart==${indexStart}` : ""}
      ${indexStop ? `&indexStop=${indexStop}` : ""}`
    );
    dispatch(fetchArchiveSuccess(res.data));
  } catch (error) {
    dispatch(handleApiError(error, fetchArchiveError()));
  }
};
