import axios from "axios";
import { API, handleApiError } from "api";
import { createAction } from "redux-actions";

export const fetchTransportCardOperationsRequest = createAction(
  "FETCH_TRANSPORT_CARD_OPERATIONS_REQUEST"
);
export const fetchTransportCardOperationsSuccess = createAction(
  "FETCH_TRANSPORT_CARD_OPERATIONS_SUCCESS"
);
export const fetchTransportCardOperationsError = createAction(
  "FETCH_TRANSPORT_CARD_OPERATIONS_ERROR"
);

export const fetchTransportCardReplenishmentUrlRequest = createAction(
  "FETCH_TRANSPORT_CARD_REPLENISHMENT_URL_REQUEST"
);
export const fetchTransportCardReplenishmentUrlSuccess = createAction(
  "FETCH_TRANSPORT_CARD_REPLENISHMENT_URL_SUCCESS"
);
export const fetchTransportCardReplenishmentUrlError = createAction(
  "FETCH_TRANSPORT_CARD_REPLENISHMENT_URL_ERROR"
);

export const fetchTransportCardPurchaseUrlRequest = createAction(
  "FETCH_TRANSPORT_CARD_PURCHASE_URL_REQUEST"
);
export const fetchTransportCardPurchaseUrlSuccess = createAction(
  "FETCH_TRANSPORT_CARD_PURCHASE_URL_SUCCESS"
);
export const fetchTransportCardPurchaseUrlError = createAction(
  "FETCH_TRANSPORT_CARD_PURCHASE_URL_ERROR"
);

export const transportCardPayUrlClear = createAction("TRANSPORT_CARD_PAY_URL_CLEAR");

export const fetchTransportCardOperations = pupilId => async dispatch => {
  try {
    dispatch(fetchTransportCardOperationsRequest());
    const res = await axios.get(`${API}/tc/available-operations/${pupilId}`);
    dispatch(fetchTransportCardOperationsSuccess({ operations: res.data }));
  } catch (error) {
    dispatch(handleApiError(error, fetchTransportCardOperationsError()));
  }
};

export const fetchTransportCardReplenishmentUrl = (pupilId, data) => async dispatch => {
  try {
    dispatch(fetchTransportCardReplenishmentUrlRequest());
    const res = await axios.post(`${API}/tc/replenishment/pay-link/${pupilId}`, data);
    dispatch(fetchTransportCardReplenishmentUrlSuccess({ payUrl: res.data.message }));
  } catch (error) {
    dispatch(handleApiError(error, fetchTransportCardReplenishmentUrlError()));
  }
};

export const fetchTransportCardPurchaseUrl = (pupilId, data) => async dispatch => {
  try {
    dispatch(fetchTransportCardPurchaseUrlRequest());
    const res = await axios.post(`${API}/tc/purchase/pay-link/${pupilId}`, data);
    dispatch(fetchTransportCardPurchaseUrlSuccess({ payUrl: res.data.message }));
  } catch (error) {
    dispatch(handleApiError(error, fetchTransportCardPurchaseUrlError()));
  }
};
