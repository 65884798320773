import React from "react";
import { Link } from "react-router-dom";
import Drawer from "@material-ui/core/Drawer";
import { withStyles } from "@material-ui/core/styles";
import Hidden from "@material-ui/core/Hidden";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Typography from "@material-ui/core/Typography";
import Badge from "@material-ui/core/Badge";

import ListItemIcon from "@material-ui/core/ListItemIcon";

import MobileMenu from "./MobileMenu";

const styles = theme => ({
  drawerPaper: {
    width: 250,
    height: "100%",
    background: "#FFF",
    [theme.breakpoints.up("sm")]: {
      position: "relative",
      height: "100%",
      width: 250
    }
  },
  menuList: {
    paddingTop: 0
  },
  menuItem: {
    height: 49,
    padding: "10px",
    color: "#CCC",
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
      color: "white"
    },
    "&:focus": {
      backgroundColor: theme.palette.primary.main,
      color: "white"
    }
  },
  menuItemSelected: {
    backgroundColor: theme.palette.primary.main + " !important",
    color: "white",
    fontWeight: 600
  },
  icon: {
    color: "inherit"
  },
  text: {
    paddingLeft: 0
  },
  needToPayBange: {
    paddingRight: 10
  }
});

const MainMenu = props => {
  const { classes, needToPay, schoolMenu, pupilsIds, parentDilerId } = props;
  let path = props.locationPath;

  const menuItems = () => {
    if (path === "/") path = "/profile";
    return props.menuList.map((item, index) => {
      if (
        // если нет детей то маршруты не строим
         ["attendance","meals", "tariffs"].indexOf(item.id) >= 0 && (
          pupilsIds.length < 1
        )
      ) {
        return null
      }
      if (
        // если страхование не найдено в меню сервера - маршрут не строим
        item.id === "insurance" && (
        schoolMenu.filter(item => item.id === 9).length === 0
        || pupilsIds.length < 1)
      ) {
        return null;
      }
      if (
        // если у дилера нет gps маршрут не строим
        item.id === "gps" &&
        schoolMenu.filter(item => item.id === 8).length === 0
      ) {
        return null;
      }
      if (
        item.availableDilers &&
        item.availableDilers.indexOf(parentDilerId) < 0
      )
      {
        return null
      }

      return (
        <ListItem
          button
          divider
          key={index}
          classes={{ root: classes.menuItem, selected: classes.menuItemSelected }}
          component={Link}
          onClick={props.handleDrawerToggle}
          to={{
            pathname: `/${item.id}`,
            state: { title: item.name, showPupils: item.showPupils }
          }}
          selected={!!(path && path.substr(1) === item.id)} // Сравниваем роут с id пункта меню
        >
          <ListItemIcon className={classes.icon}>{item.icon}</ListItemIcon>
          <ListItemText className={classes.text}>
            {needToPay !== null && item.id === "pays" && +needToPay < 0 ? (
              <Badge color="error" badgeContent={"!"} className={classes.needToPayBange}>
                <Typography variant="subtitle2">{item.name}</Typography>
              </Badge>
            ) : (
              <Typography variant="subtitle2">{item.name}</Typography>
            )}
          </ListItemText>
        </ListItem>
      );
    });
  };

  return (
    <>
      <MobileMenu
        open={props.settings.open}
        toggle={props.handleDrawerToggle}
        menuItems={menuItems}
      />
      <Hidden smDown>
        <Drawer
          variant="permanent"
          open
          classes={{
            paper: classes.drawerPaper
          }}
        >
          <List className={classes.menuList}>{menuItems()}</List>
        </Drawer>
      </Hidden>
    </>
  );
};

export default withStyles(styles)(MainMenu);
