import React, {Component} from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";

import withStyles from "@material-ui/core/styles/withStyles";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography
} from "@material-ui/core";


const mapStateToProps = state => ({
  user: state.user,
  shop: state.shop,
});

const mapDispatchToProps = {};

const styles = theme => ({
  dialog: {},
  closeButton: {
    position: 'absolute',
    right: theme.spacing.unit,
    top: theme.spacing.unit,
  }
})

class OrderDetailsDlg extends Component {

  handlePayment = () => {
    if (typeof this.props.onPayment !== 'function') return;
    if (!!this.props.shopOrderDetails)
      this.props.onPayment(this.props.shopOrderDetails);
    this.handleClose();
  }

  handleClose = () => {
    if (typeof this.props.onToggle !== 'function') return;
    this.props.onToggle();
  }
  render() {
    const {classes, shopOrderDetails} = this.props;
    return (
      <Dialog
        id="request-order-pay-dlg"
        className={classes.dialog}
        open={(!!shopOrderDetails && shopOrderDetails.hasOwnProperty('requestPayPath'))}
        fullWidth
        maxWidth="sm"
        onClose={this.handleClose}>
        {!!shopOrderDetails ? (
          <DialogContent
            id="request-order-pay-dlg-content">
            <Typography variant="h5">Сформирован заказ №{shopOrderDetails['id']}:</Typography>
            <Table size="small">
              <TableBody>
                <TableRow key="status">
                  <TableCell>Статус заказа</TableCell>
                  <TableCell>{shopOrderDetails['orderStatus']}</TableCell>
                </TableRow>
                {(shopOrderDetails.hasOwnProperty('items') && shopOrderDetails['items'].length > 0)
                  ? shopOrderDetails['items'].map(item => (
                    <TableRow key={item.id}>
                      <TableCell>{item.setName}</TableCell>
                      <TableCell>{(item.priceCent / 100).toFixed(2)} р.</TableCell>
                    </TableRow>
                  ))
                  : (
                    <TableRow key={shopOrderDetails['id'] + '-item'}>
                      <TableCell>{shopOrderDetails['fullSetName']}</TableCell>
                      <TableCell>{(shopOrderDetails['priceCent'] / 100).toFixed(2)} р.</TableCell>
                    </TableRow>
                  )}
                {(shopOrderDetails.hasOwnProperty('deliveryPrice')) ? (
                  <TableRow key={shopOrderDetails['id'] + '-delivery-price'}>
                    <TableCell>Доставка ({shopOrderDetails['deliveryAddress']})</TableCell>
                    <TableCell>{(shopOrderDetails['deliveryPrice'] / 100).toFixed(2)} р.</TableCell>
                  </TableRow>
                ) : null}
                <TableRow key={shopOrderDetails['id'] + '-amount'}>
                  <TableCell align="right" variant="footer"><Typography variant="body1">Сумма
                    заказа*</Typography></TableCell>
                  <TableCell variant="footer"><Typography
                    variant="body1">{(shopOrderDetails['priceCent'] / 100).toFixed(2)} р.</Typography></TableCell>
                </TableRow>
              </TableBody>
            </Table>
            <Typography variant="caption">* При оплате, платежным шлюзом, взимается комиссия </Typography>
          </DialogContent>
        ) : null}
        <DialogActions
          id="request-order-pay-dlg-actions"
        >
          {(!!shopOrderDetails && shopOrderDetails['orderStatusId'] < 1) && (<Button
            variant="contained"
            color="primary"
            onClick={this.handlePayment}>Оплатить</Button>)}
          <Button
            variant="contained"
            color="primary"
            onClick={this.handleClose}>Закрыть</Button>
        </DialogActions>
      </Dialog>
    )
  }
}


OrderDetailsDlg.propTypes = {
  shopOrderDetails: PropTypes.object,
  onToggle: PropTypes.func.isRequired,
  onPayment: PropTypes.func.isRequired,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(OrderDetailsDlg));
