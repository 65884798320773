import React from "react";
import {Link} from "react-router-dom";

import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import Typography from "@material-ui/core/Typography";

import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import FindInPage from "@material-ui/icons/FindInPage";

import moment from "moment";

import withStyles from "@material-ui/core/styles/withStyles";

const styles = theme => ({
  header: {
    fontSize: 18
  },
  button: {
    margin: "0 10px"
  }
});

const InsuranceCard = props => {
  const {classes, insurance, downloadPdf, deleteInsuranceInvoice, detailsPopupOpen} = props;
  return (
    <Card square elevation={1}>
      <CardContent>
        <Typography variant="h6">{insurance.accountFam} {insurance.accountIm} {insurance.accountOtch}</Typography>
        <Typography variant="subtitle2">{insurance.policyName}</Typography>
        <Typography variant="subtitle1">
          Срок действия:{" "}
          {insurance.payDate === null
            ? "Счет не оплачен"
            : insurance.insuranceExpiryDate === null
              ? "Ожидание полиса"
              : "c " + moment(insurance.insuranceDate, "YYYY-MM-DD").format("DD.MM.YYYY") + "г." +
              " по " + moment(insurance.insuranceExpiryDate, "YYYY-MM-DD").format("DD.MM.YYYY") + "г."}
        </Typography>
        <Typography variant="subtitle1">Сумма покрытия: {insurance.insuredSum} руб.</Typography>
        <Typography variant="subtitle1">Стоимость: {insurance.insuranceFee} руб.</Typography>
      </CardContent>
      <CardActions disableActionSpacing>
        {(insurance.insuranceExpiryDate !== null) ? (
          <Button
            className={classes.button}
            variant="contained"
            color="primary"
            onClick={e => downloadPdf(insurance.id)}
          >
            Скачать PDF
          </Button>
        ) : insurance.payDate === null ? (
          <Button
            component={Link}
            to={{
              pathname: "/pays",
              state: {payType: 3, paySum: parseFloat(insurance.insuranceFee)}
            }}
            className={classes.button}
            variant="contained"
            color="primary"
          >
            Оплатить
          </Button>
        ) : null}
        {insurance.payDate === null ? (
          <Tooltip title="Удалить счет" placement="top">
            <IconButton onClick={e => deleteInsuranceInvoice(insurance.id)}>
              <DeleteIcon fontSize="large" color="error"/>
            </IconButton>
          </Tooltip>
        ) : null}
        <Tooltip title="Подробно" placement="top">
          <IconButton onClick={e => detailsPopupOpen(insurance.id)}>
            <FindInPage fontSize="large"/>
          </IconButton>
        </Tooltip>
      </CardActions>
    </Card>
  );
};

export default withStyles(styles)(InsuranceCard);
