import axios from "axios";
import {createAction} from "redux-actions";
import {API, handleApiError} from "api";
import {showInfoMessage} from "./infoMessageActions";
import {getProfile} from "./userActions";

export const foodCommissionRequest = createAction("FOOD_COMMISSION_REQUEST");
export const foodCommissionSuccess = createAction("FOOD_COMMISSION_SUCCESS");
export const foodCommissionError = createAction("FOOD_COMMISSION_ERROR");

export const commissionsRequest = createAction("COMMISSIONS_REQUEST");
export const commissionsSuccess = createAction("COMMISSIONS_SUCCESS");
export const commissionsError = createAction("COMMISSIONS_ERROR");

export const promoAmountRequest = createAction("PROMO_AMOUNT_REQUEST");
export const promoAmountSuccess = createAction("PROMO_AMOUNT_SUCCESS");
export const promoAmountError = createAction("PROMO_AMOUNT_ERROR");
export const promoAmountClear = createAction("PROMO_AMOUNT_CLEAR");

export const payUrlRequest = createAction("PAY_URL_REQUEST");
export const payUrlSuccess = createAction("PAY_URL_SUCCESS");
export const payUrlError = createAction("PAY_URL_ERROR");
export const payUrlClear = createAction("PAY_URL_CLEAR");

export const invoicesRequest = createAction("INVOICES_REQUEST");
export const invoicesSuccess = createAction("INVOICES_SUCCESS");
export const invoicesError = createAction("INVOICES_ERROR");

export const invoicePeriodsRequest = createAction("INVOICE_PERIODS_REQUEST");
export const invoicePeriodsSuccess = createAction("INVOICE_PERIODS_SUCCESS");
export const invoicePeriodsError = createAction("INVOICE_PERIODS_ERROR");

export const invoiceDetailsRequest = createAction("INVOICE_DETAILS_REQUEST");
export const invoiceDetailsSuccess = createAction("INVOICE_DETAILS_SUCCESS");
export const invoiceDetailsError = createAction("INVOICE_DETAILS_ERROR");

export const setLastInvoice = createAction("SET_LAST_INVOICE");
export const setLastOperations = createAction("SET_LAST_OPERATIONS");

export const transferRequest = createAction("TRANSFER_REQUEST");
export const transferSuccess = createAction("TRANSFER_SUCCESS");
export const transferError = createAction("TRANSFER_ERROR");

export const paperInvoiceRequest = createAction("PAPER_INVOICE_REQUEST");
export const paperInvoiceSuccess = createAction("PAPER_INVOICE_SUCCESS");
export const paperInvoiceError = createAction("PAPER_INVOICE_ERROR");

export const getFoodCommission = parentId => async dispatch => {
  try {
    dispatch(foodCommissionRequest());
    const res = await axios.get(`${API}/pays/food-commission/${parentId}`);
    dispatch(
      foodCommissionSuccess({
        foodCommission: res.data.commission
      })
    );
  } catch (error) {
    dispatch(handleApiError(error, foodCommissionError()));
  }
};

const getCommissions = parentId => async dispatch => {
  try {
    dispatch(commissionsRequest());
    const res = await axios.get(`${API}/pays/commissions/${parentId}`);
    dispatch(
      commissionsSuccess({
        commissions: res.data
      })
    );
  } catch (error) {
    dispatch(handleApiError(error, commissionsError()));
  }
};

export const getPromoToPay = (parentId, promoCode) => async dispatch => {
  try {
    dispatch(promoAmountRequest());
    const res = await axios.get(`${API}/promo/pupils/${parentId}?promoCode=${promoCode}`);
    dispatch(
      promoAmountSuccess({
        promoAmount: res.data.promoAmountCent / 100,
        pupils: res.data.pupils
      })
    );
  } catch (error) {
    dispatch(handleApiError(error, promoAmountError()));
  }
};
export const clearPromoAmount = () => dispatch => dispatch(promoAmountClear());

export const getPayUrl = (parentId, typeId, amount, pupilId, promoCode, subscription) => async dispatch => {
  try {
    dispatch(payUrlRequest());
    const res = await axios.get(
      `${API}/pays/${parentId}?typeId=${typeId}&amount=${amount.toFixed(0)}&pupilId=${pupilId}&promoCode=${promoCode}&subscription=${subscription}`
    );
    dispatch(
      payUrlSuccess({
        payUrl: res.data.message
      })
    );
  } catch (error) {
    dispatch(handleApiError(error, payUrlError()));
  }
};

export const clearPayUrl = (schoolIndex) => async dispatch => {
  dispatch(payUrlClear())
  if (schoolIndex !== null && schoolIndex >= 0) {
    await dispatch(getProfile(schoolIndex));
  }
};

export const getInvoices = (parentId, year) => async dispatch => {
  try {
    dispatch(invoicesRequest());
    const res = await axios.get(`${API}/invoices/${parentId}?year=${year}`);
    dispatch(
      invoicesSuccess({
        invoices: res.data
      })
    );
    return await Promise.resolve(res.data);
  } catch (error) {
    return dispatch(handleApiError(error, invoicesError()));
  }
};

const getInvoicePeriods = parentId => async dispatch => {
  try {
    dispatch(invoicePeriodsRequest());
    const res = await axios.get(`${API}/invoices/available-periods/${parentId}`);
    dispatch(
      invoicePeriodsSuccess({
        invoicePeriods: res.data
      })
    );
    return await Promise.resolve(res.data);
  } catch (error) {
    return dispatch(handleApiError(error, invoicePeriodsError()));
  }
};

export const getInvoicesAndCommission = parentId => async dispatch => {
  try {
    let lastInvoice;
    let lastOperations;
    dispatch(getCommissions(parentId));
    const periods = await dispatch(getInvoicePeriods(parentId));
    if (periods.length > 0) {
      const invoices = await dispatch(getInvoices(parentId, periods[0]));
      if (invoices.length > 0) {
        if (invoices.length === 1) {
          [lastInvoice] = invoices.filter(item => item.schetId !== null);
          [lastOperations] = invoices.filter(item => item.schetId === null);
        } else if (invoices[invoices.length - 1].schetId === null) {
          lastOperations = invoices[invoices.length - 1];
          lastInvoice = invoices[invoices.length - 2];
        } else {
          lastInvoice = invoices[invoices.length - 1];
        }
        dispatch(
          setLastInvoice({
            lastInvoice
          })
        );
        dispatch(
          setLastOperations({
            lastOperations
          })
        );
      }
    }
  } catch (err) {
    console.error("Unhandled error");
  }
};

export const getInvoiceDetails = (parentId, month) => async dispatch => {
  try {
    dispatch(invoiceDetailsRequest());
    const res = await axios.get(`${API}/invoices/details/${parentId}?date=${month}`);
    dispatch(
      invoiceDetailsSuccess({
        details: res.data
      })
    );
  } catch (error) {
    dispatch(handleApiError(error, invoiceDetailsError()));
  }
};

export const transferBalance = (
  accountFrom,
  serviceFrom,
  accountTo,
  serviceTo,
  amount,
  schoolIndex
) => async dispatch => {
  try {
    dispatch(transferRequest());
    await axios.post(`${API}/user/transfer-balance`, {
      accountFrom,
      serviceFrom,
      accountTo,
      serviceTo,
      sum: amount
    });
    await dispatch(getProfile(schoolIndex));
    dispatch(transferSuccess());
    dispatch(showInfoMessage("Перевод успешно выполнен!"));
  } catch (error) {
    dispatch(handleApiError(error, transferError()));
  }
};

export const getCardPaperInvoice = (pupilId, summInPenny) => async dispatch => {
  try {
    dispatch(paperInvoiceRequest());
    const res = await axios.get(
      `${API}/invoices/card-replace-invoice/${pupilId}?summcop=${summInPenny}&href`
    );

    window.open(res.data.message, "_parent");

    dispatch(
      paperInvoiceSuccess({
        paperInvoice: res.data
      })
    );
  } catch (error) {
    return dispatch(handleApiError(error, paperInvoiceError()));
  }
};

export const getServicePaperInvoice = (parentId, serviceId, summInPenny) => async dispatch => {
  try {
    dispatch(paperInvoiceRequest());
    const res = await axios.get(
      `${API}/invoices/service-invoice/${parentId}?serviceId=${serviceId}&summcop=${summInPenny}&href`
    );

    window.open(res.data.message, "_parent");

    dispatch(
      paperInvoiceSuccess({
        paperInvoice: res.data
      })
    );
  } catch (error) {
    return dispatch(handleApiError(error, paperInvoiceError()));
  }
};
