import React, {Component} from "react";

import withStyles from "@material-ui/core/styles/withStyles";
import ComboBox from "../../../ComboBox";
import TextField from "@material-ui/core/TextField";
import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";

const styles = theme => ({
  price: {
    minHeight: 60,
  },
  cardTypeName: {
    margin: theme.spacing.unit,
    minWidth: 120,
  },
  button: {
    margin: theme.spacing.unit / 2,
    float: "right"
  }
});


class TrackDeviceData extends Component {

  componentDidUpdate(prevProps, prevState, snapshot) {
    //перезагружаем модели если изменили тип стройства
    if (prevProps.values.parentId !== this.props.values.parentId ||
      prevProps.values.trackDevicesTypeId !== this.props.values.trackDevicesTypeId) {
      const {values} = this.props;
      if (typeof values.parentId === 'number' && Number(values.parentId) >= 0 &&
        typeof values.trackDevicesTypeId === 'number' && Number(values.trackDevicesTypeId) >= 0) {
        // this.props.getShopTrackDeviceModels(values.parentId, values.trackDevicesTypeId);
      }
      this.props.setFieldValue('trackDevicesModelId', null);
    }

    // if(
    //   prevProps.values.pupilId !== this.props.values.pupilId ||
    //   prevProps.values.altCardKindId !== this.props.values.altCardKindId ||
    //   prevProps.values.altCardColorId !== this.props.values.altCardColorId) {
    //   const {values} = this.props;
    //   if(typeof values.pupilId === 'number' && Number(values.pupilId) >= 0 &&
    //     typeof values.altCardTypeId === 'number' && Number(values.altCardTypeId) >= 0 &&
    //     typeof values.altCardKindId === 'number' && Number(values.altCardKindId) >= 0 &&
    //     typeof values.altCardColorId === 'number' && Number(values.altCardColorId) >= 0) {
    //     this.props.getShopAltCardSetPrice(values.pupilId, values.altCardTypeId, values.altCardKindId, values.altCardColorId);
    //   }

    // }
  }

  render() {
    const {
      classes,
      values, setFieldValue,
      trackDevicesType, trackDevicesModels, trackDevicesModelsById,
      trackDevicesOrderItemAdd, trackDevicesOrderConfirm
    } = this.props;

    let trackDevicesPriceCent = 0;
    if (trackDevicesModelsById.hasOwnProperty(values.trackDevicesModelId))
      trackDevicesPriceCent = trackDevicesModelsById[values.trackDevicesModelId].priceCent;


    const isTrackDeviceOrderIncomplete = values => {
      return !(typeof values.trackDevicesModelId === 'number' && Number(values.trackDevicesModelId) >= 0)
    }

    return (
      <>
        <TextField
          className={classes.cardTypeName}
          id="trackDevicesTypeId"
          label="Устройство *"
          defaultValue={trackDevicesType.name}
          InputProps={{
            readOnly: true,
          }}
          variant="outlined"
          fullWidth
        />
        <ComboBox
          id="trackDevicesModelId"
          label="Модель *"
          labelWidth={110}
          items={trackDevicesModels}
          selectedValue={values.trackDevicesModelId}
          handleChange={(event) => {
            setFieldValue("trackDevicesModelId", event.target.value);
          }}
          fullWidth
        />
        <Divider variant="middle"/>
        <div className={classes.price}>
          <Typography variant="h6">Стоимость: {((trackDevicesPriceCent || 0) / 100).toFixed(2) || '-'} р.</Typography>
          <Typography variant="caption">Заказ будет принят к исполнению, только после оплаты</Typography>
        </div>
        <div>
          <Button
            className={classes.button}
            variant="contained"
            color="primary"
            size="small"
            type="submit"
            disabled={isTrackDeviceOrderIncomplete(values)}
            onClick={()=> {
              if (!!trackDevicesOrderConfirm) trackDevicesOrderConfirm(values);
            }}
          >Оформить заказ</Button>
          <Button
            className={classes.button}
            variant="contained"
            color="primary"
            size="small"
            disabled={isTrackDeviceOrderIncomplete(values)}
            onClick={()=> {
              if (!!trackDevicesOrderItemAdd) trackDevicesOrderItemAdd(values);
            }}
          >Добавить к заказу</Button>
        </div>
      </>
    );
  }
}

export default withStyles(styles)(TrackDeviceData);
