import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import classNames from "classnames";
import Snackbar from "@material-ui/core/Snackbar";
import SnackbarContent from "@material-ui/core/SnackbarContent";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import green from "@material-ui/core/colors/green";
import messageStyles from "./styles";
import withStyles from "@material-ui/core/styles/withStyles";

import * as infoMessageActions from "actions/infoMessageActions";

const mapStateToProps = state => {
  return {
    info: state.info
  };
};

const mapDispatchToProps = dispatch => {
  return {
    infoMessageActions: bindActionCreators(infoMessageActions, dispatch)
  };
};

const styles = theme => ({
  ...messageStyles(theme),
  info: {
    backgroundColor: green[600]
  }
});

class InfoMessage extends Component {
  render() {
    const { classes } = this.props;
    const { infoMessage } = this.props.info;
    const { clearInfoMessage } = this.props.infoMessageActions;
    return (
      <Snackbar
        variant="success"
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={infoMessage ? true : false}
        onClose={clearInfoMessage}
      >
        <SnackbarContent
          classes={{
            root: classNames(classes.content, classes.info),
            action: classes.action
          }}
          aria-describedby="message-id"
          message={<span id="message-id">{infoMessage}</span>}
          action={
            <IconButton
              key="close"
              aria-label="Close"
              color="inherit"
              className={classes.close}
              onClick={clearInfoMessage}
            >
              <CloseIcon />
            </IconButton>
          }
        />
      </Snackbar>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(InfoMessage));
