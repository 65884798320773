import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';

import CircularProgress from '@material-ui/core/CircularProgress';

const mapStateToProps = state => {
  return {
    loading: state.loading
  };
};

const styles = theme => ({
  loader: {
    width: '100%',
    height: '100%',
		position: 'absolute',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
    backgroundColor: 'white',
    opacity: 0.8,
    zIndex: 1200
  }
});

class LoadingMessage extends Component {
  render() {
    const { isLoading } = this.props.loading;
    const { classes } = this.props;
    if (isLoading) {
      return (
        <div className={classes.loader}>
          <CircularProgress size={50} />
        </div>
      );
    } else {
      return null;
    }
  }
}

export default connect(mapStateToProps)(withStyles(styles)(LoadingMessage));
