import React from "react";
import Typography from "@material-ui/core/Typography";

import Email from "@material-ui/icons/Email";
// import Call from "@material-ui/icons/Call";
import Computer from "@material-ui/icons/Computer";
import LocationOn from "@material-ui/icons/LocationOn";
import VpnLock from "@material-ui/icons/VpnLock";

import { withStyles } from "@material-ui/core/styles";

const styles = theme => ({
  container: {
    padding: "10px 0",
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "space-around",
    background: theme.palette.primary.main,
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column"
    }
  },
  element: {
    width: "20%",
    display: "flex",
    flexDirection: "column",
    color: "white",
    alignItems: "center",
    justifyContent: "flex-start",
    textAlign: "center",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      paddingBottom: 40
    }
  },
  link: {
    color: "white",
    textDecoration: "none"
  }
});

const FooterElements = props => {
  const { classes, dilerId, showAddress = false } = props;
  return (
    <div className={classes.container}>
      <div className={classes.element}>
        <Email fontSize="large" />
        <Typography variant="caption" color="inherit">
          Напишите нам
        </Typography>
        <Typography variant="caption" color="inherit">
          {dilerId === 7 || dilerId === 26 ? (
            <a className={classes.link} href="mailto:info@cyber-card.ru">
              info@cyber-card.ru
            </a>
          ) : (
            <a className={classes.link} href="mailto:support@ueshka.ru">
              support@ueshka.ru
            </a>
          )}
        </Typography>
      </div>
      {/*<div className={classes.element}>*/}
      {/*  <Call fontSize="large" />*/}
      {/*  <Typography align="center" variant="caption" color="inherit">*/}
      {/*    Звонок по России бесплатный*/}
      {/*  </Typography>*/}
      {/*  <Typography variant="caption" color="inherit">*/}
      {/*    {dilerId === 7 ? (*/}
      {/*      <a href="tel:8(8452)244404" style={{textDecoration: 'none', color: "white"}}>8(8452)24-44-04</a>*/}
      {/*    ) : dilerId === 26 ? (*/}
      {/*      <a href="tel:8(8452)244404" style={{textDecoration: 'none', color: "white"}}>8(8452)24-44-04</a>*/}
      {/*    ) : (*/}
      {/*      <div>*/}
      {/*        <a href="tel:8(804)333-0153" style={{textDecoration: 'none', color: "white"}}>8-804-333-01-53</a>*/}
      {/*        <br />*/}
      {/*        Крым: <a href="tel:8(800)333-2953" style={{textDecoration: 'none', color: "white"}}>8-800-333-2953</a>*/}
      {/*      </div>*/}
      {/*    )}*/}
      {/*  </Typography>*/}
      {/*</div>*/}
      <div className={classes.element}>
        <Computer fontSize="large" />
        <Typography variant="caption" color="inherit">
          Официальный сайт
        </Typography>
        <Typography variant="caption" color="inherit">
          {dilerId === 7 || dilerId === 26 ? (
            <a className={classes.link} href="http://cyber-card.ru" target="_blank" rel="noopener noreferrer" >
              cyber-card.ru
            </a>
          ) : (
            <a className={classes.link} href="https://ueshka.ru" target="_blank" rel="noopener noreferrer" >
              ueshka.ru
            </a>
          )}
        </Typography>
      </div>
      {showAddress && (<div className={classes.element}>
        <LocationOn fontSize="large" />
        <Typography variant="caption" color="inherit">
          Наш адрес
        </Typography>
        <Typography align="center" variant="caption" color="inherit">
          {dilerId === 7 || dilerId === 26
            ? "413111, Саратовская область, г. Энгельс, пр-т Ф. Энгельса, д. 11"
            : "194156, г. Санкт-Петербург, ул. Новороссийская, д. 28 корпус 2"}
        </Typography>
      </div>)}
      <div className={classes.element}>
        <VpnLock fontSize="large" />
        <Typography variant="caption" color="inherit">
          Оператор
        </Typography>
        <Typography align="center" variant="caption" color="inherit">
          {new Date().getFullYear()} ООО «ИНПО «КлассКарт»
        </Typography>
      </div>
    </div>
  );
};

export default withStyles(styles)(FooterElements);
