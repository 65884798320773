import React from "react";
import ArrowRightAlt from "@material-ui/icons/ArrowRightAlt";
import Clear from "@material-ui/icons/Clear";

import withStyles from "@material-ui/core/styles/withStyles";
import { Typography } from "@material-ui/core";

const styles = theme => ({
  container: {
    padding: "150px 10px 10px 10px",
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.down("xs")]: {
      padding: "70px 10px 10px 10px"
    }
  },
  row: {
    display: "flex",
    alignItems: "flex-start"
  },
  enter: {
    color: "green",
    fontSize: 23,
    fontWeight: 600,
    transform: "rotate(-90deg)"
  },
  exit: {
    color: "red",
    fontSize: 23,
    fontWeight: 600,
    transform: "rotate(90deg)"
  }
});

class CalendarInfo extends React.Component {
  state = { paddingTop: 130 };

  componentDidMount() {
    const calendarHeight = document.querySelector(".CalendarMonthGrid").clientHeight;
    const infoPadding = 130 + (calendarHeight - 440);
    this.setState({ paddingTop: infoPadding < 130 ? 130 : infoPadding });
  }

  componentDidUpdate(prevProps) {
    const calendarHeight = document.querySelector(".CalendarMonthGrid").clientHeight;
    const infoPadding = 130 + (calendarHeight - 440);
    if (this.state.paddingTop !== (infoPadding < 130 ? 130 : infoPadding)) {
      this.setState({ paddingTop: infoPadding < 130 ? 130 : infoPadding });
    }
  }

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.container} style={{ paddingTop: this.state.paddingTop }}>
        <Typography variant="body1">Обозначения:</Typography>
        <Typography className={classes.row}>
          <ArrowRightAlt className={classes.enter} /> - в указанный день был вход
        </Typography>
        <Typography className={classes.row}>
          <ArrowRightAlt className={classes.exit} /> - в указанный день был выход
        </Typography>
        <Typography className={classes.row}>
          <Clear className={classes.exit} /> - ученик находился на нулевом тарифе, информация
          недоступна
        </Typography>
      </div>
    );
  }
}

export default withStyles(styles)(CalendarInfo);
