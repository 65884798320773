import React, {PureComponent} from "react";
import PropTypes from "prop-types";
import {withStyles} from "@material-ui/core/styles";

import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Logo from "../Logo";
import Typography from "@material-ui/core/Typography";
import Hidden from "@material-ui/core/Hidden";


const styles = theme => ({
  appBar: {
    zIndex: theme.zIndex.drawer + 1, // чтобы меню не заходило на хедер
    [theme.breakpoints.down("sm")]: {
      position: "fixed"
    }
  },
  appBarGrey: {
    backgroundColor: "#CCC",
    zIndex: theme.zIndex.drawer + 1, // чтобы меню не заходило на хедер
    [theme.breakpoints.down("sm")]: {
      position: "fixed"
    }
  },
  toolbar: {
    position: "flex",
    justifyContent: "space-between",
    flexDirection: "row",
    marginLeft: 90,
    // minWidth: 50,
    [theme.breakpoints.down("xs")]: {
      minHeight: 50,
      height: 50
    }
  },
  element: {
    // width: "20%",
    display: "flex",
    flexDirection: "column",
    color: "white",
    alignItems: "center",
    justifyContent: "flex-start",
    textAlign: "center",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      paddingBottom: 40
    }
  },
  title: {
    margin: 5,
  },
  toLogin: {
    "&:hover": {
      cursor: "pointer"
    }
  }
})

class RegHeader extends PureComponent {
  render() {
    const {classes, parentDilerId = 6, title, showLogin = true, isGray = false} = this.props
    return (
      <AppBar className={(isGray) ? classes.appBarGrey : classes.appBar}>
        <Hidden smDown>
          <Logo parentDilerId={parentDilerId}/>
        </Hidden>
        <Toolbar className={classes.toolbar}>
          {!!title && (<div className={classes.element}>
              <Typography variant="h6" color={(isGray) ? "textPrimary" : "inherit"}
                          className={classes.title}>{title}</Typography>
            </div>
          )}
          {!!showLogin && (<div className={classes.element}>
            <Typography variant="button" onClick={this.props.goToLogin} className={classes.toLogin}>Личный
              кабинет</Typography>
          </div>)}
        </Toolbar>
      </AppBar>
    )
  }
}

RegHeader.propTypes = {
  parentDilerId: PropTypes.number,
  title: PropTypes.string,
  showLogin: PropTypes.bool,
  goToLogin: PropTypes.func.isRequired,
}
export default withStyles(styles)(RegHeader);