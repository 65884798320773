import React from "react";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";

import moment from "moment";
import { withStyles } from "@material-ui/core/styles";

const styles = theme => ({
  archiveTable: {
    margin: 5,
    border: "1px solid lightgrey",
    height: "calc(100% - 75px)",
    overflowY: "auto"
  },
  dateCell: {
    width: 50
  },
  date: {
    padding: "0 5px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "flex-start",
    height: "100%"
  },
  timeCell: {
    width: 50,
    padding: "0 10px 0 5px",
    alignItems: "flex-start"
  }
});

const ArchiveTable = props => {
  const { classes, messages, pupil, event } = props;
  const filteredMessages =
    pupil !== -1 || event !== -1
      ? messages.filter(message => {
          if (event !== -1 && pupil !== -1) {
            return message.eventType === event && message.userId === pupil;
          } else if (event === -1 || pupil === -1) {
            return event !== -1 ? message.eventType === event : message.userId === pupil;
          } else {
            return messages;
          }
        })
      : messages;
  return (
    <div className={classes.archiveTable}>
      <Table padding="none">
        <TableHead>
          <TableRow>
            <TableCell>
              <Typography align="center">Дата</Typography>
            </TableCell>
            <TableCell>
              <Typography align="center">Время</Typography>
            </TableCell>
            <TableCell>
              <Typography align="center">Сообщение</Typography>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {messages &&
            filteredMessages.map((item, index) => (
              <TableRow key={index}>
                <TableCell className={classes.dateCell}>
                  <div className={classes.date}>
                    <Typography variant="h6">
                      {moment(item.dateTime, "YYYY-MM-DD HH:mm").format("DD")}
                    </Typography>
                    <Typography>
                      {moment(item.dateTime, "YYYY-MM-DD HH:mm").format("MMMM")}
                    </Typography>
                  </div>
                </TableCell>
                <TableCell className={classes.timeCell}>
                  <Typography variant="subtitle1">
                    {moment(item.dateTime, "YYYY-MM-DD HH:mm").format("HH:mm")}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography>{item.message}</Typography>
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
      {messages && messages.length === 0 ? (
        <Typography variant="h6" align="center">
          За выбранный период сообщения не найдены
        </Typography>
      ) : filteredMessages.length === 0 ? (
        <Typography variant="h6" align="center">
          С выбранным фильтром за указанный период сообщения отсутствуют
        </Typography>
      ) : null}
    </div>
  );
};

export default withStyles(styles)(ArchiveTable);
