import React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import AccessAlarm from '@material-ui/icons/AccessAlarm';
import VerticalAlignTop from '@material-ui/icons/VerticalAlignTop';
import VerticalAlignBottom from '@material-ui/icons/VerticalAlignBottom';

import Typography from '@material-ui/core/Typography';

import moment from 'moment';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
  leftCell: {
    width: 70
  }
});

const AttendanceDetails = props => {
  const { classes, selectedDate, attendanceDaily } = props;
  const formatSelectedDate = moment(selectedDate).format('YYYY-MM-DD');
  const attendanceDailyArray = attendanceDaily[formatSelectedDate];

  const rows =
    attendanceDailyArray &&
    attendanceDailyArray.map((item, index) => {
      return (
        <TableRow key={index}>
          <TableCell className={classes.leftCell}>
            <Typography variant="subtitle1">{item.time}</Typography>
          </TableCell>
          <TableCell>
            <Typography variant="subtitle1">{item.operationName}</Typography>
          </TableCell>
          <TableCell numeric>
            {item.operationType.toLowerCase() === 'начало урока' ||
            item.operationType.toLowerCase() === 'окончание урока' ? (
              <AccessAlarm color="primary" />
            ) : item.operationType.toLowerCase() === 'вход' ? (
              <VerticalAlignTop color="primary" />
            ) : item.operationType.toLowerCase() === 'выход' ? (
              <VerticalAlignBottom color="primary" />
            ) : null}
          </TableCell>
        </TableRow>
      );
    });

  return (
      <Table padding="none">
        <TableBody>
          <TableRow>
            <TableCell colSpan={3}>
              <Typography variant="h6">{moment(selectedDate).format('D MMMM, dddd')}</Typography>
            </TableCell>
          </TableRow>
          {rows}
        </TableBody>
      </Table>
  );
};

export default withStyles(styles)(AttendanceDetails);
