import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import {Table} from "@material-ui/core";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";

const styles = () => ({
  // price: {
  //   minHeight: 60,
  // },
  // cardTypeName: {
  //   margin: theme.spacing.unit,
  //   minWidth: 120,
  // },
})

const TrackDeviceOrderItems = (props) => {
  const { values} = props;

  return (
    <div>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Наименование</TableCell>
            <TableCell>Цена</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {values.items.map(item => (
            <TableRow key={`${item.trackDeviceType}-${item.modelName}`}>
              <TableCell>{item.trackDeviceType}, {item.modelName}</TableCell>
              <TableCell>{(item.priceCent / 100).toFixed(2)} р.</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  )
}

export default withStyles(styles)(TrackDeviceOrderItems)
