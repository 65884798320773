import React from "react";

import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import OutlinedInput from "@material-ui/core/OutlinedInput";

import { withStyles } from "@material-ui/core/styles";
const styles = theme => ({
  formControl: {
    minWidth: 220,
    width: 220,
    margin: "10px 10px 10px 0"
  }
});

const PaperInvoiceServicesList = props => {
  const { classes, paperInvoice, paperInvoiceMethods, profile } = props;
  return (
      <FormControl variant="outlined" className={classes.formControl}>
        <InputLabel>Тип услуги</InputLabel>
        <Select
          value={paperInvoice.serviceId}
          onChange={paperInvoiceMethods.selectServiceId}
          input={
            <OutlinedInput
              labelWidth={100}
              name="serviceType"
              id="serviceType"
            />
          }
        >
          {profile.balances.ids.map(id => (
            <MenuItem key={id} value={id}>
              {profile.balances[id].name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
  );
};

export default withStyles(styles)(PaperInvoiceServicesList);
