import React from "react";

import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepButton from "@material-ui/core/StepButton";
import StepContent from "@material-ui/core/StepContent";
import Typography from "@material-ui/core/Typography";
import Hidden from "@material-ui/core/Hidden";

import Step1 from "./Step1";
import Step2 from "./Step2";
import Step3 from "./Step3";
import Step4 from "./Step4";
import LastInvoice from "../invoices/LastInvoice";

import { withStyles } from "@material-ui/core/styles";

const styles = theme => ({
  container: {
    //width: "calc(100% - 10px)",
    display: "flex",
    flexDirection: "column",
    padding: 5
  },
  paysContainer: {
    display: "flex",
    flexDirection: "row",
    padding: "5px 0",
    overflow: "auto",
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column"
    }
  },
  title: {
    paddingTop: 10
  },
  stepper: {
    width: "calc(100% - 270px)",
    height: "100%",
    [theme.breakpoints.down("xs")]: {
      padding: "10px 5px",
      width: "calc(100% - 10px)",
      overflow: "hidden",
      order: 2
    }
  },
  bottomText: {
    paddingLeft: 5
  }
});

const Pay = props => {
  const {
    classes,
    step,
    payType,
    pupil,
    pupils,
    commissions,
    isFoodActive,
    errorOnValidateAmount,
    amount,
    promoCode,
    autoPayment,
    promoPupils,
    pays: {
      lastInvoicePay,
      changeStep,
      selectPayType,
      selectPupilPay,
      setAmount,
      setPromoCode,
      setAutoPayment,
      onEnterPress,
      submitPay
    },
    lastInvoice,
    parentCode,
    lastOperations,
    defaultPayType,
    profile
  } = props;

  // const isParentFood = Number(profile.parent.foodBalance) !== 0 || profile.parent.foodModuleActive

  return (
    <div className={classes.container}>
      <Hidden smUp>
        <LastInvoice
          {...{ lastInvoice, lastInvoicePay, defaultPayType, parentCode, lastOperations }}
          defaultExpanded={false}
        />
      </Hidden>
      <Typography variant="subtitle2" align="center" className={classes.title}>
        Оплатить с помощью банковской карты
      </Typography>
      <div className={classes.paysContainer}>
        <Stepper className={classes.stepper} activeStep={step} orientation="vertical">
          <Step>
            <StepButton onClick={e => changeStep("none", 0)}>
              {step !== 0 ? "Услуга: " + payType.name : "Выберите услугу"}
            </StepButton>
            <StepContent>
              <Step1 {...{ profile, payType, selectPayType, changeStep, step, isFoodActive, commissions, setPromoCode, promoCode}} />
            </StepContent>
          </Step>
          {(isFoodActive || (payType.id === 1 && promoCode.length > 0)) ? (
            <Step>
              <StepButton onClick={e => changeStep("none", 1)}>
                {((payType.id !== 0 && payType.id !== 1) || (payType.id === 1 && promoCode.length < 1))
                  ? "Ученик: выбор не требуется"
                  : (payType.id === 0 || (payType.id === 1 && promoCode.length > 0)) && step > 1
                  ? (pupils[pupil.id]) ? "Ученик: " + pupil.name : pupil.name
                  : "Выберите ученика"}
              </StepButton>
              <StepContent>
                <Step2
                  {...{
                    profile,
                    pupil,
                    pupils,
                    payType,
                    commissions,
                    promoPupils,
                    selectPupilPay,
                    step,
                    changeStep
                  }}
                />
              </StepContent>
            </Step>
          ) : null}
          <Step>
            <StepButton>
              {step === 3 ? "Сумма зачисления: " + amount + " руб." : "Введите сумму зачисления"}
            </StepButton>
            <StepContent>
              <Step3
                {...{
                  changeStep,
                  payType,
                  promoCode,
                  autoPayment,
                  setAmount,
                  setAutoPayment,
                  autoPaymentAvailable: profile.autoPaymentAvailable,
                  autoPaymentAvailableServices: profile.autoPaymentAvailableServices,
                  step,
                  errorOnValidateAmount,
                  amount,
                  onEnterPress
                }}
              />
            </StepContent>
          </Step>
          <Step>
            <StepButton>Подтверждение платежа</StepButton>
            <StepContent>
              <Step4 {...{ payType, pupil, step, commissions, changeStep, amount, promoCode, autoPayment, submitPay }} />
            </StepContent>
          </Step>
        </Stepper>
        <Hidden xsDown>
          <LastInvoice
            {...{ lastInvoice, lastInvoicePay, defaultPayType, parentCode, lastOperations }}
            defaultExpanded={true}
          />
        </Hidden>
      </div>
      <Typography align="justify" className={classes.bottomText}>
        Обращаем Ваше внимание, что при ошибочном зачислении денег возврат и перевод средств
        осуществляется по письменному Заявлению абонента. Перечислению подлежит сумма за вычетом
        текущей абонентской задолженности и комиссии за совершенные банковские операции в размере
        4%.
      </Typography>
    </div>
  );
};

export default withStyles(styles)(Pay);
