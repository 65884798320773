import React from "react";
import { Polygon } from "react-yandex-maps";

const MapZones = props => {
  const { selectZone, selectedZone, addZoneIsOpen, addZoneCoordinates } = props;
  const pointsArray = points => {
    return points.map(point => {
      return [point.lat, point.lng];
    });
  };

  return (
    <>
      {props.zones &&
        props.zones.map(zone => {
          return (
            <Polygon
              key={zone.id}
              geometry={[pointsArray(zone.points)]}
              options={{
                fillColor:
                  selectedZone === zone.id ? "#4fc3f7" : zone.state === 0 ? "#f44336" : "#00FF00",
                strokeColor: "#0000FF",
                opacity: 0.5
              }}
              onClick={addZoneIsOpen ? addZoneCoordinates : selectZone(zone.id, zone.points)}
            />
          );
        })}
    </>
  );
};

export default MapZones;
