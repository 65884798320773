import React from "react";
import PropTypes from "prop-types";
import Typography from "@material-ui/core/Typography";
import InputField from "../../../InputField";

const PupilFio = props => {
  const { pupilIndex, pupil, handleInputChange, handleDateChange, errors, onKeyUp, parentDilerId } = props;
  return (
    <>
      <Typography variant="button">Ученик №{pupilIndex + 1}</Typography>
      {errors["children_" + pupilIndex] && (
        <Typography variant="body2" color="error">
          Ошибка: {errors["children_" + pupilIndex]}
        </Typography>
      )}
      <div>
        <InputField
          id="cardNumber"
          label={(parentDilerId !== 7) ? "Номер карты УЭШКа": "Номер карты Кибер-Карта"}
          required
          disabled={!!pupil.id}
          error={errors["children_" + pupilIndex + "_cardNumber"]}
          value={pupil.cardNumber || ""}
          onChange={handleInputChange("cardNumber", pupilIndex)}
          onKeyUp={onKeyUp}
        />

        <InputField
          id="birthDay"
          label="Дата рождения *"
          datePicker
          error={errors["children_" + pupilIndex + "_birthDay"]}
          value={pupil.birthDay}
          onChange={handleDateChange("birthDay", pupilIndex)}
          onKeyUp={onKeyUp}
        />
      </div>

      <div>
        <InputField
          id="fam"
          label="Фамилия"
          required
          error={errors["children_" + pupilIndex + "_fam"]}
          onChange={handleInputChange("fam", pupilIndex)}
          onKeyUp={onKeyUp}
          value={pupil.fam}
        />

        <InputField
          id="im"
          label="Имя"
          required
          error={errors["children_" + pupilIndex + "_im"]}
          onChange={handleInputChange("im", pupilIndex)}
          onKeyUp={onKeyUp}
          value={pupil.im}
        />

        <InputField
          id="otch"
          label="Отчество"
          error={errors["children_" + pupilIndex + "_otch"]}
          onChange={handleInputChange("otch", pupilIndex)}
          onKeyUp={onKeyUp}
          value={pupil.otch}
        />
      </div>
      <br />
    </>
  );
};

PupilFio.propTypes = {
  pupil: PropTypes.shape({
    fam: PropTypes.string,
    im: PropTypes.string,
    otch: PropTypes.string,
    birthDay: PropTypes.string,
    cardNumber: PropTypes.number
  }),
  pupilIndex: PropTypes.number.isRequired,
  handleDateChange: PropTypes.func.isRequired,
  handleInputChange: PropTypes.func.isRequired,
  errors: PropTypes.object,
  parentDilerId: PropTypes.number
};

export default PupilFio;
