import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";

const styles = theme => ({
  container: {
    minHeight: 50,
    display: "block",
    // position: "fixed",
    marginTop: 20,
    bottom: 0,
    zIndex: 0,
    flexShrink: 0,
    backgroundImage: "linear-gradient(180deg, rgb(251, 121, 91) 0%, rgb(239, 76, 88) 100%)",
    [theme.breakpoints.down("md")]: {
      position: "relative",
    },
  },
  footerText: {
    color: "white",
    margin: 15
  },
  aLink: {
    color: "white",
    ":visited": {
      color: "white",
    }
  },
})

const KhalvaFooter = props => {
  const { classes } = props;
  return (
      <footer className={classes.container}>
        <Typography variant="caption" className={classes.footerText}>
          Карта рассрочки «Халва». Плата за обслуживание - 0 руб. Ставка в течение
          льготного периода (36 мес.) - 0% год. При его нарушении по окончании - 10% годовых. Возобновляемый кредитный
          лимит - до 350 000 руб. в течение 10 лет. Срок рассрочки от 1 до 18 мес. Клиентам от 20 до 75 лет по
          паспорту РФ. Начисление вознаграждения по программе балльного кэшбэка (макс. 5000 баллов). Клиенту
          необходимо в каждом отчетном периоде выполнять условие по минимальному обороту (от 5 покупок или платежей в
          разделе «Оплата услуг» мобильного приложения «Совкомбанк — Халва» или личном кабинете на сайте halvacard.ru
          на сумму от 10 000 рублей, для карт «Социальная Халва» — от 5000 руб.). Размер кэшбэка (начисляется только
          за покупки своими деньгами): в сети партнёров - при оплате телефоном 6%* за каждую покупку от 10000 руб., 4%
          за каждую покупку от 5000 до 9999,99 руб., 2% за каждую покупку до 4999,99 руб. (для карт «Социальная Халва»
          в т.ч. при оплате картой), при оплате картой 2%; вне партнёрской сети - 1% за каждую покупку от 1000 руб.
          (при любом способе оплаты). * В кассах магазинов через Google, Apple или Samsung Pay (кроме покупок в
          Интернете). При совершении в отчетном периоде от 1 покупки и более (в любых магазинах, со своими деньгами
          или в рассрочку) начисляется 4% на остаток собственных средств (без покупок проценты не начисляются). Чтобы
          увеличить ставку до 5,5% , необходимо сделать не менее 5 покупок в отчетном периоде на общую сумму от 10 000
          руб. (в любых магазинах, своими деньгами или в рассрочку). Ставка до 5,5% годовых - при соблюдении условий
          «5 и более покупок на общую сумму от 10 000 руб.» для суммы до 500 000 руб., на сумму свыше 500 000 руб. -
          4%. Пример расчёта общей стоимости займа: при покупке товара в магазине партнёра «М.Видео» на сумму 30 000
          руб., Срок рассрочки - 4 месяца, ежемесячный платеж составит 7500 руб., Общая сумма переплаты - 0 руб. По
          факту просрочки взимается штраф в размере: 590 руб. - впервые; 1% от полной суммы задолженности +590 руб. -
          за второй раз подряд; 2% от полной суммы задолженности +590 руб. - в третий раз и более. По факту просрочки
          начисляется плата за неустойку в размере 19% годовых. Начисление происходит за каждый календарный день.
          Взимается штраф / начисляется неустойка: по карте «Халва» - через 5 дней просрочки в сумме свыше 500 рублей.
          Условия (по карте с лимитом более 0,1 руб.): кредит предоставляется клиентам от 20 до 75 лет по паспорту РФ.
          Официальное трудоустройство (стаж на последнем месте работы не менее 4 месяцев). Наличие постоянной
          регистрации не менее 4 последних месяцев (на территории одного населенного пункта РФ) и проживание в городе
          подразделений Банка или прилегающих населенных пунктах (но не более 70 километров от любого внутреннего
          структурного подразделения Банка). Наличие стационарного рабочего или домашнего телефона. «Халва - лучшая
          банковская карта 2018 года в России!» - по версии banki.ru. <a href="https://halvacard.ru/" target="_blank"
                                                                         rel="noopener noreferrer"
                                                                         className={classes.aLink}>Подробнее</a>.
        </Typography>
        <Typography variant="caption" className={classes.footerText}>ПАО «Совкомбанк». Генеральная лицензия ЦБ РФ
          №963. г. Кострома, пр. Текстильщиков, д. 46. г. Москва, Краснопресненская наб., д.14, стр.1. На правах
          рекламы.
        </Typography>
      </footer>
  );
};

export default withStyles(styles)(KhalvaFooter);