import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import Typography from "@material-ui/core/Typography";
import Add from "@material-ui/icons/Add";

import panelsStyles from "../styles";

import withStyles from "@material-ui/core/styles/withStyles";

const styles = theme => ({
  ...panelsStyles(theme),
  gpsTitle: {
    paddingBottom: 0
  }
});

const PanelTitle = props => {
  const { classes, addZoneIsOpen, addZoneOpen, selectedZone } = props;
  return (
    <div
      className={classNames(classes.header, {
        [classes.gpsTitle]: addZoneIsOpen
      })}
    >
      {selectedZone ? (
        <Typography color="inherit" variant="h6">
          Выбрана GPS-зона
        </Typography>
      ) : !addZoneIsOpen ? (
        <Typography color="inherit" variant="h6">
          GPS-зоны
        </Typography>
      ) : null}

      {!addZoneIsOpen ? (
        <Add fontSize="large" color="secondary" className={classes.icon} onClick={addZoneOpen} />
      ) : null}
    </div>
  );
};

export default withStyles(styles)(PanelTitle);

PanelTitle.propTypes = {
  classes: PropTypes.object,
  addZoneIsOpen: PropTypes.bool.isRequired,
  addZoneOpen: PropTypes.func.isRequired
};
