import axios from "axios";
import {API, handleApiError} from "../api";
import {createAction} from "redux-actions";

export const fiasVersionRequest = createAction("FIAS_VERSION_REQUEST");
export const fiasVersionSuccess = createAction("FIAS_VERSION_SUCCESS");
export const fiasVersionError = createAction("FIAS_VERSION_ERROR");

export const fiasRegionsRequest = createAction("FIAS_REGIONS_REQUEST");
export const fiasRegionsSuccess = createAction("FIAS_REGIONS_SUCCESS");
export const fiasRegionsError = createAction("FIAS_REGIONS_ERROR");

export const fiasAreasRequest = createAction("FIAS_AREAS_REQUEST_LOCAL");
export const fiasAreasSuccess = createAction("FIAS_AREAS_SUCCESS");
export const fiasAreasError = createAction("FIAS_AREAS_ERROR");

export const fiasCitiesRequest = createAction("FIAS_CITIES_REQUEST_LOCAL");
export const fiasCitiesSuccess = createAction("FIAS_CITIES_SUCCESS");
export const fiasCitiesError = createAction("FIAS_CITIES_ERROR");

export const fiasLocalitiesRequest = createAction("FIAS_LOCALITIES_REQUEST_LOCAL");
export const fiasLocalitiesSuccess = createAction("FIAS_LOCALITIES_SUCCESS");
export const fiasLocalitiesError = createAction("FIAS_LOCALITIES_ERROR");

export const fiasStructuresRequest = createAction("FIAS_STRUCTURES_REQUEST_LOCAL");
export const fiasStructuresSuccess = createAction("FIAS_STRUCTURES_SUCCESS");
export const fiasStructuresError = createAction("FIAS_STRUCTURES_ERROR");

export const fiasStreetsRequest = createAction("FIAS_STREETS_REQUEST_LOCAL");
export const fiasStreetsSuccess = createAction("FIAS_STREETS_SUCCESS");
export const fiasStreetsError = createAction("FIAS_STREETS_ERROR");

export const fiasHousesRequest = createAction("FIAS_HOUSES_REQUEST_LOCAL");
export const fiasHousesSuccess = createAction("FIAS_HOUSES_SUCCESS");
export const fiasHousesError = createAction("FIAS_HOUSES_ERROR");

export const clearFiasDataStart = createAction("CLEAR_FIAS_DATA");

export const getFiasVersion = () => async dispatch => {
  try {
    dispatch(fiasVersionRequest());
    const res = await axios.get(`${API}/fias/version`);
    dispatch(fiasVersionSuccess(res.data));
  } catch (error) {
    dispatch(handleApiError(error, fiasVersionError()));
  }
};

export const getFiasRegions = (dilerId = null, regionId = null) => async dispatch => {
  try {
    dispatch(fiasRegionsRequest());
    if (typeof dilerId === 'number' ) dilerId = [dilerId];
    const res = await axios.get(encodeURI(`${API}/fias/regions` +
      `?dilerId=${(!dilerId)? null : dilerId.join(',')}` +
      `&regionId=${(!regionId)? null : regionId}`));
    dispatch(fiasRegionsSuccess({regions: res.data}));
  } catch (error) {
    dispatch(handleApiError(error, fiasRegionsError()));
  }
};

export const getFiasAreas = (regionId, parentGuid, likeString) => async dispatch => {
  try {
    dispatch(fiasAreasRequest());
    regionId = encodeURIComponent(regionId);
    parentGuid = encodeURIComponent(parentGuid);
    likeString = encodeURIComponent(likeString);
    const res = await axios.get(
      `${API}/fias/areas?regionId=${regionId}&parentGuid=${parentGuid}&likeString=%25${likeString}%25`
    );
    dispatch(fiasAreasSuccess({areas: res.data}));
  } catch (error) {
    dispatch(handleApiError(error, fiasAreasError()));
  }
};

export const getFiasCities = (regionId, parentGuid, likeString) => async dispatch => {
  try {
    dispatch(fiasCitiesRequest());
    regionId = encodeURIComponent(regionId);
    parentGuid = encodeURIComponent(parentGuid);
    likeString = encodeURIComponent(likeString);
    const res = await axios.get(
      `${API}/fias/cities?regionId=${regionId}&parentGuid=${parentGuid}&likeString=%25${likeString}%25`
    );
    dispatch(fiasCitiesSuccess({cities: res.data}));
  } catch (error) {
    dispatch(handleApiError(error, fiasCitiesError()));
  }
};

export const getFiasLocalities = (regionId, parentGuid, likeString) => async dispatch => {
  try {
    dispatch(fiasLocalitiesRequest());
    regionId = encodeURIComponent(regionId);
    parentGuid = encodeURIComponent(parentGuid);
    likeString = encodeURIComponent(likeString);
    const res = await axios.get(
      `${API}/fias/localities?regionId=${regionId}&parentGuid=${parentGuid}&likeString=%25${likeString}%25`
    );
    dispatch(fiasLocalitiesSuccess({localities: res.data}));
  } catch (error) {
    dispatch(handleApiError(error, fiasLocalitiesError()));
  }
};

export const getFiasStructures = (regionId, parentGuid, likeString) => async dispatch => {
  try {
    dispatch(fiasStructuresRequest());
    regionId = encodeURIComponent(regionId);
    parentGuid = encodeURIComponent(parentGuid);
    likeString = encodeURIComponent(likeString);
    const res = await axios.get(
      `${API}/fias/plane-structs?regionId=${regionId}&parentGuid=${parentGuid}&likeString=%25${likeString}%25`
    );
    dispatch(fiasStructuresSuccess({structures: res.data}));
  } catch (error) {
    dispatch(handleApiError(error, fiasStructuresError()));
  }
};

export const getFiasStreets = (parentGuid, likeString, rowCount = 10) => async dispatch => {
  try {
    dispatch(fiasStreetsRequest());
    parentGuid = encodeURIComponent(parentGuid);
    likeString = encodeURIComponent(likeString);
    rowCount = encodeURIComponent(rowCount);
    const res = await axios.get(
      `${API}/fias/streets?parentGuid=${parentGuid}&likeString=%25${likeString}%25&rowCount=${rowCount}`
    );
    dispatch(fiasStreetsSuccess({streets: res.data}));
  } catch (error) {
    dispatch(handleApiError(error, fiasStreetsError()));
  }
};

export const getFiasHouses = (parentGuid, likeString) => async dispatch => {
  try {
    dispatch(fiasHousesRequest());
    parentGuid = encodeURIComponent(parentGuid);
    likeString = encodeURIComponent(likeString);
    const res = await axios.get(
      `${API}/fias/houses?parentGuid=${parentGuid}&likeString=%25${likeString}%25`
    );

    dispatch(fiasHousesSuccess({houses: res.data}));
  } catch (error) {
    dispatch(handleApiError(error, fiasHousesError()));
  }
};

export const clearFiasData = name => dispatch => {
  dispatch(clearFiasDataStart(name));
};
