import { createSelector } from "reselect";

const getRegions = state => state.fias.regions;

export const getRegionsSelector = createSelector(
  [getRegions],
  regions => {
    if (!regions) return [];
    return regions.map(region => ({
      value: region.id,
      label: region.formalNameCC,
      AOGuid: region.AOGuid
    }));
  }
);

const getAreas = state => state.fias.areas;

export const getAreasSelector = createSelector(
  [getAreas],
  areas => {
    if (!areas) return [];
    return areas.map(area => ({
      value: area.id,
      label: area.formalNameCC,
      AOGuid: area.AOGuid
    }));
  }
);

const getCities = state => state.fias.cities;

export const getCitiesSelector = createSelector(
  [getCities],
  cities => {
    if (!cities) return [];
    return cities.map(city => ({
      value: city.id,
      label: city.formalNameCC,
      AOGuid: city.AOGuid
    }));
  }
);

const getLocalities = state => state.fias.localities;

export const getLocalitiesSelector = createSelector(
  [getLocalities],
  localities => {
    if (!localities) return [];
    return localities.map(locality => ({
      value: locality.id,
      label: locality.formalNameCC,
      AOGuid: locality.AOGuid
    }));
  }
);

const getStructures = state => state.fias.structures;

export const getStructuresSelector = createSelector(
  [getStructures],
  structures => {
    if (!structures) return [];
    return structures.map(structure => ({
      value: structure.id,
      label: structure.formalNameCC,
      AOGuid: structure.AOGuid
    }));
  }
);

const getStreets = state => state.fias.streets;

export const getStreetsSelector = createSelector(
  [getStreets],
  streets => {
    if (!streets) return [];
    return streets.map(street => ({
      value: street.AOGuid,
      label: street.formalNameCC,
      AOGuid: street.AOGuid,
      formalName: street.formalName,
      streetType: street.streetType,
      postalCode: street.postalCode
    }));
  }
);

const getHouses = state => state.fias.houses;

export const getHousesSelector = createSelector(
  [getHouses],
  houses => {
    if (!houses) return [];
    return houses.map(house => ({
      value: house.formalNameCC,
      label: house.formalNameCC,
      housenum: house.housenum,
      buildnum: house.buildnum,
      strucnum: house.strucnum,
      postalCode: house.postalCode
    }));
  }
);
