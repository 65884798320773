import React from "react";
import moment from "moment";

import Button from "@material-ui/core/Button";
import AppBar from "@material-ui/core/AppBar";
import Typography from "@material-ui/core/Typography";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import TableHead from "@material-ui/core/TableHead";

import LoadingMessage from "components/messages/LoadingMessage";

import { withStyles } from "@material-ui/core/styles";

const styles = theme => ({
  appBar: {
    height: 48,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    padding: 5
  },
  historyContainer: {
    width: "calc(100% - 20px)",
    padding: 10
  },
  row: {
    height: 40
  },
  longCell: {
    padding: "4px 10px 4px 15px"
  },
  detailsTitle: {
    [theme.breakpoints.down("xs")]: {
      fontSize: 18
    }
  }
});

const TariffHistory = props => {
  const { classes, tariffHistory, history, cancelStepsClick } = props;

  const formatChangeDate = changeDate =>
    moment(changeDate, "YYYY-MM-DD HH:mm:ss").format("HH:mm:ss") === "00:00:00"
      ? moment(changeDate, "YYYY-MM-DD HH:mm:ss").format("DD.MM.YY")
      : moment(changeDate, "YYYY-MM-DD HH:mm:ss").format("DD.MM.YY HH:mm:ss");

  if (!tariffHistory) {
    return <LoadingMessage />;
  } else {
    return (
      <>
        <AppBar color="default" position="sticky" elevation={1} className={classes.appBar}>
          <Typography variant="subtitle2" color="inherit">
            {history.im} {history.className}, история изменения тарифов
          </Typography>
          <Button variant="outlined" size="small" onClick={e => cancelStepsClick()}>
            Закрыть
          </Button>
        </AppBar>
        <LoadingMessage />
        <div className={classes.historyContainer}>
          <Table padding="none">
            <TableHead>
              <TableRow>
                <TableCell>
                  <Typography variant="body1">Дата</Typography>
                </TableCell>
                <TableCell className={classes.longCell}>
                  <Typography variant="body1">Тариф</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="body1">Изменено</Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {tariffHistory.map((history, index) => (
                <TableRow key={index} className={classes.row}>
                  <TableCell>
                    <Typography variant="button">
                      {formatChangeDate(history.changeDate)}
                    </Typography>
                  </TableCell>
                  <TableCell className={classes.longCell}>
                    <Typography>{history.tariffName}</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography>{history.changeTypeName}</Typography>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>
      </>
    );
  }
};

export default withStyles(styles)(TariffHistory);
