import { createStore, applyMiddleware } from "redux";
import rootReducer from "../reducers/";
import thunk from "redux-thunk";
let logger;
if (process.env.NODE_ENV !== "production") {
  logger = require("redux-logger").logger;
}

export default function configureStore(initialState) {
  const store = createStore(
    rootReducer,
    initialState,
    process.env.NODE_ENV !== "production"
      ? applyMiddleware(thunk, logger)
      : applyMiddleware(thunk)
  );

  if (module.hot) {
    module.hot.accept("../", () => {
      const nextRootReducer = require("../reducers/");
      store.replaceReducer(nextRootReducer);
    });
  }

  return store;
}
