import React from "react";
import Paper from "@material-ui/core/Paper";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";

import TariffDescription from "./TariffDescription";
import LoadingMessage from "components/messages/LoadingMessage";
import { withStyles } from "@material-ui/core/styles";

const styles = theme => ({
  tariffsContainer: {
    padding: "0 5px",
    [theme.breakpoints.down("xs")]: {
      padding: 0
    }
  },
  card: {
    marginBottom: 1,
    [theme.breakpoints.down("xs")]: {
      margin: 0
    }
  },
  cardWrapper: {
    display: "flex",
    justifyContent: "space-between"
  },
  cardHeader: {
    paddingRight: 0
  },
  buttons: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center"
  },
  button: {
    margin: 5
  }
});

const CurrentTariffs = props => {
  const { classes, pupils, changeExpand, expanded } = props;

  return (
    <>
      <LoadingMessage />
      <Paper className={classes.tariffsContainer} square elevation={0}>
        {// добавляем данные для каждой вкладки ученика
        pupils &&
          pupils.ids.map(pupilId => (
            <Card className={classes.card} square elevation={1} key={pupilId}>
              <div className={classes.cardWrapper}>
                <CardHeader
                  className={classes.cardHeader}
                  title={
                    <Typography variant="h5">
                      {pupils[pupilId].im} {pupils[pupilId].className}
                    </Typography>
                  }
                  subheader={
                    <Typography variant="subtitle1">
                      {pupils[pupilId].tarifName}
                      <br />
                      {pupils[pupilId].tarifPrice} руб./мес.
                    </Typography>
                  }
                />
                <CardContent className={classes.buttons}>
                  <Button
                    className={classes.button}
                    variant="contained"
                    color="primary"
                    onClick={props.changeTariffClick(pupilId)}
                  >
                    Изменить
                  </Button>
                  <Button
                    className={classes.button}
                    variant="contained"
                    onClick={props.tariffHistoryClick(pupilId)}
                  >
                    История
                  </Button>
                </CardContent>
              </div>
            </Card>
          ))}
        <TariffDescription {...{ changeExpand, expanded }} />
      </Paper>
    </>
  );
};

export default withStyles(styles)(CurrentTariffs);
