import React, { Component } from "react";
import moment from "moment";

import Typography from "@material-ui/core/Typography";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpansionPanelActions from "@material-ui/core/ExpansionPanelActions";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import Button from "@material-ui/core/Button";

import { withStyles } from "@material-ui/core/styles";

const styles = theme => ({
  invoice: {
    border: "1px solid lightgrey",
    marginBottom: 3
  },
  monthTitle: {
    flexGrow: 1
  },
  invoiceDetails: {
    paddingBottom: 0
  },
  row: {
    height: 40
  }
});

class Invoice extends Component {
  handleDetailsClick = period => {
    this.props.changeMonth(period);
  };

  render() {
    const { classes, invoice, changeExpand, expandedInvoice } = this.props;

    return (
      <div className={classes.invoice}>
        <ExpansionPanel
          elevation={0}
          expanded={expandedInvoice === invoice.invoicesPeriod}
          defaultExpanded={expandedInvoice === invoice.invoicesPeriod}
          onChange={changeExpand(invoice.invoicesPeriod)}
        >
          <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
            {invoice.schetId !== null ? (
              <>
                <Typography variant="subtitle1" className={classes.monthTitle}>
                  {moment(invoice.invoicesPeriod, "YYYY-MM").format("MMMM YYYY")}
                </Typography>
                <Typography variant="subtitle1" color={invoice.outcome < 0 ? "error" : "secondary"}>
                  {invoice.outcome}
                </Typography>
              </>
            ) : (
              <Typography variant="subtitle1">Текущие операции</Typography>
            )}
          </ExpansionPanelSummary>
          <ExpansionPanelDetails className={classes.invoiceDetails}>
            <Table padding="none">
              <TableBody>
                {invoice.schetId !== null ? (
                  <TableRow className={classes.row}>
                    <TableCell>
                      <Typography>На начало</Typography>
                    </TableCell>
                    <TableCell numeric>
                      <Typography>{invoice.income ? invoice.income : "0.00"} руб.</Typography>
                    </TableCell>
                  </TableRow>
                ) : null}
                <TableRow className={classes.row}>
                  <TableCell>
                    <Typography>Списания</Typography>
                  </TableCell>
                  <TableCell numeric>
                    <Typography>{invoice.buys} руб.</Typography>
                  </TableCell>
                </TableRow>
                <TableRow className={classes.row}>
                  <TableCell>
                    <Typography>Поступления</Typography>
                  </TableCell>
                  <TableCell numeric>
                    <Typography>{invoice.pays} руб.</Typography>
                  </TableCell>
                </TableRow>
                {invoice.schetId !== null ? (
                  <TableRow className={classes.row}>
                    <TableCell>
                      <Typography>На окончание</Typography>
                    </TableCell>
                    <TableCell numeric>
                      <Typography>{invoice.outcome} руб.</Typography>
                    </TableCell>
                  </TableRow>
                ) : null}
              </TableBody>
            </Table>
          </ExpansionPanelDetails>
          <ExpansionPanelActions>
            <Button
              color="primary"
              variant="contained"
              onClick={e => this.handleDetailsClick(invoice.invoicesPeriod)}
            >
              Детализация
            </Button>
          </ExpansionPanelActions>
        </ExpansionPanel>
      </div>
    );
  }
}

export default withStyles(styles)(Invoice);
