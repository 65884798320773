import { handleActions } from "redux-actions";
import {
  currentTariffSuccess,
  availableTariffsSuccess,
  tariffPriceRequest,
  tariffPriceSuccess,
  tariffPriceError,
  tariffHistorySuccess,
  getPayUrlReferralRequest,
  getPayUrlReferralSuccess,
  getPayUrlReferralClear
} from "actions/tariffsActions";

const initialState = {};

const tariffs = handleActions(
  {
    [currentTariffSuccess]: (state, action) => ({
      ...state,
      current: action.payload.current
    }),
    [availableTariffsSuccess]: (state, action) => ({
      ...state,
      available: action.payload.available
    }),
    [tariffPriceRequest]: state => ({
      ...state,
      isLocalLoading: true
    }),
    [tariffPriceSuccess]: (state, action) => ({
      ...state,
      isLocalLoading: false,
      selectedTariffProps: action.payload.selectedTariffProps
    }),
    [tariffPriceError]: state => ({
      ...state,
      isLocalLoading: false
    }),
    [tariffHistorySuccess]: (state, action) => ({
      ...state,
      tariffHistory: action.payload.tariffHistory
    }),
    [getPayUrlReferralClear]: (state) => ({
      ...state,
      referralPayUrl: undefined
    }),
    [getPayUrlReferralRequest]: state => ({
      ...state,
      isLocalLoading: true
    }),
    [getPayUrlReferralSuccess]: (state, action) => ({
      ...state,
      isLocalLoading: false,
      referralPayUrl: action.payload.payUrlReferral
    })
  },
  initialState
);

export default tariffs;
